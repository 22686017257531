import React, { ReactNode, ComponentProps } from 'react';
import { View, Text } from '@react-pdf/renderer';

type TableElementProps = {
  children?: ReactNode;
  style?: ComponentProps<typeof View>['style'];
};

export function TableCell({ children, style }: TableElementProps) {
  return (
    <View style={{ flex: 1, paddingLeft: '5px', paddingRight: '5px', height: '15px', ...style }}>
      <Text>{children}</Text>
    </View>
  );
}

export function Table({ children, style }: TableElementProps) {
  return <View style={style}>{children}</View>;
}

export function TableHeader({ children, style }: TableElementProps) {
  return (
    <TableRow key="header" style={{ borderTop: '1pt solid black', ...style }}>
      {children}
    </TableRow>
  );
}
export function TableBody({ children, style }: TableElementProps) {
  return <View style={style}>{children}</View>;
}

export function TableRow({ children, style }: TableElementProps) {
  const rowCells = React.Children.toArray(children);

  return (
    <View
      style={{
        width: '100%',
        height: '15px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderBottom: '1pt solid black',
        borderRight: '1pt solid black',
        borderLeft: '1pt solid black',
        ...style,
      }}
    >
      {rowCells.map((cell, index) => (
        <View style={{ flex: 1, borderLeft: index > 0 ? '1pt solid black' : '' }}>{cell}</View>
      ))}
    </View>
  );
}

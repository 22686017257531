import { resetStores } from '@datorama/akita';

import { AuthApi } from 'api/auth/auth-api';
import { ManagersApi } from 'api/app/api/managers-api';
import { AuthApi as ZsgAuthApi } from 'api/app/api/auth-api';
import { CompaniesApi } from 'api/app/api/companies-api';
import { formService } from '../forms';
import { UserForms } from 'state/users/user.forms';
import { SessionStore, sessionStore } from './session.store';
import { IAuthToken, mapAuthState, UserInfo } from './session.models';
import { CompanyModel, ManagerModel } from 'api/app';

export class SessionService {
  constructor(
    private readonly store: SessionStore,
    private readonly authApi: AuthApi,
    private readonly managersApi: ManagersApi,
    private readonly kvcoreAuthApi: ZsgAuthApi,
    private readonly companiesApi: CompaniesApi
  ) {}

  async kvCoreLogin(token: string, staySignedIn: boolean = true) {
    const response = await this.authApi.kvCoreLogin(token);
    const auth = mapAuthState(response.data);

    this.store.update({
      staySignedIn,
      auth,
    });

    if (auth.authenticated) {
      this.refreshUserInfo(auth.token);
    }

    return auth.authenticated;
  }

  async login(username: string, password: string, staySignedIn: boolean) {
    try {
      const response = await this.authApi.login(username, password);
      const auth = mapAuthState(response.data);

      this.store.update({
        staySignedIn,
        auth,
      });

      if (auth.authenticated) {
        await this.refreshUserInfo(auth.token);
      }
    } catch {
      this.store.reset();
      formService.setError(UserForms.Login, new Error('Invalid Login'));
    }
  }

  async logout(token: string, refreshToken: string | null | undefined) {
    try {
      if (token === "") throw new Error("No access token provided");
      const response = await this.authApi.logout(token, refreshToken);
      const auth = mapAuthState(response.data);

      this.store.update({
        auth
      });

      if (auth.authenticated) {
        await this.refreshUserInfo(auth.token);
        window.location.href = "/company";
      } else {
        resetStores();
      }
    }
    catch {
      resetStores();
    }
  }

  async refreshToken(token: IAuthToken) {
    if (token.refreshToken == null) throw new Error('No refresh token provided');

    try {
      const response = await this.authApi.refreshToken(token.refreshToken, token.accessToken);
      const auth = mapAuthState(response.data);
      this.store.update({
        auth,
      });

      if (auth.authenticated) {
        await this.refreshUserInfo(auth.token);
      }
    } catch {
      this.store.reset();
    }
  }

  async impersonate(id: string) {
    try {
      const response = await this.authApi.impersonate(id);
      const auth = mapAuthState(response.data);

      this.store.update({
        auth
      });

      if (auth.authenticated) {
        await this.refreshUserInfo(auth.token);
        window.location.href = '/company';
      }
    } catch (error) {
      console.log(error);
    }
  }

  private async refreshUserInfo({ accessToken }: IAuthToken) {
    const user = await this.authApi.userInfo(accessToken);
    const isActiveUser = await this.getUserActiveStatus(user.data);
    const isKvCoreUser = await this.isKvCore();
    const companyData = await this.getUserCompany();

    this.store.update({
      user: user.data,
      isActiveUser,
      isKvCoreUser,
      companyData,
    });
  }

  private async getUserActiveStatus(user: UserInfo): Promise<boolean> {
    if (!!user.role && user.role !== '') return true;

    const isActiveUser = await this.managersApi.managersExistsPost({
      email: user.preferred_username,
    });
    return isActiveUser.data.exists ?? false;
  }

  private async isKvCore() {
    const response = await this.kvcoreAuthApi.authAuthKvcoresyncGet();

    return response.data ?? false;
  }

  private async getUserCompany(): Promise<CompanyModel> {
    const response = await this.companiesApi.companiesUserCompanyGet();

    return response.data.data;
  }

  public async refreshCompanyData(): Promise<void> {
    const companyData = await this.getUserCompany();
    this.store.update({
      companyData
    });
  }
}

export const sessionService = new SessionService(
  sessionStore,
  new AuthApi(),
  new ManagersApi(),
  new ZsgAuthApi(),
  new CompaniesApi()
);

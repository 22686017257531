import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { Zsg } from '../../../api';
import { dispatchForm } from '../../forms';
import { EditCompanyForm } from './companies.models';
import { CompanyForms } from './companies.forms';
import { CompaniesStore, companiesStore } from './companies.store';
import { applyTransaction, setLoading } from '@datorama/akita';
import { appofficesService } from '../appoffices';

export class CompaniesService {
  constructor(
    private readonly store: CompaniesStore,
    private readonly companiesApi: Zsg.CompaniesApi
  ) {}

  createCompany(request: EditCompanyForm) {
    from(this.companiesApi.companiesPost(request))
      .pipe(
        map(x => x.data.data),
        dispatchForm(CompanyForms.Create)
      )
      .subscribe(company =>
        applyTransaction(() => {
          this.store.upsert(company.id, company);
        })
      );
  }

  getCompany(id: number) {
    from(this.companiesApi.companiesIdGet(id))
      .pipe(
        map(x => x.data.data),
        setLoading(this.store)
      )
      .subscribe(company =>
        applyTransaction(() => {
          this.store.setActive(company.id);
          this.store.upsert(company.id, company);
        })
      );
  }

  updateCompany(id: number, request: EditCompanyForm) {
    from(this.companiesApi.companiesIdPost(id, request))
      .pipe(
        map(x => x.data),
        dispatchForm(CompanyForms.Edit)
      )
      .subscribe(({ data, offices }) =>
        applyTransaction(() => {
          appofficesService.updateCompanyOffices(offices);
          this.store.upsert(data.id, data);
        })
      );
  }

  getCompanies(filterValue?: string) {
    from(this.companiesApi.companiesGet(filterValue))
      .pipe(map(x => x.data.data))
      .subscribe(companies => {
        applyTransaction(() => {
          this.store.remove();
          this.store.upsertMany(companies);
        })
      });
  }

  clearActive() {
    this.store.setActive(null);
  }
}

export const companiesService = new CompaniesService(companiesStore, new Zsg.CompaniesApi());

import React from 'react';
import { Card, CardHeader, CardContent, Grid } from '@mui/material';
import { NumberFormatters, ThemeColors } from '../../shared';
import { MarketRegion, MarketShare } from '../../state';
import { MarketShare as MarketShareDisplay } from './market-share';

interface Props {
  region: MarketRegion;
  office: MarketShare;
  market: MarketShare;
}

export const CompanyMarketShare: React.FC<Props> = ({ region, office, market }) => {
  return (
    <Card sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }} elevation={0}>
      <CardHeader
        title={
          'Market Share: ' + (region.cities?.length ? region.cities : region.boards).join(', ')
        }
      />
      <CardContent>
        <Grid item container spacing={2}>
          <Grid item xs={12} sm={12} style={{ marginBottom: '1rem' }}>
            <MarketShareDisplay
              label={'Active Listings'}
              chartColor={ThemeColors.Purple}
              company={office.activeListings}
              market={market.activeListings}
            />
          </Grid>

          <Grid item xs={12} sm={12} style={{ marginBottom: '1rem' }}>
            <MarketShareDisplay
              label={'Volume'}
              chartColor={ThemeColors.DarkTeal}
              company={office.volume}
              market={market.volume}
              formatter={NumberFormatters.currency}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

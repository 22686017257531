import React from 'react';

interface State {
  error: Error | null;
}

export function asErrorBoundry<P>(Component: React.ComponentType<P>) {
  return class ErrorBoundry extends React.PureComponent<P, State> {
    constructor(props: P) {
      super(props);

      this.state = { error: null };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
      console.error(error);
      console.error(errorInfo.componentStack);
    }

    render() {
      return <Component {...this.props} />;
    }

    static getDerivedStateFromError(error: Error) {
      return { error };
    }
  };
}

/* eslint-disable */
// tslint:disable
/**
 * MLS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';

import { AgentVolumeSummaryResponse } from '../model';
import { GetAgentResponse } from '../model';
import { GetAgentVolumeRequest } from '../model';
import { GetAgentVolumeResponse } from '../model';
import { GetAgentVolumeSummaryRequest } from '../model';
import { GetAgentsRequest } from '../model';
import { GetAgentsResponse } from '../model';
import { UpdateAgentRequest } from '../model';
/**
 * AgentsApi - axios parameter creator
 * @export
 */
export const AgentsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} memberKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsAgentsMemberKeyGet(memberKey: string, options: any = {}): RequestArgs {
      // verify required parameter 'memberKey' is not null or undefined
      if (memberKey === null || memberKey === undefined) {
        throw new RequiredError(
          'memberKey',
          'Required parameter memberKey was null or undefined when calling mlsAgentsMemberKeyGet.'
        );
      }
      const localVarPath = `/mls/agents/{MemberKey}`.replace(
        `{${'MemberKey'}}`,
        encodeURIComponent(String(memberKey))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      //@ts-ignore
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {GetAgentsRequest} [getAgentsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsAgentsPost(getAgentsRequest?: GetAgentsRequest, options: any = {}): RequestArgs {
      const localVarPath = `/mls/agents`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      //@ts-ignore
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof getAgentsRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(getAgentsRequest !== undefined ? getAgentsRequest : {})
        : getAgentsRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {GetAgentVolumeSummaryRequest} [getAgentVolumeSummaryRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsAgentsSummaryPost(
      getAgentVolumeSummaryRequest?: GetAgentVolumeSummaryRequest,
      options: any = {}
    ): RequestArgs {
      const localVarPath = `/mls/agents/summary`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      //@ts-ignore
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof getAgentVolumeSummaryRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(
            getAgentVolumeSummaryRequest !== undefined ? getAgentVolumeSummaryRequest : {}
          )
        : getAgentVolumeSummaryRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {UpdateAgentRequest} [updateAgentRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsAgentsUpdatePost(updateAgentRequest?: UpdateAgentRequest, options: any = {}): RequestArgs {
      const localVarPath = `/mls/agents/update`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      //@ts-ignore
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof updateAgentRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(updateAgentRequest !== undefined ? updateAgentRequest : {})
        : updateAgentRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {GetAgentVolumeRequest} [getAgentVolumeRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsAgentsVolumePost(
      getAgentVolumeRequest?: GetAgentVolumeRequest,
      options: any = {}
    ): RequestArgs {
      const localVarPath = `/mls/agents/volume`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      //@ts-ignore
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof getAgentVolumeRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(getAgentVolumeRequest !== undefined ? getAgentVolumeRequest : {})
        : getAgentVolumeRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AgentsApi - functional programming interface
 * @export
 */
export const AgentsApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} memberKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsAgentsMemberKeyGet(
      memberKey: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAgentResponse> {
      const localVarAxiosArgs = AgentsApiAxiosParamCreator(configuration).mlsAgentsMemberKeyGet(
        memberKey,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {GetAgentsRequest} [getAgentsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsAgentsPost(
      getAgentsRequest?: GetAgentsRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAgentsResponse> {
      const localVarAxiosArgs = AgentsApiAxiosParamCreator(configuration).mlsAgentsPost(
        getAgentsRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {GetAgentVolumeSummaryRequest} [getAgentVolumeSummaryRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsAgentsSummaryPost(
      getAgentVolumeSummaryRequest?: GetAgentVolumeSummaryRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentVolumeSummaryResponse> {
      const localVarAxiosArgs = AgentsApiAxiosParamCreator(configuration).mlsAgentsSummaryPost(
        getAgentVolumeSummaryRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {UpdateAgentRequest} [updateAgentRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsAgentsUpdatePost(
      updateAgentRequest?: UpdateAgentRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = AgentsApiAxiosParamCreator(configuration).mlsAgentsUpdatePost(
        updateAgentRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {GetAgentVolumeRequest} [getAgentVolumeRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsAgentsVolumePost(
      getAgentVolumeRequest?: GetAgentVolumeRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAgentVolumeResponse> {
      const localVarAxiosArgs = AgentsApiAxiosParamCreator(configuration).mlsAgentsVolumePost(
        getAgentVolumeRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * AgentsApi - factory interface
 * @export
 */
export const AgentsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {string} memberKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsAgentsMemberKeyGet(memberKey: string, options?: any): AxiosPromise<GetAgentResponse> {
      return AgentsApiFp(configuration).mlsAgentsMemberKeyGet(memberKey, options)(axios, basePath);
    },
    /**
     *
     * @param {GetAgentsRequest} [getAgentsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsAgentsPost(
      getAgentsRequest?: GetAgentsRequest,
      options?: any
    ): AxiosPromise<GetAgentsResponse> {
      return AgentsApiFp(configuration).mlsAgentsPost(getAgentsRequest, options)(axios, basePath);
    },
    /**
     *
     * @param {GetAgentVolumeSummaryRequest} [getAgentVolumeSummaryRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsAgentsSummaryPost(
      getAgentVolumeSummaryRequest?: GetAgentVolumeSummaryRequest,
      options?: any
    ): AxiosPromise<AgentVolumeSummaryResponse> {
      return AgentsApiFp(configuration).mlsAgentsSummaryPost(getAgentVolumeSummaryRequest, options)(
        axios,
        basePath
      );
    },
    /**
     *
     * @param {UpdateAgentRequest} [updateAgentRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsAgentsUpdatePost(
      updateAgentRequest?: UpdateAgentRequest,
      options?: any
    ): AxiosPromise<void> {
      return AgentsApiFp(configuration).mlsAgentsUpdatePost(updateAgentRequest, options)(
        axios,
        basePath
      );
    },
    /**
     *
     * @param {GetAgentVolumeRequest} [getAgentVolumeRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsAgentsVolumePost(
      getAgentVolumeRequest?: GetAgentVolumeRequest,
      options?: any
    ): AxiosPromise<GetAgentVolumeResponse> {
      return AgentsApiFp(configuration).mlsAgentsVolumePost(getAgentVolumeRequest, options)(
        axios,
        basePath
      );
    },
  };
};

/**
 * AgentsApi - object-oriented interface
 * @export
 * @class AgentsApi
 * @extends {BaseAPI}
 */
export class AgentsApi extends BaseAPI {
  /**
   *
   * @param {string} memberKey
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AgentsApi
   */
  public mlsAgentsMemberKeyGet(memberKey: string, options?: any) {
    return AgentsApiFp(this.configuration).mlsAgentsMemberKeyGet(memberKey, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {GetAgentsRequest} [getAgentsRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AgentsApi
   */
  public mlsAgentsPost(getAgentsRequest?: GetAgentsRequest, options?: any) {
    return AgentsApiFp(this.configuration).mlsAgentsPost(getAgentsRequest, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {GetAgentVolumeSummaryRequest} [getAgentVolumeSummaryRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AgentsApi
   */
  public mlsAgentsSummaryPost(
    getAgentVolumeSummaryRequest?: GetAgentVolumeSummaryRequest,
    options?: any
  ) {
    return AgentsApiFp(this.configuration).mlsAgentsSummaryPost(
      getAgentVolumeSummaryRequest,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {UpdateAgentRequest} [updateAgentRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AgentsApi
   */
  public mlsAgentsUpdatePost(updateAgentRequest?: UpdateAgentRequest, options?: any) {
    return AgentsApiFp(this.configuration).mlsAgentsUpdatePost(updateAgentRequest, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {GetAgentVolumeRequest} [getAgentVolumeRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AgentsApi
   */
  public mlsAgentsVolumePost(getAgentVolumeRequest?: GetAgentVolumeRequest, options?: any) {
    return AgentsApiFp(this.configuration).mlsAgentsVolumePost(getAgentVolumeRequest, options)(
      this.axios,
      this.basePath
    );
  }
}

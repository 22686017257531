import React, { ComponentProps, useRef, useState } from 'react';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers';
import { TextField } from '../textfield';

interface DatePickerProps {
  value?: Date | string | null;
  onChange: (value?: Date) => void;
  readOnly?: boolean;
  error?: boolean;
  helperText?: string;
}

export const Datepicker: React.FC<
  DatePickerProps & Omit<ComponentProps<typeof MuiDatePicker>, 'onChange' | 'value'>
> = ({ value, onChange, readOnly, error, helperText, ...props }) => {
  const [internalError, setInternalError] = useState('')
  const safeOnChange = (dateString?: string | null) => {
    if (dateString && Date.parse(dateString)) {
      onChange(new Date(dateString));
      setInternalError('');
      return;
    }

    setInternalError('Invalid date')
  };

  return (
    <MuiDatePicker
      {...props}
      value={parseDate(value)}
      disabled={readOnly}
      onChange={value => safeOnChange(value as string)}
      slots={{
        textField: TextfieldWithNoAutoDateSelection,
      }}
      slotProps={{
        textField: {
          error: error || !!internalError,
          helperText: helperText || internalError,
        },
      }}
    />
  );
};

function TextfieldWithNoAutoDateSelection(props: any) {
  const { ref, ...rest } = props;
  const textRef = useRef<HTMLInputElement>();

  return (
    <TextField
      {...rest}
      inputRef={textRef}
      onClick={() => {}}
      onFocus={() => {}}
      onMouseDown={() => {
        if (textRef.current) {
          textRef.current.setSelectionRange(0, 0);
        }
      }}
      sx={{
        '& .MuiInputBase-root': {
          height: '40px',
        },
        '& .MuiInputLabel-outlined': {
          transform: 'translate(14px, 12px) scale(1)',
          '&.MuiInputLabel-shrink': {
            transform: 'translate(14px, -6px) scale(0.75)',
          },
        },
        '& .MuiAutocomplete-inputRoot': {
          paddingTop: '4px !important',
          paddingBottom: '4px !important',
        },
        '& .MuiChip-root': {
          height: '24px',
        },
      }}
    />
  );
}

function parseDate(date?: Date | string | null) {
  if (date instanceof Date) {
    return date;
  }

  if (typeof date === 'string') {
    return new Date(date);
  }

  return undefined;
}

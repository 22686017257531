import { identity } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { EntityState, EntityStore, StoreConfig, QueryEntity, ActiveState } from '@datorama/akita';

import { IgnoreCaseMap, distinctUntilArrayChanged } from '../util';
import { Agent } from '../mls.models';

export interface AgentsState
  extends EntityState<Agent, Agent['memberKey']>,
    ActiveState<Agent['memberKey']> {
  ui: {
    searchTerm?: string;
  };
}

@StoreConfig({ name: 'agents', resettable: true, idKey: 'memberKey', deepFreezeFn: identity })
export class AgentsStore extends EntityStore<AgentsState> {
  static instance: AgentsStore;

  constructor() {
    super({
      active: null,
      ui: {},

      //Akita already initializes the store with loading set to true.
      //we need it to be false to drive our progress indicators
      //which rely on this flag starting off as false
      //https://github.com/datorama/akita/issues/22#issuecomment-408659859
      loading: false,
    });
  }
}

export class AgentsQuery extends QueryEntity<AgentsState> {
  static instance: AgentsQuery;

  lookup = this.selectAll().pipe(
    distinctUntilArrayChanged(),
    map(agents => new IgnoreCaseMap<Agent>(agents.map(x => [x.memberKey, x]))),
    shareReplay(1)
  );

  searchTerm = this.select(s => s.ui.searchTerm);
  searchFilter = this.searchTerm.pipe(
    map(searchTerm => (agent: Agent) => !searchTerm || isMatch(agent, searchTerm))
  );
}

AgentsStore.instance = new AgentsStore();
AgentsQuery.instance = new AgentsQuery(AgentsStore.instance);

const isMatch = (agent: Agent, searchTerm: string) => {
  const tokens = [agent.fullName, agent.memberNumber ?? ''].map(t => t.toLowerCase());
  const term = searchTerm.toLowerCase();

  return tokens.some(t => t.indexOf(term) >= 0);
};

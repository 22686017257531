import { identity } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  EntityState,
  EntityStore,
  StoreConfig,
  QueryEntity,
  MultiActiveState,
} from '@datorama/akita';
import { AgentVolume } from '../mls.models';

export interface AgentVolumeState
  extends EntityState<AgentVolume, AgentVolume['memberKey']>,
    MultiActiveState {}

@StoreConfig({
  name: 'agentVolume',
  resettable: true,
  idKey: 'memberKey',
  deepFreezeFn: identity,
})
export class AgentVolumeStore extends EntityStore<AgentVolumeState> {
  static instance: AgentVolumeStore;

  constructor() {
    super({
      active: [],
      //Akita already initializes the store with loading set to true.
      //we need it to be false to drive our progress indicators
      //which rely on this flag starting off as false
      //https://github.com/datorama/akita/issues/22#issuecomment-408659859
      loading: false,
    });
  }
}

export class AgentVolumeQuery extends QueryEntity<AgentVolumeState> {
  static instance: AgentVolumeQuery;

  activeKeys = this.selectActiveId().pipe(map(ids => ids as string[]));
}

AgentVolumeStore.instance = new AgentVolumeStore();
AgentVolumeQuery.instance = new AgentVolumeQuery(AgentVolumeStore.instance);

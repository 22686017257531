import React from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useTheme } from 'theme/ThemeContext';
import { Typography } from '../typography';

const dotSize = 10;

const useLoadingIndicatorCSS = makeStyles(theme => {
  const dotPadding = dotSize / 4;

  const dotLeftPosition = dotSize + dotPadding;
  const containerWidth = dotLeftPosition * 4;
  const containerHeight = dotSize * 3;

  return createStyles({
    loadingIndicatorContainer: {
      animation: `$fadeIn .6s`,
    },
    loadingIndicatorContainerFilled: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    dotsContainer: {
      position: 'relative',
      zIndex: 100,
      margin: `${dotSize * 2}px auto`,
      width: `${containerWidth}px`,
      height: `${containerHeight}px`,
    },
    dotItem: {
      display: 'block',
      position: 'absolute',
      width: `${dotSize}px`,
      height: `${dotSize}px`,
      borderRadius: `${dotSize}px`,
      top: '0',
      transform: 'rotate(-45deg)',
      transformOrigin: 'top',
      animationTimingFunction: 'linear',
    },
    animationA: {
      left: '0',
      animation: `$dotAnimationA 1.5s infinite ease`,
    },
    animationB: {
      left: `${dotLeftPosition}px`,
      animation: `$dotAnimationB 1.5s infinite ease`,
    },
    animationC: {
      left: `${dotLeftPosition * 2}px`,
      animation: `$dotAnimationC 1.5s infinite ease`,
    },
    animationD: {
      left: `${dotLeftPosition * 3}px`,
      animation: `$dotAnimationD 1.5s infinite ease`,
    },
    '@keyframes fadeIn': {
      from: {
        opacity: 0,
      },
      to: {
        opacity: 1,
      },
    },
    '@keyframes dotAnimationA': {
      '0%': {
        top: '0px',
      },
      '25%': {
        top: `${containerHeight}px`,
      },
      '50%': {
        top: '0px',
      },
      '75%': {
        top: `${containerHeight}px`,
      },
      '100%': {
        top: '0px',
      },
    },
    '@keyframes dotAnimationB': {
      '0%': {
        top: `${containerHeight / 2}px`,
      },
      '12.5%': {
        top: `${containerHeight}px`,
      },
      '37.5%': {
        top: '0px',
      },
      '62.5%': {
        top: `${containerHeight}px`,
      },
      '87.5%': {
        top: '0px',
      },
      '100%': {
        top: `${containerHeight / 2}px`,
      },
    },
    '@keyframes dotAnimationC': {
      '0%': {
        top: `${containerHeight}px`,
      },
      '25%': {
        top: '0px',
      },
      '50%': {
        top: `${containerHeight}px`,
      },
      '75%': {
        top: '0px',
      },
      '100%': {
        top: `${containerHeight}px`,
      },
    },
    '@keyframes dotAnimationD': {
      '0%': {
        top: `${containerHeight / 2}px`,
      },
      '12.5%': {
        top: '0px',
      },
      '37.5%': {
        top: `${containerHeight}px`,
      },
      '62.5%': {
        top: '0px',
      },
      '87.5%': {
        top: `${containerHeight}px`,
      },
      '100%': {
        top: `${containerHeight / 2}px`,
      },
    },
  });
});

interface Props {
  loadingText: string;
  fillContainer?: boolean;
}

export const LoadingIndicator: React.FC<Props> = ({ loadingText, fillContainer }) => {
  const styles = useLoadingIndicatorCSS();
  const theme = useTheme();
  const containerStyles = fillContainer
    ? clsx(styles.loadingIndicatorContainer, styles.loadingIndicatorContainerFilled)
    : styles.loadingIndicatorContainer;

  return (
    <div className={containerStyles}>
      <div className={styles.dotsContainer}>
        <div
          className={clsx(styles.dotItem, styles.animationA)}
          style={{ backgroundColor: theme.loadingIndicator.color }}
        />
        <div
          className={clsx(styles.dotItem, styles.animationB)}
          style={{ backgroundColor: theme.loadingIndicator.color }}
        />
        <div
          className={clsx(styles.dotItem, styles.animationC)}
          style={{ backgroundColor: theme.loadingIndicator.color }}
        />
        <div
          className={clsx(styles.dotItem, styles.animationD)}
          style={{ backgroundColor: theme.loadingIndicator.color }}
        />
      </div>
      <Typography variant="h4" align="center">
        {loadingText}
      </Typography>
    </div>
  );
};

import { EntityState, EntityStore, StoreConfig, ActiveState } from '@datorama/akita';

import { AppOffice } from './appoffices.models';

export interface AppofficesState
  extends EntityState<AppOffice, AppOffice['officeId']>,
    ActiveState<AppOffice['officeId']> {
  ui: { companyId?: number };
}

@StoreConfig({ name: 'appoffices', resettable: true, idKey: 'officeId' })
export class AppofficesStore extends EntityStore<AppofficesState> {
  constructor() {
    super({ ui: { companyId: undefined } });
  }
}

export const appofficesStore = new AppofficesStore();

import themeService from 'theme/ThemeService';

const palette = themeService.getPalette();

export const theme = {
  buttonLink: {
    primary: {
      backgroundColor: palette.primary,
      textColor: palette.neutralLightest,
      disabled: {
        backgroundColor: palette.primaryDark,
        textColor: palette.neutral,
      },
    },
    secondary: {
      backgroundColor: palette.neutralLightest,
      textColor: palette.neutralDark,
      borderColor: palette.neutral,
      disabled: {
        backgroundColor: palette.neutral,
        textColor: palette.neutralLightest,
      },
    },
    subtle: {
      backgroundColor: 'transparent',
      textColor: palette.neutralDark,
      disabled: {
        backgroundColor: palette.neutral,
        textColor: palette.neutralLightest,
      },
    },
  },
};

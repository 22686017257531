import { useTheme } from 'theme/ThemeContext';
import { Room } from '@mui/icons-material';

import { CaptionedValue } from '..';
import { Block } from 'shared/components/block';
import { NumberFormatters } from '../../utils';

export type MapStatsColor = 'teal' | 'yellow' | 'purple' | 'red';

interface MapStatProps {
  title: string;
  listings: number;
  color: MapStatsColor;
}

export function MapStat({ title, listings, color }: MapStatProps) {
  const theme = useTheme();

  return (
    <Block row>
      <Room style={{ color: theme.mapStat.colors[color], marginRight: '.5rem' }} fontSize="large" />
      <CaptionedValue
        value={NumberFormatters.decimal`${listings}`}
        color={theme.mapStat.colors[color]}
        size="175%"
        align="left"
      >
        {title}
      </CaptionedValue>
    </Block>
  );
}

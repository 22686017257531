import { EntityState, EntityStore, StoreConfig, ActiveState } from '@datorama/akita';
import {
  DefaultQuestions,
  QuestionType,
  Survey,
  SurveyAnswer,
  SurveyQuestion,
  SurveySubmission,
  SurveyEmailTemplate,
} from './surveys.models';

export interface SurveysState
  extends EntityState<Survey, Survey['id']>,
    ActiveState<Survey['id']> {}

@StoreConfig({ name: 'surveys', resettable: true })
export class SurveysStore extends EntityStore<SurveysState> {
  constructor() {
    super({ active: null });
  }
}

export interface QuestionsState extends EntityState<SurveyQuestion, SurveyQuestion['id']> {}

@StoreConfig({ name: 'questions', resettable: true })
export class QuestionsStore extends EntityStore<QuestionsState> {}

export interface SurveySubmissionState
  extends EntityState<SurveySubmission, SurveySubmission['id']>,
    ActiveState<SurveySubmission['id']> {}

@StoreConfig({ name: 'submissions', resettable: true })
export class SubmissionsStore extends EntityStore<SurveySubmissionState> {
  constructor() {
    super({ active: null });
  }
}

export interface AnswersState extends EntityState<SurveyAnswer, SurveyAnswer['id']> {}

@StoreConfig({ name: 'answers', resettable: true })
export class AnswersStore extends EntityStore<AnswersState> {}

export interface QuestionTypesState extends EntityState<QuestionType, QuestionType['id']> {}

@StoreConfig({ name: 'questionTypes', resettable: true })
export class QuestionTypesStore extends EntityStore<QuestionTypesState> {}

export interface DefaultQuestionsState
  extends EntityState<DefaultQuestions, DefaultQuestions['id']> {}

@StoreConfig({ name: 'defaultQuestions', resettable: true })
export class DefaultQuestionsStore extends EntityStore<DefaultQuestionsState> {}

export interface SurveyEmailTemplateState
  extends EntityState<SurveyEmailTemplate, SurveyEmailTemplate['subject']> {}

@StoreConfig({ name: 'surveyEmailTemplate', resettable: true })
export class SurveyEmailTemplateStore extends EntityStore<SurveyEmailTemplateState> {}

export const surveysStore = new SurveysStore();
export const questionsStore = new QuestionsStore();
export const submissionsStore = new SubmissionsStore();
export const answersStore = new AnswersStore();
export const questionTypesStore = new QuestionTypesStore();
export const defaultQuestionsStore = new DefaultQuestionsStore();
export const surveyEmailTemplateStore = new SurveyEmailTemplateStore();

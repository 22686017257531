import React, { PureComponent } from 'react';

const raf = requestAnimationFrame;

export function deferred<P>(WrappedComponent: React.ComponentType<P>) {
  class DeferredRenderWrapper extends PureComponent<P> {
    handle = 0;
    state = { shouldRender: false };

    componentDidMount() {
      this.handle = raf(() => (this.handle = raf(() => this.setState({ shouldRender: true }))));
    }

    componentWillUnmount() {
      if (this.handle) {
        cancelAnimationFrame(this.handle);
      }
    }

    render() {
      return this.state.shouldRender ? <WrappedComponent {...this.props} /> : null;
    }
  }

  return DeferredRenderWrapper;
}

import { FormStore, formStore } from './form.store';
import { FormStatus, FormEvent } from './form.models';
import { trace } from '../util/trace';

export class FormService {
  constructor(private readonly formStore: FormStore) {}

  setPending(formId: string) {
    trace('Form Pending:', formId);

    this.formStore.upsert(formId, {
      formId,
      status: FormStatus.Pending,
      submitted: true,
      error: undefined,
      response: undefined,
    } as FormEvent);
  }

  setSuccess(formId: string, response?: any) {
    trace('Form Success:', formId);

    this.formStore.upsert(formId, {
      formId,
      status: FormStatus.Success,
      submitted: true,
      error: undefined,
      response,
    } as FormEvent);
  }

  setError(formId: string, error: Error) {
    trace('Form Error:', formId, error);

    this.formStore.upsert(formId, {
      formId,
      error,
      status: FormStatus.Error,
      submitted: true,
      response: undefined,
    } as FormEvent);
  }

  resetForm(formId: string) {
    trace('Form Reset:', formId);

    this.formStore.upsert(formId, {
      formId,
      status: FormStatus.Initial,
      submitted: false,
      error: undefined,
      response: undefined,
    } as FormEvent);
  }
}

export const formService = new FormService(formStore);

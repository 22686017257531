import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography } from 'shared/components/typography';
import { Alert } from 'shared/components/alert';

export const ErrorDisplay: React.FC<{ error?: Error; errorMessage?: string }> = ({
  error,
  errorMessage,
}) => {
  if (!error) return null;

  return (
    <Alert variant="error">
      <Typography>
        <FormattedMessage
          id={errorMessage || error.name}
          defaultMessage={errorMessage || error.message}
        />
      </Typography>
    </Alert>
  );
};

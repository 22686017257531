import { InputBase } from '@mui/material';
import { withStyles, createStyles } from '@mui/styles';

export const PickerInput = withStyles(theme =>
  createStyles({
    root: {
      fontSize: '0.9375em',
      minWidth: '12em',
    },
    input: {
      fontWeight: 'bold',
      display: 'flex',
      alignItems: 'center',
      height: '2.5rem',
      padding: '0 0 0 12px',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.common.white,
      boxShadow: theme.shadows[1],
      '&:focus': {
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.common.white,
      },
    },
  })
)(InputBase);

import React from 'react';
import { Container, Grid } from '@mui/material';
import { Button, Typography, TextField } from 'shared/components';
import { ButtonLink } from 'shared/components/button-link';
import { ResetPasswordForm } from '../../state/users';
import { ErrorDisplay, FormProps, required, useForm, useInput } from '../../shared/forms';
import { SuccessDisplay } from '../../shared/forms/SuccessDisplay';
import { PasswordRulesHint } from '../../shared/components/password-rules-hint';
import LogoImage from '../../assets/BT-Recruit-Horizontal-Dark.png';

export const RecoverPasswordForm: React.FC<FormProps<ResetPasswordForm>> = ({
  error,
  onSubmit,
  onResetFeedback,
  success,
}) => {
  const newPassword = useInput('', { validators: [required()] });

  const form = useForm(() => {
    if (!form.valid) {
      newPassword.setTouched(true);
      return;
    }

    onResetFeedback();
    onSubmit({
      newPassword: newPassword.value,
    });
  }, newPassword);

  return (
    <Container fixed maxWidth="xs">
      <form {...form.bind}>
        <Grid container direction="column" rowSpacing={4}>
          <Grid item>
            <ErrorDisplay error={error} errorMessage="An error has occurred. Please try again." />
          </Grid>
          <Grid item>
            <SuccessDisplay
              display={success || false}
              successMessage="Your password has been updated successfully! Please login with your new credentials."
            />
          </Grid>
          <Grid item>
            <div>
              <img src={LogoImage} style={{width: '100%'}} alt="BoldTrail Recruit Logo" />
            </div>
          </Grid>
          {!success && (
            <>
              <Grid item>
                <Typography variant="subtitle" align="center">
                  Please enter a new password for your account.
                </Typography>
              </Grid>
              <Grid item>
                <TextField
                  name="newPassword"
                  label="New Password"
                  {...newPassword.bind}
                  autoComplete="password"
                  type="password"
                  fullWidth
                />
              </Grid>
              <Grid item>
                <PasswordRulesHint password={newPassword.value} />
              </Grid>
            </>
          )}
          <Grid item container justifyContent="space-between" alignItems="center">
            {!success && (
              <Grid item>
                <Button type="submit" fullWidth>
                  Set New Password
                </Button>
              </Grid>
            )}
            <Grid item>
              <ButtonLink to="/" variant="secondary" fullWidth>
                &larr; Back to login
              </ButtonLink>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

interface FeeCalculatorArgs {
  agentStartingSplit: any;
  splitAfterCap: any;
  avgMktCommission: any;
  franchiseFee: any;
  franchiseFeeCap: any;
  annualCoCap: any;
  salesVolume: any;
}
export default class FeeCalculator {
  agentStartingSplit: any;
  splitAfterCap: any;
  avgMktCommission: any;
  franchiseFee: any;
  franchiseFeeCap: any;
  annualCoCap: any;
  salesVolume: any;
  constructor(args: FeeCalculatorArgs) {
    Object.assign(this, args);
  }
  //C6
  calculateSplitAfterCap() {
    //this is to deal with the no cap scenario
    return (this.splitAfterCap || 0) === 0
      ? (this.agentStartingSplit || 0) / 100
      : (this.splitAfterCap || 0) / 100;
  }

  //D5
  calculateCompanySplit() {
    // franchise fee has to come out of here
    const companySplit = (100 - (this.agentStartingSplit || 0)) / 100;
    return companySplit;
  }

  //C7
  calculateAverageMarketCommission() {
    return (this.avgMktCommission || 0) / 100;
  }

  //C11
  calculateAgentGrossCommission() {
    return (this.salesVolume || 0) * this.calculateAverageMarketCommission();
  }

  //F5
  calculateFranchiseFeeCost() {
    return (this.franchiseFee || 0) / 100;
  }

  //C12
  calculateFranchiseFee() {
    //=IF(C11*F5>F6,F6,C11*F5)

    const agentGC = this.calculateAgentGrossCommission(); //C11
    const franchiseFeeCost = this.calculateFranchiseFeeCost(); //F5
    const comparison = agentGC * franchiseFeeCost; //c11 * f5

    //this is to deal with the no cap scenario
    /*if (this.franchiseFeeCap === 0) {
      return comparison;
    }*/
    if ((this.franchiseFeeCap || 0) === 0) {
      return comparison;
    }
    if (comparison > (this.franchiseFeeCap || 0)) {
      return this.franchiseFeeCap || 0;
    }

    return comparison;
  }

  //C13
  calculateAgentAdjustedGross() {
    //=C11-C12
    return this.calculateAgentGrossCommission() - this.calculateFranchiseFee();
  }

  // TODO name me
  F12() {
    return (
      (this.annualCoCap || 0) /
      this.calculateCompanySplit() /
      this.calculateAverageMarketCommission()
    );
  }

  //C14
  calculateLessCompanyDollarCap() {
    //=IF(C8*C7*D5>F7,F7,C8*C7*D5)
    const companyDollar = this.calculateAgentAdjustedGross() * this.calculateCompanySplit();
    if (this.annualCoCap === '' || this.annualCoCap === 0) {
      return companyDollar;
    }
    if (companyDollar > (this.annualCoCap || 0)) {
      return this.annualCoCap || 0;
    }
    return companyDollar;
  }

  //C15
  calculateAfterCompanyCap() {
    //=IF(C8*C7*D5<F7,0,((C8-F12)*C7*(1-C6)))
    if ((this.annualCoCap || 0) === 0) {
      return 0;
    }

    if (
      (this.salesVolume || 0) *
        this.calculateAverageMarketCommission() *
        this.calculateCompanySplit() <
      (this.annualCoCap || 0)
    ) {
      return 0;
    }

    // if ((this.annualCoCap || 0) < this.calculateLessCompanyDollarCap()) {
    //   return this.annualCoCap || 0;
    // }

    return (
      ((this.salesVolume || 0) - this.F12()) *
      this.calculateAverageMarketCommission() *
      (1 - this.calculateSplitAfterCap())
    );
  }

  //C17
  calculateAgentNetGCI() {
    if (this.calculateLessCompanyDollarCap() <= 0) {
      return this.calculateAgentAdjustedGross();
    }
    return (
      this.calculateAgentAdjustedGross() -
      this.calculateLessCompanyDollarCap() -
      this.calculateAfterCompanyCap()
    );
  }

  //D17
  calculateAgentNetGCITrueSplit() {
    return this.calculateAverageMarketCommission() / this.calculateAgentGrossCommission();
  }
}

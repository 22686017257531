import LogoImage from '../../assets/BT-Recruit-Horizontal-Dark.png';
import { Container, Grid } from '@mui/material';
import { Typography } from 'shared/components';
interface Props {
  error?: boolean;
  message: string;
}

export function SubmissionMessage({ error, message }: Props) {
  return (
    <Container fixed maxWidth="md">
      <Grid container direction="column" spacing={4}>
        <Grid item>
          <div>
            <img src={LogoImage} alt="BoldTrail Recruit Logo" />
          </div>
          <Typography
            variant="subtitle"
            align="center"
            color={error ? 'error' : undefined}
          >
            {message}
          </Typography>
          {
            error &&
            <Typography
              variant="subtitle"
              align="center"
            >
              Please contact the broker who sent this survey!
            </Typography>
          }
        </Grid>
      </Grid>
    </Container>
  );
}

import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  InputAdornment,
  Button, Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ThemeColors } from '../../../shared';
import React, { Dispatch, SetStateAction, useMemo, useState, ChangeEvent } from 'react';
import { FeeCategory, FeeScheduleItem } from 'recruiting/pages/net-income-valuation.types';
import FeeCalculator from './FeeCalculator';
import { CustomCurrencyInputMasker } from '../../../shared/components/custom-currency-input-masker';
import { TextField } from 'shared/components'

const useStyles = makeStyles(() => ({
  card: ({ color }: any) => ({
    borderLeftStyle: 'solid',
    borderLeftWidth: 4,
    borderLeftColor: color,
  }),
}));

const filterInput = (
  e: ChangeEvent<HTMLInputElement>,
  setCalculatorInput: Dispatch<SetStateAction<any>>
) => {
  const value = e.target.value;

  if (isNaN(Number(value)) || value === '') {
    setCalculatorInput('');
    return;
  }
  setCalculatorInput(value);
};

const getRequiredNonZeroFieldErrorMessage = (value: string) => {
  if (value === '' ) return 'This field is required';
  if (Number(value) <= 0) return 'Must be more than 0';
  return '';
}

interface RecruitingComparisonCalculatorBarProps {
  formCheck?: () => boolean;
  barTitle: string;
  salesVolume: number;
  color: ThemeColors;
  feeSchedule: FeeScheduleItem[];
  feeCategory: FeeCategory;
  onCalculate: Function;
}

const RecruitingComparisonCalculatorBar: React.FC<RecruitingComparisonCalculatorBarProps> = ({
  formCheck,
  barTitle,
  salesVolume, //C8
  color,
  feeCategory,
  feeSchedule,
  onCalculate,
}: RecruitingComparisonCalculatorBarProps) => {
  const classes = useStyles({ color });

  const [agentStartingSplit, setAgentStartingSplit] = useState('');
  const [splitAfterCap, setSplitAfterCap] = useState('');
  const [avgMktCommission, setAvgMktCommission] = useState('');
  const [franchiseFee, setFranchiseFee] = useState('');
  const [franchiseFeeCap, setFranchiseFeeCap] = useState('');
  const [annualCoCap, setAnnualCoCap] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const calculator = useMemo(() => {
    return new FeeCalculator({
      agentStartingSplit,
      splitAfterCap,
      avgMktCommission,
      franchiseFee,
      franchiseFeeCap,
      annualCoCap,
      salesVolume,
    });
  }, [
    agentStartingSplit,
    splitAfterCap,
    avgMktCommission,
    franchiseFee,
    franchiseFeeCap,
    annualCoCap,
    salesVolume,
  ]);

  const calculateFeeSchedule = () => {
    setIsSubmitted(true);

    if (!agentStartingSplit || !avgMktCommission) return;

    if (formCheck) {
      if (!formCheck()) {
        return;
      }
    }
    const updatedFeeSchedule = feeSchedule.map(fee => {
      const calculation = fee.calculate!(calculator);
      return {
        ...fee,
        ...(feeCategory === FeeCategory.Ours && {
          totalCostOurs: calculation,
        }),
        ...(feeCategory === FeeCategory.Current && {
          totalCostCurrent: calculation,
        }),
      };
    });
    onCalculate(updatedFeeSchedule);
  };

  return (
    <Card>
      <CardHeader title={barTitle} />
      <CardContent style={{alignSelf: 'start', maxWidth: '85%'}}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <TextField
              helperText={isSubmitted && getRequiredNonZeroFieldErrorMessage(agentStartingSplit)}
              error={isSubmitted && !!getRequiredNonZeroFieldErrorMessage(agentStartingSplit)}
              onChange={e => filterInput(e as ChangeEvent<HTMLInputElement>, setAgentStartingSplit)}
              onFocus={e => e.target.select()}
              value={agentStartingSplit}
              startAdornment={<InputAdornment position="start">Agent Starting Split:</InputAdornment>}
              endAdornment={<InputAdornment position="end">%</InputAdornment>}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <TextField
              onChange={e => filterInput(e as ChangeEvent<HTMLInputElement>, setSplitAfterCap)}
              onFocus={e => e.target.select()}
              value={splitAfterCap}
              startAdornment={<InputAdornment position="start">Split After Cap:</InputAdornment>}
              endAdornment={<InputAdornment position="end">%</InputAdornment>}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <TextField
              helperText={isSubmitted && getRequiredNonZeroFieldErrorMessage(avgMktCommission)}
              error={isSubmitted && !!getRequiredNonZeroFieldErrorMessage(avgMktCommission)}
              onChange={e => filterInput(e as ChangeEvent<HTMLInputElement>, setAvgMktCommission)}
              onFocus={e => e.target.select()}
              value={avgMktCommission}
              startAdornment={<InputAdornment position="start">Avg Mkt Commission:</InputAdornment>}
              endAdornment={<InputAdornment position="end">%</InputAdornment>}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <TextField
              onChange={e => filterInput(e as ChangeEvent<HTMLInputElement>, setFranchiseFee)}
              onFocus={e => e.target.select()}
              value={franchiseFee}
              startAdornment={<InputAdornment position="start">Franchise Fee:</InputAdornment>}
              endAdornment={<InputAdornment position="end">%</InputAdornment>}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <TextField
              onChange={e => filterInput(e as ChangeEvent<HTMLInputElement>, setFranchiseFeeCap)}
              onFocus={e => e.target.select()}
              value={franchiseFeeCap}
              startAdornment={<InputAdornment position="start">Franchise Fee Cap: $</InputAdornment>}
              _component={CustomCurrencyInputMasker as any}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <TextField
              onChange={e => filterInput(e as ChangeEvent<HTMLInputElement>, setAnnualCoCap)}
              onFocus={e => e.target.select()}
              value={annualCoCap}
              startAdornment={<InputAdornment position="start">Annual Co Cap: $</InputAdornment>}
              _component={CustomCurrencyInputMasker as any}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Button variant="contained" onClick={calculateFeeSchedule}>
              Calculate
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export const RecruitingComparisonCalculatorBarComponent = React.memo(
  RecruitingComparisonCalculatorBar
);

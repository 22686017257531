import React from 'react';
import { Redirect, Route, Switch, RouteComponentProps } from 'react-router-dom';

import { RecruitingDashboardPage } from 'recruiting/pages/recruiting-dashboard.page';
import { NetIncomeValuationPage } from 'recruiting/pages/net-income-valuation.page';
import { AgentDashboardPage } from 'recruiting/pages/agent-dashboard.page';

export const RecruitingRoutes: React.FC<RouteComponentProps> = ({ match }) => (
  <Switch>
    <Route path={match.path} exact component={RecruitingDashboardPage} />
    <Route path={`${match.path}/:recruitId/agent`} component={AgentDashboardPage} />
    <Route
      path={`${match.path}/:recruitId/:memberKey/valuation`}
      component={NetIncomeValuationPage}
    />
    <Redirect to={match.path} />
  </Switch>
);

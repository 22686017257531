import React from 'react';

import { Grid, GridSize } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';

import {NumberFormatters, ThemeColors } from '../../shared';
import {
  ResultType,
  SearchForm,
  SearchResult,
} from '../../state/search';

import { Typography } from 'shared/components';
import { DataTable, DataTableColumn, DataTableProps } from 'shared/components/data-table';
import { GridColDef } from '@mui/x-data-grid';

export interface ResultContainerProps {
  searchForm: SearchForm;
  onSearch(form: Partial<SearchForm>, resultType?: ResultType): void;
  isKvCoreUser: boolean;
}

interface ResultListProps<T extends SearchResult> extends DataTableProps<T> {
  summary?: DataTableColumn<T>;
}

export const useStyles = makeStyles(theme =>
  createStyles({
    header: {
      fontWeight: 'bold',
      fontSize: '10px',
    },
    groupHeader: {
      backgroundColor: theme.palette.grey[300],
      marginBottom: theme.spacing(2),
    },
    groupLabel: {
      fontSize: '125%',
      fontWeight: 'bold',
      padding: theme.spacing(1),
    },
    groupColumns: {
      height: '100%',
      alignItems: 'center',
      borderLeft: `2px solid ${theme.palette.grey[500]}`,
      '& .MuiGrid-item': {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
      },
    },
    renderCell: {
      fontSize: '90%',
    },
    grid: {
      '& .rdt_TableHead': {
        backgroundColor: ThemeColors.LightGray,
        padding: '0 1rem',
        '& .rdt_TableHeadRow': {
          backgroundColor: ThemeColors.LightGray,
        },
      },
      '& .rdt_TableRow': {
        padding: '.5rem 1rem',
      },
    },
  })
);

export const Columns: React.FC<{ colspans?: Array<GridSize> }> = ({ colspans, children }) => {
  const columns = React.Children.toArray(children);
  const colspan: Array<GridSize> =
    colspans === undefined ? (columns.length === 3 ? [3, 6, 3] : [4, 8]) : colspans;

  return (
    <>
      {columns.map((col, i) => (
        <Grid key={i} item xs={colspan[i]}>
          {typeof col === 'string' ? (
            <Typography align="right" variant="caption">
              {col}
            </Typography>
          ) : (
            col
          )}
        </Grid>
      ))}
    </>
  );
};

export const Header: React.FC<{ align?: 'left' | 'right' }> = ({ children, align }) => {
  return (
    <Typography align={align || 'right'} noWrap fontWeight="bold">
      {children}
    </Typography>
  );
};

export const GroupHeader: React.FC<{ label: string; colspans?: Array<GridSize> }> = ({
  colspans,
  label,
  children,
}) => {
  const styles = useStyles();
  return (
    <Grid container className={styles.groupColumns}>
      <Grid item xs={12} className={styles.groupHeader}>
        <Typography align="center" fontWeight="bold">
          {label}
        </Typography>
      </Grid>
      <Columns colspans={colspans}>{children}</Columns>
    </Grid>
  );
};

export const GroupColumns: React.FC<{ colspans?: Array<GridSize> }> = ({ colspans, children }) => {
  const styles = useStyles();
  return (
    <Grid container className={styles.groupColumns}>
      <Columns colspans={colspans}>{children}</Columns>
    </Grid>
  );
};

const defaultColumns: DataTableColumn<SearchResult>[] = [
  {
    field: 'listingSide',
    flex: 1,
    sortable: false,
    filterable: false,
    renderHeader: () => (
      <GroupHeader label="LISTING SIDE">
        <Header>#</Header>
        <Header>VOLUME</Header>
      </GroupHeader>
    ),
    renderCell: ({ row }) => (
      <GroupColumns>
        {NumberFormatters.decimal`${row.listSideCount}`}
        {NumberFormatters.currency`${row.listSideVolume}`}
      </GroupColumns>
    ),
  },
  {
    field: 'sellingSide',
    flex: 1,
    sortable: false,
    filterable: false,
    renderHeader: () => (
      <GroupHeader label="SELLING SIDE">
        <Header>#</Header>
        <Header>VOLUME</Header>
      </GroupHeader>
    ),
    renderCell: ({ row }) => (
      <GroupColumns>
        {NumberFormatters.decimal`${row.sellSideCount}`}
        {NumberFormatters.currency`${row.sellSideVolume}`}
      </GroupColumns>
    ),
  },
  {
    field: 'total',
    flex: 1.25,
    sortable: false,
    filterable: false,
    renderHeader: () => (
      <GroupHeader label="TOTAL">
        <Header>#</Header>
        <Header>VOLUME</Header>
        <Header>%</Header>
      </GroupHeader>
    ),
    renderCell: ({ row }) => (
      <GroupColumns>
        {NumberFormatters.decimal`${row.totalCount}`}
        {NumberFormatters.currency`${row.totalVolume}`}
        {(100 * row.percentTotal).toFixed(2)}
      </GroupColumns>
    ),
  },
  {
    field: 'average',
    flex: 1.25,
    sortable: false,
    filterable: false,
    renderHeader: () => (
      <GroupHeader label="AVERAGE" colspans={[8, 4]}>
        <Header>PRICE</Header>
        <Header>DOM</Header>
      </GroupHeader>
    ),
    renderCell: ({ row }) => (
      <GroupColumns colspans={[8, 4]}>
        {NumberFormatters.currency`${Math.round(
          row.totalVolume / (row.totalCount > 0 ? row.totalCount : 1)
        )}`}
        {NumberFormatters.decimal`${row.daysOnMarket}`}
      </GroupColumns>
    ),
  },
];

export function ResultsList<T extends SearchResult>({
  summary,
  columns,
  ...rest
}: ResultListProps<T>) {
  const displayColumns = [...columns, ...defaultColumns] as  readonly GridColDef<T, any, any>[] & DataTableColumn<T>[];
  if (summary) displayColumns.push(summary as DataTableColumn<T>);

  return (
    <DataTable
      {...rest}
      columns={displayColumns}
      showPagination
    />
  );
}

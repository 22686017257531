import React from 'react';
import { useParams } from 'react-router-dom';
import { SurveyDetails } from '../components';
import { useGetSurvey } from '../hooks';
import { LoadingFade } from '../../shared';

export function SurveyDetailsPage() {
  const { surveyId } = useParams<{ surveyId: string }>();
  const { survey, loading } = useGetSurvey(+surveyId);

  return (
    <LoadingFade isContentLoading={loading}>
      <SurveyDetails survey={survey} />
    </LoadingFade>
  );
}

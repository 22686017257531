import React from 'react';
import { Box } from '@mui/material';

import { Block } from 'shared/components/block';
import { ValueComparison } from 'shared/components/value-comparison';

interface Props {
  title: string;
  current: number;
  previous?: number;
  formatter?: (strings: TemplateStringsArray, value: number) => string;
  invertColor?: boolean;
  priceSum?: number;
}

export const MarketStat: React.FC<Props> = ({ title, priceSum, ...props }) => (
  <Block title={title}>
    <Box py={1}>
      <ValueComparison align="center" size="175%" {...props} priceSum={priceSum} />
    </Box>
  </Block>
);

export function trace(message: any, ...optionalParams: any[]) {
  if (console.debug) {
    console.debug(message, ...optionalParams);
  }
}
export function traceError(message: any, ...optionalParams: any[]) {
  if (console.error) {
    console.error(message, ...optionalParams);
  }
}

import React, {useEffect, useState} from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle} from "../dialog";
import {Button} from "../button";
import {Typography} from "../typography";
import {Box, Checkbox, Grid} from "@mui/material";
import {userService} from 'state/users';
import {useInterstitial, useTermsAcceptance} from 'admin/hooks';
import { WistiaPlayer } from '../wistia-player/wistia-player';

export const TermsAndConditionsDialog: React.FC = () => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [accepted, setAccepted] = useState<boolean>(false);

  const currentInterstitial = useInterstitial();
  const termsAcceptanceList = useTermsAcceptance();
  const showDialog =
    currentInterstitial
    && termsAcceptanceList
    && currentInterstitial.isActive
    && (termsAcceptanceList
    .filter(t =>
      t.interstitialId === currentInterstitial.id
      && t.timestamp > currentInterstitial.activeDate
    ).length === 0);

  useEffect(() => {
    setOpenDialog(showDialog);
  }, [showDialog, currentInterstitial]);

  if (!showDialog) return null;

  const handleClose = (event: object, reason: string) => {
    if (reason === 'backdropClick') {
      return;
    }
    setOpenDialog(false);
  };

  const onConfirm = () => {
    userService.acceptTermsOfUse(currentInterstitial.id);
    setOpenDialog(false);
  }

  return (
    <Dialog isOpen={openDialog || false} onClose={handleClose} fullWidth disableEscapeKeyDown>
      <DialogTitle variant="h4" align="center" fontWeight="bold">
        Welcome to BoldTrail Recruit
      </DialogTitle>
      <DialogContent>
        <p style={{marginBottom: '2rem'}}>
          We’re thrilled to introduce you to BoldTrail Recruit, part of the comprehensive BoldTrail platform provided by Inside Real Estate.
          To learn more about the power of BoldTrail, please go <a href="https://resources.insiderealestate.com/page/meet-boldtrail-backoffice" target="_blank">here</a>. Thank you for being a valued part of our community.
          We look forward to supporting your continued growth and success with BoldTrail.
        </p>
        <WistiaPlayer videoId="g0ojn0is0f" />
        <p style={{marginTop: '2rem'}}>
          Before continuing, we have updated our <a href={currentInterstitial.termsOfUseLink} target='_blank'>Terms of Service</a>.
        </p>
        <p style={{marginBottom: '2rem'}}>
          If you would like more information about these changes, please see our <a href={currentInterstitial.faqLink} target='_blank'>FAQ</a>.
          We ask that you follow the hyperlink below to fully review the terms and agree to them by clicking the checkbox.
          If you have any questions, please contact our <a href="mailto:recruitsupport@boldtrail.com"  target='_blank'>support team</a>.
        </p>
        <Box display="flex" alignItems="center" style={{backgroundColor: 'whitesmoke', borderRadius: '5px', padding: '5px'}}>
          <Checkbox
            checked={accepted}
            onChange={(event) => setAccepted(event.target.checked)}
            color="primary"
          />
          <Typography component="span">
              By checking this box, you acknowledge that you have read and agree to Inside Real Estate's
              updated <strong><u><a href={currentInterstitial.termsOfUseLink} target='_blank'>TERMS OF SERVICE</a></u></strong>.
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Grid container item xs={12} gap={2} m={2}>
          <Grid item>
            <Button onClick={onConfirm} disabled={!accepted}>
              Accept
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import { sessionQuery, sessionService } from '../../state';
import { useObservable } from '../../shared';

export const SignOutPage: React.FC = () => {
  const isLoggedIn = useObservable(sessionQuery.isLoggedIn, 'async');
  if (localStorage.getItem('kvcore')) {
    localStorage.removeItem('kvcore');
  }

  useEffect(() => {
    const token = getAuthToken();
    sessionService.logout(token.accessToken, token.refreshToken);
  }, []);

  if (isLoggedIn) return null;

  return <Redirect to="/account/login" />;
};

function getAuthToken() {
  const auth = sessionQuery.getValue().auth;

  if (!auth.authenticated) return {
    accessToken: "",
    refreshToken: ""
  };

  return {
    accessToken: auth.token.accessToken,
    refreshToken: auth.token.refreshToken
  };
}

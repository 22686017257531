import { FormControl, AbstractControl } from './controls';
import { aria } from './util';

type FormLike = Pick<React.HTMLProps<HTMLFormElement>, 'onSubmit'>;

export interface FormGroup extends AbstractControl<FormLike> {}

export const useForm = (
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void,
  ...controls: FormControl[]
): FormGroup => {
  const valid = controls.every(ctrl => ctrl.valid);
  const touched = controls.some(ctrl => ctrl.touched);

  return {
    valid,
    touched,
    reset: () => controls.forEach(ctrl => ctrl.reset()),
    bind: {
      ...aria(valid, touched),
      onSubmit: e => {
        onSubmit(e);
        e.preventDefault();
      },
    },
  };
};

import React, { useEffect } from 'react';
import { deferred, useObservable } from '..';
import { mlsQuery, mlsService } from '../../state';
import { VolumeSummaryTooltip } from './volume-summary-tooltip';

interface Props {
  officeKey: string;
  officeName: string;
}

const OfficeVolume: React.FC<Props> = ({ officeKey, officeName }) => {
  const officeVolumes = useObservable(mlsQuery.officeVolumeSummaries);
  const volume = officeVolumes.find(v => v.officeKey === officeKey);
  useEffect(() => mlsService.getOfficeVolumeSummary(officeKey), [officeKey]);

  if (!volume?.periods) {
    return null;
  }

  return <VolumeSummaryTooltip title={officeName} periods={volume.periods} />;
};

export const OfficeVolumeSummaryTooltip = deferred(OfficeVolume);

import React, { memo, useMemo } from 'react';

import { EMPTY } from 'rxjs';

import { Card, CardContent, CardHeader, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ArrowDropDown } from '@mui/icons-material';
import {
  VictoryChart,
  VictoryBar,
  VictoryGroup,
  VictoryAxis,
  VictoryLabel,
  VictoryLegend,
  VictoryTooltip,
} from 'victory';

import { VolumeSummary, DateRangeOptions, ListingGroup } from '../../state';

import {
  MarketStat,
  NumberFormatters,
  useObservable,
  deferred,
} from '../../shared';
import { ValueComparison } from 'shared/components/value-comparison';
import { startOfMonth } from 'date-fns/esm';
import themeService from 'theme/ThemeService';

export interface SalesVolumeProps {
  volume: VolumeSummary;
  hideTransactionTile?: boolean;
}

interface SalesVolumeChartProps extends SalesVolumeProps {
  data: VolumeSummary[];
}

const palette = themeService.getPalette();

const useArrowStyle = makeStyles({
  arrow: {
    background: palette.neutralLight,
    borderColor: palette.purple,
    borderStyle: 'dashed',
    borderWidth: '1px',
    padding: '4px',
  },
  value: {
    color: palette.purple,
  },
});
enum SalesVolumeBarType {
  Previous,
  Current,
}
const SalesVolumeToolTip: React.FC = (props: any) => {
  const styles = useArrowStyle();
  return (
    <foreignObject
      width={125}
      height={40}
      x={
        props.datum.barType === SalesVolumeBarType.Current
          ? props.center.x - 53
          : props.center.x - 73
      }
      y={-30}
    >
      <div className={styles.arrow}>
        <ValueComparison
          align="left"
          size="80%"
          colorCurrent
          {...props.datum.volume}
          formatter={NumberFormatters.currency}
          classes={{ value: styles.value }}
        />
      </div>
      <div style={{ textAlign: 'center', marginTop: '-10px' }}>
        <ArrowDropDown style={{ color: palette.purple }} />
      </div>
    </foreignObject>
  );
};

const grouping = ListingGroup.Months;
const months = Array.from({ length: 12 }, (_, i) => i);

const SalesVolumeContainer: React.FC<SalesVolumeProps> = ({ volume, ...props }) => {
  const yoyVolume$ = useMemo(
    () => volume.withNewDateFilter(DateRangeOptions.Last12Months),
    [volume]
  );
  const yoyVolume = useObservable(yoyVolume$, 'async');

  const data$ = useMemo(
    () => (yoyVolume ? yoyVolume.getSummaryByGroup(grouping, months) : EMPTY),
    [yoyVolume]
  );

  const data = useObservable(data$, 'async');

  if (!yoyVolume || !data) return null;

  return <SalesVolumeChart volume={yoyVolume} data={data} {...props} />;
};

const SalesVolumeChart: React.FC<SalesVolumeChartProps> = memo(
  ({ volume, data, hideTransactionTile = false }) => {
    const monthFormatter = new Intl.DateTimeFormat('en-US', { month: 'short' });
    const monthNames = months.map(m => monthFormatter.format(new Date(2020, m, 1)));

    const currentData = months.map(m => ({
      x: monthNames[m],
      y: data[m].totalVolume.current,
      label: '', //label has to be included as a prop or tooltip doesnt display
      volume: data[m].totalVolume,
      barType: SalesVolumeBarType.Current,
    }));

    const previousData = months.map(m => ({
      x: monthNames[m],
      y: data[m].totalVolume.previous,
      label: '', //label has to be included as a prop or tooltip doesnt display
      volume: data[m].totalVolume,
      barType: SalesVolumeBarType.Previous,
    }));

    const legendData = [
      {
        name: 'Last 12 Months',
        total: volume.totalVolume.current,
        symbol: { fill: palette.primary, type: 'square' },
      },
      {
        name: 'Prior Period',
        total: volume.totalVolume.previous,
        symbol: { fill: palette.purple, type: 'square' },
      },
    ];

    // categories start in reverse from the current month, with the current month last
    const firstMonth = startOfMonth(new Date()).getMonth();
    const categories = months.map((_, i) => monthNames[(12 + firstMonth - i) % 12]).reverse();

    return (
      <Card style={{ overflow: 'visible' }}>
        <CardHeader title="Closed Sales Volume" />
        <CardContent>
          <Grid container>
            <Grid item xs={12} sm={hideTransactionTile ? 12 : 9}>
              <VictoryChart
                height={200}
                width={700}
                categories={{ x: categories }}
                padding={{ left: 20, right: 20, top: 10, bottom: 80 }}
              >
                <VictoryGroup offset={20}>
                  <VictoryBar
                    style={{ data: { fill: palette.primary } }}
                    labelComponent={
                      <VictoryTooltip pointerLength={20} flyoutComponent={<SalesVolumeToolTip />} />
                    }
                    data={currentData}
                    barWidth={8}
                  />
                  <VictoryBar
                    style={{ data: { fill: palette.purple } }}
                    labelComponent={
                      <VictoryTooltip pointerLength={20} flyoutComponent={<SalesVolumeToolTip />} />
                    }
                    data={previousData}
                    barWidth={8}
                  />
                </VictoryGroup>
                <VictoryLegend
                  data={legendData}
                  labelComponent={<LegendLabel />}
                  orientation="horizontal"
                  itemsPerRow={2}
                  gutter={50}
                  width={100}
                  x={220}
                  y={160}
                />
                <VictoryAxis style={{ tickLabels: { fontSize: '8px', fontFamily: 'Albert Sans' }}}/>
              </VictoryChart>
            </Grid>
            {!hideTransactionTile && (
              <Grid item xs={12} sm={3}>
                <MarketStat title="Total Transactions" {...volume.totalTransactions} />
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    );
  }
);

export const LegendLabel: React.FC = (props: any) => {
  return (
    <VictoryLabel
      {...props}
      style={[
        { fontWeight: 'bold', fontSize: '9px', fontFamily: 'Albert Sans' },
        { fontWeight: 'lighter', fontSize: '7px', fontFamily: 'Albert Sans' },
      ]}
      text={({ datum }) => [NumberFormatters.currency`${datum.total}`, datum.name]}
    />
  );
};

export const SalesVolume = deferred(SalesVolumeContainer);

import defaultsDeep from 'lodash.defaultsdeep';

export interface IAppConfig {
  readonly FadeLoadingTimeout: number;
  readonly Api: {
    readonly AppBaseUrl: string;
    readonly MlsBaseUrl: string;

    readonly Auth: {
      readonly BaseUrl: string;
      readonly ClientId: string;
      readonly GrantType: string;
      readonly RefreshGrantType: string;
      readonly Scope: string;
    };
  };
  readonly GoogleAnalytics: {
    readonly Required: boolean;
  };
  readonly KvCore: {
    readonly OAuth: {
      readonly AuthorizeEndpoint: string;
      readonly TokenEndpoint: string;
      readonly RefreshTokenEndpoint: string;
      readonly Login: string;
      readonly ClientId: string;
      readonly CallbackUrl: string;
    };
  };
}

const configDefaults = {
  FadeLoadingTimeout: 1000,
  Api: {
    AppBaseUrl: process.env.REACT_APP_API_APP_BASE_URL,
    MlsBaseUrl: process.env.REACT_APP_API_MLS_BASE_URL,
    Auth: {
      BaseUrl: process.env.REACT_APP_API_AUTH_BASE_URL,
      ClientId: process.env.REACT_APP_API_AUTH_CLIENT_ID,
      GrantType: process.env.REACT_APP_API_AUTH_GRANT_TYPE,
      RefreshGrantType: process.env.REACT_APP_API_AUTH_REFRESH_GRANT_TYPE,
      Scope: process.env.REACT_APP_API_AUTH_SCOPE,
    },
  },
  GoogleAnalytics: {
    Required: false,
  },
  KvCore: {
    OAuth: {
      AuthorizeEndpoint: process.env.REACT_APP_KVCORE_OAUTH_AUTHORIZE_ENDPOINT,
      TokenEndpoint: process.env.REACT_APP_KVCORE_OAUTH_TOKEN_ENDPOINT,
      RefreshTokenEndpoint: process.env.REACT_APP_KVCORE_OAUTH_TOKEN_REFRESH,
      Login: process.env.REACT_APP_KVCORE_OATH_LOGIN,
      ClientId: process.env.REACT_APP_KVCORE_OAUTH_CLIENT_ID,
      CallbackUrl: process.env.REACT_APP_KVCORE_OAUTH_CALLBACK_URL,
    },
  },
} as IAppConfig;

function getRuntimeConfig(): IAppConfig {
  let bundleConfig = {};
  try {
    bundleConfig = JSON.parse(document.getElementById('runtime-config')?.innerHTML ?? '{}');
  } catch (error) {
    if (process.env.NODE_ENV === 'production') {
      console.warn('Runtime application config failed to load.', error);
    }
  }

  return defaultsDeep(bundleConfig, configDefaults);
}

export const AppConfig = getRuntimeConfig();

import React from 'react';
import { Redirect, Route, Switch, RouteComponentProps } from 'react-router-dom';

import { AgentDashboardPage } from './pages';

export const AgentsRoutes: React.FC<RouteComponentProps> = ({ match }) => (
  <Switch>
    <Route path={`${match.path}/:memberKey`} component={AgentDashboardPage} />
    <Redirect to={match.path} />
  </Switch>
);

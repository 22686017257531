import { useEffect, useMemo } from 'react';
import { useObservable } from '../../shared/utils';

import {
  mlsService,
  mlsQuery,
  recruitsService,
  recruitsQuery,
  agentListingFilter,
  DateRangeOptions,
  dateListingFilter,
  createLookupByAgent,
  emptyLookupByAgent,
} from '../../state';

export function useGetAgent(memberKey: string) {
  useEffect(() => 
    mlsService.getAgent(memberKey)
  , [memberKey]);

  return useObservable(mlsQuery.activeAgent);
}

function useAgentTransactionsInternal(dateRange: DateRangeOptions) {
  const agent = useObservable(mlsQuery.activeAgent, 'async');
  const listings = useObservable(mlsQuery.activeAgentTransactions);
  const summaries = useObservable(mlsQuery.agentVolumeSummaries);

  const transactions = useMemo(() => {
    const agentFilter = agentListingFilter(agent);
    const dateFilter = dateListingFilter(dateRange);
    const currentListings = listings.filter(dateFilter.filterAllCurrent);
    return {
      listings: currentListings,
      listSide: currentListings.filter(agentFilter.isListSideOnly),
      sellSide: currentListings.filter(agentFilter.isSellSideOnly),
      bothSides: currentListings.filter(agentFilter.isBothSide),
    };
  }, [agent, dateRange, listings]);

  const volume$ = useMemo(
    () =>
      agent
        ? createLookupByAgent(
            listings,
            { memberKeys: [agent.memberKey] },
            dateRange,
            [agent],
            summaries
          )
        : Promise.resolve(emptyLookupByAgent()),
    [agent, dateRange, listings, summaries]
  );

  const volume = useObservable(volume$, 'async');

  return {
    agent,
    transactions,
    volume: agent ? volume?.get(agent.memberKey) : undefined,
  };
}

export function useAgentTransactions(memberKey: string, dateRange: DateRangeOptions) {
  useEffect(() => {
    mlsService.getAgentTransactions(memberKey);
    mlsService.getAgentVolumes([memberKey]);
  }, [memberKey]);

  return useAgentTransactionsInternal(dateRange);
}

export function useNetIncomeCalculatorTransactions(
  managerId: string,
  memberKey: string,
  recruitId: number,
  dateRange: DateRangeOptions
) {
  useEffect(() => {
    if (recruitId !== 0) {
      recruitsService.getRecruit(managerId, recruitId);
      recruitsService.selectRecruit(recruitId);
    } else {
      mlsService.getAgentTransactions(memberKey);
      mlsService.getAgentVolumes([memberKey]);
    }
  }, [managerId, recruitId, memberKey]);

  const recruit = useObservable(recruitsQuery.activeRecruit, 'async');

  useEffect(() => {
    if (recruitId !== 0 && recruit?.memberKey) {
      mlsService.getAgentTransactions(recruit.memberKey);
    }
    // Ignore eslint warnings, using only recruit causes
    // multiple api requests  for a single recruit.
    // eslint-disable-next-line
  }, [recruit?.memberKey, recruitId]);

  return useAgentTransactionsInternal(dateRange);
}

export function useRecruitTransactions(
  managerId: string,
  recruitId: number,
  dateRange: DateRangeOptions
) {
  useEffect(() => {
    recruitsService.getRecruit(managerId, recruitId);
    recruitsService.selectRecruit(recruitId);
  }, [managerId, recruitId]);

  const recruit = useObservable(recruitsQuery.activeRecruit, 'async');

  useEffect(() => {
    if (recruit?.memberKey) {
      mlsService.getAgentTransactions(recruit.memberKey);
    }
    // Ignore eslint warnings, using only recruit causes
    // multiple api requests  for a single recruit.
    // eslint-disable-next-line
  }, [recruit?.memberKey]);

  const result = useAgentTransactionsInternal(dateRange);

  return { recruit, ...result };
}

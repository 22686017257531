import React, { useState } from 'react';

import { Card, Grid, CardHeader, CardContent, Tooltip, Typography } from '@mui/material';
import { Search } from '@mui/icons-material';
import { Button } from 'shared/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icons, NumberFormatters } from '../../shared';
import { Link, useCurrentUserId } from '../../shared';
import { searchService, AgentSearchResult, ResultType } from '../../state/search';
import { SortInput } from '../components/sort-input';
import { useRecruit } from '../../recruiting';
import {
  Header,
  useStyles,
  ResultsList,
  ResultContainerProps,
  GroupHeader,
  GroupColumns,
} from './results-list';
import { RecruitAgent } from '../../shared/components/recruit-agent';
import { DrillDownLink } from './drilldown-link';
import { AgentVolumeSummaryTooltip } from '../../shared/components/agent-volume-summary-tooltip';
import { useAgentResults } from '../../state/search/search.hooks';
import { DataTableColumn } from 'shared/components/data-table';
import themeService from 'theme/ThemeService';

const palette = themeService.getPalette();

export const AgentResults: React.FC<ResultContainerProps> = ({
  searchForm,
  onSearch,
  isKvCoreUser,
}) => {
  const {
    data,
    isFetching,
    sortField,
    sortOrder,
    setSortOrder,
    searchType,
    page,
    setPage: setCurrentPage,
    pageSize: perPage,
    setPageSize: setPerPage,
  } = useAgentResults();

  const styles = useStyles();

  const managerId = useCurrentUserId();

  const { isRecruit, isRecruited, toggleRecruit } = useRecruit(managerId);

  const [loading, setLoading] = useState(false);

  const handleExportClick = async () => {
    setLoading(true);

    try {
      await searchService.exportAgents(searchForm, sortField, sortOrder, searchType);

      // Handle successful download or further actions here
    } catch (error) {
      // Handle error during download here
    }

    setLoading(false);
  };

  if (!data) return null;

  const { data: rows, total } = data;

  const columns: DataTableColumn<AgentSearchResult>[] = [
    {
      renderHeader: () => <Typography noWrap fontWeight="bold">WATCHING</Typography>,
      headerAlign: 'center',
      align: 'center',
      display: 'flex',
      field: 'watching',
      flex: 0.5,
      renderCell: ({ row }) => (
        <RecruitAgent
          memberKey={row.memberKey}
          isRecruit={isRecruit(row.memberKey)}
          isRecruited={isRecruited(row.memberKey)}
          toggleRecruit={toggleRecruit}
        />
      ),
    },
    {
      renderHeader: () => <Typography noWrap fontWeight="bold">RANK</Typography>,
      headerAlign: 'center',
      align: 'center',
      field: 'rank',
      flex: 0.5,
    },
    {
      renderHeader: () => <Typography noWrap fontWeight="bold">AGENT NAME</Typography>,
      headerAlign: 'center',
      align: 'center',
      display: 'flex',
      field: 'agentName',
      flex: 1.5,
      renderCell: ({ row }) => (
        <>
          <Link
            to={`/agents/${row.memberKey}`}
            color="primary"
            style={{
              marginRight: "10px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "calc(100% - 32px)",
            }}
          >
            {row.agentName}
          </Link>
          <a
            href={`https://www.google.com/search?q=${row.agentName} Realtor ${row.officeName}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Search color="primary"/>
          </a>
        </>
      ),
    },
    {
      renderHeader: () => <Typography noWrap fontWeight="bold">MOVE DATE</Typography>,
      headerAlign: 'center',
      align: 'center',
      display: 'flex',
      field: 'officeKeyModified',
      flex: 1,
      renderCell: ({ row }) =>
        row.officeKeyModified ? new Date(row.officeKeyModified).toLocaleDateString() : undefined,
      sortable: true,
      filterable: false,
      valueGetter: (value, row) => {
        if (row.officeKeyModified) return new Date(row.officeKeyModified);
        return null;
      },
      type: "date"
    },
    {
      renderHeader: () => <Typography noWrap fontWeight="bold">PREVIOUS OFFICE</Typography>,
      headerAlign: 'center',
      align: 'center',
      display: 'flex',
      field: 'previousOffice',
      flex: 1,
      renderCell: ({ row }) =>
        row.previousOfficeName ? (
          <DrillDownLink
            onSearch={onSearch}
            formKey="branch"
            formValue={row.previousOfficeKey!}
            resultType={ResultType.Agent}
            activeStatus={row.previousOfficeStatus}
          >
            <Tooltip title={row.previousOfficeName}>
              <span>{row.previousOfficeMlsId}</span>
            </Tooltip>
          </DrillDownLink>
        ) : undefined,
    },
    {
      renderHeader: () => <Typography noWrap fontWeight="bold">OFFICE</Typography>,
      headerAlign: 'center',
      align: 'center',
      display: 'flex',
      field: 'Office',
      flex: 1,
      renderCell: ({ row }) => (
        <DrillDownLink
          onSearch={onSearch}
          formKey="branch"
          formValue={row.officeKey}
          resultType={ResultType.Agent}
          activeStatus={null}
        >
          <Tooltip title={row.officeName!}>
            <span>{row.officeNumber}</span>
          </Tooltip>
        </DrillDownLink>
      ),
    },
  ];

  return (
    <Card>
      <CardHeader
        title="Agents"
        className="actionHeader"
        action={
          <Grid container spacing={2}>
            <Grid item>
              <SortInput
                sortField={sortField}
                sortOrder={sortOrder}
                resultType={ResultType.Agent}
                onChange={(newSortField, newSortOrder) => {
                  setSortOrder(newSortField, newSortOrder);
                }}
              />
            </Grid>

            <Grid item>
              <Button
                onClick={handleExportClick}
                pending={loading}
                startIcon={<FontAwesomeIcon icon={icons.download} size="lg" />}
              >
                Export Results
              </Button>
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        <ResultsList
          progressPending={isFetching}
          getRowId={(row) => row.memberKey}
          rows={rows}
          columns={columns}
          showPagination
          paginationMode="server"
          rowCount={total}
          paginationModel={{
            page: page,
            pageSize: perPage,
          }}
          pageSizeOptions={[10, 25, 50, 100]}
          onPaginationModelChange={({ page, pageSize }) => {
            setPerPage(pageSize);
            setCurrentPage(page);
          }}
          summary={{
            field: 'sumary',
            renderHeader: () => (
              <GroupHeader label="TREND" colspans={[12]}>
                <Header>3yr</Header>
              </GroupHeader>
            ),
            renderCell: ({ row }) => (
              <GroupColumns colspans={[12]}>
                <AgentVolumeSummaryTooltip memberKey={row.memberKey} agentName={row.agentName} />
              </GroupColumns>
            ),
          }}
        />
      </CardContent>
    </Card>
  );
};

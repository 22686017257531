import React, { useState } from 'react';
import { CheckBoxOutlineBlank, CheckBox } from '@mui/icons-material';
import { Button, ConfirmationDialog } from 'shared/components';
import themeService from 'theme/ThemeService';

interface Props {
  memberKey: string;
  isRecruit: boolean;
  isRecruited: boolean;
  toggleRecruit(memberKey: string): void;
}

const palette = themeService.getPalette();

export const RecruitAgent: React.FC<Props> = ({
  memberKey,
  isRecruit,
  isRecruited,
  toggleRecruit,
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const dialogContent = isRecruit
    ? 'This will remove this agent from your recruiting watch list. Would you like to proceed?'
    : 'This will add this agent to your recruiting watch list. Would you like to proceed?';

  const handleConfirm = (confirmed: boolean) => {
    if (confirmed) {
      toggleRecruit(memberKey);
    }
    setOpenDialog(false);
  };

  return (
    <>
      <Button onClick={() => setOpenDialog(true)} disabled={isRecruited} variant="subtle">
        {isRecruit ? (
          <CheckBox htmlColor={palette.primary} />
        ) : (
          <CheckBoxOutlineBlank htmlColor={palette.primary} />
        )}
      </Button>

      <ConfirmationDialog
        isOpen={openDialog}
        title="Are you sure?"
        onConfirm={() => handleConfirm(true)}
        onCancel={() => handleConfirm(false)}
        onClose={() => handleConfirm(false)}
      >
        {dialogContent}
      </ConfirmationDialog>
    </>
  );
};

/* eslint-disable */
// tslint:disable
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';

import { CreateCompanyRequest } from '../model';
import { CreateCompanyResponse } from '../model';
import { GetCompaniesResponse } from '../model';
import { GetCompanyResponse } from '../model';
import { GetOfficeManagersResponse } from '../model';
import { GetOfficesResponse } from '../model';
import { GetS3UrlResponse } from '../model';
import { UpdateCompanyRequest } from '../model';
import { UpdateCompanyResponse } from '../model';
/**
 * CompaniesApi - axios parameter creator
 * @export
 */
export const CompaniesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} [filterValue]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companiesGet(
      filterValue?: string,
      options: any = {}
    ): RequestArgs {
      const localVarPath = `/companies`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (filterValue !== undefined) {
        localVarQueryParameter['filterValue'] = filterValue;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      //@ts-ignore
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {boolean} [hasLogo]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companiesHasLogoIdPost(id: number, hasLogo?: boolean, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling companiesHasLogoIdPost.'
        );
      }
      const localVarPath = `/companies/has-logo/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (hasLogo !== undefined) {
        localVarQueryParameter['hasLogo'] = hasLogo;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      //@ts-ignore
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companiesIdDelete(id: number, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling companiesIdDelete.'
        );
      }
      const localVarPath = `/companies/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      //@ts-ignore
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companiesIdGet(id: number, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling companiesIdGet.'
        );
      }
      const localVarPath = `/companies/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      //@ts-ignore
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companiesIdManagersGet(id: number, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling companiesIdManagersGet.'
        );
      }
      const localVarPath = `/companies/{id}/managers`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      //@ts-ignore
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companiesIdOfficesGet(id: number, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling companiesIdOfficesGet.'
        );
      }
      const localVarPath = `/companies/{id}/offices`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      //@ts-ignore
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {UpdateCompanyRequest} [updateCompanyRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companiesIdPost(
      id: number,
      updateCompanyRequest?: UpdateCompanyRequest,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling companiesIdPost.'
        );
      }
      const localVarPath = `/companies/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      //@ts-ignore
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof updateCompanyRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(updateCompanyRequest !== undefined ? updateCompanyRequest : {})
        : updateCompanyRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companiesLogoUrlIdGet(id: number, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling companiesLogoUrlIdGet.'
        );
      }
      const localVarPath = `/companies/logo-url/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      //@ts-ignore
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {CreateCompanyRequest} [createCompanyRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companiesPost(createCompanyRequest?: CreateCompanyRequest, options: any = {}): RequestArgs {
      const localVarPath = `/companies`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      //@ts-ignore
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof createCompanyRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(createCompanyRequest !== undefined ? createCompanyRequest : {})
        : createCompanyRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companiesUserCompanyGet(options: any = {}): RequestArgs {
      const localVarPath = `/companies/user-company`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      //@ts-ignore
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CompaniesApi - functional programming interface
 * @export
 */
export const CompaniesApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} [filterValue]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companiesGet(
      filterValue?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCompaniesResponse> {
      const localVarAxiosArgs = CompaniesApiAxiosParamCreator(configuration).companiesGet(
        filterValue,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} id
     * @param {boolean} [hasLogo]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companiesHasLogoIdPost(
      id: number,
      hasLogo?: boolean,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = CompaniesApiAxiosParamCreator(configuration).companiesHasLogoIdPost(
        id,
        hasLogo,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companiesIdDelete(
      id: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = CompaniesApiAxiosParamCreator(configuration).companiesIdDelete(
        id,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companiesIdGet(
      id: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCompanyResponse> {
      const localVarAxiosArgs = CompaniesApiAxiosParamCreator(configuration).companiesIdGet(
        id,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companiesIdManagersGet(
      id: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOfficeManagersResponse> {
      const localVarAxiosArgs = CompaniesApiAxiosParamCreator(configuration).companiesIdManagersGet(
        id,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companiesIdOfficesGet(
      id: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOfficesResponse> {
      const localVarAxiosArgs = CompaniesApiAxiosParamCreator(configuration).companiesIdOfficesGet(
        id,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} id
     * @param {UpdateCompanyRequest} [updateCompanyRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companiesIdPost(
      id: number,
      updateCompanyRequest?: UpdateCompanyRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateCompanyResponse> {
      const localVarAxiosArgs = CompaniesApiAxiosParamCreator(configuration).companiesIdPost(
        id,
        updateCompanyRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companiesLogoUrlIdGet(
      id: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetS3UrlResponse> {
      const localVarAxiosArgs = CompaniesApiAxiosParamCreator(configuration).companiesLogoUrlIdGet(
        id,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {CreateCompanyRequest} [createCompanyRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companiesPost(
      createCompanyRequest?: CreateCompanyRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateCompanyResponse> {
      const localVarAxiosArgs = CompaniesApiAxiosParamCreator(configuration).companiesPost(
        createCompanyRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companiesUserCompanyGet(
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCompanyResponse> {
      const localVarAxiosArgs =
        CompaniesApiAxiosParamCreator(configuration).companiesUserCompanyGet(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * CompaniesApi - factory interface
 * @export
 */
export const CompaniesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {string} [filterValue]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companiesGet(
      filterValue?: string,
      options?: any
    ): AxiosPromise<GetCompaniesResponse> {
      return CompaniesApiFp(configuration).companiesGet(
        filterValue,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {number} id
     * @param {boolean} [hasLogo]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companiesHasLogoIdPost(id: number, hasLogo?: boolean, options?: any): AxiosPromise<void> {
      return CompaniesApiFp(configuration).companiesHasLogoIdPost(
        id,
        hasLogo,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companiesIdDelete(id: number, options?: any): AxiosPromise<void> {
      return CompaniesApiFp(configuration).companiesIdDelete(id, options)(axios, basePath);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companiesIdGet(id: number, options?: any): AxiosPromise<GetCompanyResponse> {
      return CompaniesApiFp(configuration).companiesIdGet(id, options)(axios, basePath);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companiesIdManagersGet(id: number, options?: any): AxiosPromise<GetOfficeManagersResponse> {
      return CompaniesApiFp(configuration).companiesIdManagersGet(id, options)(axios, basePath);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companiesIdOfficesGet(id: number, options?: any): AxiosPromise<GetOfficesResponse> {
      return CompaniesApiFp(configuration).companiesIdOfficesGet(id, options)(axios, basePath);
    },
    /**
     *
     * @param {number} id
     * @param {UpdateCompanyRequest} [updateCompanyRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companiesIdPost(
      id: number,
      updateCompanyRequest?: UpdateCompanyRequest,
      options?: any
    ): AxiosPromise<UpdateCompanyResponse> {
      return CompaniesApiFp(configuration).companiesIdPost(
        id,
        updateCompanyRequest,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companiesLogoUrlIdGet(id: number, options?: any): AxiosPromise<GetS3UrlResponse> {
      return CompaniesApiFp(configuration).companiesLogoUrlIdGet(id, options)(axios, basePath);
    },
    /**
     *
     * @param {CreateCompanyRequest} [createCompanyRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companiesPost(
      createCompanyRequest?: CreateCompanyRequest,
      options?: any
    ): AxiosPromise<CreateCompanyResponse> {
      return CompaniesApiFp(configuration).companiesPost(createCompanyRequest, options)(
        axios,
        basePath
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companiesUserCompanyGet(options?: any): AxiosPromise<GetCompanyResponse> {
      return CompaniesApiFp(configuration).companiesUserCompanyGet(options)(axios, basePath);
    },
  };
};

/**
 * CompaniesApi - object-oriented interface
 * @export
 * @class CompaniesApi
 * @extends {BaseAPI}
 */
export class CompaniesApi extends BaseAPI {
  /**
   *
   * @param {string} [filterValue]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApi
   */
  public companiesGet(filterValue?: string, options?: any) {
    return CompaniesApiFp(this.configuration).companiesGet(
      filterValue,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} id
   * @param {boolean} [hasLogo]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApi
   */
  public companiesHasLogoIdPost(id: number, hasLogo?: boolean, options?: any) {
    return CompaniesApiFp(this.configuration).companiesHasLogoIdPost(
      id,
      hasLogo,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApi
   */
  public companiesIdDelete(id: number, options?: any) {
    return CompaniesApiFp(this.configuration).companiesIdDelete(id, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApi
   */
  public companiesIdGet(id: number, options?: any) {
    return CompaniesApiFp(this.configuration).companiesIdGet(id, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApi
   */
  public companiesIdManagersGet(id: number, options?: any) {
    return CompaniesApiFp(this.configuration).companiesIdManagersGet(id, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApi
   */
  public companiesIdOfficesGet(id: number, options?: any) {
    return CompaniesApiFp(this.configuration).companiesIdOfficesGet(id, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {number} id
   * @param {UpdateCompanyRequest} [updateCompanyRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApi
   */
  public companiesIdPost(id: number, updateCompanyRequest?: UpdateCompanyRequest, options?: any) {
    return CompaniesApiFp(this.configuration).companiesIdPost(
      id,
      updateCompanyRequest,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApi
   */
  public companiesLogoUrlIdGet(id: number, options?: any) {
    return CompaniesApiFp(this.configuration).companiesLogoUrlIdGet(id, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {CreateCompanyRequest} [createCompanyRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApi
   */
  public companiesPost(createCompanyRequest?: CreateCompanyRequest, options?: any) {
    return CompaniesApiFp(this.configuration).companiesPost(createCompanyRequest, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApi
   */
  public companiesUserCompanyGet(options?: any) {
    return CompaniesApiFp(this.configuration).companiesUserCompanyGet(options)(
      this.axios,
      this.basePath
    );
  }
}

import React from 'react';
import { FormControl, Select, MenuItem } from '@mui/material';
import { managerService } from '../../state/managers';
import { useManagers } from '../hooks';

interface Props {
  managerId: string;
}

export const ManagerPicker: React.FC<Props> = ({ managerId }) => {
  const managers = useManagers();

  const options = Array.from(managers);
  if (options.length === 0) return null;

  const onSelect = (value: string) => {
    managerService.setActiveManagerId(value);
  };

  return (
    <FormControl>
      <Select
        variant="outlined"
        value={managerId}
        onChange={event => onSelect(event.target.value as string)}
      >
        {options.map(({ id, name }) => (
          <MenuItem key={id} value={id}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

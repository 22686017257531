import { withRouter, useHistory } from 'react-router-dom';
import ReactGA from 'react-ga';

export const RouteChangeTracker = () => {
  const history = useHistory();

  history.listen((location, action) => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  });

  return null;
};

export default withRouter(RouteChangeTracker);

import { EntityState, EntityStore, StoreConfig, ActiveState } from '@datorama/akita';

import { MlsOffice } from './mlsoffices.models';

export interface MlsofficesState extends EntityState<MlsOffice>, ActiveState<MlsOffice> {
  ui: { companyKeys?: string[]; feeds?: number[] };
}

@StoreConfig({ name: 'mlsoffices', resettable: true, idKey: 'officeKey' })
export class MlsofficesStore extends EntityStore<MlsofficesState> {
  constructor() {
    super({ ui: { companyKeys: [], feeds: undefined } });
  }
}

export const mlsofficesStore = new MlsofficesStore();

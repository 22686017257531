import React from 'react';
import { VictoryGroup, VictoryLine, VictoryScatter } from 'victory';
import _ from 'lodash';
import { AgentVolumePeriod } from '../../state/mls';
import themeService from 'theme/ThemeService';

const chartPadding = { bottom: 10 };

interface Props {
  summary: Array<AgentVolumePeriod> | null | undefined;
  chartScope?: 'volume' | 'transactions' | 'both';
}

const getRatio = (maxSalesVolume: number, maxTransaction: number, currentTransaction: number) => {
  return (maxSalesVolume / maxTransaction) * currentTransaction;
};

const palette = themeService.getPalette();

export const AgentVolumeTrend = React.memo(({ summary, chartScope = 'both' }: Props) => {
  //if there isn't enough data to generate a chart, dont bother
  if (!summary || summary.length < 2) {
    return (
      <b>
        Insufficient
        <br />
        Data
      </b>
    );
  }

  //'both' will be what's on a list page
  if (chartScope === 'both') {
    const maxSalesVolume = _.max(summary.map(item => item.volume));
    const maxTransactions = _.max(summary.map(item => item.transactions));
    return (
      <VictoryGroup
        height={40}
        width={140}
        domainPadding={10}
        padding={chartPadding}
        singleQuadrantDomainPadding={false}
      >
        <VictoryGroup
          data={summary.map(item => ({
            x: item.dates.from?.toString(),
            y: item.volume,
          }))}
        >
          <VictoryLine />
          <VictoryScatter size={3} />
        </VictoryGroup>
        <VictoryGroup
          data={summary.map(item => ({
            x: item.dates.from?.toString(),
            y: getRatio(maxSalesVolume!, maxTransactions!, item.transactions),
          }))}
          style={{ data: { fill: palette.primary } }}
        >
          <VictoryLine />
          <VictoryScatter size={3} />
        </VictoryGroup>
      </VictoryGroup>
    );
  }

  //otherwise, it's being displayed on an agent details page
  return (
    <VictoryGroup
      height={80}
      width={140}
      domainPadding={10}
      padding={chartPadding}
      singleQuadrantDomainPadding={false}
    >
      <VictoryGroup
        data={summary.map(item => ({
          x: item.dates.from?.toString(),
          y: chartScope === 'volume' ? item.volume : item.transactions,
        }))}
        style={{ data: { fill: chartScope === 'volume' ? palette.primary : palette.purple } }}
      >
        <VictoryLine />
        <VictoryScatter size={3} />
      </VictoryGroup>
    </VictoryGroup>
  );
});

import { useEffect } from 'react';
import { useObservable } from '../shared';
import { MarketRegion, marketService, marketQuery, mlsQuery } from '../state';

export function useMarketVolume(region: MarketRegion, offices: string[]) {
  const dateRange = useObservable(mlsQuery.activeDateRange);
  useEffect(() => {
    marketService.getMarketVolume(dateRange, region, offices);
  }, [dateRange, region, offices]);

  const market = useObservable(marketQuery.market);
  const office = useObservable(marketQuery.office);

  return { market, office };
}

/* eslint-disable */
// tslint:disable
/**
 * MLS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';

import { GetFeedsResponse } from '../model';
import { UpdateFeedRequest } from '../model';
import { UpdateFeedResponse } from '../model';
/**
 * FeedsApi - axios parameter creator
 * @export
 */
export const FeedsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsFeedsGet(options: any = {}): RequestArgs {
      const localVarPath = `/mls/feeds`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      //@ts-ignore
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {UpdateFeedRequest} [updateFeedRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsFeedsIdPost(
      id: number,
      updateFeedRequest?: UpdateFeedRequest,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling mlsFeedsIdPost.'
        );
      }
      const localVarPath = `/mls/feeds/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      //@ts-ignore
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof updateFeedRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(updateFeedRequest !== undefined ? updateFeedRequest : {})
        : updateFeedRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * FeedsApi - functional programming interface
 * @export
 */
export const FeedsApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsFeedsGet(
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetFeedsResponse> {
      const localVarAxiosArgs = FeedsApiAxiosParamCreator(configuration).mlsFeedsGet(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} id
     * @param {UpdateFeedRequest} [updateFeedRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsFeedsIdPost(
      id: number,
      updateFeedRequest?: UpdateFeedRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateFeedResponse> {
      const localVarAxiosArgs = FeedsApiAxiosParamCreator(configuration).mlsFeedsIdPost(
        id,
        updateFeedRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * FeedsApi - factory interface
 * @export
 */
export const FeedsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsFeedsGet(options?: any): AxiosPromise<GetFeedsResponse> {
      return FeedsApiFp(configuration).mlsFeedsGet(options)(axios, basePath);
    },
    /**
     *
     * @param {number} id
     * @param {UpdateFeedRequest} [updateFeedRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsFeedsIdPost(
      id: number,
      updateFeedRequest?: UpdateFeedRequest,
      options?: any
    ): AxiosPromise<UpdateFeedResponse> {
      return FeedsApiFp(configuration).mlsFeedsIdPost(
        id,
        updateFeedRequest,
        options
      )(axios, basePath);
    },
  };
};

/**
 * FeedsApi - object-oriented interface
 * @export
 * @class FeedsApi
 * @extends {BaseAPI}
 */
export class FeedsApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FeedsApi
   */
  public mlsFeedsGet(options?: any) {
    return FeedsApiFp(this.configuration).mlsFeedsGet(options)(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} id
   * @param {UpdateFeedRequest} [updateFeedRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FeedsApi
   */
  public mlsFeedsIdPost(id: number, updateFeedRequest?: UpdateFeedRequest, options?: any) {
    return FeedsApiFp(this.configuration).mlsFeedsIdPost(
      id,
      updateFeedRequest,
      options
    )(this.axios, this.basePath);
  }
}

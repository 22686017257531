import React from 'react';
import { LoadingFade, PageHeader } from '../../shared';
import { SurveySubmission } from '../../state/surveys';
import { useAgentLookup } from '../../agents';
import { useAnswers, useQuestions } from '../hooks';
import { Grid, Card, CardHeader } from '@mui/material';
import { AnswerList } from './answer-list';
import { SubmissionInfo } from './submission-info';

interface Props {
  submission: SurveySubmission;
}

export function SubmissionDetails({ submission }: Props) {
  const agentLookup = useAgentLookup();
  const member = submission.memberKey ? agentLookup.get(submission.memberKey) : undefined;
  const coMember = submission.coMemberKey ? agentLookup.get(submission.coMemberKey) : undefined;
  const { answers, loading: answersLoading } = useAnswers(submission.id!);
  const { questions, loading: questionsLoading } = useQuestions(submission.surveyId!);

  return (
    <>
      <PageHeader title={`${member?.fullName}'s Submission`} backButton />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} md={3}>
          <SubmissionInfo submission={submission} member={member} coMember={coMember} />
        </Grid>
        <Grid item xs={12} sm={8} md={9}>
          <LoadingFade isContentLoading={answersLoading && questionsLoading}>
            <Card>
              <CardHeader title="Answers" sx={{ marginBottom: 2 }} />
              <AnswerList questions={questions} answers={answers} />
            </Card>
          </LoadingFade>
        </Grid>
      </Grid>
    </>
  );
}

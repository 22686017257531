import { QueryEntity } from '@datorama/akita';
import { switchMap } from 'rxjs/operators';
import { MlsQuery, mlsQuery } from 'state/mls/mls.query';

import { FavoritesState, FavoritesStore, favoritesStore } from './favorites.store';

export class FavoritesQuery extends QueryEntity<FavoritesState> {
  constructor(store: FavoritesStore, private readonly mls: MlsQuery) {
    super(store);
  }

  activeFavorite = this.selectActive();

  favorites = this.select(({ ui }) => ui).pipe(
    switchMap(({ managerId }) => this.selectAll({ filterBy: a => a.managerId === managerId }))
  );

  // used for Favorites widget in nav bar
  volumes = this.favorites.pipe(
    switchMap(favorites =>
      this.mls.getAgentVolumeQuery({ memberKeys: favorites.map(f => f.memberKey) })
    )
  );
}

export const favoritesQuery = new FavoritesQuery(favoritesStore, mlsQuery);

import { from } from 'rxjs';

import { SalesVolume, SearchApi, SearchType } from '../../api/mls';

import { SearchForm, SortField, SortOrder } from './search.model';
import { saveAs } from 'file-saver';

export class SearchService {
  constructor(private readonly searchApi: SearchApi) {}

  exportAgents(
    searchForm: SearchForm,
    sortField: SortField,
    sortOrder: SortOrder,
    type: SearchType
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      from(
        this.searchApi.mlsSearchAgentsExportPost(
          {
            sortField,
            sortOrder,
            ...prepareSearchForm(searchForm),
            searchType: type,
          },
          { responseType: 'blob' }
        )
      ).subscribe(
        response => {
          saveAs(response.data as any, 'AgentExport.csv');
          resolve();
        },
        error => {
          reject(error);
        }
      );
    });
  }

  exportOffices(searchForm: SearchForm, sortField: SortField, sortOrder: SortOrder): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      from(
        this.searchApi.mlsSearchOfficesExportPost(
          {
            sortField,
            sortOrder,
            ...prepareSearchForm(searchForm),
          },
          { responseType: 'blob' }
        )
      ).subscribe(
        response => {
          saveAs(response.data as any, 'OfficesExport.csv');
          resolve();
        },
        error => {
          reject(error);
        }
      );
    });
  }

  exportCompanies(
    searchForm: SearchForm,
    sortField: SortField,
    sortOrder: SortOrder
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      from(
        this.searchApi.mlsSearchCompaniesExportPost(
          {
            sortField,
            sortOrder,
            ...prepareSearchForm(searchForm),
          },
          { responseType: 'blob' }
        )
      ).subscribe(
        response => {
          saveAs(response.data as any, 'CompaniesExport.csv');
          resolve();
        },
        error => {
          reject(error);
        }
      );
    });
  }
}

function prepareSearchForm(form: SearchForm) {
  const { feed, ...rest } = form;

  const salesVolume: SalesVolume = {
    percentChange: rest.percentageChange,
    salesChange: rest.salesChange,
  };

  return { feed: feed?.map(Number), ...rest, salesVolume };
}

export const searchService = new SearchService(new SearchApi());

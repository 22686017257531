import { QueryEntity } from '@datorama/akita';

import {
  InterstitialState,
  interstitialStore,
  TermsAcceptanceState,
  termsAcceptanceStore
} from './interstitial.store';

export class InterstitialQuery extends QueryEntity<InterstitialState> {
  interstitial = this.selectActive();
}

export class TermsAcceptanceQuery extends QueryEntity<TermsAcceptanceState> {
  acceptanceList = this.selectAll();
}

export const interstitialQuery = new InterstitialQuery(interstitialStore);

export const termsAcceptanceQuery = new TermsAcceptanceQuery(termsAcceptanceStore);

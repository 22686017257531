import React from 'react';
import { LoadingFade } from '../../shared';
import { SubmissionDetails } from '../components';
import { useParams } from 'react-router-dom';
import { useGetSubmission } from '../hooks';
import { mlsService } from '../../state';

export function SubmissionDetailsPage() {
  const { submissionId } = useParams<{ submissionId: string }>();
  const { submission, loading } = useGetSubmission(+submissionId);
  const { memberKey, coMemberKey } = submission || {};
  mlsService.getAgents([memberKey ?? '', coMemberKey ?? '']);

  return (
    <LoadingFade isContentLoading={loading}>
      <SubmissionDetails submission={submission} />
    </LoadingFade>
  );
}

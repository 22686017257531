import React, {useEffect, useState } from 'react';
import { Grid, Box, Card, CardHeader, CardContent } from '@mui/material';

import { Agent, mlsQuery, mlsService, DateRangeOptions, activitiesService } from '../../state';
import {
  useObservable,
  PeriodPicker,
  useManager,
  RecruitingPipeline,
  RecruitingGoal,
  LoadingFade,
  useLoadingIndicator,
  PageHeader,
  icons,
} from '../../shared';
import { RecruitingWatchList, ManagerPicker } from '../components';
import { ActivityHistoryChart, ActiveAgentContext } from '../../shared/components/activity-history';
import { withManagerId } from '../../shared/utils/withManagerId';
import { useRecruit } from '../useRecruit';
import { RecruitModel } from 'api/app';

interface Props {
  managerId: string;
  agent: Agent;
}

const RecruitingDashboard: React.FC<Props> = ({ managerId, agent }) => {
  let loading = useLoadingIndicator();

  const dateRange = useObservable(mlsQuery.activeDateRange);
  const updateDateRange = (newRange: DateRangeOptions) => {
    mlsService.setActiveDateRange(newRange);
    activitiesService.setActiveDateRange(newRange);
  };

  const { recruitingGoal, recruits, volume, activities: activityData } = useManager(managerId);
  const { isRecruit, isRecruited, toggleRecruit } = useRecruit(managerId);
  const [recruitList, setRecruitList] = useState<RecruitModel[]>(recruits);

  useEffect(() => {
    let newRecruitList: RecruitModel[] = [];
    recruits.forEach(r => {
      let updatedRecruit = { ...r };
      let activities = activityData.filter(a => a.recruitId === r.id);
      activities.forEach(a => {
        if (a.recordedAt && updatedRecruit.lastActivity && a.recordedAt > updatedRecruit.lastActivity.recordedAt!) {
          updatedRecruit.lastActivity = a;
        }
      });
      newRecruitList.push(updatedRecruit);
    });
    setRecruitList(newRecruitList);
  }, [recruits, activityData]);

  return (
    <>
      <PageHeader
        title="PIPELINE"
        icon={icons.filter}
        iconSize='large'
        filters={[
          <PeriodPicker activeRange={dateRange} updateRange={updateDateRange} />,
          <ManagerPicker managerId={managerId} />,
        ]}
      />
      <LoadingFade
        isContentLoading={loading}
        content={() => (
          <Grid container spacing={2} sx={{ height: '100%' }}>
            <Grid item container xs={12} spacing={2} sx={{ flexGrow: 1 }}>
              <Grid item xs={12} sm={4} sx={{ display: 'flex' }}>
                <RecruitingPipeline recruits={recruitList} volume={volume} />
              </Grid>
              <Grid item xs={12} sm={4} sx={{ display: 'flex' }}>
                <RecruitingGoal recruits={recruitList} volume={volume} goal={recruitingGoal} />
              </Grid>
              <Grid item xs={12} sm={4} sx={{ display: 'flex' }}>
                <Card sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                  <CardHeader title="Activity" />
                  <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                    <ActivityHistoryChart data={activityData} />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <ActiveAgentContext.Provider value={{ agent: agent }}>
                <RecruitingWatchList
                  recruits={recruitList}
                  setRecruits={setRecruitList}
                  volume={volume}
                  managerId={managerId}
                  toggleRecruit={toggleRecruit}
                  isRecruited={isRecruited}
                  isRecruit={isRecruit}
                />
              </ActiveAgentContext.Provider>
            </Grid>
          </Grid>
        )}
      />
    </>
  );
};

export const RecruitingDashboardPage = withManagerId(RecruitingDashboard);

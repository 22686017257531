import { identity } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  EntityState,
  EntityStore,
  StoreConfig,
  QueryEntity,
  MultiActiveState,
} from '@datorama/akita';
import { OfficeVolume } from '../mls.models';

export interface OfficeVolumeState
  extends EntityState<OfficeVolume, OfficeVolume['officeKey']>,
    MultiActiveState {}

@StoreConfig({
  name: 'officeVolume',
  resettable: true,
  idKey: 'officeKey',
  deepFreezeFn: identity,
})
export class OfficeVolumeStore extends EntityStore<OfficeVolumeState> {
  static instance: OfficeVolumeStore;

  constructor() {
    super({
      active: [],
      //Akita already initializes the store with loading set to true.
      //we need it to be false to drive our progress indicators
      //which rely on this flag starting off as false
      //https://github.com/datorama/akita/issues/22#issuecomment-408659859
      loading: false,
    });
  }
}

export class OfficeVolumeQuery extends QueryEntity<OfficeVolumeState> {
  static instance: OfficeVolumeQuery;

  activeKeys = this.selectActiveId().pipe(map(ids => ids as string[]));
}

OfficeVolumeStore.instance = new OfficeVolumeStore();
OfficeVolumeQuery.instance = new OfficeVolumeQuery(OfficeVolumeStore.instance);

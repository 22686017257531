import { AxiosRequestConfig, AxiosResponse, AxiosInstance } from 'axios';

import { sessionQuery } from 'state/session/session.query';

declare module 'axios' {
  interface AxiosRequestConfig {
    tokenRefreshed?: boolean;
  }
}

export function withAuthentication(client: AxiosInstance) {
  client.interceptors.request.use(async request => {
    const token = getAuthToken();
    if (token == null) return request;

    request.headers['Authorization'] = `Bearer ${token.accessToken}`;

    if (request.tokenRefreshed === undefined) request.tokenRefreshed = false;

    return request;
  });

  client.interceptors.response.use(undefined, async error => {
    if (!isAuthError(error) || error.config.tokenRefreshed) throw error;

    const token = getAuthToken();
    if (token?.refreshToken == null) throw error;

    error.config.tokenRefreshed = true;
    const { sessionService } = await import('state/session/session.service');
    await sessionService.refreshToken(token);

    return await client(error.config);
  });

  return client;
}

function getAuthToken() {
  const auth = sessionQuery.getValue().auth;

  if (!auth.authenticated) return null;

  return auth.token;
}

interface AuthError {
  config: AxiosRequestConfig;
  response: AxiosResponse;
}

function isAuthError(error: any): error is AuthError {
  const err = error as AuthError;
  return err.config !== undefined && err.response?.status === 401;
}

/* eslint-disable */
// tslint:disable
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';

import { CreateActivityRequest } from '../model';
import { CreateActivityResponse } from '../model';
import { GetRecruitActivitiesResponse } from '../model';
import { UpdateActivityRequest } from '../model';
import { UpdateActivityResponse } from '../model';
/**
 * ActivityApi - axios parameter creator
 * @export
 */
export const ActivityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityIdDelete(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling activityIdDelete.');
            }
            const localVarPath = `/activity/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            //@ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateActivityRequest} [updateActivityRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityIdPost(id: number, updateActivityRequest?: UpdateActivityRequest, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling activityIdPost.');
            }
            const localVarPath = `/activity/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            //@ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof updateActivityRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateActivityRequest !== undefined ? updateActivityRequest : {}) : (updateActivityRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateActivityRequest} [createActivityRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityPost(createActivityRequest?: CreateActivityRequest, options: any = {}): RequestArgs {
            const localVarPath = `/activity`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            //@ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof createActivityRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createActivityRequest !== undefined ? createActivityRequest : {}) : (createActivityRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityRecruitIdGet(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling activityRecruitIdGet.');
            }
            const localVarPath = `/activity/recruit/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            //@ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} managerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityRecruitManagerManagerIdGet(managerId: string, options: any = {}): RequestArgs {
            // verify required parameter 'managerId' is not null or undefined
            if (managerId === null || managerId === undefined) {
                throw new RequiredError('managerId','Required parameter managerId was null or undefined when calling activityRecruitManagerManagerIdGet.');
            }
            const localVarPath = `/activity/recruit/manager/{managerId}`
                .replace(`{${"managerId"}}`, encodeURIComponent(String(managerId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            //@ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActivityApi - functional programming interface
 * @export
 */
export const ActivityApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityIdDelete(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ActivityApiAxiosParamCreator(configuration).activityIdDelete(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateActivityRequest} [updateActivityRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityIdPost(id: number, updateActivityRequest?: UpdateActivityRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateActivityResponse> {
            const localVarAxiosArgs = ActivityApiAxiosParamCreator(configuration).activityIdPost(id, updateActivityRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {CreateActivityRequest} [createActivityRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityPost(createActivityRequest?: CreateActivityRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateActivityResponse> {
            const localVarAxiosArgs = ActivityApiAxiosParamCreator(configuration).activityPost(createActivityRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityRecruitIdGet(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRecruitActivitiesResponse> {
            const localVarAxiosArgs = ActivityApiAxiosParamCreator(configuration).activityRecruitIdGet(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} managerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityRecruitManagerManagerIdGet(managerId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRecruitActivitiesResponse> {
            const localVarAxiosArgs = ActivityApiAxiosParamCreator(configuration).activityRecruitManagerManagerIdGet(managerId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ActivityApi - factory interface
 * @export
 */
export const ActivityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityIdDelete(id: number, options?: any): AxiosPromise<void> {
            return ActivityApiFp(configuration).activityIdDelete(id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateActivityRequest} [updateActivityRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityIdPost(id: number, updateActivityRequest?: UpdateActivityRequest, options?: any): AxiosPromise<UpdateActivityResponse> {
            return ActivityApiFp(configuration).activityIdPost(id, updateActivityRequest, options)(axios, basePath);
        },
        /**
         * 
         * @param {CreateActivityRequest} [createActivityRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityPost(createActivityRequest?: CreateActivityRequest, options?: any): AxiosPromise<CreateActivityResponse> {
            return ActivityApiFp(configuration).activityPost(createActivityRequest, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityRecruitIdGet(id: number, options?: any): AxiosPromise<GetRecruitActivitiesResponse> {
            return ActivityApiFp(configuration).activityRecruitIdGet(id, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} managerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityRecruitManagerManagerIdGet(managerId: string, options?: any): AxiosPromise<GetRecruitActivitiesResponse> {
            return ActivityApiFp(configuration).activityRecruitManagerManagerIdGet(managerId, options)(axios, basePath);
        },
    };
};

/**
 * ActivityApi - object-oriented interface
 * @export
 * @class ActivityApi
 * @extends {BaseAPI}
 */
export class ActivityApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public activityIdDelete(id: number, options?: any) {
        return ActivityApiFp(this.configuration).activityIdDelete(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {UpdateActivityRequest} [updateActivityRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public activityIdPost(id: number, updateActivityRequest?: UpdateActivityRequest, options?: any) {
        return ActivityApiFp(this.configuration).activityIdPost(id, updateActivityRequest, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {CreateActivityRequest} [createActivityRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public activityPost(createActivityRequest?: CreateActivityRequest, options?: any) {
        return ActivityApiFp(this.configuration).activityPost(createActivityRequest, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public activityRecruitIdGet(id: number, options?: any) {
        return ActivityApiFp(this.configuration).activityRecruitIdGet(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} managerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public activityRecruitManagerManagerIdGet(managerId: string, options?: any) {
        return ActivityApiFp(this.configuration).activityRecruitManagerManagerIdGet(managerId, options)(this.axios, this.basePath);
    }

}

import themeService from 'theme/ThemeService';

const palette = themeService.getPalette();

export const theme = {
  block: {
    backgroundColor: palette.neutralLightest,
    borderColor: palette.neutral,
  },
};

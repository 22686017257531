import { createTheme, responsiveFontSizes } from '@mui/material';
import { common } from '@mui/material/colors';
import themeService from './ThemeService';

const palette = themeService.getPalette();

export const theme = responsiveFontSizes(
  createTheme({
    palette: {
      background: {
        default: palette.neutralLightest,
      },
      primary: {
        main: palette.primary,
        contrastText: palette.neutralLightest,
      },
      secondary: {
        main: palette.neutralLightest,
        contrastText: palette.neutralDarkest,
      },
    },
    typography: {
      body1: {
        fontFamily: 'Albert Sans',
        fontWeight: 500,
        letterSpacing: '0.025em',
        fontSize: '.813rem',
      },
      body2: {
        letterSpacing: '0.025em',
        fontWeight: 400,
        fontFamily: 'Albert Sans',
      },
      h1: {
        fontFamily: 'Albert Sans',
        fontSize: '2rem',
        fontWeight: 400,
      },
      h4: {
        fontFamily: 'Albert Sans',
        fontSize: '1.75rem',
        fontWeight: 400,
      },
      button: {
        textTransform: 'unset',
        letterSpacing: '0.075em',
        fontFamily: 'Albert Sans',
      },
    },
    shape: {
      borderRadius: 5,
    },
    components: {
      MuiPaper: {
        defaultProps: {
          square: true,
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            boxShadow: '0px 8px 16px rgb(0 0 0 / 10%)',
            borderRadius: '.5rem',
            backgroundColor: common.white,
            display: 'flex!important',
            flexDirection: 'column',
            flex: '1 1 auto',
            padding: '1rem',
            fontFamily: 'Albert Sans',
          },
        },
      },
      MuiCardHeader: {
        defaultProps: {
          titleTypographyProps: {
            variant: 'h5',
            fontFamily: 'Albert Sans',
          },
        },
        styleOverrides: {
          root: {
            paddingBottom: 0,
            fontSize: '1.125rem',
            fontWeight: 700,
            fontFamily: 'Albert Sans',
          },
          action: {
            marginTop: 0,
            marginRight: 0,
            alignSelf: 'unset',
            fontFamily: 'Albert Sans',
          },
          title: {
            paddingBottom: 0,
            fontSize: '1.125rem',
            fontWeight: 700,
            fontFamily: 'Albert Sans',
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            boxShadow: 'none!important',
            backgroundColor: palette.neutralLightest,
            color: palette.neutralDark,
            padding: '0.5rem',
            paddingLeft: '0.75rem',
            fontFamily: 'Albert Sans',
          },
        },
      },
    },
  })
);

import React, { useState, useEffect, memo } from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import GoogleMapReact, { Heatmap } from 'google-map-react';

import { hasAddress, Listing, Office } from '../../state';
import { ListingMarker, OfficeMarker, deferred } from '../../shared';

import { AgentTransactionStatsMap } from './agent-transaction-stats-map';
import { MapStatsColor } from 'shared/components/map-stat';

interface Props {
  listings: Array<Listing>;
  heatmap?: boolean;
  offices?: Array<Office>;
}

declare var google: any;
const GoogleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY!;

interface Location {
  lat: number;
  lng: number;
}

const useStyles = makeStyles({
  map: {
    // we force the map to grow by a given aspect ratio
    position: 'relative',
    width: '100%',
    paddingBottom: '56.25%',

    '&>div': {
      position: 'absolute !important',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
    },
  },
});

const Map: React.FC<Props> = memo(({ listings, heatmap = false, offices = [] }) => {
  const styles = useStyles();

  const [map, setMap] = useState<any>();
  const [officePins, setOfficePins] = useState<Array<Office & Location>>([]);

  useEffect(() => {
    // todo: this should be improved, using an observable with cancellation support
    // .. instead of allowing the operation to continue running and discarding results
    let isMounted = true;

    const update: typeof setOfficePins = pins => isMounted && setOfficePins(pins);
    if (map && offices.length > 0) {
      geocodeOffices(offices, pins => update(pins));
    }

    return () => {
      isMounted = false;
    };
  }, [map, offices]);

  useEffect(() => {
    if (map && listings.length > 0) {
      centerMap(map, listings, officePins);
    }
  }, [map, listings, officePins]);

  const buckets: Array<{ listings: Listing[]; label: string; color: MapStatsColor }> = [
    {
      label: 'Active Listings',
      color: 'teal',
      listings: listings.filter(l => l.status === 'Active'),
    },
    {
      label: 'Pending Transactions',
      color: 'yellow',
      listings: listings.filter(l => l.status === 'Pending'),
    },
    {
      label: 'Closed Transactions',
      color: 'purple',
      listings: listings.filter(l => l.status === 'Closed'),
    },
    {
      label: 'Expired Listings',
      color: 'red',
      listings: listings.filter(l => l.status === 'Expired'),
    },
  ];

  let heatmapData: Heatmap | undefined = undefined;
  if (heatmap) {
    heatmapData = {
      positions: listings
        .filter(l => l.latitude && l.longitude)
        .map(l => ({
          lat: l.latitude!,
          lng: l.longitude!,
          weight: l.closePrice ?? l.listPrice,
        })),
      options: {
        radius: 20,
        opacity: 1,
      },
    };
  }

  return (
    <>
      <Box marginBottom={2}>
        <AgentTransactionStatsMap buckets={buckets} />
      </Box>
      <Box className={styles.map}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: GoogleMapsApiKey, libraries: ['visualization'] }}
          defaultCenter={{ lat: 0, lng: 0 }}
          defaultZoom={12}
          heatmap={heatmapData}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map }) => setMap(map)}
        >
          {!heatmap && // exclude markers if we have heatmap enabled
            buckets.flatMap(({ color, listings }) =>
              listings.map(l => (
                <ListingMarker
                  key={l.listingKey}
                  lat={l.latitude}
                  lng={l.longitude}
                  name={hasAddress(l) ? l.address : l.listingKey}
                  color={color}
                />
              ))
            )}

          {officePins.map(o => (
            <OfficeMarker key={o.officeKey} lat={o.lat} lng={o.lng} name={o.officeName} />
          ))}
        </GoogleMapReact>
      </Box>
    </>
  );
});

function centerMap(map: google.maps.Map, listings: Array<Listing>, offices: Array<Office & Location> = []) {
  const bounds = new google.maps.LatLngBounds();

  listings.forEach(l => {
    if (
      l.latitude &&
      l.longitude &&
      l.latitude <= 90 &&
      l.latitude >= -90 &&
      l.longitude >= -180 &&
      l.longitude <= 180 &&
      l.latitude !== 0 &&
      l.longitude !== 0
    ) {
      const location = new google.maps.LatLng(l.latitude, l.longitude);
      bounds.extend(location);
    }
  });

  if (offices.length > 0) {
    offices.forEach(o => {
      if (
        o.lat &&
        o.lng &&
        o.lat <= 90 &&
        o.lat >= -90 &&
        o.lng >= -180 &&
        o.lng <= 180 &&
        o.lat !== 0 &&
        o.lng !== 0
      ) {
        const location = new google.maps.LatLng(o.lat, o.lng);
        bounds.extend(location);
      }
    });
  }

  map.fitBounds(bounds);
}

function geocodeOffices(
  offices: Office[],
  callback: (officePins: Array<Office & Location>) => void
) {
  Promise.all(offices.map(geocodeOffice)).then(callback);
}

async function geocodeOffice(office: Office) {
  const geocoder = new google.maps.Geocoder();
  const address = [office.address, office.city, office.state, office.postalCode].join(' ');

  return new Promise<Office & Location>((resolve, reject) => {
    geocoder.geocode(
      {
        address: address,
      },
      function (results: { geometry: { location: any } }[], status: string) {
        if (status === google.maps.GeocoderStatus.OK) {
          const loc = results[0]?.geometry?.location;
          if (loc) {
            const lat = loc.lat();
            const lng = loc.lng();
            resolve({ ...office, lat, lng });
            return;
          }
        }

        reject(`Geocoding failed for address: ${address}`);
      }
    );
  });
}

export const AgentTransactionMap = deferred(Map);

import { AriaAttributes } from 'react';

export function aria(
  valid: boolean,
  touched: boolean,
  ...errors: string[]
): Partial<AriaAttributes> | null {
  if (valid || !touched) return null;

  return {
    'aria-invalid': true,
    'aria-errormessage': errors[0],
  };
}

export function asArray<T>(value?: T | T[]): T[] {
  if (value === undefined) return [];
  if (Array.isArray(value)) return value;
  return [value];
}

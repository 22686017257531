import { ReactNode } from 'react';
import { DataGrid, GridColDef, DataGridProps as MuiDataGridProps, GridValidRowModel } from '@mui/x-data-grid';
import { LoadingIndicator } from 'shared/components/loading-indicator';
import { useTheme } from 'theme/ThemeContext';

type AccentColor = 'none' | 'orange' | 'green' | 'red' | 'magenta';

export type DataTableProps<R extends GridValidRowModel> = MuiDataGridProps<R> & {
  rows: R[];
  columns: DataTableColumn<R>[];
  progressPending?: boolean;
  showPagination?: boolean;
  noDataComponent?: ReactNode;
  conditionalRowStyles?: (row: R) => AccentColor;
};

export type DataTableColumn<T extends Object> = GridColDef<T[][number]>;

const defaultColumnProps = {
  flex: 1,
}

export function DataTable<T extends GridValidRowModel,>({
  progressPending,
  showPagination = false,
  noDataComponent,
  ...rest
}: DataTableProps<T>) {
  const theme = useTheme();

  if (progressPending) {
    return <LoadingIndicator loadingText="Loading..." />;
  }

  if (rest.rows.length === 0) {
    if (noDataComponent) return <>{noDataComponent}</>;

    return <div>There are no records to display</div>;
  }

  return (
    <DataGrid
      {...rest}
      columns={rest.columns.map((column) => ({...defaultColumnProps, ...column}))}
      sx={{
        minHeight: '200px',
        '& .MuiDataGrid-container--top [role=row]': {
          background: '#f1f1f1',
        },
        '& .orange': {
          borderLeft: '5px solid',
          borderColor: theme.dataTable.accentRowColors.orange,
        },
        '& .green': {
          borderLeft: '5px solid',
          borderColor: theme.dataTable.accentRowColors.green,
        },
        '& .red': {
          borderLeft: '5px solid',
          borderColor: theme.dataTable.accentRowColors.red,
        },
        '& .magenta': {
          borderLeft: '5px solid',
          borderColor: theme.dataTable.accentRowColors.magenta,
        }
      }}
      hideFooterPagination={!showPagination}
      hideFooter={!showPagination}
      getRowClassName={({row}) => rest.conditionalRowStyles ? rest.conditionalRowStyles(row) : ''}
      autoHeight={true}
    />
  );
}

import React, { useEffect } from 'react';
import { Card, CardContent } from '@mui/material';
import { useFormEvents } from '../../shared/forms';
import { FormStatus } from '../../state';
import { EditCompanyForm } from '../components/edit-company.form';
import {
  companiesService,
  CompanyForms,
  EditCompanyForm as EditCompanyFormModel,
  mlsofficesService,
} from '../../state/admin';
import { useOffices, useRegions } from '../hooks';

export const CreateCompanyPage: React.FC = () => {
  const [{ status, error }, resetFormUI] = useFormEvents(CompanyForms.Create);

  useEffect(() => {
    companiesService.clearActive();
    mlsofficesService.setCompanyKeys(undefined);
  }, []);

  const { companies, offices, feedOptions } = useOffices();
  const { cities } = useRegions();

  const submit = (form: EditCompanyFormModel) => {
    companiesService.createCompany(form);
  };

  return (
    <Card>
      <CardContent>
        <EditCompanyForm
          companies={companies}
          offices={offices}
          feedOptions={feedOptions}
          cityOptions={cities}
          onSubmit={submit}
          onResetFeedback={resetFormUI}
          error={error}
          success={status === FormStatus.Success}
          pending={status === FormStatus.Pending}
          numOfUsers={0}
        />
      </CardContent>
    </Card>
  );
};

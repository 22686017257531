import React from 'react';
import { Card, CardHeader, CardContent, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { ReportType, Filter } from '../../state/search/reportType';
import { ThemeColors } from '../../shared';

interface FilterGroupProps {
  reportType: ReportType;
  filters: Filter | Filter[];
  title: string;
}

const useStyles = makeStyles(() => ({
  card: () => ({
    boxShadow: 'none',
    backgroundColor: 'none',
    borderRadius: '0',
  }),
  box: () => ({
    borderBottom: 'solid thin',
    borderBottomColor: ThemeColors.DarkerGray,
    '&::last': { borderBottom: '0px' },
  }),
}));

export const FilterGroup: React.FC<FilterGroupProps> = ({
  reportType,
  filters,
  title,
  children,
}) => {
  const classes = useStyles(reportType);

  const filterArr = Array.isArray(filters) ? filters : [filters];

  if (!filterArr.some(filter => reportType.filters.includes(filter))) return null;

  return (
    <Box mb={0} className={classes.box}>
      <Card className={classes.card}>
        <CardHeader title={title} />
        <CardContent>{children}</CardContent>
      </Card>
    </Box>
  );
};

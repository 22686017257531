import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Box, Grid } from '@mui/material';
import { Button } from 'shared/components/button';
import { Typography } from 'shared/components/typography';
import { ArrowBack } from '@mui/icons-material';
import { PaginationProps } from '../../shared';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import themeService from 'theme/ThemeService';
import { faSquareFull } from '@fortawesome/free-solid-svg-icons';

const palette = themeService.getPalette();

interface PageHeaderProps {
  title?: React.ReactNode;
  backButton?: boolean;
  navigation?: React.ReactNodeArray;
  filters?: React.ReactNodeArray;
  icon?: IconProp;
  iconSize?: 'small' | 'medium' | 'large' | undefined;
}

export const PageHeader: React.FC<PageHeaderProps> = ({
  children,
  title,
  backButton = false,
  navigation,
  filters,
  icon,
  iconSize,
}) => {
  const history = useHistory();
  const location = useLocation<PaginationProps>();

  const backButtonClick = () => {
    if (location.state) {
      history.push(
        `${location.state.prevPath}`,
        {
          pageNum: location.state.pageNum,
          dateRange: location.state.dateRange,
          rowsPerPage: location.state.rowsPerPage
        });
    } else {
      history.goBack();
    }
  };

  return (
    <Box mb={2}>
      <Grid container justifyContent="space-between" spacing={1}>
        <Grid container item xs={12} md={'auto'}>
          {backButton && history.length > 0 && (
            <Grid item>
              <Button onClick={() => backButtonClick()} variant='subtle'>
                <ArrowBack></ArrowBack>
              </Button>
            </Grid>
          )}

          {React.Children.map(navigation, (child, i) => (
            <Grid item key={i}>{child}</Grid>
          ))}

          <Grid item>
            {!!children ? (
              children
            ) : (
              <Box display="flex" alignItems="center">
                {icon && (
                  <FontAwesomeIcon
                    icon={icon}
                    mask={faSquareFull}
                    size="2x"
                    transform="shrink-3.5"
                    style={{
                      marginRight: '0.5rem',
                      backgroundImage: palette.magentaGradient,
                      color: palette.neutralLightest,
                      fontSize: getIconSize(iconSize),
                    }}
                  />
                )}
                <Typography variant="h4" sx={{fontWeight: 800, color: palette.neutralDark}} component="span">
                  {title}
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
        <Grid container justifyContent="end" item xs={12} md={'auto'} spacing={1}>
          {React.Children.map(filters, (child, i) => (
            <Grid item key={i}>{child}</Grid>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

function getIconSize(size?: string) {
  if (!size) {return '2.5rem'};
  switch (size) {
    case 'small': {
      return '2rem';
    }
    case 'medium': {
      return '2.5rem';
    }
    case 'large': {
      return '3rem';
    }
    default: {
      return '2.5rem';
    }
  }
}

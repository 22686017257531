import { useMemo } from 'react';

import { mlsQuery, officeQuery } from '../../state';
import { useObservable } from '../../shared/utils';

export function useMlsOfficeLookup() {
  const offices = useObservable(mlsQuery.offices);
  return offices;
}

export function useAppOfficeLookup() {
  const hierarchy = useObservable(officeQuery.officeHierarchy);
  const activeOfficeCodes = useObservable(officeQuery.activeOfficeCodes);

  // produce a map from the office hierarchy so that we have both the broker and branch ids
  const offices = useMemo(
    () =>
      new Map(
        Array.from(hierarchy.entries()).flatMap(([brokerId, { branches }]) =>
          branches.flatMap(branch => branch.codes.map(code => [code, { ...branch, brokerId }]))
        )
      ),
    [hierarchy]
  );

  return [offices, activeOfficeCodes] as const;
}

import {
  addYears,
  startOfToday,
  addDays,
  addMonths,
  startOfYear,
  startOfMonth,
  endOfMonth,
  formatISO,
} from 'date-fns';
import { Listing } from './mls.models';

type DateValueSelector<TValue> = (date: string) => TValue;
type DateStringSelector<T> = (input: T) => string | null | undefined;
type DatePredicate<T> = (input: T, compareBy: DateStringSelector<T>) => boolean;

export enum DateRangeOptions {
  Last365Days = 'Last 365 Days',
  YearToDate = 'Year over Year',
  MonthToDate = 'This Month',
  Last12Months = 'Last 12 Months',
  Last3Months = 'Last 3 Months',
}
export interface DateFilter<T> {
  currentPeriod: DatePredicate<T>;
  previousPeriod: DatePredicate<T>;
  futurePeriod: DatePredicate<T>;
}

function predicateFactory<T>(startDate: Date, endDate: Date): DatePredicate<T> {
  // perform a sortable date string comparison, instead of parsing the dates
  const startStr = formatISO(startDate, { representation: 'date' });
  const endStr = formatISO(endDate, { representation: 'date' }) + '~';

  return dateSelector<T, boolean>(date => startStr <= date && date <= endStr, false);
}

export function dateSelector<T, TValue>(
  selector: DateValueSelector<TValue>,
  defaultValue: TValue
): (input: T, compareBy: DateStringSelector<T>) => TValue;
export function dateSelector<T, TValue>(
  selector: DateValueSelector<TValue>
): (input: T, compareBy: DateStringSelector<T>) => TValue | undefined;
export function dateSelector<T, TValue>(
  selector: DateValueSelector<TValue>,
  defaultValue?: TValue
) {
  return (input: T, compareBy: DateStringSelector<T>) => {
    const date = compareBy(input);
    return !!date && date !== '1900-01-01' ? selector(date) : defaultValue;
  };
}

export function dateRangeFilter<T>(
  startDate: Date,
  endDate: Date,
  priorStartDate: Date,
  priorEndDate: Date
): DateFilter<T> {
  const currentPeriod = predicateFactory<T>(startDate, endDate);
  const previousPeriod = predicateFactory<T>(priorStartDate, priorEndDate);
  const futurePeriod = predicateFactory<T>(endDate, addYears(endDate, 1000));

  return {
    currentPeriod,
    previousPeriod,
    futurePeriod,
  };
}

export function getStartAndEndDates(dateRange: DateRangeOptions, today: Date = startOfToday()) {
  let startDate;
  let endDate;
  let priorStartDate;
  let priorEndDate;

  switch (dateRange) {
    case DateRangeOptions.Last365Days:
      startDate = addDays(today, -365);
      endDate = today;
      priorStartDate = addDays(startDate, -365);
      priorEndDate = addDays(startDate, -1);
      break;
    case DateRangeOptions.YearToDate:
      startDate = startOfYear(today);
      endDate = today;
      priorStartDate = addYears(startDate, -1);
      priorEndDate = addYears(endDate, -1);
      break;
    case DateRangeOptions.MonthToDate:
      startDate = startOfMonth(today);
      endDate = today;
      priorStartDate = addYears(startDate, -1);
      priorEndDate = addYears(endDate, -1);
      break;
    case DateRangeOptions.Last12Months:
      startDate = addMonths(startOfMonth(today), -11);
      endDate = today;
      priorStartDate = addYears(startDate, -1);
      priorEndDate = endOfMonth(addYears(endDate, -1));
      break;
    case DateRangeOptions.Last3Months:
      startDate = addMonths(startOfMonth(today), -3);
      endDate = today;
      priorStartDate = addYears(startDate, -1);
      priorEndDate = endOfMonth(addYears(endDate, -1));
  }

  return { startDate, endDate, priorStartDate, priorEndDate };
}

export function dateFilter<T>(dateRange: DateRangeOptions): DateFilter<T> {
  const { startDate, endDate, priorStartDate, priorEndDate } = getStartAndEndDates(dateRange);
  return dateRangeFilter<T>(startDate, endDate, priorStartDate, priorEndDate);
}

/** Listing specific date filters */

type ListingLike = Pick<Listing, 'closeDate' | 'listDate'>;
export type ListingDateSelector = DateStringSelector<ListingLike>;
export type DateListingFilter = DateFilter<ListingLike>;

export function dateListingFilter(dateRange: DateRangeOptions = DateRangeOptions.Last365Days) {
  const { currentPeriod, ...filter } = dateFilter<ListingLike>(dateRange);
  // Listing passes date filter if it's close date is within the given range,
  // or if it never closed, but it was listed within the range (ie an 'Active', 'Pending', or 'Expired' listing)
  const filterAllCurrent = (list: ListingLike) =>
    currentPeriod(list, l => l.closeDate) || currentPeriod(list, l => l.listDate);

  return { currentPeriod, filterAllCurrent, ...filter };
}

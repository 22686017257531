import React from 'react';
import { Button, Typography, TextField } from 'shared/components';
import { FormProps, ErrorDisplay, useForm, useInput } from '../../shared/forms';
import { UserForm, User } from '../../state/users';
import { Grid, Box } from '@mui/material';
import { PasswordRulesHint } from '../../shared/components/password-rules-hint';
import { SuccessDisplay } from '../../shared/forms/SuccessDisplay';
import { useObservable } from 'shared';
import { sessionQuery } from 'state';

interface Props extends FormProps<UserForm> {
  activeForm: User;
}

export const ProfileForm: React.FC<Props> = ({
  activeForm,
  pending,
  error,
  onSubmit,
  onResetFeedback,
  success,
}) => {
  const email = useInput(activeForm.email as string);
  const currentPassword = useInput<string>('');
  const newPassword = useInput<string>('');
  const currentCompany = useObservable(sessionQuery.currentCompany);

  const form = useForm(
    () => {
      if (!form.valid) return;

      onResetFeedback();
      onSubmit({
        email: email.value,
        currentPassword: currentPassword.value,
        newPassword: newPassword.value,
      });
    },
    email,
    currentPassword,
    newPassword
  );

  return (
    <form {...form.bind}>
      {!!error ? (
        <Box marginBottom={4}>
          <ErrorDisplay error={error} errorMessage="An error has occurred. Please try again." />
        </Box>
      ) : null}

      {success ? (
        <Box marginBottom={4}>
          <SuccessDisplay
            display={!!success}
            successMessage="Your account has been updated successfully!"
          />
        </Box>
      ) : null}

      <Grid container item xs={12} spacing={6}>
        <Grid container item xs={12} sm={6} spacing={2}>
          <Grid item xs={3}>
            <Typography variant="h6">Basic Info</Typography>
          </Grid>
          <Grid item xs={9} container spacing={2} direction="column">
            <Grid item>
              <TextField name="companyName" value={currentCompany?.name} label="Company Name" fullWidth readonly/>
            </Grid>
            <Grid item>
              <TextField name="email" {...email.bind} label="Email" fullWidth/>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={6} spacing={2}>
          <Grid item xs={3}>
            <Typography variant="h6">Change Password</Typography>
          </Grid>
          <Grid item xs={9} container direction="column" spacing={2}>
            <Grid item>
              <TextField
                name="currentPassword"
                {...currentPassword.bind}
                label="Current Password"
                fullWidth
                type="password"
              />
            </Grid>
            <Grid item>
              <TextField
                name="newPassword"
                {...newPassword.bind}
                label="New Password"
                fullWidth
                type="password"
              />
            </Grid>
            <Grid item>
              <PasswordRulesHint password={newPassword.value} />
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Button
            disabled={!form.valid}
            pending={pending}
          >
            Save Profile
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

import { ActiveState, EntityState, StoreConfig } from '@datorama/akita';

import { Company } from './offices.model';
import { ResettableEntityStore } from '../util';

export interface CompanyState extends EntityState<Company, Company['id']>, ActiveState<Company['id']> {
  ui: {};
}

const defaultState: CompanyState = {
  ui: {},
  active: null,
};

@StoreConfig({ name: 'companies', resettable: true, idKey: 'id' })
export class CompanyStore extends ResettableEntityStore<CompanyState, Company, Company['id']> {
  constructor() {
    super(defaultState);
  }
}

export const companyStore = new CompanyStore();

import React, { useState, useContext } from 'react';
import { Card, Box, Divider, CardContent, CardHeader } from '@mui/material';
import { Button } from 'shared/components/button';
import { useFormEvents } from '../../shared/forms';
import { MlsForms, mlsService } from '../../state/mls';
import { AgentEditDialog } from 'agents/components/agent-edit-dialog';
import { ActiveAgentContext } from '../../shared/components/activity-history';

import { EditAgentForm, FormStatus } from '../../state';
import { InfoRow, ThemeColors } from '../../shared';

export const AgentInfo: React.FC = () => {
  const [{ status, error }] = useFormEvents(MlsForms.AgentEdit);
  const [open, setOpen] = useState(false);
  const agentContext = useContext(ActiveAgentContext);
  const agent = agentContext.agent;

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = (form: EditAgentForm) => {
    mlsService.updateAgent({
      memberKey: agent.memberKey,
      alternateEmail: form.alternateEmail,
      alternatePhone: form.alternatePhone,
    });
    setOpen(false);
  };

  return (
    <>
      <Box height="100%">
        <Card>
          <CardHeader title="Agent Info" />
          <CardContent>
            <InfoRow label="Company" value={agent.officeName} />
            {agent.mobilePhone ? (
              <InfoRow
                label="Mobile"
                value={
                  <a href={`tel:${agent.mobilePhone}`} style={{ color: ThemeColors.Teal }}>
                    {agent.mobilePhone}
                  </a>
                }
              />
            ) : (
              <InfoRow label="Mobile" value={'Unavailable'} />
            )}
            {agent.phone ? (
              <InfoRow
                label="Phone"
                value={
                  <a href={`tel:${agent.phone}`} style={{ color: ThemeColors.Teal }}>
                    {agent.phone}
                  </a>
                }
              />
            ) : (
              <InfoRow label="Mobile" value={'Unavailable'} />
            )}
            {agent.email ? (
              <InfoRow
                label={<span>Email</span>}
                value={
                  <a href={`mailto:${agent.email}`} style={{ color: ThemeColors.Teal }}>
                    {agent.email}
                  </a>
                }
              />
            ) : (
              <InfoRow label="Email" value={'Unavailable'} />
            )}

            <InfoRow
              label={<span>Alternate Phone</span>}
              value={
                agent.alternatePhone ? (
                  <a href={`tel:${agent.alternatePhone}`} style={{ color: ThemeColors.Teal }}>
                    {agent.alternatePhone}
                  </a>
                ) : null
              }
            />

            <InfoRow
              label={<span>Alternate Email</span>}
              value={
                agent.alternateEmail ? (
                  <a href={`mailto:${agent.alternateEmail}`} style={{ color: ThemeColors.Teal }}>
                    {agent.alternateEmail}
                  </a>
                ) : null
              }
            />
            <Box marginTop="24px" textAlign="left">
              <Button onClick={() => setOpen(true)}>
                Edit
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Box>
      {open ? (
        <AgentEditDialog
          handleClose={handleClose}
          onResetFeedback={handleClose}
          onSubmit={onSubmit}
          agent={agent}
          success={status === FormStatus.Success}
          pending={status === FormStatus.Pending}
          error={error}
        />
      ) : null}
    </>
  );
};

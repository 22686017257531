import React, { createRef, RefObject, useEffect, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { FormGroup, FormLabel } from '@mui/material';
import { ConfirmationDialog } from './confirmation-dialog';

export interface EditorProperties {
  originalImage: string | File;
  position: { x: number; y: number };
  scale: number;
}

interface Props {
  image: string | File;
  openDialog: boolean;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit: (file: File) => void;
  onCancel: () => void;
}

export const AvatarEditorDialog: React.FC<Props> = ({
  image,
  openDialog,
  setOpenDialog,
  onSubmit,
  onCancel,
}) => {
  const editorRef: RefObject<AvatarEditor> = createRef();
  const [editorProps, setEditorProps] = useState<EditorProperties>({
    originalImage: '',
    position: { x: 0.5, y: 0.5 },
    scale: 1,
  });

  useEffect(() => {
    setEditorProps({
      originalImage: image,
      position: { x: 0.5, y: 0.5 },
      scale: 1,
    });
  }, [image]);

  const resetEditorProps = () => {
    setEditorProps({
      originalImage: '',
      position: { x: 0.5, y: 0.5 },
      scale: 1,
    });
  };

  const handleZoom = (event: React.ChangeEvent<any>) => {
    const scale = +event.target.value;
    setEditorProps(prevState => ({ ...prevState, scale }));
  };

  const handlePositionChange = (position: { x: number; y: number }) => {
    setEditorProps(prevState => ({ ...prevState, position }));
  };

  const handleCancel = () => {
    resetEditorProps();
    setOpenDialog(false);
    onCancel();
  };

  const handleSubmit = () => {
    setOpenDialog(false);
    if (editorRef?.current) {
      const dataURL = editorRef.current.getImageScaledToCanvas().toDataURL();
      const blobBin = atob(dataURL.split(',')[1]);
      const array = [];
      for (var i = 0; i < blobBin.length; i++) {
        array.push(blobBin.charCodeAt(i));
      }
      const file = new File([new Uint8Array(array)], 'logo.png', { type: 'image/png' });
      onSubmit(file);
      resetEditorProps();
    }
  };

  return (
    <ConfirmationDialog title="Edit Image" isOpen={openDialog} onConfirm={handleSubmit} onCancel={handleCancel}>
      <>
        <AvatarEditor
          ref={editorRef}
          color={[200, 200, 200, 0.6]}
          scale={editorProps.scale}
          width={200}
          crossOrigin="anonymous"
          height={60}
          image={editorProps.originalImage}
          position={editorProps.position}
          onPositionChange={handlePositionChange}
        />
        <FormGroup>
          <FormLabel>Zoom</FormLabel>
          <input
            type="range"
            onChange={handleZoom}
            min={0}
            max={2}
            step={0.01}
            defaultValue={editorProps.scale}
          />
        </FormGroup>
      </>
    </ConfirmationDialog>
  );
};

import { identity } from 'rxjs';
import { EntityState, EntityStore, StoreConfig, QueryEntity } from '@datorama/akita';

import { Listing } from '../mls.models';
import { DateRangeOptions } from '../date-filter';

export interface ListingsState extends EntityState<Listing, Listing['listingKey']> {
  ui: {
    dateRange: DateRangeOptions;
  };
}

@StoreConfig({ name: 'listings', resettable: true, idKey: 'listingKey', deepFreezeFn: identity })
export class ListingsStore extends EntityStore<ListingsState> {
  static instance: ListingsStore;

  constructor() {
    super({
      ui: {
        dateRange: DateRangeOptions.Last365Days,
      },

      //Akita already initializes the store with loading set to true.
      //we need it to be false to drive our progress indicators
      //which rely on this flag starting off as false
      //https://github.com/datorama/akita/issues/22#issuecomment-408659859
      loading: false,
    });
  }

  akitaPreUpdateEntity(oldListingState: Listing, newListingState: Listing) {
    //ensure nothing is lost between old state and new state, in array types
    //and make sure to enforce uniqueness

    return {
      ...oldListingState,
      ...newListingState,
      // ...ensurePreservedListingKeys(oldListingState, newListingState),
    };
  }
}

export class ListingsQuery extends QueryEntity<ListingsState> {
  static instance: ListingsQuery;
}

ListingsStore.instance = new ListingsStore();
ListingsQuery.instance = new ListingsQuery(ListingsStore.instance);

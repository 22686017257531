import { Grid } from '@mui/material';

import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from 'shared/components';

interface Props {
  open: boolean;
  handleClose: () => void;
  onConfirm: () => void;
  onDeny?: () => void;
  activeSurvey?: boolean;
}


export function ConfirmationDialog({ open, handleClose, onConfirm, onDeny, activeSurvey }: Props) {
  return (
    <Dialog isOpen={open} onClose={handleClose}>
      <DialogTitle>Please Confirm</DialogTitle>
      <DialogContent>
        <Grid container direction="column">
          {activeSurvey ? (
            <>
              <Typography>
                An active survey already exists. You can only have one active survey at a time.
              </Typography>
              <Typography>
                Do you want to deactivate the current active survey and make this new survey the
                active one?
              </Typography>
            </>
          ) : <>
            <Typography>This will save as an active survey which will be sent out the next time you send an agent survey request.</Typography>
            <Typography>Would you like to proceed?</Typography>
          </>}
        </Grid>
      </DialogContent>

      <DialogActions>
        <Grid container item xs={12} gap={2} m={2}>
          <Grid item>
            <Button onClick={onConfirm} type="button">
              {!activeSurvey ? 'Confirm' : 'Save and Make Active'}
            </Button>
          </Grid>
          {activeSurvey && (
            <Grid item>
              <Button variant="secondary" onClick={onDeny} type="button">
                Save and Make Inactive
              </Button>
            </Grid>
          )}
          <Grid item>
            <Button variant="secondary" onClick={handleClose} type="button">
              Cancel
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

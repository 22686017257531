/* eslint-disable */
// tslint:disable
/**
 * Auth API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';

import { ChangePasswordRequest } from '../model';
import { ForgotPasswordRequest } from '../model';
import { ResetPasswordRequest } from '../model';
import { UpdateProfileRequest } from '../model';
import { UserModel } from '../model';
/**
 * AccountApi - axios parameter creator
 * @export
 */
export const AccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ForgotPasswordRequest} [forgotPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountPasswordForgotPost(forgotPasswordRequest?: ForgotPasswordRequest, options: any = {}): RequestArgs {
            const localVarPath = `/account/password/forgot`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            //@ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof forgotPasswordRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(forgotPasswordRequest !== undefined ? forgotPasswordRequest : {}) : (forgotPasswordRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ChangePasswordRequest} [changePasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountPasswordPost(changePasswordRequest?: ChangePasswordRequest, options: any = {}): RequestArgs {
            const localVarPath = `/account/password`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            //@ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof changePasswordRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(changePasswordRequest !== undefined ? changePasswordRequest : {}) : (changePasswordRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ResetPasswordRequest} [resetPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountPasswordResetPost(resetPasswordRequest?: ResetPasswordRequest, options: any = {}): RequestArgs {
            const localVarPath = `/account/password/reset`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            //@ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof resetPasswordRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(resetPasswordRequest !== undefined ? resetPasswordRequest : {}) : (resetPasswordRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountProfileGet(options: any = {}): RequestArgs {
            const localVarPath = `/account/profile`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            //@ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateProfileRequest} [updateProfileRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountProfilePatch(updateProfileRequest?: UpdateProfileRequest, options: any = {}): RequestArgs {
            const localVarPath = `/account/profile`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            //@ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof updateProfileRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateProfileRequest !== undefined ? updateProfileRequest : {}) : (updateProfileRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateProfileRequest} [updateProfileRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountProfilePut(updateProfileRequest?: UpdateProfileRequest, options: any = {}): RequestArgs {
            const localVarPath = `/account/profile`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            //@ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof updateProfileRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateProfileRequest !== undefined ? updateProfileRequest : {}) : (updateProfileRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountApi - functional programming interface
 * @export
 */
export const AccountApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ForgotPasswordRequest} [forgotPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountPasswordForgotPost(forgotPasswordRequest?: ForgotPasswordRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = AccountApiAxiosParamCreator(configuration).accountPasswordForgotPost(forgotPasswordRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {ChangePasswordRequest} [changePasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountPasswordPost(changePasswordRequest?: ChangePasswordRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = AccountApiAxiosParamCreator(configuration).accountPasswordPost(changePasswordRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {ResetPasswordRequest} [resetPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountPasswordResetPost(resetPasswordRequest?: ResetPasswordRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = AccountApiAxiosParamCreator(configuration).accountPasswordResetPost(resetPasswordRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountProfileGet(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserModel> {
            const localVarAxiosArgs = AccountApiAxiosParamCreator(configuration).accountProfileGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {UpdateProfileRequest} [updateProfileRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountProfilePatch(updateProfileRequest?: UpdateProfileRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserModel> {
            const localVarAxiosArgs = AccountApiAxiosParamCreator(configuration).accountProfilePatch(updateProfileRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {UpdateProfileRequest} [updateProfileRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountProfilePut(updateProfileRequest?: UpdateProfileRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserModel> {
            const localVarAxiosArgs = AccountApiAxiosParamCreator(configuration).accountProfilePut(updateProfileRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AccountApi - factory interface
 * @export
 */
export const AccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {ForgotPasswordRequest} [forgotPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountPasswordForgotPost(forgotPasswordRequest?: ForgotPasswordRequest, options?: any): AxiosPromise<void> {
            return AccountApiFp(configuration).accountPasswordForgotPost(forgotPasswordRequest, options)(axios, basePath);
        },
        /**
         * 
         * @param {ChangePasswordRequest} [changePasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountPasswordPost(changePasswordRequest?: ChangePasswordRequest, options?: any): AxiosPromise<void> {
            return AccountApiFp(configuration).accountPasswordPost(changePasswordRequest, options)(axios, basePath);
        },
        /**
         * 
         * @param {ResetPasswordRequest} [resetPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountPasswordResetPost(resetPasswordRequest?: ResetPasswordRequest, options?: any): AxiosPromise<void> {
            return AccountApiFp(configuration).accountPasswordResetPost(resetPasswordRequest, options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountProfileGet(options?: any): AxiosPromise<UserModel> {
            return AccountApiFp(configuration).accountProfileGet(options)(axios, basePath);
        },
        /**
         * 
         * @param {UpdateProfileRequest} [updateProfileRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountProfilePatch(updateProfileRequest?: UpdateProfileRequest, options?: any): AxiosPromise<UserModel> {
            return AccountApiFp(configuration).accountProfilePatch(updateProfileRequest, options)(axios, basePath);
        },
        /**
         * 
         * @param {UpdateProfileRequest} [updateProfileRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountProfilePut(updateProfileRequest?: UpdateProfileRequest, options?: any): AxiosPromise<UserModel> {
            return AccountApiFp(configuration).accountProfilePut(updateProfileRequest, options)(axios, basePath);
        },
    };
};

/**
 * AccountApi - object-oriented interface
 * @export
 * @class AccountApi
 * @extends {BaseAPI}
 */
export class AccountApi extends BaseAPI {
    /**
     * 
     * @param {ForgotPasswordRequest} [forgotPasswordRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountPasswordForgotPost(forgotPasswordRequest?: ForgotPasswordRequest, options?: any) {
        return AccountApiFp(this.configuration).accountPasswordForgotPost(forgotPasswordRequest, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {ChangePasswordRequest} [changePasswordRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountPasswordPost(changePasswordRequest?: ChangePasswordRequest, options?: any) {
        return AccountApiFp(this.configuration).accountPasswordPost(changePasswordRequest, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {ResetPasswordRequest} [resetPasswordRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountPasswordResetPost(resetPasswordRequest?: ResetPasswordRequest, options?: any) {
        return AccountApiFp(this.configuration).accountPasswordResetPost(resetPasswordRequest, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountProfileGet(options?: any) {
        return AccountApiFp(this.configuration).accountProfileGet(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {UpdateProfileRequest} [updateProfileRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountProfilePatch(updateProfileRequest?: UpdateProfileRequest, options?: any) {
        return AccountApiFp(this.configuration).accountProfilePatch(updateProfileRequest, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {UpdateProfileRequest} [updateProfileRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountProfilePut(updateProfileRequest?: UpdateProfileRequest, options?: any) {
        return AccountApiFp(this.configuration).accountProfilePut(updateProfileRequest, options)(this.axios, this.basePath);
    }

}

import { useObservable } from './useObservable';
import {
  activitiesQuery,
  activitiesService,
  recruitsQuery,
  recruitsService,
  sessionQuery,
} from '../../state';
import { useEffect } from 'react';
import { managerQuery, RecruitingGoal } from '../../state/managers';
import { managerService } from 'state/managers/manager.service';

export function useCurrentUserId() {
  const user = useObservable(sessionQuery.currentUser);
  return user?.sub;
}

export function useCurrentUser() {
  const userId = useObservable(sessionQuery.currentUser)?.sub
  useEffect(() => {
    if (userId) {
      managerService.getManager(userId);
    }
  }, [userId]);

  return useObservable(managerQuery.activeManager);
}

export function useManager(managerId: string) {
  useEffect(() => {
    managerService.getManager(managerId);
    recruitsService.getManagerRecruits(managerId);
    activitiesService.getManagerRecruitActivities(managerId);
  }, [managerId]);

  const manager = useObservable(managerQuery.activeManager);
  const recruits = useObservable(recruitsQuery.recruits);
  const volume = useObservable(recruitsQuery.volume);
  const activities = useObservable(activitiesQuery.managerActivities);

  const recruitingGoal: RecruitingGoal = manager?.recruitingGoal ?? {
    managerId: managerId,
    year: new Date().getUTCFullYear(),
    agents: 0,
    volume: 0,
    transactions: 0,
    companyDollar: 0,
  };

  return { manager, recruitingGoal, recruits, volume, activities };
}

export function useManagerInfo(managerId?: string) {
  useEffect(() => {
    if (!!managerId) managerService.getManager(managerId);
  }, [managerId]);

  const manager = useObservable(managerQuery.activeManager);

  return manager;
}

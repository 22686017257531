import { Theme } from 'theme/theme';

const palette = {
  primary: '#05205E',
  primaryDark: '#05205E',
  neutralLightest: '#FFFFFF',
  neutralLight: '#f1f1f1',
  neutral: '#DADEE7',
  neutralDark: '#444444',
  neutralDarkest: '#000000',
  orange: '#F6BD60',
  teal: '#6ab4e4',
  blueLight: '#e5f6fd',
  blue: '#0b66b1',
  blueDark: '#014361',
  green: '#67c100',
  yellowLight: '#fff4e5',
  yellow: '#f0b019',
  yellowDark: '#663c00',
  purple: '#784D9C',
  red: '#FF0000',
  magenta: '#CD00FC',
  logoGradient: 'linear-gradient(46.25deg, #471090 7%, #8300B6 14%, #AE00D6 21%, #D300EE 28%, #EF00FF 35%, #FF00FF 41%, #FF00FF 47%, #FF00FF 53%, #FF00FC 59%, #FF00E7 65%, #FF00CA 71%, #FF00A1 77%, #FF0071 82%, #FF0029 88%, #FF0000 94%, #FF0000 98%)',
  magentaGradient: 'linear-gradient(137.12deg, #CD00FC 0%, #FF0000 100%)',
  background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)),' +
    'linear-gradient(270deg, rgba(255, 255, 255, 0.3) 0%, rgba(192, 168, 255, 0.3) 22%, rgba(207, 217, 255, 0.3) 82.5%, rgba(255, 255, 255, 0.3) 100%),' +
    'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 41.16%)'
};

interface ModuleTheme {
  [key: string]: string | number | ModuleTheme;
}

export class ThemeService {
  private theme: Theme = {} as Theme;
  private palette = palette;

  private validateModuleNotRewritingTheme(moduleTheme: ModuleTheme) {
    const conflictedKeys: string[] = [];
    const themeKeys = Object.keys(this.theme);
    const moduleThemeKeys = Object.keys(moduleTheme);

    themeKeys.forEach(key => {
      if (moduleThemeKeys.includes(key)) {
        conflictedKeys.push(key);
      }
    });

    if (conflictedKeys.length > 0) {
      console.error(
        `Theme module error - trying to rewrite fields that already exists: ${conflictedKeys.join(
          ', '
        )}`
      );
      return false;
    }

    return true;
  }

  registerModuleTheme(moduleTheme: ModuleTheme) {
    if (this.validateModuleNotRewritingTheme(moduleTheme)) {
      this.theme = { ...this.theme, ...moduleTheme };
    }
  }

  getTheme() {
    return this.theme;
  }

  getPalette() {
    return this.palette;
  }
}

const themeService = new ThemeService();
export default themeService;

export type FieldError = {
  field: string;
  error: string;
};

export class ValidationError extends Error {
  errors: FieldError[];

  constructor(errors: any) {
    let message = '';
    Object.keys(errors).forEach(x => (message += `${x}: ${errors[x]}\n`));
    super(message);

    this.errors = [];
    Object.keys(errors).forEach(x => {
      this.errors.push({
        field: x,
        error: errors[x],
      });
    });
    this.name = 'ValidationError';
  }
}

import { useEffect } from 'react';
import { useObservable } from '../utils/useObservable';
import { mlsService, mlsQuery } from '../../state';

export function useAgentVolume(memberKey: string | undefined) {
  useEffect(() => {
    if (!!memberKey) mlsService.getAgentVolumes([memberKey]);
  }, [memberKey]);

  const summaries = useObservable(mlsQuery.agentVolumeSummaries);

  return summaries.find(d => d.memberKey === memberKey);
}

import { Theme, Tooltip } from '@mui/material';
import { withStyles } from '@mui/styles';
import React from 'react';
import { NumberFormatters, ThemeColors, deferred } from '..';
import { Home } from '@mui/icons-material';
import { VolumeTrendChart } from './volume-trend-chart';
import { DateRange } from '../../api/mls/model/date-range';
import { Typography } from './typography';
import { TableContainer, Table, TableBody, TableRow, TableCell } from 'shared/components/table';

const HtmlTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    maxWidth: 550,
    fontSize: theme.typography.pxToRem(12),
  },
}))(Tooltip);

interface Props {
  title: string;
  periods: Array<{
    dates: DateRange;
    volume: number;
    transactions: number;
  }>;
}

const Volume: React.FC<Props> = ({ periods, title }) => {
  return (
    <HtmlTooltip
      arrow
      title={
        <>
          <Typography variant="subtitle">{title}</Typography>
          <TableContainer>
            <Table size="small">
              <TableBody>
                {periods.map((item, idx) => (
                  <TableRow key={idx}>
                    <TableCell component="th" scope="row">
                      <span style={{ color: '#fff' }}>{item.dates.from}</span>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <span style={{ color: '#fff' }}>{item.dates.to}</span>
                    </TableCell>
                    <TableCell align="right">
                      <span style={{ color: '#fff' }}>
                        {NumberFormatters.currency`${item.volume || 0}`}
                      </span>
                    </TableCell>
                    <TableCell align="right">
                      <span
                        style={{
                          display: 'inline-block',
                          verticalAlign: 'super',
                          color: '#fff',
                        }}
                      >
                        {item.transactions}
                      </span>
                      <Home style={{ color: ThemeColors.Green }} fontSize="small" />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      }
    >
      <div>{<VolumeTrendChart periods={periods} />}</div>
    </HtmlTooltip>
  );
};

export const VolumeSummaryTooltip = deferred(Volume);

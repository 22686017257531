import { from } from 'rxjs';
import { applyTransaction } from '@datorama/akita';

import { MlsService, mlsService } from 'state/mls/mls.service';

import { CompanyModel, OfficeModel, OfficesApi } from '../../api/app';
import { OfficeState, OfficeStore, officeStore } from './offices.store';
import { CompanyStore, companyStore } from './company.store';
export class OfficeService {
  constructor(
    private readonly store: OfficeStore,
    private readonly companyStore: CompanyStore,
    private readonly officesApi: OfficesApi,
    private readonly mlsService: MlsService
  ) {}

  getOffices() {
    from(this.officesApi.officesGet()).subscribe(({ data }) => {
      // load the corresponding mlsoffices too
      this.mlsService.getOffices(
        data.companies.flatMap(c => c.companyCodes),
        true
      );

      applyTransaction(() => {
        this.store.set(data.data);
        this.companyStore.set(data.companies);

        this.store.update(({ ui, ...rest }) => ({
          ui: { ...ui, ...getDefaultSelections(ui, data.companies, data.data) },
          ...rest,
        }));
      });
    });
  }

  setActiveBroker(brokerId: number | 'All') {
    this.store.update(({ ui, ...rest }) => ({
      ui: { ...ui, brokerId: brokerId === 'All' ? undefined : brokerId, branchId: undefined },
      ...rest,
    }));
  }

  setActiveBranch(brokerId: number, id: number | 'All') {
    this.store.update(({ ui, ...rest }) => ({
      ui: { ...ui, brokerId, branchId: id === 'All' ? undefined : id },
      ...rest,
    }));
  }
}

function getDefaultSelections(
  ui: OfficeState['ui'],
  companies: CompanyModel[],
  offices: OfficeModel[]
) {
  let brokerId = ui.brokerId;
  let branchId = ui.branchId;

  const companyCodes = companies.flatMap(c => c.companyCodes);

  if (brokerId && !offices.some(c => c.officeId === brokerId)) {
    brokerId = undefined;
    branchId = undefined;
  }

  if (branchId && !offices.some(c => c.officeId === branchId)) {
    branchId = undefined;
  }

  if (!brokerId && companyCodes.length === 1) {
    brokerId = offices.find(o => o.codes.includes(companyCodes[0]))?.officeId;
  }

  if (brokerId && !branchId && offices.length === 1) {
    branchId = offices[0].officeId;
  }

  return { brokerId, branchId };
}

export const officeService = new OfficeService(
  officeStore,
  companyStore,
  new OfficesApi(),
  mlsService
);

import { EntityState, EntityStore, StoreConfig, ActiveState } from '@datorama/akita';

import { Recruit } from './recruits.models';

export interface RecruitsState
  extends EntityState<Recruit, Recruit['id']>,
    ActiveState<Recruit['id']> {
  ui: { managerId: string | null };
}

@StoreConfig({ name: 'recruits', resettable: true })
export class RecruitsStore extends EntityStore<RecruitsState> {
  constructor() {
    super({ active: null, ui: { managerId: null } });
  }
}

export const recruitsStore = new RecruitsStore();

import React, { useEffect, useMemo, useState } from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { CookieNames, LoadingFade, useObservable } from '../../shared';
import { ErrorDisplay } from '../../shared/forms';
import { authService, sessionQuery, sessionService } from '../../state';
import { useCookies } from 'react-cookie';
import { RefreshKvCoreTokens } from '../utils';

export const KvCoreCode: React.FC<RouteComponentProps> = ({ location }) => {
  const isLoggedIn = useObservable(sessionQuery.isLoggedIn, 'async');
  const [cookies, setCookies, removeCookies] = useCookies([
    CookieNames.kvCoreToken,
    CookieNames.kvCoreRefreshToken,
  ]);
  const [tokensAvailable, setTokensAvailable] = useState<boolean>(false);
  const [authStarted, setAuthStarted] = useState<boolean>(false);
  const [loginError, setLoginError] = useState<boolean>(false);

  const [code, state] = useMemo(() => {
    const query = new URLSearchParams(location.search);
    return [query.get('code'), query.get('state')];
  }, [location]);

  useEffect(() => {
    if (!code || authStarted) return;

    if (state === 'kvCore') {
      try {
        setAuthStarted(true);
        authService.getKvCoreTokenRequest(code, RefreshKvCoreTokens(setCookies, removeCookies));
      } catch {}
    } else {
      // If there is a known state we can inspect
      // when coming from kvcore, we should check it here.
      sessionService.kvCoreLogin(code).catch(err => setLoginError(true));
    }
  }, [code, state]);

  useEffect(() => {
    if (cookies.kvCoreToken !== undefined && cookies.kvCoreRefreshToken !== undefined)
      setTokensAvailable(true);
  }, [cookies]);

  if (loginError) return <ErrorDisplay error={new Error("Something went wrong. Please contact customer service.")} />;

  if (!code || (isLoggedIn && state !== 'kvCore')) return <Redirect to="/" />;

  if (tokensAvailable) return <Redirect to="/kvCore/sso" />;

  return <LoadingFade isContentLoading={true}></LoadingFade>;
};

import { Listing, Agent, Office } from './mls.models';

type ListingPredicate = (listing: Listing) => boolean;

export interface ListingFilter {
  isListSide: ListingPredicate;
  isSellSide: ListingPredicate;
  isListSideOnly: ListingPredicate;
  isSellSideOnly: ListingPredicate;
  isBothSide: ListingPredicate;
  isAnySide: ListingPredicate;
  getShare: ShareFn;
}

export interface ListingFilterKeys {
  memberKeys?: Array<string>;
  officeKeys?: Array<string>;
}

export function isNullFilter({ memberKeys, officeKeys }: ListingFilterKeys) {
  return !memberKeys?.length && !officeKeys?.length;
}

const nullShare = { list: false, sell: false, share: 0 };

const NullListingFilter: ListingFilter = {
  isListSide: () => false,
  isSellSide: () => false,
  isListSideOnly: () => false,
  isSellSideOnly: () => false,
  isBothSide: () => false,
  isAnySide: () => false,
  getShare: () => nullShare,
};

export function keyListingFilter({ memberKeys, officeKeys }: ListingFilterKeys): ListingFilter {
  if (memberKeys && officeKeys) {
    return combineFilters(agentListingFilter(memberKeys), officeListingFilter(officeKeys));
  }

  if (memberKeys) {
    return agentListingFilter(memberKeys);
  }

  if (officeKeys) {
    return officeListingFilter(officeKeys);
  }

  return NullListingFilter;
}

export function officeListingFilter(office?: Office | string | string[]): ListingFilter {
  return filter<Office>(office, 'officeKey', 'listOfficeKeys', 'sellOfficeKeys');
}

export function agentListingFilter(member?: Agent | string | string[]): ListingFilter {
  return filter<Agent>(member, 'memberKey', 'listMemberKeys', 'sellMemberKeys');
}

type FilterFlags<Base, Condition> = {
  [Key in keyof Base]: Base[Key] extends Condition ? Key : never;
};
type AllowedNames<Base, Condition> = FilterFlags<Base, Condition>[keyof Base];
type SubType<Base, Condition> = Pick<Base, AllowedNames<Base, Condition>>;
type AllowedKeys<Base, Condition> = NonNullable<keyof SubType<Base, Condition>>;

type KeyPredicate = (i: string) => boolean;
type ShareFn = (listing: Listing) => Readonly<{ list: boolean; sell: boolean; share: number }>;

function check(arr: string[], predicate: KeyPredicate): number {
  switch (arr.length) {
    case 0:
      return 0;
    case 1:
      return predicate(arr[0]) ? 1 : 0;
    case 2:
      return (predicate(arr[0]) ? 1 : 0) + (predicate(arr[1]) ? 1 : 0);
    default:
      return arr.filter(predicate).length;
  }
}

function filter<T>(
  input: T | string | string[] | undefined,
  keyProp: AllowedKeys<T, string>,
  listSide: AllowedKeys<Listing, Array<string>>,
  sellSide: AllowedKeys<Listing, Array<string>>
): ListingFilter {
  if (!input) {
    return NullListingFilter;
  }

  let predicate: KeyPredicate;

  if (Array.isArray(input)) {
    if (input.length === 0) {
      return NullListingFilter;
    }

    input = input.map(i => i.toLowerCase());

    const set = new Set(input);
    predicate = i => set.has(i.toLowerCase());
  } else {
    const key = typeof input === 'string' ? input : input[keyProp];
    predicate = i => i.toLowerCase() === (typeof key === 'string' ? key.toLowerCase(): key);
  }

  const list: ListingPredicate = listing => check(listing[listSide], predicate) > 0;
  const sell: ListingPredicate = listing => check(listing[sellSide], predicate) > 0;
  const share: ShareFn = listing => {
    const listArr = listing[listSide];
    const sellArr = listing[sellSide];
    const listN = check(listArr, predicate);
    const sellN = check(sellArr, predicate);

    return {
      list: listN > 0,
      sell: sellN > 0,
      share: listN / (listArr.length || 1) + sellN / (sellArr.length || 1),
    };
  };

  return {
    isListSide: l => list(l),
    isSellSide: l => sell(l),
    isListSideOnly: l => list(l) && !sell(l),
    isSellSideOnly: l => sell(l) && !list(l),
    isBothSide: l => list(l) && sell(l),
    isAnySide: l => list(l) || sell(l),
    getShare: l => share(l),
  };
}

export function combineFilters(f1: ListingFilter, f2: ListingFilter): ListingFilter {
  return {
    isListSide: l => f1.isListSide(l) && f2.isListSide(l),
    isSellSide: l => f1.isSellSide(l) && f2.isSellSide(l),
    isListSideOnly: l => f1.isListSideOnly(l) && f2.isListSideOnly(l),
    isSellSideOnly: l => f1.isSellSideOnly(l) && f2.isSellSideOnly(l),
    isBothSide: l => f1.isBothSide(l) && f2.isBothSide(l),
    isAnySide: l => f1.isAnySide(l) && f2.isAnySide(l),

    getShare: l => combineShares(f1.getShare(l), f2.getShare(l)),
  };
}

function combineShares(s1: ReturnType<ShareFn>, s2: ReturnType<ShareFn>) {
  return {
    list: s1.list && s2.list,
    sell: s1.sell && s2.sell,
    // when combining filters, the share is the minimum because it reflects the most restrictive filter
    share: Math.min(s1.share, s2.share),
  };
}

import { SimpleUserModel } from 'api/auth/model';
import React from 'react';
import {DataTable, DataTableColumn } from 'shared';
import {Typography} from "@mui/material";
import { ImpersonateButton } from './impersonate-button';

interface Props {
  users: SimpleUserModel[];
}

export const UsersList: React.FC<Props> = ({users}) => {

  const columns: DataTableColumn<SimpleUserModel>[] = [
    {
      renderHeader: () => <Typography noWrap fontWeight="bold">NAME</Typography>,
      headerAlign: 'center',
      align: 'center',
      display: 'flex',
      field: 'name',
      sortable: true,
      filterable: true,
      valueGetter: (value, row) => row.name,
      renderCell: ({row}) => <Typography noWrap>{row.name}</Typography>
    },
    {
      renderHeader: () => <Typography noWrap fontWeight="bold">EMAIL</Typography>,
      headerAlign: 'center',
      align: 'center',
      display: 'flex',
      field: 'email',
      sortable: true,
      filterable: true,
      valueGetter: (value, row) => row.email,
      renderCell: ({row}) => <Typography noWrap>{row.email}</Typography>
    },
    {
      renderHeader: () => <Typography noWrap fontWeight="bold">COMPANY NAME</Typography>,
      headerAlign: 'center',
      align: 'center',
      display: 'flex',
      field: 'companyName',
      sortable: true,
      filterable: true,
      valueGetter: (value, row) => row.companyName,
      renderCell: ({row}) => <Typography noWrap>{row.companyName}</Typography>
    },
    {
      headerName: '',
      headerAlign: 'center',
      display: 'flex',
      align: 'center',
      field: '',
      sortable: false,
      filterable: false,
      renderCell: ({row}) => <ImpersonateButton user={row} />
    }
  ];

  return (
    <DataTable
      rows={users}
      columns={columns}
      noDataComponent={<Typography>Enter a value in search to load users.</Typography>}
      initialState={{
        sorting: {
          sortModel: [
            {
              field: 'name',
              sort: 'asc',
            },
          ],
        },
      }}
    />
  );
}

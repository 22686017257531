import { EntityState, EntityStore, StoreConfig, ActiveState } from '@datorama/akita';

import { Feed } from './feeds.models';

export interface FeedState extends EntityState<Feed>, ActiveState<Feed> {
  ui: {};
}

@StoreConfig({ name: 'feeds', resettable: true })
export class FeedStore extends EntityStore<FeedState> {
  constructor() {
    super({ ui: {} });
  }
}

export const feedStore = new FeedStore();

import { Observable } from 'rxjs';

import IWorker from './types';
import Worker from './worker.shim';

const worker = Worker as () => IWorker;

const workerPool: Array<IWorker> = [];
const workerPoolLimit = 2;

function acquireWorker() {
  return workerPool.pop() ?? worker();
}

function releaseWorker(instance: IWorker) {
  if ((instance as any).killed) return;

  if (workerPool.length < workerPoolLimit) {
    workerPool.push(instance);
  } else {
    killWorker(instance);
  }
}

function killWorker(instance?: IWorker) {
  if (instance) {
    (instance as any).killed = true;
    instance.terminate();
  }
}

export function onWorkerThread<T>(
  label: string,
  callback: (worker: IWorker) => Promise<T>
): Observable<T> {
  return new Observable<T>(subscriber => {
    const start = performance.now();
    let instance: IWorker;
    let released = false;

    const timer = setTimeout(() => {
      instance = acquireWorker();

      callback(instance)
        .then(result => {
          const stop = performance.now();
          console.log(`${label} in ${Math.round(stop - start)}ms.`);
          return result;
        })
        .then(
          value => subscriber.next(value),
          reason => subscriber.error(reason)
        )
        .finally(() => {
          releaseWorker(instance);
          released = true;

          subscriber.complete();
        });
    }, 0);

    return () => {
      const stop = performance.now();

      clearTimeout(timer);

      if (!released) {
        console.debug(`Cancelling ${label} after ${Math.round(stop - start)}ms.`);
        killWorker(instance);
      }
    };
  });
}

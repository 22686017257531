import { Observable, of } from 'rxjs';
import { Statistics, VolumeAggregator, onWorkerThread } from './worker';

import { Listing } from './mls.models';
import { CompareValue } from '../compare-value';
import { agentListingFilter, isNullFilter, ListingFilterKeys } from './listing-filter';
import { DateRangeOptions, dateListingFilter } from './date-filter';
import { ListingGroup } from './date-grouping';

/**
 * Read-only view of various statistics for an agent, office or company.
 */
export interface VolumeSummary
  extends Readonly<{ [P in keyof Statistics]: Readonly<Statistics[P]> }> {
  withNewDateFilter(dateRange: DateRangeOptions): Observable<VolumeSummary>;

  getSummaryByGroup(groupType: ListingGroup, groupKeys: any[]): Observable<VolumeSummary[]>;
}

export class VolumeSummaryImpl implements VolumeSummary {
  constructor(
    readonly listings: Array<Listing>,
    readonly filter: ListingFilterKeys,
    readonly dateRange: DateRangeOptions,
    stats: Statistics
  ) {
    this.activeListings = stats.activeListings;
    this.activePriceSum = stats.activePriceSum;
    this.pendingListings = stats.pendingListings;
    this.pendingPriceSum = stats.pendingPriceSum
    this.expiredListings = stats.expiredListings;
    this.listTransactions = stats.listTransactions;
    this.sellTransactions = stats.sellTransactions;
    this.listTransactionsVol = stats.listTransactionsVol;
    this.sellTransactionsVol = stats.sellTransactionsVol;
    this.totalTransactions = stats.totalTransactions;
    this.totalVolume = stats.totalVolume;
    this.avgSalesPrice = stats.avgSalesPrice;
    this.avgDaysOnMarket = stats.avgDaysOnMarket;
  }

  activeListings: CompareValue<number, undefined>;
  activePriceSum: CompareValue<number, undefined>;
  pendingListings: CompareValue<number, undefined>;
  pendingPriceSum:CompareValue<number, undefined>;
  expiredListings: CompareValue<number>;
  listTransactions: CompareValue<number>;
  sellTransactions: CompareValue<number>;
  listTransactionsVol: CompareValue<number>;
  sellTransactionsVol: CompareValue<number>;
  totalTransactions: CompareValue<number>;
  totalVolume: CompareValue<number>;
  avgSalesPrice: CompareValue<number>;
  avgDaysOnMarket: CompareValue<number>;

  withNewDateFilter(dateRange: DateRangeOptions): Observable<VolumeSummary> {
    if (this.dateRange === dateRange) return of(this);

    const listings = this.listings;

    return createVolumeSummary(listings, this.filter, dateRange);
  }

  getSummaryByGroup(groupType: ListingGroup, groupKeys: any[]): Observable<VolumeSummary[]> {
    const listings = this.listings;
    const dateRange = this.dateRange;

    return onWorkerThread(
      `Volume summary by ${groupType} for ${listings.length} listings over ${dateRange}`,
      worker =>
        worker
          .createVolumeSummaryByGroup(listings, this.filter, dateRange, groupType, groupKeys)
          .then(stats => stats.map(s => new VolumeSummaryImpl(listings, this.filter, dateRange, s)))
    );
  }
}

export function emptyVolumeSummary(): VolumeSummary {
  const listingFilter = agentListingFilter([]);
  const dateFilter = dateListingFilter(DateRangeOptions.Last365Days);

  return new VolumeSummaryImpl(
    [],
    {},
    DateRangeOptions.Last365Days,
    new VolumeAggregator(listingFilter, dateFilter)
  );
}

export function createVolumeSummary(
  listings: Array<Listing>,
  filter: ListingFilterKeys,
  dateRange: DateRangeOptions
): Observable<VolumeSummary> {
  if (listings.length === 0 || isNullFilter(filter)) return of(emptyVolumeSummary());

  return onWorkerThread(
    `Volume summary for ${listings.length} listings over ${dateRange}`,
    worker =>
      worker
        .createVolumeSummary(listings, filter, dateRange)
        .then(stats => new VolumeSummaryImpl(listings, filter, dateRange, stats))
  );
}

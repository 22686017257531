import FeeCalculator from "recruiting/components/net-income-calculator/FeeCalculator";

export interface FeeScheduleItem {
  type: FeeType;
  label: string;
  totalCostOurs?: number;
  totalCostCurrent?: number;
  monthlyCostOurs?: number;
  monthlyCostCurrent?: number;
  feeGroup: 'fixed' | 'variable';
  calculate?: (calculator: FeeCalculator) => number;
  customMultiplier?: number; //this one applies for TransactionFees for now, just to flag it, which is multiplied or divided by totalTransactions
  align?: 'left' | 'center' | 'right' | undefined;
}

export enum FeeCategory {
  Ours,
  Current,
}

export enum FeeType {
  //Fixed
  AgentGrossCommission,
  LessFranchiseFee,
  AgentAdjustedGross,
  LessCompanyDollarCap,
  AfterCapCompanyDollar,

  //Variable
  TransactionFees,
  MonthlyFeesTech,
  EOInsurance,
  DeskOfficeFee,
  LeadGeneration,
  AdminExpense,
  Copies,
  Advertising,
  Signs,
  Postage,
  MarketingMaterials,
  Other,
}

import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { applyTransaction } from '@datorama/akita';

import { ActivityApi } from 'api/app/api';

import { ActivitiesStore, activitiesStore } from './activities.store';
import { ActivitiesForms } from './activities.forms';
import { Activity } from './activities.model';
import { DateRangeOptions } from '../mls/date-filter';
import { dispatchForm } from '../forms';

export class ActivitiesService {
  constructor(private readonly store: ActivitiesStore, private readonly activityApi: ActivityApi) {}

  getRecruitActivities(recruitId: number) {
    from(this.activityApi.activityRecruitIdGet(recruitId))
      .pipe(
        map(
          response =>
            response.data.data.map(m => {
              return { ...m, recruitId: recruitId };
            }) || {}
        )
      )
      .subscribe(activities =>
        applyTransaction(() => {
          this.store.upsertMany(activities);
          this.store.update(({ ui }) => ({ ui: { ...ui, recruitId } }));
        })
      );
  }

  getManagerRecruitActivities(managerId: string) {
    from(this.activityApi.activityRecruitManagerManagerIdGet(managerId))
      .pipe(
        map(
          response =>
            response.data.data.map(m => {
              return { ...m, managerId: managerId };
            }) || {}
        )
      )
      .subscribe(activities =>
        applyTransaction(() => {
          this.store.upsertMany(activities);
          this.store.update(({ ui }) => ({ ui: { ...ui, managerId } }));
        })
      );
  }

  deleteActivity(activityId: number) {
    from(this.activityApi.activityIdDelete(activityId)).subscribe(() =>
      applyTransaction(() => {
        this.store.remove(activityId);
      })
    );
  }

  createActivity(recruitId: number, activity: Activity) {
    if (activity.id != null || activity.type == null || activity.recordedAt == null) return;
    var request = {
      recruitId: recruitId,
      type: activity.type,
      notes: activity.notes,
      recordedAt: activity.recordedAt,
    };
    from(this.activityApi.activityPost(request))
      .pipe(
        map(response => {
          return { ...response.data.data, recruitId: recruitId } || {};
        }),
        dispatchForm(ActivitiesForms.Create)
      )
      .subscribe(res =>
        applyTransaction(() => {
          this.store.upsert(res.id, res);
        })
      );
  }

  updateActivity(recruitId: number, activity: Activity) {
    var id = activity.id;
    if (id == null || activity.type == null || activity.recordedAt == null) return;
    var request = {
      type: activity.type,
      notes: activity.notes,
      recordedAt: activity.recordedAt,
    };
    from(this.activityApi.activityIdPost(id, request))
      .pipe(
        map(response => {
          return { ...response.data.data, recruitId: recruitId } || {};
        })
      )
      .subscribe(res =>
        applyTransaction(() => {
          this.store.upsert(res.id, res);
        })
      );
  }

  setActiveDateRange(dateRange: DateRangeOptions) {
    this.store.update(({ ui }) => ({ ui: { ...ui, dateRange } }));
  }
}

export const activitiesService = new ActivitiesService(activitiesStore, new ActivityApi());

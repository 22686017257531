import { Zsg } from '../../api';

export type CreateSurvey = Zsg.CreateSurveyRequest;
export type UpdateSurvey = Zsg.UpdateSurveyRequest;

export type Survey = Zsg.SurveyModel;
export type SurveyAnswer = Zsg.AnswerModel;
export type SurveyQuestion = Zsg.QuestionModel;
export type QuestionType = Zsg.QuestionTypeModel;
export type SurveySubmission = Zsg.SubmissionModel;
export type DefaultQuestions = Zsg.DefaultQuestionModel;
export type SurveyEmailTemplate = Zsg.EmailTemplateModel;

export function ConvertDefaultToSurveyQuestion(question: DefaultQuestions): SurveyQuestion {
  return {
    questionText: question.questionText,
    questionTypeId: question.questionTypeId,
    questionOrder: question.questionOrder,
  };
}

export function ConvertDefaultToSurveyQuestions(
  questions: Array<DefaultQuestions>
): Array<SurveyQuestion> {
  var surveyQuestions = questions.map(ConvertDefaultToSurveyQuestion);
  return surveyQuestions.sort((qa, qb) => (qa.questionOrder || 0) - (qb.questionOrder || 0));
}

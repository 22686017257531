import { ReactNode } from 'react';
import { Box } from '@mui/material';
import { useTheme } from 'theme/ThemeContext';
import { Typography } from '../typography';

interface BlockProps {
  title?: string;
  row?: boolean;
  children?: ReactNode;
}

export function Block ({ title, row = false, children }: BlockProps) {
  const theme = useTheme();

  return (
    <Box
      sx={({breakpoints}) => ({
        display: 'flex',
        flexDirection: row ? 'row' : 'column',
        alignItems: 'center',
        padding: '1rem',
        width: '100%',
        borderRadius: '.5rem',
        backgroundColor: theme.block.backgroundColor,
        border: 'solid thin',
        borderColor: theme.block.borderColor,
        justifyContent: 'space-around',
        [breakpoints.down('lg')]: {
          justifyContent: 'start',
        },
        '& > p': {
          maxWidth: '100%',
        }
      })}
    >
      {title && (
        <Typography variant="body" fontWeight="bold" noWrap>
          {title}
        </Typography>
      )}
      {children}
    </Box>
  );
};

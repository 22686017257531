import React, { memo, useMemo } from 'react';
import { Card, CardContent, Grid, Typography } from '@mui/material';

import { officeService, VolumeSummary, ListingGroup } from '../../state';

import { NumberFormatters, useObservable } from '../../shared';
import { ValueComparison } from 'shared/components/value-comparison';
import _ from 'lodash';
import { Button } from 'shared/components';
import { DataTable, DataTableColumn } from 'shared/components/data-table';
import { useAppOfficeLookup, useMlsOfficeLookup } from '../../agents/components';
import { OfficeModel as MlsOfficeModel } from '../../api/mls';
import { OfficeModel as AppOfficeModel } from '../../api/app';
import { useHistory } from 'react-router-dom';
import { EMPTY } from 'rxjs';

export interface SalesVolumeProps {
  volume: VolumeSummary;
}

const grouping = ListingGroup.Offices;

export const CompanySalesVolumeTable: React.FC<SalesVolumeProps> = memo(
  ({ volume: marketStats }) => {
    const history = useHistory();
    const [appOffices, officeKeys] = useAppOfficeLookup();
    const mlsOffices = useMlsOfficeLookup();
    const data$ = useMemo(
      () => (marketStats ? marketStats.getSummaryByGroup(grouping, officeKeys) : EMPTY),
      [marketStats, officeKeys]
    );

    const data = useObservable(data$, 'async');

    if (data == null) return null;

    const offices = data.map((vol, i) => ({ officeKey: officeKeys[i], volume: vol }));

    const summaryGridData: ViewModel[] = _.orderBy(
      offices,
      ['volume.totalVolume.current'],
      ['desc']
    ).map(({ officeKey, volume }, idx) => {
      return {
        rank: ++idx,
        appOffice: appOffices.get(officeKey),
        mlsOffice: mlsOffices.get(officeKey),
        volume,
      } as ViewModel;
    });

    const goToOffice = (brokerId: number, officeId: number) => {
      officeService.setActiveBranch(brokerId, officeId);
      history.push('/retention');
    };

    const columns: DataTableColumn<ViewModel>[] = [
      {
        headerName: '',
        field: 'name',
        renderCell: ({ row }) => {
          return (
            <>
              {!!row.appOffice && (
                <Button
                  onClick={() => goToOffice(row.appOffice!.brokerId, row.appOffice!.officeId!)}
                  variant="subtle"
                >
                  {`${row.rank}. ${row.mlsOffice?.officeName} (${row.mlsOffice?.officeNumber})`}
                </Button>
              )}
            </>
          );
        },
        flex: 2,
        sortable: true,
        valueGetter: (value, row) => row.mlsOffice?.officeName
      },
      {
        renderHeader: () => <Typography noWrap fontWeight="bold">ACTIVE</Typography>,
        headerAlign: 'center',
        align: 'center',
        display: 'flex',
        renderCell: ({ row }) =>
          row.volume && <ValueComparison {...row.volume.activeListings} size="13px" />,
        sortable: true,
        valueGetter: (value, row) => row.volume?.activeListings.current,
        field: 'volume.activeListings.current',
        type: 'number'
      },
      {
        renderHeader: () => <Typography noWrap fontWeight="bold">PENDING</Typography>,
        headerAlign: 'center',
        align: 'center',
        display: 'flex',
        renderCell: ({ row }) =>
          row.volume && <ValueComparison {...row.volume.pendingListings} size="13px" />,
        sortable: true,
        valueGetter: (value, row) => row.volume?.pendingListings.current,
        field: 'volume.pendingListings.current',
        type: 'number'
      },
      {
        renderHeader: () => <Typography noWrap fontWeight="bold">LIST TRANS</Typography>,
        headerAlign: 'center',
        align: 'center',
        display: 'flex',
        renderCell: ({ row }) =>
          row.volume && <ValueComparison {...row.volume.listTransactions} size="13px" />,
        sortable: true,
        valueGetter: (value, row) => row.volume?.listTransactions.current,
        field: 'volume.listTransactions.current',
        type: 'number'
      },
      {
        renderHeader: () => <Typography noWrap fontWeight="bold">LIST TRANS VOL</Typography>,
        headerAlign: 'center',
        align: 'center',
        display: 'flex',
        renderCell: ({ row }) =>
          row.volume && (
            <ValueComparison
              {...row.volume.listTransactionsVol}
              size="13px"
              formatter={NumberFormatters.currency}
            />
          ),
        sortable: true,
        valueGetter: (value, row) => row.volume?.listTransactionsVol.current,
        field: 'volume.listTransactionsVol.current',
        type: 'number'
      },
      {
        renderHeader: () => <Typography noWrap fontWeight="bold">SELL TRANS</Typography>,
        headerAlign: 'center',
        align: 'center',
        display: 'flex',
        renderCell: ({ row }) =>
          row.volume && <ValueComparison {...row.volume.sellTransactions} size="13px" />,
        sortable: true,
        valueGetter: (value, row) => row.volume?.sellTransactions.current,
        field: 'volume.sellTransactions.current',
        type: 'number'
      },
      {
        renderHeader: () => <Typography noWrap fontWeight="bold">SELL TRANS VOL</Typography>,
        headerAlign: 'center',
        align: 'center',
        display: 'flex',
        renderCell: ({ row }) =>
          row.volume && (
            <ValueComparison
              {...row.volume.sellTransactionsVol}
              size="13px"
              formatter={NumberFormatters.currency}
            />
          ),
        sortable: true,
        valueGetter: (value, row) => row.volume?.sellTransactionsVol.current,
        field: 'volume.sellTransactionsVol.current',
        type: 'number'
      },
      {
        renderHeader: () => <Typography noWrap fontWeight="bold">TOTAL TRANS</Typography>,
        headerAlign: 'center',
        align: 'center',
        display: 'flex',
        renderCell: ({ row }) =>
          row.volume && <ValueComparison {...row.volume.totalTransactions} size="13px" />,
        sortable: true,
        valueGetter: (value, row) => row.volume?.totalTransactions.current,
        field: 'volume.totalTransactions.current',
        type: 'number'
      },
      {
        renderHeader: () => <Typography noWrap fontWeight="bold">TOTAL VOL</Typography>,
        headerAlign: 'center',
        align: 'center',
        display: 'flex',
        renderCell: ({ row }) => (
          <>
            {row.volume && (
              <ValueComparison
                {...row.volume.totalVolume}
                size="13px"
                formatter={NumberFormatters.currency}
              />
            )}
          </>
        ),
        sortable: true,
        valueGetter: (value, row) => row.volume?.totalVolume.current,
        field: 'volume.totalVolume.current',
        type: 'number'
      },
    ];

    return (
      <Grid item container spacing={1}>
        <Grid item xs={12}>
          <Card style={{ overflow: 'visible' }}>
            <CardContent>
              <DataTable
                rows={summaryGridData}
                columns={columns}
                getRowId={(row) => row.rank}
                showPagination
                pageSizeOptions={[10, 25, 50]}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10
                    }
                  }
                }}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
);

interface ViewModel {
  rank: number;
  appOffice?: AppOfficeModel & { brokerId: number };
  mlsOffice?: MlsOfficeModel;
  volume?: VolumeSummary;
}

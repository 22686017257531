/* eslint-disable */
// tslint:disable
/**
 * MLS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';

import { CreateUserRequest } from '../model';
import { CreateUserResponse } from '../model';
import { UpdateUserRequest } from '../model';
import { UpdateUserResponse } from '../model';
/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {CreateUserRequest} [createUserRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsUsersPost(createUserRequest?: CreateUserRequest, options: any = {}): RequestArgs {
      const localVarPath = `/mls/users`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      //@ts-ignore
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof createUserRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(createUserRequest !== undefined ? createUserRequest : {})
        : createUserRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} userId
     * @param {UpdateUserRequest} [updateUserRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsUsersUserIdPost(
      userId: string,
      updateUserRequest?: UpdateUserRequest,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          'userId',
          'Required parameter userId was null or undefined when calling mlsUsersUserIdPost.'
        );
      }
      const localVarPath = `/mls/users/{userId}`.replace(
        `{${'userId'}}`,
        encodeURIComponent(String(userId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      //@ts-ignore
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof updateUserRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(updateUserRequest !== undefined ? updateUserRequest : {})
        : updateUserRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {CreateUserRequest} [createUserRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsUsersPost(
      createUserRequest?: CreateUserRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateUserResponse> {
      const localVarAxiosArgs = UsersApiAxiosParamCreator(configuration).mlsUsersPost(
        createUserRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} userId
     * @param {UpdateUserRequest} [updateUserRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsUsersUserIdPost(
      userId: string,
      updateUserRequest?: UpdateUserRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateUserResponse> {
      const localVarAxiosArgs = UsersApiAxiosParamCreator(configuration).mlsUsersUserIdPost(
        userId,
        updateUserRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {CreateUserRequest} [createUserRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsUsersPost(
      createUserRequest?: CreateUserRequest,
      options?: any
    ): AxiosPromise<CreateUserResponse> {
      return UsersApiFp(configuration).mlsUsersPost(createUserRequest, options)(axios, basePath);
    },
    /**
     *
     * @param {string} userId
     * @param {UpdateUserRequest} [updateUserRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsUsersUserIdPost(
      userId: string,
      updateUserRequest?: UpdateUserRequest,
      options?: any
    ): AxiosPromise<UpdateUserResponse> {
      return UsersApiFp(configuration).mlsUsersUserIdPost(
        userId,
        updateUserRequest,
        options
      )(axios, basePath);
    },
  };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
  /**
   *
   * @param {CreateUserRequest} [createUserRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public mlsUsersPost(createUserRequest?: CreateUserRequest, options?: any) {
    return UsersApiFp(this.configuration).mlsUsersPost(createUserRequest, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {string} userId
   * @param {UpdateUserRequest} [updateUserRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public mlsUsersUserIdPost(userId: string, updateUserRequest?: UpdateUserRequest, options?: any) {
    return UsersApiFp(this.configuration).mlsUsersUserIdPost(
      userId,
      updateUserRequest,
      options
    )(this.axios, this.basePath);
  }
}

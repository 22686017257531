import React from 'react';
import { ics, google, yahoo } from 'calendar-link';
import { Agent, ActivityType } from '../../../state';
import { Box, Link } from '@mui/material';

interface Props {
  type: ActivityType | null;
  recordedAt: string | null;
  notes: string | null;
  agent: Agent;
}

export const CalendarDownload: React.FC<Props> = ({ type, recordedAt, notes, agent }) => {
  const phone = agent.phone ?? '';
  const email = agent.email ?? '';
  const mobilePhone = agent.mobilePhone ?? '';

  const lnkEvent = {
    title: `${type} - ${agent.fullName}`,
    description: `${notes}
    Office: ${agent.officeName}
    Phone: ${phone}
    Email Address: ${email}
    Mobile Number: ${mobilePhone}
    `,
    start: recordedAt,
    allDay: true,
  };

  if (!recordedAt) return null;
  {
    const links = [
      <Link
        href={ics(lnkEvent)}
        target="_blank"
        rel="noopener noreferrer"
        download="calendar.ics"
        underline="hover"
      >
        Download as ics
      </Link>,
      <Link href={google(lnkEvent)} target="_blank" rel="noopener noreferrer" underline="hover">
        Add to gmail
      </Link>,
      <Link href={yahoo(lnkEvent)} target="_blank" rel="noopener noreferrer" underline="hover">
        Add to yahoo
      </Link>,
    ];
    return (
      <>
        {links.map((link, i) => (
          <Box key={i} component="span" mx={1}>
            {link}
          </Box>
        ))}
      </>
    );
  }
};

import { useEffect } from 'react';

import { favoritesService, favoritesQuery } from '../../state';
import { useObservable } from './useObservable';

export function useFavorites(managerId: string | undefined) {
  const favorites = useObservable(favoritesQuery.favorites);

  const set = new Set<string>(favorites.map(f => f.memberKey!));

  function isFavorite(memberKey: string) {
    return set.has(memberKey);
  }

  function toggleFavorite(memberKey: string) {
    if (isFavorite(memberKey)) {
      if (!!managerId) favoritesService.deleteFavorite(managerId, memberKey);
    } else {
      if (!!managerId) favoritesService.createFavorite(managerId, memberKey);
    }
  }

  return { favorites, isFavorite, toggleFavorite };
}

export function useFavoriteVolumes(managerId: string | undefined) {
  useEffect(() => {
    if (!!managerId) favoritesService.getManagerFavorites(managerId);
  }, [managerId]);

  const volume = useObservable(favoritesQuery.volumes);

  return volume;
}

import React from 'react';

import { sessionService, sessionQuery, FormStatus } from '../../state';
import { useObservable } from '../../shared';

import { SignInForm } from '../components';
import { Redirect } from 'react-router-dom';
import { useFormEvents } from '../../shared/forms';
import { UserForms, LoginForm } from '../../state/users';

export const SignInPage: React.FC = () => {
  const isLoggedIn = useObservable(sessionQuery.isLoggedIn, 'async');

  const [{ status, error }, resetFormUI] = useFormEvents(UserForms.Login);

  const submit = (form: LoginForm) => {
    if (form.email !== '' && form.password !== '')
      sessionService.login(form.email, form.password, form.staySignedIn);
  };

  if (isLoggedIn) {
    return <Redirect to="/" />;
  }

  return (
    <React.Fragment>
      <SignInForm
        onSubmit={submit}
        onResetFeedback={resetFormUI}
        error={error}
        success={status === FormStatus.Success}
        pending={status === FormStatus.Pending}
      />
    </React.Fragment>
  );
};

import React from 'react';

import { sessionQuery } from '../../state';
import { useObservable, Link } from '../../shared';
import { Typography } from 'shared/components';
import LogoImage from '../../assets/BT-Recruit-Horizontal-Dark.png';

export const InactiveAccountPage: React.FC = () => {
  const isLoggedIn = useObservable(sessionQuery.isLoggedIn, 'async');

  if (!!!isLoggedIn) return null;

  return (
    <>
      <img style={{ width: '60%', marginBottom: '20px' }} src={LogoImage} alt="BoldTrail Recruit Logo" />
      <Typography>
        This account is no longer active. If you have questions regarding the status of this account
        please email billing@insiderealestate.com or contact an administrator.{' '}
        <Link to="/account/signout">Return to sign in page.</Link>
      </Typography>
    </>
  );
};

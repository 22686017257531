import { History } from 'history';
import { SetStateAction, WritableAtom } from 'jotai';
import { atomFamily, atomWithStorage } from 'jotai/utils';

type Unsubscribe = () => void;

type SyncStorage<Value> = {
  getItem: (key: string) => Value;
  setItem: (key: string, newValue: Value) => void;
  removeItem: (key: string) => void;
  delayInit?: boolean;
  subscribe?: (key: string, callback: (value: Value) => void) => Unsubscribe;
};

type HistoryStateMap = Record<string, any>;

export const REPLACE_STATE = Symbol();

export function atomHistory<T extends Object>(initialState: T) {
  return atomFamily<History, WritableAtom<T, SetStateAction<T>>>(history =>
    atomWithStorage(
      'search',
      initialState,
      createHistoryStorage(history as History<HistoryStateMap>, initialState)
    )
  );
}

function createHistoryStorage<T extends Object>(
  history: History<HistoryStateMap>,
  initialValue: T
): SyncStorage<T> {
  const currentState = Object.assign({}, history.location.state);

  function pushState() {
    history.push(history.location.pathname, { ...currentState });
  }

  function replaceState() {
    history.replace(history.location.pathname, { ...currentState });
  }

  return {
    getItem: (key: string) => {
      // get may be called before the state is set by the subscribe callback
      const value = currentState[key] ?? history.location.state?.[key];
      console.debug('STORAGE', 'GET', key, value);

      return value ?? initialValue;
    },
    setItem: (key: string, newValue: T) => {
      console.debug('STORAGE', 'SET', key, newValue);
      currentState[key] = newValue;

      const isReplace = REPLACE_STATE in newValue && (newValue as any)[REPLACE_STATE];
      if (isReplace) replaceState();
      else pushState();
    },
    removeItem: (key: string) => {
      console.debug('STORAGE', 'REMOVE', key);
      currentState[key] = undefined;

      pushState();
    },
    subscribe: (key: string, callback: (value: T) => void) => {
      return history.listen(({ state }) => {
        const value = state?.[key];
        console.debug('STORAGE', 'SYNC', key, value);

        if (value) {
          currentState[key] = value;
          callback(value);
        }
      });
    },
  };
}

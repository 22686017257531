import { applyTransaction } from '@datorama/akita';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { Mls } from '../../../api';
import { RegionsStore, regionsStore } from './regions.store';

export class RegionsService {
  constructor(
    private readonly store: RegionsStore,
    private readonly mlsofficesApi: Mls.OfficesApi
  ) {}

  getRegions() {
    from(this.mlsofficesApi.mlsOfficesRegionsPost())
      .pipe(map(response => response.data))
      .subscribe(({ cities }) =>
        applyTransaction(() => {
          if (!cities) this.store.update({ cities: [] });
          else this.store.update({ cities });
        })
      );
  }
}

export const regionsService = new RegionsService(regionsStore, new Mls.OfficesApi());

import { Observable, of } from 'rxjs';
import { Statistics, onWorkerThread } from './worker';

import { Agent, AgentVolume, Listing } from './mls.models';
import { DateRangeOptions } from './date-filter';
import { isNullFilter, ListingFilterKeys } from './listing-filter';
import { VolumeSummary, VolumeSummaryImpl } from './volume-summary';

export type AgentVolumeLookup = Map<Agent['memberKey'], AgentVolumeSummary>;

export interface AgentVolumeSummary extends VolumeSummary {
  readonly agent: Readonly<Agent>;
  readonly summary: Readonly<AgentVolume> | undefined;
}

export class AgentVolumeSummaryImpl extends VolumeSummaryImpl implements AgentVolumeSummary {
  constructor(
    public readonly agent: Readonly<Agent>,
    public readonly summary: Readonly<AgentVolume> | undefined,
    listings: Array<Listing>,
    filter: ListingFilterKeys,
    dateRange: DateRangeOptions,
    stats: Statistics
  ) {
    super(listings, filter, dateRange, stats);
  }
}

export function emptyLookupByAgent(): AgentVolumeLookup {
  return new Map<string, AgentVolumeSummary>();
}

export function createLookupByAgent(
  listings: Array<Listing>,
  filter: ListingFilterKeys,
  dateRange: DateRangeOptions,
  agents: Array<Agent>,
  summaries: Array<AgentVolume>
): Observable<AgentVolumeLookup> {
  if (listings.length === 0 || isNullFilter(filter)) return of(emptyLookupByAgent());

  return onWorkerThread(
    `Volume summary per agent for ${listings.length} listings over ${dateRange}`,
    worker =>
      worker.createVolumeSummaryByAgent(listings, filter, dateRange).then(hash => {
        const resultMap = new Map<string, AgentVolumeSummary>();

        const agentMap = new Map<string, Agent>(agents.map(a => [a.memberKey, a]));
        const summaryMap = new Map<string, AgentVolume>(summaries.map(a => [a.memberKey, a]));

        for (const memberKey of Object.keys(hash)) {
          const agent = agentMap.get(memberKey);
          if (agent) {
            resultMap.set(
              memberKey,
              new AgentVolumeSummaryImpl(
                agent,
                summaryMap.get(memberKey),
                listings,
                filter,
                dateRange,
                hash[memberKey]
              )
            );
          }
        }

        return resultMap;
      })
  );
}

import React from 'react';
import { FeeScheduleItem } from 'recruiting/pages/net-income-valuation.types';
import { Button } from 'shared/components/button';
import { SaveAlt } from '@mui/icons-material';
import { Page, Text, Document, PDFDownloadLink } from '@react-pdf/renderer';

import { Table, TableHeader, TableBody, TableRow, TableCell } from './pdf-table/PdfTable';
import { NumberFormatters } from '../../../shared';

interface RecrutingCaculatorPdfExportProps {
  feeSchedule: FeeScheduleItem[];
  agentName: string;
  oursTrueNetIncome: number;
  oursTrueValueSplit: number;
  currentTrueValueSplit: number;
  currentTrueNetIncome: number;

  fixedCurrentFeeTotals?: number;
  fixedOursFeeTotals?: number;

  variableCurrentFeeTotals?: number;
  variableOursFeeTotals?: number;

  notes?: string;

  salesVolume?: number;
  totalTransactions?: number;
}

const CalculationPDF: React.FC<RecrutingCaculatorPdfExportProps> = ({
  feeSchedule,
  agentName,
  oursTrueNetIncome,
  oursTrueValueSplit,
  currentTrueValueSplit,
  currentTrueNetIncome,

  fixedCurrentFeeTotals,
  fixedOursFeeTotals,
  variableCurrentFeeTotals,
  variableOursFeeTotals,

  notes,

  salesVolume,
  totalTransactions,
}) => {
  return (
    <Document>
      <Page
        style={{
          margin: 20,
          padding: 40,
          fontSize: 10,
        }}
      >
        <Text style={{ marginBottom: 5, textAlign: 'left', fontSize: 15 }}>{agentName}</Text>
        <Text style={{ textAlign: 'left', fontSize: 8, marginBottom: 20 }}>
          Sales Volume: {NumberFormatters.currency`${salesVolume || 0}`}&nbsp; Transaction Count:{' '}
          {totalTransactions || 0}
        </Text>

        <Text style={{ marginBottom: 20, textAlign: 'left' }}>
          Estimate generated on {new Date().toLocaleString().replace(',', ' at')}
        </Text>

        <Table>
          <TableHeader>
            <TableCell></TableCell>
            <TableCell style={{ textAlign: 'right', fontSize: 11 }}>Current</TableCell>
            <TableCell style={{ textAlign: 'right', fontSize: 11 }}>Ours</TableCell>
          </TableHeader>
          <TableBody>
            {feeSchedule
              .filter(fee => fee.feeGroup === 'fixed')
              .map(fee => (
                <TableRow key={fee.label}>
                  <TableCell>{fee.label}</TableCell>
                  <TableCell style={{ textAlign: 'right' }}>{NumberFormatters.currency`${
                    fee.totalCostCurrent || 0
                  }`}</TableCell>
                  <TableCell style={{ textAlign: 'right' }}>{NumberFormatters.currency`${
                    fee.totalCostOurs || 0
                  }`}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>

        <Text style={{ marginTop: 10 }}></Text>

        <Table>
          <TableHeader>
            <TableCell></TableCell>
            <TableCell style={{ textAlign: 'right', fontSize: 11 }}>Current</TableCell>
            <TableCell style={{ textAlign: 'right', fontSize: 11 }}>Ours</TableCell>
          </TableHeader>
          <TableBody>
            <TableRow>
              <TableCell>Your Net GCI</TableCell>
              <TableCell style={{ textAlign: 'right' }}>{NumberFormatters.currency`${
                fixedCurrentFeeTotals || 0
              }`}</TableCell>
              <TableCell style={{ textAlign: 'right' }}>{NumberFormatters.currency`${
                fixedOursFeeTotals || 0
              }`}</TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <Text style={{ marginTop: 30 }}></Text>

        <Table>
          <TableHeader>
            <TableCell style={{ fontSize: 11 }}>Add'l Expenses</TableCell>
            <TableCell style={{ textAlign: 'right', fontSize: 11 }}>Current Monthly</TableCell>
            <TableCell style={{ textAlign: 'right', fontSize: 11 }}>Current Yearly</TableCell>
            <TableCell style={{ textAlign: 'right', fontSize: 11 }}>Ours Monthly</TableCell>
            <TableCell style={{ textAlign: 'right', fontSize: 11 }}>Ours Yearly</TableCell>
          </TableHeader>
          <TableBody>
            {feeSchedule
              .filter(fee => fee.feeGroup !== 'fixed')
              .map(fee => (
                <TableRow key={fee.label}>
                  <TableCell>{fee.label}</TableCell>
                  <TableCell style={{ textAlign: 'right' }}>
                    {NumberFormatters.currency`${fee.monthlyCostCurrent || 0}`}
                  </TableCell>
                  <TableCell style={{ textAlign: 'right' }}>{NumberFormatters.currency`${
                    (fee.monthlyCostCurrent || 0) * (fee.customMultiplier || 12)
                  }`}</TableCell>
                  <TableCell style={{ textAlign: 'right' }}>{NumberFormatters.currency`${
                    fee.monthlyCostOurs || 0
                  }`}</TableCell>
                  <TableCell style={{ textAlign: 'right' }}>{NumberFormatters.currency`${
                    (fee.monthlyCostOurs || 0) * (fee.customMultiplier || 12)
                  }`}</TableCell>
                </TableRow>
              ))}
            <TableRow key="total">
              <TableCell>Your Total Fees</TableCell>
              <TableCell></TableCell>
              <TableCell style={{ textAlign: 'right' }}>{NumberFormatters.currency`${
                variableCurrentFeeTotals || 0
              }`}</TableCell>
              <TableCell></TableCell>
              <TableCell style={{ textAlign: 'right' }}>{NumberFormatters.currency`${
                variableOursFeeTotals || 0
              }`}</TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <Text style={{ marginTop: 10 }}></Text>

        <Table>
          <TableHeader>
            <TableCell>Bottom Line</TableCell>
            <TableCell style={{ textAlign: 'right', fontSize: 11 }}>Current</TableCell>
            <TableCell style={{ textAlign: 'right', fontSize: 11 }}>Ours</TableCell>
          </TableHeader>
          <TableBody>
            <TableRow>
              <TableCell>Your True Value Split</TableCell>
              <TableCell
                style={{ textAlign: 'right' }}
              >{NumberFormatters.decimalPercent`${currentTrueValueSplit}`}</TableCell>
              <TableCell
                style={{ textAlign: 'right' }}
              >{NumberFormatters.decimalPercent`${oursTrueValueSplit}`}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Your True Net Income</TableCell>
              <TableCell
                style={{ textAlign: 'right' }}
              >{NumberFormatters.currency`${currentTrueNetIncome}`}</TableCell>
              <TableCell
                style={{ textAlign: 'right' }}
              >{NumberFormatters.currency`${oursTrueNetIncome}`}</TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <Text style={{ marginTop: 20 }}></Text>
        <Text style={{ marginTop: 20 }}>{notes ? 'Notes:' : null}</Text>
        <Text style={{ marginTop: 5 }}>{notes}</Text>

        <Text style={{ marginTop: 30, textAlign: 'left', fontSize: 8 }}>
          The content of this presentation is provided for informational purposes only. It is not
          intended nor should it be viewed as legal or financial advice. No one should act or
          refrain from acting on the basis of any information in this presentation without seeking
          the appropriate professional counsel on his or her particular circumstances. Although
          every effort is made to provide accurate and useful information, our company assumes no
          legal liability for the accuracy, completeness, or usefulness of any information disclosed
          in this presentation.
        </Text>
      </Page>
    </Document>
  );
};

const generatePdfName = (agentName: string) => {
  return `${agentName}-${new Date()
    .toLocaleDateString()
    .replace(/\//g, '-')}-calculation-estimate.pdf`;
};

export const RecruitingCalculatorPdfExportComponent: React.FC<RecrutingCaculatorPdfExportProps> = ({
  feeSchedule,
  agentName,
  oursTrueNetIncome,
  oursTrueValueSplit,
  currentTrueValueSplit,
  currentTrueNetIncome,

  fixedCurrentFeeTotals,
  fixedOursFeeTotals,
  variableCurrentFeeTotals,
  variableOursFeeTotals,

  notes,

  salesVolume,
  totalTransactions,
}) => {
  return (
    <PDFDownloadLink
      style={{ color: 'white', textDecoration: 'none' }}
      document={
        <CalculationPDF
          feeSchedule={feeSchedule}
          agentName={agentName}
          oursTrueNetIncome={oursTrueNetIncome}
          oursTrueValueSplit={oursTrueValueSplit}
          currentTrueValueSplit={currentTrueValueSplit}
          currentTrueNetIncome={currentTrueNetIncome}
          fixedCurrentFeeTotals={fixedCurrentFeeTotals}
          fixedOursFeeTotals={fixedOursFeeTotals}
          variableCurrentFeeTotals={variableCurrentFeeTotals}
          variableOursFeeTotals={variableOursFeeTotals}
          notes={notes}
          salesVolume={salesVolume}
          totalTransactions={totalTransactions}
        />
      }
      fileName={generatePdfName(agentName)}
    >
      <Button type="button" startIcon={<SaveAlt />}>
        Save as PDF
      </Button>
    </PDFDownloadLink>
  );
};

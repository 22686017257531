import React, { useLayoutEffect } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';

import { sessionQuery, UserInfo } from './state';
import { NavLayout, CenteredLayout, useObservable } from './shared';
import { AccountRoutes, ProfileRoutes, NotificationsRoutes } from './account';
import { RecruitingRoutes } from './recruiting';
import { SearchRoutes } from './search';
import { RetentionRoutes } from './retention';
import { AgentsRoutes } from './agents';
import { CompanyRoutes } from './company';
import { ToolsRoutes } from './tools';
import { AdminRoutes } from './admin';
import IntercomMessenger from './intercom';
import { SurveysRoutes, TakeSurveyPage } from './surveys';
import { CoBrokeRoutes } from './cobroke';
import { KvCoreRoutes } from './sso';
import { TermsAndConditionsDialog } from 'shared/components/terms-and-conditions/terms-and-conditions-dialog';

export const AppRoutes: React.FC = () => {
  const isLoggedIn = useObservable(sessionQuery.isLoggedIn, 'async');
  const isActiveUser = useObservable(sessionQuery.isActiveUser);
  const isCompanyAccountActive = useObservable(sessionQuery.isCompanyAccountActive);

  //went with useLocation, since it's less work to track
  //in the dependency array, than say useHistory
  const { pathname } = useLocation();

  //also went with useLayoutEffect over useEffect because of the event lifecycle:
  //useEffect: render components -> paint to screen -> scroll to top (run effect)
  //useLayoutEffect: render components -> scroll to top (run effect) -> paint to screen
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Switch>
      <Route path="/submit" component={PublicRoutes} />
      <Route path="/account" component={PublicRoutes} />
      <Route path="/kvCore" component={KvCoreRoutes} />
      {isLoggedIn && (isActiveUser === false || isCompanyAccountActive === false) && (
        <Redirect to="/account/account-disabled" />
      )}
      {isLoggedIn && <Route path="/" component={PrivateRoutes} />}
      <Redirect to="/account/signin" />
    </Switch>
  );
};

const PublicRoutes: React.FC = () => (
  <CenteredLayout>
    <Switch>
      <Route path="/account" component={AccountRoutes} />
      <Route path="/submit/:submissionLink" component={TakeSurveyPage} />
    </Switch>
  </CenteredLayout>
);

const mustChangePassword = (user: UserInfo) => user['must_change_password'] === 'true';
const queryClient = new QueryClient();

const PrivateRoutes: React.FC = () => {
  const user = useObservable(sessionQuery.currentUser);

  return (
    <QueryClientProvider client={queryClient}>
      <NavLayout>
        <TermsAndConditionsDialog />
        <Switch>
          <Route path="/agents">{AgentsRoutes}</Route>
          <Route path="/company">{CompanyRoutes}</Route>
          <Route path="/profile">{ProfileRoutes}</Route>
          <Route path={'/notifications'}>{NotificationsRoutes}</Route>
          <Route path="/retention" component={RetentionRoutes} />
          <Route path="/cobroke" component={CoBrokeRoutes} />
          <Route path="/pipeline" component={RecruitingRoutes} />
          <Route path="/search" component={SearchRoutes} />
          <Route path="/tools" component={ToolsRoutes} />

          <Route path="/admin" component={AdminRoutes} />

          <Route path="/survey" component={SurveysRoutes} />

          {user && mustChangePassword(user) && <Redirect to="/profile" />}
          {user && !mustChangePassword(user) && <Redirect to="/company" />}
        </Switch>
      </NavLayout>
      <IntercomMessenger />
    </QueryClientProvider>
  );
};

import { AppBar, Toolbar, Button, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AgentModel } from '../../../api/mls';
import { FeeScheduleItem } from 'recruiting/pages/net-income-valuation.types';
import { drawerWidth } from 'shared/layout/nav-drawer';
import React from 'react';
import { Refresh } from '@mui/icons-material';
import { NumberFormatters } from '../../../shared';
import { RecruitingCalculatorPdfExportComponent } from './recruiting-calculator-pdf-export';
import { common } from '@mui/material/colors';
import themeService from 'theme/ThemeService';

interface NetIncomeSummaryFooterProps {
  currentTrueNetIncome: number;
  currentTrueValueSplit: number;
  oursTrueNetIncome: number;
  oursTrueValueSplit: number;
  agent: AgentModel;
  fixedFeeSchedule: FeeScheduleItem[];
  variableFeeSchedule: FeeScheduleItem[];

  fixedCurrentFeeTotals?: number;
  fixedOursFeeTotals?: number;

  variableCurrentFeeTotals?: number;
  variableOursFeeTotals?: number;

  notes?: string;
  salesVolume?: number;
  totalTransactions?: number;
}

const palette = themeService.getPalette();

const navCollapse = 'lg';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },

  appBar: {
    [theme.breakpoints.up(navCollapse)]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    backgroundColor: palette.neutralLight,
    borderTop: 'solid thin',
    borderTopColor: palette.neutralDark,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(navCollapse)]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  divider: {
    alignSelf: 'stretch',
    height: 'auto',
    margin: '1rem',
  },
}));

const NetIncomeSummaryFooter: React.FC<NetIncomeSummaryFooterProps> = ({
  currentTrueNetIncome,
  currentTrueValueSplit,
  oursTrueNetIncome,
  oursTrueValueSplit,
  agent,
  fixedFeeSchedule,
  variableFeeSchedule,

  fixedCurrentFeeTotals,
  fixedOursFeeTotals,

  variableCurrentFeeTotals,
  variableOursFeeTotals,

  notes,

  salesVolume,
  totalTransactions,
}) => {
  const styles = useStyles();
  return (
    <div className={styles.root}>
      <AppBar position="fixed" className={styles.appBar} style={{ top: 'auto', bottom: 0 }}>
        <Toolbar style={{ overflowX: 'auto' }}>
          <Button
            variant="contained"
            onClick={() => window.location.reload()}
            style={{ minWidth: '100px' }}
          >
            <Refresh /> Reset
          </Button>

          <Box
            py={1}
            height="100%"
            style={{
              color: 'black',
              border: `solid 2px ${palette.neutral}`,
              borderRadius: '.25rem',
              padding: '.5rem',
              backgroundColor: common.white,
              width: 'auto',
              minWidth: '250px',
              margin: '0 .25rem',
            }}
          >
            Current True Net Income:{' '}
            <strong>{NumberFormatters.currency`${currentTrueNetIncome}`}</strong>
          </Box>

          <Box
            py={1}
            height="100%"
            style={{
              color: 'black',
              border: `solid 2px ${palette.neutral}`,
              borderRadius: '.25rem',
              padding: '.5rem',
              backgroundColor: common.white,
              width: 'auto',
              minWidth: '250px',
              margin: '0 .25rem',
            }}
          >
            Current True Value Split:{' '}
            <strong>{NumberFormatters.decimalPercent`${currentTrueValueSplit}`}</strong>
          </Box>

          <Box
            py={1}
            height="100%"
            style={{
              color: 'black',
              border: `solid 2px ${palette.neutral}`,
              borderRadius: '.25rem',
              padding: '.5rem',
              backgroundColor: common.white,
              width: 'auto',
              minWidth: '250px',
              margin: '0 .25rem',
            }}
          >
            Ours True Net Income: <strong>{NumberFormatters.currency`${oursTrueNetIncome}`}</strong>
          </Box>

          <Box
            py={1}
            height="100%"
            style={{
              color: 'black',
              border: `solid 2px ${palette.neutral}`,
              borderRadius: '.25rem',
              padding: '.5rem',
              backgroundColor: common.white,
              width: 'auto',
              minWidth: '250px',
              margin: '0 .25rem',
            }}
          >
            Ours True Value Split:{' '}
            <strong>{NumberFormatters.decimalPercent`${oursTrueValueSplit}`}</strong>
          </Box>

          {(currentTrueNetIncome !== 0 || oursTrueNetIncome !== 0) && (
            <RecruitingCalculatorPdfExportComponent
              feeSchedule={[
                ...fixedFeeSchedule,
                ...variableFeeSchedule.sort(
                  (a: FeeScheduleItem, b: FeeScheduleItem) => a.type - b.type
                ),
              ]}
              oursTrueNetIncome={oursTrueNetIncome}
              oursTrueValueSplit={oursTrueValueSplit}
              currentTrueValueSplit={currentTrueValueSplit}
              currentTrueNetIncome={currentTrueNetIncome}
              agentName={agent.fullName}
              fixedCurrentFeeTotals={fixedCurrentFeeTotals}
              fixedOursFeeTotals={fixedOursFeeTotals}
              variableCurrentFeeTotals={variableCurrentFeeTotals}
              variableOursFeeTotals={variableOursFeeTotals}
              notes={notes}
              salesVolume={salesVolume}
              totalTransactions={totalTransactions}
            />
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
};

export const NetIncomeSummaryFooterComponent = React.memo(NetIncomeSummaryFooter);

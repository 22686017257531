import { SurveySubmission } from '../../state/surveys';
import { InfoRow, Link } from '../../shared';
import { AgentModel } from '../../api/mls';
import { SubmissionScore } from './submission-score';
import { Search } from '@mui/icons-material';
import { Card, CardHeader, CardContent, Box } from '@mui/material';
import { Typography } from 'shared/components';

interface Props {
  submission: SurveySubmission;
  member?: AgentModel;
  coMember?: AgentModel;
}

export function SubmissionInfo({ submission, member, coMember }: Props) {
  return (
    <>
      <Box height="100%">
        <Card>
          <CardHeader title="Submission Info" />
          <CardContent>
            <InfoRow
              label="Submitted Date"
              value={
                submission.submissionDate
                  ? new Date(submission.submissionDate).toLocaleString()
                  : undefined
              }
            />
            <InfoRow
              label="Member"
              value={
                <Typography>
                  <Link to={`/agents/${member?.memberKey}`} color="primary">
                    <>{member?.fullName}</>
                  </Link>
                  <a
                    href={`https://www.google.com/search?q=${member?.fullName} Realtor ${member?.officeName}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Search color="primary"/>
                  </a>
                </Typography>
              }
            />
            {coMember && (
              <InfoRow
                label="Co-member"
                value={
                  <Typography>
                    <Link to={`/agents/${coMember.memberKey}`} color="primary">
                      {coMember.fullName}
                    </Link>
                    <a
                      href={`https://www.google.com/search?q=${coMember.fullName} Realtor ${coMember.officeName}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Search color="primary"/>
                    </a>
                  </Typography>
                }
              />
            )}
            <InfoRow
              label="Score"
              value={
                <SubmissionScore
                  totalPoints={submission.totalPoints || 0}
                  possiblePoints={submission.possiblePoints || 0}
                />
              }
            />
          </CardContent>
        </Card>
      </Box>
    </>
  );
}

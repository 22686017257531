/**
 * @deprecated use theme service
 *
 * import themeService from 'theme/ThemeService';
 *
 * const palette = themeService.getPalette();
 */
export enum ThemeColors {
  LightGray = '#f1f1f1',
  DarkGrey = '#444444',
  DarkerGray = '#dddddd',
  MediumGrey = '#535353',
  Purple = '#C490D1',
  Magenta = '#B8336A',
  Teal = '#6ab4e4', //#5C9EAD',
  DarkTeal = '#135EFC', //'#528D9A',
  Orange = '#F6BD60',
  Green = '#7ebd39',
  DarkGreen = '#40794e',
  Yellow = '#f0b019',
  Blue = '#0b66b1',
  Red = '#D9322F',
}

export function valueDeltaColor(previous: number, delta: number, invertColor: boolean = false) {
  if (previous === 0 || delta === 0) return ThemeColors.DarkGrey;
  if (invertColor) delta *= -1;
  return delta < 0 ? ThemeColors.Red : ThemeColors.Green;
}

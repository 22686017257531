import { Theme, Collapse } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import React, { useEffect } from 'react';
import { Alert } from 'shared/components/alert';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      marginBottom: theme.spacing(2),
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
  })
);

interface Props {
  password: string;
}

export const PasswordRulesHint: React.FC<Props> = ({ password }) => {
  const [open, setOpen] = React.useState(true);
  const classes = useStyles();

  useEffect(() => {
    if (/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{6,}$/.test(password)) setOpen(false);
    else setOpen(true);
  }, [password]);

  return (
    <div className={classes.root}>
      <Collapse in={open}>
        <Alert title="">
          Passwords requirements:
          <ol>
            <li>At least 6 characters long</li>
            <li>Contains an uppercase character</li>
            <li>Contains a lowercase character</li>
            <li>Contains a digit</li>
            <li>Contains a non-alphanumeric character</li>
          </ol>
        </Alert>
      </Collapse>
    </div>
  );
};

import React from 'react';
import { useProcessLogs } from '../hooks';
import {icons, LoadingFade, PageHeader } from '../../shared';
import { Card, CardContent, CardHeader, Box } from '@mui/material';
import { ProcessLogList } from '../components';

export function FeedStatusPage() {
  var { processLogs, loading } = useProcessLogs();

  return (
    <>
      <PageHeader
        title="FEED STATUS"
        icon={icons.admin}
      />
      <LoadingFade isContentLoading={loading}>
        {processLogs.map(log => (
          <Card style={{margin: '1rem'}}>
            <CardHeader>Feed Status</CardHeader>
            <CardContent>
                <Box marginBottom={8} key={log.feed?.id}>
                  <ProcessLogList processLog={log} />
                </Box>
            </CardContent>
          </Card>
        ))}
      </LoadingFade>
    </>
  );
}

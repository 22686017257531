import React from 'react';
import { ActivityModel, ActivityType } from '../../../api/app';
import { Box, Grid } from '@mui/material';
import { VictoryPie, VictoryLabel } from 'victory';
import { ThemeColors } from '../../../shared';
import { FiberManualRecord } from '@mui/icons-material';
import themeService from 'theme/ThemeService';

interface Props {
  data: Array<ActivityModel>;
}

function GetTypeCount(data: Array<ActivityModel>, type: ActivityType) {
  return data.reduce(function (n, val) {
    return val.type === type ? n + 1 : n;
  }, 0);
}

const palette = themeService.getPalette();

export const ActivityHistoryChart: React.FC<Props> = ({ data }) => {
  const emailActivityCount = GetTypeCount(data, ActivityType.Email);
  const meetingActivityCount = GetTypeCount(data, ActivityType.Meetings);
  const phoneActivityCount = GetTypeCount(data, ActivityType.Phone);
  const textActivityCount = GetTypeCount(data, ActivityType.Text);
  const noteActivityCount = GetTypeCount(data, ActivityType.Notes);
  const activityCount = data.length;

  return (
    <Box display="flex">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <svg viewBox="0 0 200 200">
            <VictoryPie
              height={175}
              width={175}
              padding={25}
              radius={70}
              innerRadius={57.5}
              data={[
                { x: 'Email', y: emailActivityCount, fill: ThemeColors.Purple },
                { x: 'Phone', y: phoneActivityCount, fill: ThemeColors.Orange },
                { x: 'Text', y: textActivityCount, fill: ThemeColors.Teal },
                { x: 'Meetings', y: meetingActivityCount, fill: ThemeColors.Green },
                { x: 'Notes', y: noteActivityCount, fill: ThemeColors.Magenta },
              ]}
              style={{ data: { fill: ({ datum }) => datum.fill } }}
              labels={() => ''}
              standalone={false}
            />
            <VictoryLabel
              x={87.5}
              y={87.5}
              textAnchor="middle"
              verticalAnchor="middle"
              text={`${activityCount}`}
              style={{ fontSize: 48, fontWeight: 700 }}
            />
          </svg>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Box sx={{border: 'solid thin', borderColor: palette.neutral, borderRadius: '8px', padding: '1rem'}}>
            <ActivityTypeLabel
              color={ThemeColors.Purple}
              label="Email"
              value={emailActivityCount}
            />
            <ActivityTypeLabel
              color={ThemeColors.Orange}
              label="Phone"
              value={phoneActivityCount}
            />
            <ActivityTypeLabel color={ThemeColors.Teal} label="Text" value={textActivityCount} />
            <ActivityTypeLabel
              color={ThemeColors.Green}
              label="Meetings"
              value={meetingActivityCount}
            />
            <ActivityTypeLabel
              color={ThemeColors.Magenta}
              label="Notes"
              value={noteActivityCount}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

interface RowProps {
  color: string;
  label: string;
  value: number;
}

const ActivityTypeLabel: React.FC<RowProps> = ({ label, value, color }) => {
  return (
    <Box>
      <Box fontSize="90%" display="flex" my={1}>
        <FiberManualRecord style={{ color: color }} />
        <Box fontWeight={700} fontSize={16}>{label}</Box>
        <Box flexGrow={1} textAlign="right" fontWeight={700} fontSize={16}>
          {value}
        </Box>
      </Box>
    </Box>
  );
};

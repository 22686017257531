import { Observable } from 'rxjs';
import { filter, startWith } from 'rxjs/operators';

import { FormStatus, FormEvent } from './form.models';
import { formService } from './form.service';
import { formQuery } from './form.query';

export function resetFormUI(formId: string) {
  formService.resetForm(formId);
}

export function getFormEvents(formId: string) {
  return formQuery.events.pipe(
    filter(x => x.formId === formId),
    startWith({
      formId,
      status: FormStatus.Initial,
      submitted: false,
    } as FormEvent)
  );
}

export function dispatchForm<T>(
  formId: string,
  responseSelector: (value: T) => any = x => x
): (source: Observable<T>) => Observable<T> {
  return (source: Observable<T>) => {
    formService.setPending(formId);

    return new Observable<T>(observer => {
      let lastValue: T;

      return source.pipe().subscribe({
        next(value: T) {
          observer.next((lastValue = value));
        },
        error(err: Error) {
          formService.setError(formId, err);
          observer.error(err);
        },
        complete() {
          formService.setSuccess(formId, responseSelector(lastValue));
          observer.complete();
        },
      });
    });
  };
}

import { QueryEntity } from '@datorama/akita';
import { switchMap } from 'rxjs/operators';
import { MlsQuery, mlsQuery } from 'state/mls/mls.query';

import { RecruitsState, RecruitsStore, recruitsStore } from './recruits.store';

export class RecruitsQuery extends QueryEntity<RecruitsState> {
  constructor(store: RecruitsStore, private readonly mls: MlsQuery) {
    super(store);
  }

  activeRecruit = this.selectActive();

  recruits = this.selectAll();

  volume = this.recruits.pipe(
    switchMap(recruits =>
      this.mls.getAgentVolumeQuery({ memberKeys: recruits.map(r => r.memberKey) })
    )
  );
}

export const recruitsQuery = new RecruitsQuery(recruitsStore, mlsQuery);

import { useQuery } from 'react-query';
import { ContactsApi } from '../../api/app';
import { useState } from 'react';

const contactsApi = new ContactsApi();

export function useSearchHashtags() {
  const [search, setSearch] = useState('');

  const { data, ...query } = useQuery(['search', 'hashtags', search], () => searchHashtag(search), {
    placeholderData: [],
  });

  return { ...query, data: data!, search, setSearch };
}

async function searchHashtag(hashtag: string) {
  if (hashtag.length > 0) return (await contactsApi.contactsHashtagsHashtagGet(hashtag)).data;

  return [];
}

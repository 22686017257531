import React, { useCallback } from 'react';
import { Link, LinkProps } from '@mui/material';
import { ResultContainerProps } from './results-list';
import { ResultType, SearchForm } from '../../state/search';
import {makeStyles} from "@mui/styles";
import themeService from 'theme/ThemeService';

const palette = themeService.getPalette();

const useStyles = makeStyles({
  link: {
    marginRight: "10px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: palette.teal,
  }
});

export const DrillDownLink: React.FC<
  {
    onSearch: ResultContainerProps['onSearch'];
    formKey: keyof SearchForm;
    formValue: string;
    resultType: ResultType;
    activeStatus: string | null | undefined;
  } & LinkProps
> = ({ formKey, formValue, resultType, onSearch, activeStatus, ...props }) => {
  const styles = useStyles();

  const drillDown = useCallback(
    (event: React.SyntheticEvent) => {
      event.preventDefault();
      onSearch({ [formKey]: [formValue] }, resultType);
    },
    [onSearch, formKey, formValue, resultType]
  );

  if (!props.children) return null;

  if (activeStatus === 'Inactive') {
    return <Link {...props} className={styles.link} style={{ pointerEvents: 'none', color: 'black' }} underline="hover" />;
  }

  return <Link {...props} className={styles.link} onClick={drillDown} style={{ cursor: 'pointer' }} underline="hover" />;
};

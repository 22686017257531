import React from 'react';
import { Grid, Card, CardHeader, CardContent, Typography } from '@mui/material';
import { Button } from 'shared/components/button';
import { ButtonLink } from 'shared/components/button-link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icons, PageHeader } from '../../shared';

export const OverviewPage: React.FC = () => {
  return (
    <>
      <PageHeader
        title="RESOURCES"
        icon={icons.tools}
      />
      <Grid container item xs={12} spacing={3}>
        <Grid item xs={12} md={4} style={{ display: 'flex' }}>
          <Card>
            <CardHeader title={'Tools'}></CardHeader>
            <CardContent>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <ButtonLink
                    to="/tools/calculator"
                    fullWidth
                    variant="secondary"
                    hideShadow
                  >
                    <Typography>
                      <FontAwesomeIcon style={{marginRight: '5px'}} icon={icons.calculator}/>
                      Net Income Valuation Calculator
                    </Typography>
                  </ButtonLink>
                </Grid>

                <Grid item>
                  <a
                    style={{ textDecoration: 'none' }}
                    href="https://help.insiderealestate.com/en/articles/8727505"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button
                      variant="secondary"
                      fullWidth
                      hideShadow
                    >
                      <Typography>
                        <FontAwesomeIcon style={{marginRight: '5px'}} icon={icons.question}/>
                        How to use the Net Income Valuation Calculator
                      </Typography>
                    </Button>
                  </a>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4} style={{ display: 'flex' }}>
          <Card>
            <CardHeader title={'Get Started'}></CardHeader>
            <CardContent>
              <a
                style={{ textDecoration: 'none' }}
                href="https://help.insiderealestate.com/en/articles/8723046"
                target="_blank"
                rel="noreferrer"
              >
                <Button
                  variant="secondary"
                  fullWidth
                  hideShadow
                >
                  <Typography>
                    <FontAwesomeIcon style={{marginRight: '5px'}} icon={icons.plus}/>
                    Getting Started Guide
                  </Typography>
                </Button>
              </a>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4} style={{ display: 'flex' }}>
          <Card>
            <CardHeader title={'Feature Guides'}></CardHeader>
            <CardContent>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <a
                    style={{ textDecoration: 'none' }}
                    href="https://help.insiderealestate.com/en/articles/8723375"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button
                      variant="secondary"
                      fullWidth
                      hideShadow
                    >
                      <Typography>
                        <FontAwesomeIcon style={{marginRight: '5px'}} icon={icons.searchGlass}/>
                        Recruit Search
                      </Typography>
                    </Button>
                  </a>
                </Grid>
                <Grid item>
                  <a
                    style={{ textDecoration: 'none' }}
                    href="https://help.insiderealestate.com/en/articles/8723098"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button
                      variant="secondary"
                      fullWidth
                      hideShadow
                    >
                      <Typography>
                        <FontAwesomeIcon style={{marginRight: '5px'}} icon={icons.poll}/>
                        Dashboard
                      </Typography>
                    </Button>
                  </a>
                </Grid>
                <Grid item>
                  <a
                    style={{ textDecoration: 'none' }}
                    href="https://help.insiderealestate.com/en/articles/8723717"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button
                      variant="secondary"
                      fullWidth
                      hideShadow
                    >
                      <Typography>
                        <FontAwesomeIcon style={{marginRight: '5px'}} icon={icons.cobroke}/>
                        Co-Broke Sales
                      </Typography>
                    </Button>
                  </a>
                </Grid>
                <Grid item>
                  <a
                    style={{ textDecoration: 'none' }}
                    href="https://help.insiderealestate.com/en/articles/8723729"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button
                      variant="secondary"
                      fullWidth
                      hideShadow
                    >
                      <Typography>
                        <FontAwesomeIcon style={{marginRight: '5px'}} icon={icons.filter}/>
                        Pipeline
                      </Typography>
                    </Button>
                  </a>
                </Grid>
                <Grid item>
                  <a
                    style={{ textDecoration: 'none' }}
                    href="https://help.insiderealestate.com/en/articles/8724716"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button
                      variant="secondary"
                      fullWidth
                      hideShadow
                    >
                      <Typography>
                        <FontAwesomeIcon style={{marginRight: '5px'}} icon={icons.dollar}/>
                        Agent Retention
                      </Typography>
                    </Button>
                  </a>
                </Grid>
                <Grid item>
                  <a
                    style={{ textDecoration: 'none' }}
                    href="https://help.insiderealestate.com/en/articles/8727545"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button
                      variant="secondary"
                      fullWidth
                      hideShadow
                    >
                      <Typography>
                        <FontAwesomeIcon style={{marginRight: '5px'}} icon={icons.admin}/>
                        My Account, Recruiting Goal, and Email Notifications
                      </Typography>
                    </Button>
                  </a>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={4} style={{ display: 'flex' }}>
          <Card>
            <CardHeader title={'FAQ'}></CardHeader>
            <CardContent>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <a
                    style={{ textDecoration: 'none' }}
                    href="https://help.insiderealestate.com/en/articles/6973758"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button
                      variant="secondary"
                      fullWidth
                      hideShadow
                    >
                      <Typography>
                        <FontAwesomeIcon style={{marginRight: '5px'}} icon={icons.question}/>
                        Frequently Asked Questions
                      </Typography>
                    </Button>
                  </a>
                </Grid>

                <Grid item>
                  <a style={{ textDecoration: 'none' }} href="mailto:recruitsupport@boldtrail.com">
                    <Button
                      variant="secondary"
                      fullWidth
                      hideShadow
                    >
                      <Typography>
                        <FontAwesomeIcon style={{marginRight: '5px'}} icon={icons.email}/>
                        Email Support
                      </Typography>
                    </Button>
                  </a>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

import { EntityState, EntityStore, StoreConfig, ActiveState } from '@datorama/akita';

import { NotificationModel } from './notifications.models';

export interface NotificationsState
  extends EntityState<NotificationModel>,
    ActiveState<NotificationModel> {
  ui: {
    totalRecords: number;
    memberKeys: string[];
    listingKeys: string[];
  };
}

@StoreConfig({ name: 'notifications', resettable: true })
export class NotificationsStore extends EntityStore<NotificationsState> {
  constructor() {
    super({ ui: { totalRecords: -1, memberKeys: [], listingKeys: [] } });
  }
}

export const notificationsStore = new NotificationsStore();

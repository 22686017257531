import { SurveyAnswer, SurveyQuestion } from '../../state/surveys';
import { Rating } from '@mui/material';
import { Typography } from 'shared/components';
import { DataTable, DataTableColumn } from 'shared/components/data-table';
interface Props {
  questions: Array<SurveyQuestion>;
  answers: Array<SurveyAnswer>;
}

export function AnswerList({ questions, answers }: Props) {
  const columns: Array<DataTableColumn<SurveyAnswer>> = [
    {
      headerName: '#',
      renderCell: ({ row }) => questions.find(q => q.id === row.questionId)?.questionOrder,
      field: 'questionOrder',
      sortable: true,
      valueGetter: (value, row) => row.questionId,
      sortComparator: (v1: number, v2: number) => {
        const v1QuestionOrder = questions.find(q => q.id === v1)?.questionOrder || 0;
        const v2QuestionOrder = questions.find(q => q.id === v2)?.questionOrder || 0;
        return v1QuestionOrder - v2QuestionOrder;
      },
    },
    {
      headerName: 'Question',
      renderCell: ({ row }) => questions.find(q => q.id === row.questionId)?.questionText,
      field: 'questionText',
      sortable: true,
      valueGetter: (value, row) => row.questionId,
      sortComparator: (v1: number, v2: number) => {
        const v1QuestionOrder = questions.find(q => q.id === v1)?.questionText || '';
        const v2QuestionOrder = questions.find(q => q.id === v2)?.questionText || '';
        return v1QuestionOrder.localeCompare(v2QuestionOrder);
      },
    },
    {
      headerName: 'Answer',
      renderCell: ({ row }) => (
        <AnswerCell
          question={questions.find(q => q.id === row.questionId)!}
          rating={row.answerRating}
          text={row.answerText ?? ''}
        />
      ),
      field: 'questionType',
      sortable: true,
      valueGetter: (value, row) => row.questionId,
      sortComparator: (v1: number, v2: number) => {
        const questionV1 = questions.find(q => q.id === v1);
        const questionV2 = questions.find(q => q.id === v2);

        if (!questionV1 || !questionV2 || !questionV1.questionTypeId || !questionV2.questionTypeId)
          return 0;

        return questionV1.questionTypeId > questionV2.questionTypeId ? 1 : -1;
      },
    },
  ];

  return (
    <DataTable
      columns={columns}
      rows={answers}
      initialState={{
        sorting: {
          sortModel: [
            {
              field: 'questionOrder',
              sort: 'asc',
            },
          ],
        },
      }}
    />
  );
}

interface AnswerCellProps {
  text: string;
  rating?: number;
  question: SurveyQuestion;
}

function AnswerCell({ text, rating, question }: AnswerCellProps) {
  return (
    <>
      {question.questionTypeId === 1 ? (
        <Rating value={rating || 0} readOnly />
      ) : (
        <Typography>{text}</Typography>
      )}
    </>
  );
}

import React from 'react';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ThemeColors } from '../theme';
import classNames from 'classnames';

export interface ButtonProps<E> {
  label: string;
  filter: E;
  active: boolean;
  disabled?: boolean;
  onSelection(filter: E): void;
}

const useHotListTypeButtonStyles = makeStyles(() => ({
  button: () => ({
    flexGrow: 1,
    fontWeight: 'bold',
    borderWidth: 3,
    fontSize: '8pt',
    marginRight: 8,
    justifyContent: 'flex-start',
    '&:last-child': {
      marginRight: 0,
    },
    '&:hover': {
      color: 'white',
      backgroundColor: ThemeColors.DarkTeal,
    },
  }),
  active: () => ({
    borderColor: ThemeColors.DarkTeal,
    backgroundColor: 'white',
    '&:hover': {
      borderColor: 'white',
    },
  }),
}));

export const QuickFilterButton = <E,>({
  label,
  filter,
  active,
  disabled = true,
  onSelection,
}: ButtonProps<E>) => {
  const classes = useHotListTypeButtonStyles();

  const onClick = () => {
    onSelection(filter);
  };

  return (
    <Button
      className={classNames(classes.button, { [classes.active]: active })}
      onClick={onClick}
      variant="outlined"
      disabled={disabled}
    >
      {label}
    </Button>
  );
};

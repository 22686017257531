import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { FormEvent } from './form.models';

export interface FormState extends EntityState<FormEvent, FormEvent['formId']> {}

@StoreConfig({
  name: 'forms',
  idKey: 'formId',
  resettable: true,
})
export class FormStore extends EntityStore<FormState, FormEvent, FormEvent['formId']> {}

export const formStore = new FormStore();

import React from 'react';
import { Card, CardContent } from '@mui/material';
import { EditUserForm as EditUserFormModel } from '../../state/users';
import { useFormEvents } from '../../shared/forms';
import { FormStatus } from '../../state';
import { EditUserForm } from '../components';
import { useParams } from 'react-router-dom';
import { useCompanyOffices, useManager } from '../hooks';
import { UpsertManagerForm, managersService, ManagerForms } from '../../state/admin';

export const EditUserPage: React.FC = () => {
  const params = useParams<{ companyId: string; userId: string }>();
  const companyId = parseInt(params.companyId);
  const userId = params.userId;
  const [savingEvents, resetFormUI] = useFormEvents(ManagerForms.Edit);
  const [resetEvents] = useFormEvents(ManagerForms.Reset);

  const { companyOffices: offices } = useCompanyOffices(companyId);
  const manager = useManager(userId);

  if (!manager || manager.id !== userId) return null;

  const activeForm = {
    name: manager?.name ?? '',
    email: manager?.email ?? '',
    officeIds: manager?.officeIds ?? [],
    owner: manager?.owner ?? false,
  };

  const submit = (form: EditUserFormModel) => {
    form.companyId = companyId;
    const officeCodes = offices
      .filter(o => form.owner || form.officeIds?.some(id => id === o.officeId))
      .flatMap(o => o.codes ?? []);
    if (!!manager && !!manager.id)
      managersService.updateManager(manager.id, form as UpsertManagerForm, officeCodes);
  };

  const resetPassword = () => {
    managersService.resetPassword(userId);
  };

  return (
    <Card>
      <CardContent>
        <EditUserForm
          activeForm={activeForm}
          offices={offices}
          onSubmit={submit}
          onResetFeedback={resetFormUI}
          error={savingEvents.error}
          success={savingEvents.status === FormStatus.Success}
          pending={savingEvents.status === FormStatus.Pending}
          handleResetPassword={resetPassword}
          resetEvents={resetEvents}
        />
      </CardContent>
    </Card>
  );
};

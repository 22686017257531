import React from 'react';
import { Survey } from '../../state/surveys';
import { InfoRow } from '../../shared';
import { SubmissionScore } from './submission-score';
import { Card, CardHeader, CardContent, Box } from '@mui/material';

interface Props {
  survey: Survey;
  possiblePoints?: number;
  totalPoints?: number;
}

export function SurveyInfo({ survey, possiblePoints, totalPoints }: Props) {
  return (
    <Box display="flex" height="100%">
      <Card>
        <CardHeader title="Survey Info" />
        <CardContent>
          <InfoRow
            label="Created Date"
            value={survey.createdDate ? new Date(survey.createdDate).toLocaleString() : undefined}
          />
          <InfoRow label="Manager" value={survey.manager?.name} />
          <InfoRow
            label="Active Status"
            value={survey.active ? 'Active' : 'Not Active'}
            styles={{ color: survey.active ? '#90C341' : '#B66963' }}
          />
          <InfoRow
            label="Average Score"
            value={
              <SubmissionScore
                totalPoints={totalPoints || 0}
                possiblePoints={possiblePoints || 0}
              />
            }
          />
        </CardContent>
      </Card>
    </Box>
  );
}

import { EntityState, EntityStore, StoreConfig, ActiveState } from '@datorama/akita';

import { User } from './users.models';

export interface UsersState
  extends EntityState<User, User['id']>,
    ActiveState<User['id']> {
  ui: {};
}

@StoreConfig({ name: 'users', resettable: true })
export class UsersStore extends EntityStore<UsersState> {
  constructor() {
    super({ active: null, ui: {} });
  }
}

export const usersStore = new UsersStore();

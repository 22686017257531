import { ReactNode } from 'react';
import { Alert as MuiAlert, AlertTitle as MuiAlertTitle } from '@mui/material';
import { useTheme } from 'theme/ThemeContext';

interface AlertProps {
  title?: string;
  children?: ReactNode;
  variant?: 'info' | 'success' | 'warning' | 'error';
  onClose?: () => void;
}

export function Alert({ title, variant = 'info', onClose, children }: AlertProps) {
  const theme = useTheme();

  return (
    <MuiAlert severity={variant} onClose={onClose}
    sx={{
      borderRadius: theme.alert.borderRadius,
      '&.MuiAlert-standardInfo': {
        background: theme.alert.info.backgroundColor,
        color: theme.alert.info.textColor,
      },
      '&.MuiAlert-standardInfo .MuiAlert-icon': {
        color: theme.alert.info.iconColor,
      },
      '&.MuiAlert-standardSuccess': {
        background: theme.alert.success.backgroundColor,
        color: theme.alert.success.textColor,
      },
      '&.MuiAlert-standardSuccess .MuiAlert-icon': {
        color: theme.alert.success.iconColor,
      },
      '&.MuiAlert-standardWarning': {
        background: theme.alert.warning.backgroundColor,
        color: theme.alert.warning.textColor,
      },
      '&.MuiAlert-standardWarning .MuiAlert-icon': {
        color: theme.alert.warning.iconColor,
      },
      '&.MuiAlert-standardError': {
        background: theme.alert.error.backgroundColor,
        color: theme.alert.error.textColor,
      },
      '&.MuiAlert-standardError .MuiAlert-icon': {
        color: theme.alert.error.iconColor,
      },
    }}>
      {title ? <MuiAlertTitle>{title}</MuiAlertTitle> : null}
      {children}
    </MuiAlert>
  );
}

import React from 'react';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import MatLink, { LinkProps as MatLinkProps } from '@mui/material/Link';
import themeService from 'theme/ThemeService';

const palette = themeService.getPalette();

export const Link: React.FC<MatLinkProps & RouterLinkProps> = ({ ...props }) => (
  <MatLink component={RouterLink} {...props} color={palette.teal} underline="hover" />
);

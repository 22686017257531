import { QueryEntity } from '@datorama/akita';
import { map, switchMap } from 'rxjs/operators';
import { Agent, Listing } from '../mls';
import { AgentsQuery, ListingsQuery } from '../mls/stores';
import { distinctUntilArrayChanged } from '../mls/util';

import { NotificationsState, NotificationsStore, notificationsStore } from './notifications.store';

export class NotificationsQuery extends QueryEntity<NotificationsState> {
  constructor(
    notificationsStore: NotificationsStore,
    private readonly agentsQuery: AgentsQuery,
    private readonly listingsQuery: ListingsQuery
  ) {
    super(notificationsStore);
  }

  activeNotification = this.selectActive();

  userNotifications = this.selectAll();

  totalNotifications = this.select(({ ui }) => ui.totalRecords);

  loadedNotifications = this.selectCount();

  agents = this.select(({ ui }) => ui.memberKeys).pipe(
    distinctUntilArrayChanged(),
    switchMap(memberKeys => this.agentsQuery.selectMany(memberKeys)),
    map(members => new Map<string, Agent>(members.map(i => [i.memberKey, i])))
  );

  listings = this.select(({ ui }) => ui.listingKeys).pipe(
    distinctUntilArrayChanged(),
    switchMap(listingKeys => this.listingsQuery.selectMany(listingKeys)),
    map(listings => new Map<string, Listing>(listings.map(i => [i.listingKey, i])))
  );
}

export const notificationsQuery = new NotificationsQuery(
  notificationsStore,
  AgentsQuery.instance,
  ListingsQuery.instance
);

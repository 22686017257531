import React, { useEffect } from 'react';
import { useObservable } from 'shared/utils/useObservable';
import { useCurrentUserId } from 'shared/utils/useManager';
import { managerQuery, managerService } from '../../state/managers';

function useManagerId() {
  const managerId = useObservable(managerQuery.activeManagerId);
  const currentUserId = useCurrentUserId();
  useEffect(() => {
    if (managerId === null && !!currentUserId)
      managerService.setActiveManagerId(currentUserId as string);
  }, [managerId, currentUserId]);
  return managerId;
}

interface ManagerIdProps {
  managerId: string;
}

export function withManagerId<P extends {}>(
  WrappedComponent: React.ComponentType<P & ManagerIdProps>
) {
  function WithManagerId(props: P) {
    const managerId = useManagerId();
    if (!managerId) {
      return null; // nothing to render yet...
    }

    return <WrappedComponent {...props} managerId={managerId} />;
  }

  return WithManagerId;
}

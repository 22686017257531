export enum UserForms {
  // User
  Profile = 'profile',
  Login = 'login',
  ForgotPassword = 'forgotPassword',
  ResetPassword = 'resetPassword',

  // Admin
  Create = 'create',
  Edit = 'edit',
}

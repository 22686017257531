import { makeStyles } from '@mui/styles';
import React, {CSSProperties} from 'react';
import LogoImage from '../../assets/BT-Recruit-Horizontal-Dark.png';

interface Props {
  imgStyle: CSSProperties | undefined;
}

export const Logo: React.FC<Props> = ({imgStyle}) => {
  const useStyles = makeStyles(theme => ({
    logo: {
      ...theme.mixins.toolbar,
      ...imgStyle,
      backgroundColor: theme.palette.primary.contrastText,
      color: theme.palette.primary.contrastText,
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      paddingTop: theme.spacing(1),
    },
  }));

  const styles = useStyles();

  return (
    <div className={styles.logo}>
      <img src={LogoImage} style={{maxWidth: '90%', maxHeight: '90%'}} alt="BoldTrail Recruit Logo" />
    </div>
  );
};

import React from 'react';
import { LinearProgress, Typography } from '@mui/material';

import { Company } from '../../state/admin';
import { Link, useObservable } from '../../shared';
import { DataTable, DataTableColumn } from 'shared/components/data-table';

import { useLoadingIndicator } from '../../shared/indicators/isLoading';
import { sessionQuery } from 'state';

interface Props {
  companies: Array<Company>;
}

export const CompanyList: React.FC<Props> = React.memo(({ companies }) => {
  const loading = useLoadingIndicator();
  const currentCompany = useObservable(sessionQuery.currentCompany);

  const columns: DataTableColumn<Company>[] = [
    {
      renderHeader: () => <Typography noWrap fontWeight="bold">NAME</Typography>,
      headerAlign: 'center',
      align: 'left',
      display: 'flex',
      field: 'name',
      renderCell: ({ row }) => (
        <Link to={'/admin/companies/' + row.id} color="primary">
          {row.name}
        </Link>
      ),
      sortable: true,
      filterable: false,
      flex: 1,
      valueGetter: (value, row) => row.name
    },
    {
      renderHeader: () => <Typography noWrap fontWeight="bold">ACCOUNT STATUS</Typography>,
      headerAlign: 'center',
      align: 'center',
      field: 'accountStatus',
      sortable: true,
      filterable: false,
      flex: 1,
      valueGetter: (value, row) => row.accountStatus
    },
    {
      renderHeader: () => <Typography noWrap fontWeight="bold"># OF MANAGERS</Typography>,
      headerAlign: 'center',
      align: 'center',
      field: 'managers',
      sortable: true,
      filterable: false,
      type: 'number',
      flex: 1,
      valueGetter: (value, row) => row.managers
    },
    {
      renderHeader: () => <Typography noWrap fontWeight="bold">BOLDTRAIL RECRUIT ID</Typography>,
      headerAlign: 'center',
      align: 'center',
      field: 'id',
      sortable: true,
      filterable: false,
      type: 'number',
      flex: 1,
      valueGetter: (value, row) => row.id
    },
    {
      renderHeader: () => <Typography noWrap fontWeight="bold">BOLDTRAIL RECRUIT SID</Typography>,
      headerAlign: 'center',
      align: 'center',
      field: 'ampstatsSID',
      sortable: true,
      filterable: false,
      flex: 1,
      valueGetter: (value, row) => row.ampstatsSid
    },
    {
      renderHeader: () => <Typography noWrap fontWeight="bold">BOLDTRAIL SID</Typography>,
      headerAlign: 'center',
      align: 'center',
      field: 'kvCoreSID',
      sortable: true,
      filterable: false,
      flex: 1,
      valueGetter: (value, row) => row.kvCoreSid
    },
  ];

  return (
    <>
      {loading && <LinearProgress />}
      <DataTable
        rows={companies}
        columns={columns}
        progressPending={loading}
        conditionalRowStyles={row => {
          if (row.id === currentCompany?.id) return 'green';
          return 'none';
        }}
        initialState={{
          sorting: {
            sortModel: [
              {
                field: 'name',
                sort: 'asc',
              },
            ],
          },
        }}
      />
    </>
  );
});

import { ConfirmationDialog, Typography } from 'shared/components';
interface Props {
  open: boolean;
  onClose: () => void;
  error?: Error;
  message?: string;
}

export function ErrorDisplay({ open, onClose, error, message }: Props) {
  return (
    <ConfirmationDialog isOpen={open} title="Error" onClose={onClose} onConfirm={onClose}>
      <Typography color="error">
        {error?.message || message}
      </Typography>
    </ConfirmationDialog>
  );
}

/* eslint-disable */
// tslint:disable
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';

import { AnswerModel } from '../model';
import { CreateEmailTemplateRequest } from '../model';
import { CreateSurveyRequest } from '../model';
import { DefaultQuestionModel } from '../model';
import { DefaultQuestionsResponse } from '../model';
import { GetAnswersResponse } from '../model';
import { GetCompanyResponse } from '../model';
import { GetEmailTemplateResponse } from '../model';
import { GetSubmissionsResponse } from '../model';
import { GetSurveysResponse } from '../model';
import { QuestionTypesResponse } from '../model';
import { QuestionsResponse } from '../model';
import { SendSurveyRequest } from '../model';
import { SubmissionResponse } from '../model';
import { SurveyResponse } from '../model';
import { UpdateSurveyRequest } from '../model';
/**
 * SurveyApi - axios parameter creator
 * @export
 */
export const SurveyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyActiveGet(options: any = {}): RequestArgs {
            const localVarPath = `/survey/active`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            //@ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} submissionLink 
         * @param {Array<AnswerModel>} [answerModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyAnswerSubmissionLinkPost(submissionLink: string, answerModel?: Array<AnswerModel>, options: any = {}): RequestArgs {
            // verify required parameter 'submissionLink' is not null or undefined
            if (submissionLink === null || submissionLink === undefined) {
                throw new RequiredError('submissionLink','Required parameter submissionLink was null or undefined when calling surveyAnswerSubmissionLinkPost.');
            }
            const localVarPath = `/survey/answer/{submissionLink}`
                .replace(`{${"submissionLink"}}`, encodeURIComponent(String(submissionLink)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            //@ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof answerModel !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(answerModel !== undefined ? answerModel : {}) : (answerModel || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyAnswersIdGet(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling surveyAnswersIdGet.');
            }
            const localVarPath = `/survey/answers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            //@ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyCompanyEmailtemplateGet(options: any = {}): RequestArgs {
            const localVarPath = `/survey/company/emailtemplate`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            //@ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateEmailTemplateRequest} [createEmailTemplateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyCompanyEmailtemplatePost(createEmailTemplateRequest?: CreateEmailTemplateRequest, options: any = {}): RequestArgs {
            const localVarPath = `/survey/company/emailtemplate`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            //@ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof createEmailTemplateRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createEmailTemplateRequest !== undefined ? createEmailTemplateRequest : {}) : (createEmailTemplateRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} submissionLink 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyCompanySubmissionLinkGet(submissionLink: string, options: any = {}): RequestArgs {
            // verify required parameter 'submissionLink' is not null or undefined
            if (submissionLink === null || submissionLink === undefined) {
                throw new RequiredError('submissionLink','Required parameter submissionLink was null or undefined when calling surveyCompanySubmissionLinkGet.');
            }
            const localVarPath = `/survey/company/{submissionLink}`
                .replace(`{${"submissionLink"}}`, encodeURIComponent(String(submissionLink)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            //@ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyCompanySubmissionsCompanyIdGet(companyId: number, options: any = {}): RequestArgs {
            // verify required parameter 'companyId' is not null or undefined
            if (companyId === null || companyId === undefined) {
                throw new RequiredError('companyId','Required parameter companyId was null or undefined when calling surveyCompanySubmissionsCompanyIdGet.');
            }
            const localVarPath = `/survey/company-submissions/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            //@ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyDefaultsGet(options: any = {}): RequestArgs {
            const localVarPath = `/survey/defaults`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            //@ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<DefaultQuestionModel>} [defaultQuestionModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyDefaultsPut(defaultQuestionModel?: Array<DefaultQuestionModel>, options: any = {}): RequestArgs {
            const localVarPath = `/survey/defaults`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            //@ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof defaultQuestionModel !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(defaultQuestionModel !== undefined ? defaultQuestionModel : {}) : (defaultQuestionModel || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyIdGet(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling surveyIdGet.');
            }
            const localVarPath = `/survey/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            //@ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyManagerIdGet(id: string, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling surveyManagerIdGet.');
            }
            const localVarPath = `/survey/manager/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            //@ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateSurveyRequest} [createSurveyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyPost(createSurveyRequest?: CreateSurveyRequest, options: any = {}): RequestArgs {
            const localVarPath = `/survey`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            //@ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof createSurveyRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createSurveyRequest !== undefined ? createSurveyRequest : {}) : (createSurveyRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateSurveyRequest} [updateSurveyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyPut(updateSurveyRequest?: UpdateSurveyRequest, options: any = {}): RequestArgs {
            const localVarPath = `/survey`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            //@ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof updateSurveyRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateSurveyRequest !== undefined ? updateSurveyRequest : {}) : (updateSurveyRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyQuestionsIdGet(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling surveyQuestionsIdGet.');
            }
            const localVarPath = `/survey/questions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            //@ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyQuestiontypesGet(options: any = {}): RequestArgs {
            const localVarPath = `/survey/questiontypes`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            //@ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SendSurveyRequest} [sendSurveyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveySendPost(sendSurveyRequest?: SendSurveyRequest, options: any = {}): RequestArgs {
            const localVarPath = `/survey/send`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            //@ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof sendSurveyRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(sendSurveyRequest !== undefined ? sendSurveyRequest : {}) : (sendSurveyRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [email] 
         * @param {CreateEmailTemplateRequest} [createEmailTemplateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveySenddemoemailPost(email?: string, createEmailTemplateRequest?: CreateEmailTemplateRequest, options: any = {}): RequestArgs {
            const localVarPath = `/survey/senddemoemail`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            //@ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof createEmailTemplateRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createEmailTemplateRequest !== undefined ? createEmailTemplateRequest : {}) : (createEmailTemplateRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} submissionLink 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveySubmissionQuestionsSubmissionLinkGet(submissionLink: string, options: any = {}): RequestArgs {
            // verify required parameter 'submissionLink' is not null or undefined
            if (submissionLink === null || submissionLink === undefined) {
                throw new RequiredError('submissionLink','Required parameter submissionLink was null or undefined when calling surveySubmissionQuestionsSubmissionLinkGet.');
            }
            const localVarPath = `/survey/submission/questions/{submissionLink}`
                .replace(`{${"submissionLink"}}`, encodeURIComponent(String(submissionLink)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            //@ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} submissionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveySubmissionSubmissionIdGet(submissionId: number, options: any = {}): RequestArgs {
            // verify required parameter 'submissionId' is not null or undefined
            if (submissionId === null || submissionId === undefined) {
                throw new RequiredError('submissionId','Required parameter submissionId was null or undefined when calling surveySubmissionSubmissionIdGet.');
            }
            const localVarPath = `/survey/submission/{submissionId}`
                .replace(`{${"submissionId"}}`, encodeURIComponent(String(submissionId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            //@ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} surveyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveySubmissionsSurveyIdGet(surveyId: number, options: any = {}): RequestArgs {
            // verify required parameter 'surveyId' is not null or undefined
            if (surveyId === null || surveyId === undefined) {
                throw new RequiredError('surveyId','Required parameter surveyId was null or undefined when calling surveySubmissionsSurveyIdGet.');
            }
            const localVarPath = `/survey/submissions/{surveyId}`
                .replace(`{${"surveyId"}}`, encodeURIComponent(String(surveyId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            //@ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyUpdatestatusIdPut(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling surveyUpdatestatusIdPut.');
            }
            const localVarPath = `/survey/updatestatus/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            //@ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SurveyApi - functional programming interface
 * @export
 */
export const SurveyApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyActiveGet(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SurveyResponse> {
            const localVarAxiosArgs = SurveyApiAxiosParamCreator(configuration).surveyActiveGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} submissionLink 
         * @param {Array<AnswerModel>} [answerModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyAnswerSubmissionLinkPost(submissionLink: string, answerModel?: Array<AnswerModel>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubmissionResponse> {
            const localVarAxiosArgs = SurveyApiAxiosParamCreator(configuration).surveyAnswerSubmissionLinkPost(submissionLink, answerModel, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyAnswersIdGet(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAnswersResponse> {
            const localVarAxiosArgs = SurveyApiAxiosParamCreator(configuration).surveyAnswersIdGet(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyCompanyEmailtemplateGet(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetEmailTemplateResponse> {
            const localVarAxiosArgs = SurveyApiAxiosParamCreator(configuration).surveyCompanyEmailtemplateGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {CreateEmailTemplateRequest} [createEmailTemplateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyCompanyEmailtemplatePost(createEmailTemplateRequest?: CreateEmailTemplateRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = SurveyApiAxiosParamCreator(configuration).surveyCompanyEmailtemplatePost(createEmailTemplateRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} submissionLink 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyCompanySubmissionLinkGet(submissionLink: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCompanyResponse> {
            const localVarAxiosArgs = SurveyApiAxiosParamCreator(configuration).surveyCompanySubmissionLinkGet(submissionLink, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyCompanySubmissionsCompanyIdGet(companyId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSubmissionsResponse> {
            const localVarAxiosArgs = SurveyApiAxiosParamCreator(configuration).surveyCompanySubmissionsCompanyIdGet(companyId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyDefaultsGet(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefaultQuestionsResponse> {
            const localVarAxiosArgs = SurveyApiAxiosParamCreator(configuration).surveyDefaultsGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {Array<DefaultQuestionModel>} [defaultQuestionModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyDefaultsPut(defaultQuestionModel?: Array<DefaultQuestionModel>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefaultQuestionsResponse> {
            const localVarAxiosArgs = SurveyApiAxiosParamCreator(configuration).surveyDefaultsPut(defaultQuestionModel, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyIdGet(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SurveyResponse> {
            const localVarAxiosArgs = SurveyApiAxiosParamCreator(configuration).surveyIdGet(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyManagerIdGet(id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSurveysResponse> {
            const localVarAxiosArgs = SurveyApiAxiosParamCreator(configuration).surveyManagerIdGet(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {CreateSurveyRequest} [createSurveyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyPost(createSurveyRequest?: CreateSurveyRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SurveyResponse> {
            const localVarAxiosArgs = SurveyApiAxiosParamCreator(configuration).surveyPost(createSurveyRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {UpdateSurveyRequest} [updateSurveyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyPut(updateSurveyRequest?: UpdateSurveyRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SurveyResponse> {
            const localVarAxiosArgs = SurveyApiAxiosParamCreator(configuration).surveyPut(updateSurveyRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyQuestionsIdGet(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestionsResponse> {
            const localVarAxiosArgs = SurveyApiAxiosParamCreator(configuration).surveyQuestionsIdGet(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyQuestiontypesGet(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestionTypesResponse> {
            const localVarAxiosArgs = SurveyApiAxiosParamCreator(configuration).surveyQuestiontypesGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {SendSurveyRequest} [sendSurveyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveySendPost(sendSurveyRequest?: SendSurveyRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubmissionResponse> {
            const localVarAxiosArgs = SurveyApiAxiosParamCreator(configuration).surveySendPost(sendSurveyRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [email] 
         * @param {CreateEmailTemplateRequest} [createEmailTemplateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveySenddemoemailPost(email?: string, createEmailTemplateRequest?: CreateEmailTemplateRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = SurveyApiAxiosParamCreator(configuration).surveySenddemoemailPost(email, createEmailTemplateRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} submissionLink 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveySubmissionQuestionsSubmissionLinkGet(submissionLink: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestionsResponse> {
            const localVarAxiosArgs = SurveyApiAxiosParamCreator(configuration).surveySubmissionQuestionsSubmissionLinkGet(submissionLink, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} submissionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveySubmissionSubmissionIdGet(submissionId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubmissionResponse> {
            const localVarAxiosArgs = SurveyApiAxiosParamCreator(configuration).surveySubmissionSubmissionIdGet(submissionId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} surveyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveySubmissionsSurveyIdGet(surveyId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSubmissionsResponse> {
            const localVarAxiosArgs = SurveyApiAxiosParamCreator(configuration).surveySubmissionsSurveyIdGet(surveyId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyUpdatestatusIdPut(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SurveyResponse> {
            const localVarAxiosArgs = SurveyApiAxiosParamCreator(configuration).surveyUpdatestatusIdPut(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SurveyApi - factory interface
 * @export
 */
export const SurveyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyActiveGet(options?: any): AxiosPromise<SurveyResponse> {
            return SurveyApiFp(configuration).surveyActiveGet(options)(axios, basePath);
        },
        /**
         * 
         * @param {string} submissionLink 
         * @param {Array<AnswerModel>} [answerModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyAnswerSubmissionLinkPost(submissionLink: string, answerModel?: Array<AnswerModel>, options?: any): AxiosPromise<SubmissionResponse> {
            return SurveyApiFp(configuration).surveyAnswerSubmissionLinkPost(submissionLink, answerModel, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyAnswersIdGet(id: number, options?: any): AxiosPromise<GetAnswersResponse> {
            return SurveyApiFp(configuration).surveyAnswersIdGet(id, options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyCompanyEmailtemplateGet(options?: any): AxiosPromise<GetEmailTemplateResponse> {
            return SurveyApiFp(configuration).surveyCompanyEmailtemplateGet(options)(axios, basePath);
        },
        /**
         * 
         * @param {CreateEmailTemplateRequest} [createEmailTemplateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyCompanyEmailtemplatePost(createEmailTemplateRequest?: CreateEmailTemplateRequest, options?: any): AxiosPromise<void> {
            return SurveyApiFp(configuration).surveyCompanyEmailtemplatePost(createEmailTemplateRequest, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} submissionLink 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyCompanySubmissionLinkGet(submissionLink: string, options?: any): AxiosPromise<GetCompanyResponse> {
            return SurveyApiFp(configuration).surveyCompanySubmissionLinkGet(submissionLink, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyCompanySubmissionsCompanyIdGet(companyId: number, options?: any): AxiosPromise<GetSubmissionsResponse> {
            return SurveyApiFp(configuration).surveyCompanySubmissionsCompanyIdGet(companyId, options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyDefaultsGet(options?: any): AxiosPromise<DefaultQuestionsResponse> {
            return SurveyApiFp(configuration).surveyDefaultsGet(options)(axios, basePath);
        },
        /**
         * 
         * @param {Array<DefaultQuestionModel>} [defaultQuestionModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyDefaultsPut(defaultQuestionModel?: Array<DefaultQuestionModel>, options?: any): AxiosPromise<DefaultQuestionsResponse> {
            return SurveyApiFp(configuration).surveyDefaultsPut(defaultQuestionModel, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyIdGet(id: number, options?: any): AxiosPromise<SurveyResponse> {
            return SurveyApiFp(configuration).surveyIdGet(id, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyManagerIdGet(id: string, options?: any): AxiosPromise<GetSurveysResponse> {
            return SurveyApiFp(configuration).surveyManagerIdGet(id, options)(axios, basePath);
        },
        /**
         * 
         * @param {CreateSurveyRequest} [createSurveyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyPost(createSurveyRequest?: CreateSurveyRequest, options?: any): AxiosPromise<SurveyResponse> {
            return SurveyApiFp(configuration).surveyPost(createSurveyRequest, options)(axios, basePath);
        },
        /**
         * 
         * @param {UpdateSurveyRequest} [updateSurveyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyPut(updateSurveyRequest?: UpdateSurveyRequest, options?: any): AxiosPromise<SurveyResponse> {
            return SurveyApiFp(configuration).surveyPut(updateSurveyRequest, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyQuestionsIdGet(id: number, options?: any): AxiosPromise<QuestionsResponse> {
            return SurveyApiFp(configuration).surveyQuestionsIdGet(id, options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyQuestiontypesGet(options?: any): AxiosPromise<QuestionTypesResponse> {
            return SurveyApiFp(configuration).surveyQuestiontypesGet(options)(axios, basePath);
        },
        /**
         * 
         * @param {SendSurveyRequest} [sendSurveyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveySendPost(sendSurveyRequest?: SendSurveyRequest, options?: any): AxiosPromise<SubmissionResponse> {
            return SurveyApiFp(configuration).surveySendPost(sendSurveyRequest, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [email] 
         * @param {CreateEmailTemplateRequest} [createEmailTemplateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveySenddemoemailPost(email?: string, createEmailTemplateRequest?: CreateEmailTemplateRequest, options?: any): AxiosPromise<void> {
            return SurveyApiFp(configuration).surveySenddemoemailPost(email, createEmailTemplateRequest, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} submissionLink 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveySubmissionQuestionsSubmissionLinkGet(submissionLink: string, options?: any): AxiosPromise<QuestionsResponse> {
            return SurveyApiFp(configuration).surveySubmissionQuestionsSubmissionLinkGet(submissionLink, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} submissionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveySubmissionSubmissionIdGet(submissionId: number, options?: any): AxiosPromise<SubmissionResponse> {
            return SurveyApiFp(configuration).surveySubmissionSubmissionIdGet(submissionId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} surveyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveySubmissionsSurveyIdGet(surveyId: number, options?: any): AxiosPromise<GetSubmissionsResponse> {
            return SurveyApiFp(configuration).surveySubmissionsSurveyIdGet(surveyId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyUpdatestatusIdPut(id: number, options?: any): AxiosPromise<SurveyResponse> {
            return SurveyApiFp(configuration).surveyUpdatestatusIdPut(id, options)(axios, basePath);
        },
    };
};

/**
 * SurveyApi - object-oriented interface
 * @export
 * @class SurveyApi
 * @extends {BaseAPI}
 */
export class SurveyApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyApi
     */
    public surveyActiveGet(options?: any) {
        return SurveyApiFp(this.configuration).surveyActiveGet(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} submissionLink 
     * @param {Array<AnswerModel>} [answerModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyApi
     */
    public surveyAnswerSubmissionLinkPost(submissionLink: string, answerModel?: Array<AnswerModel>, options?: any) {
        return SurveyApiFp(this.configuration).surveyAnswerSubmissionLinkPost(submissionLink, answerModel, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyApi
     */
    public surveyAnswersIdGet(id: number, options?: any) {
        return SurveyApiFp(this.configuration).surveyAnswersIdGet(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyApi
     */
    public surveyCompanyEmailtemplateGet(options?: any) {
        return SurveyApiFp(this.configuration).surveyCompanyEmailtemplateGet(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {CreateEmailTemplateRequest} [createEmailTemplateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyApi
     */
    public surveyCompanyEmailtemplatePost(createEmailTemplateRequest?: CreateEmailTemplateRequest, options?: any) {
        return SurveyApiFp(this.configuration).surveyCompanyEmailtemplatePost(createEmailTemplateRequest, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} submissionLink 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyApi
     */
    public surveyCompanySubmissionLinkGet(submissionLink: string, options?: any) {
        return SurveyApiFp(this.configuration).surveyCompanySubmissionLinkGet(submissionLink, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyApi
     */
    public surveyCompanySubmissionsCompanyIdGet(companyId: number, options?: any) {
        return SurveyApiFp(this.configuration).surveyCompanySubmissionsCompanyIdGet(companyId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyApi
     */
    public surveyDefaultsGet(options?: any) {
        return SurveyApiFp(this.configuration).surveyDefaultsGet(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {Array<DefaultQuestionModel>} [defaultQuestionModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyApi
     */
    public surveyDefaultsPut(defaultQuestionModel?: Array<DefaultQuestionModel>, options?: any) {
        return SurveyApiFp(this.configuration).surveyDefaultsPut(defaultQuestionModel, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyApi
     */
    public surveyIdGet(id: number, options?: any) {
        return SurveyApiFp(this.configuration).surveyIdGet(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyApi
     */
    public surveyManagerIdGet(id: string, options?: any) {
        return SurveyApiFp(this.configuration).surveyManagerIdGet(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {CreateSurveyRequest} [createSurveyRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyApi
     */
    public surveyPost(createSurveyRequest?: CreateSurveyRequest, options?: any) {
        return SurveyApiFp(this.configuration).surveyPost(createSurveyRequest, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {UpdateSurveyRequest} [updateSurveyRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyApi
     */
    public surveyPut(updateSurveyRequest?: UpdateSurveyRequest, options?: any) {
        return SurveyApiFp(this.configuration).surveyPut(updateSurveyRequest, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyApi
     */
    public surveyQuestionsIdGet(id: number, options?: any) {
        return SurveyApiFp(this.configuration).surveyQuestionsIdGet(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyApi
     */
    public surveyQuestiontypesGet(options?: any) {
        return SurveyApiFp(this.configuration).surveyQuestiontypesGet(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {SendSurveyRequest} [sendSurveyRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyApi
     */
    public surveySendPost(sendSurveyRequest?: SendSurveyRequest, options?: any) {
        return SurveyApiFp(this.configuration).surveySendPost(sendSurveyRequest, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [email] 
     * @param {CreateEmailTemplateRequest} [createEmailTemplateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyApi
     */
    public surveySenddemoemailPost(email?: string, createEmailTemplateRequest?: CreateEmailTemplateRequest, options?: any) {
        return SurveyApiFp(this.configuration).surveySenddemoemailPost(email, createEmailTemplateRequest, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} submissionLink 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyApi
     */
    public surveySubmissionQuestionsSubmissionLinkGet(submissionLink: string, options?: any) {
        return SurveyApiFp(this.configuration).surveySubmissionQuestionsSubmissionLinkGet(submissionLink, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} submissionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyApi
     */
    public surveySubmissionSubmissionIdGet(submissionId: number, options?: any) {
        return SurveyApiFp(this.configuration).surveySubmissionSubmissionIdGet(submissionId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} surveyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyApi
     */
    public surveySubmissionsSurveyIdGet(surveyId: number, options?: any) {
        return SurveyApiFp(this.configuration).surveySubmissionsSurveyIdGet(surveyId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyApi
     */
    public surveyUpdatestatusIdPut(id: number, options?: any) {
        return SurveyApiFp(this.configuration).surveyUpdatestatusIdPut(id, options)(this.axios, this.basePath);
    }

}

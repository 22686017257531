import React, { useState } from 'react';
import { Button, Typography } from 'shared/components';
import { FormProps, ErrorDisplay, useForm, SuccessDisplay } from '../../shared/forms';
import { NotificationSettingsForm as NotificationSettingsFormModel } from '../../state/managers';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';

interface Props extends FormProps<NotificationSettingsFormModel> {
  activeForm: NotificationSettingsFormModel;
}

export const NotificationSettingsForm: React.FC<Props> = (
  {
  activeForm,
  pending,
  error,
  onSubmit,
  onResetFeedback,
  success,
}) => {
  const [emailNotificationsEnabled, setEmailNotificationsEnabled] = useState(
    activeForm.emailNotificationsEnabled
  );
  const [surveyEmailNotificationsEnabled, setSurveyEmailNotificationsEnabled] = useState(
    activeForm.surveyEmailNotificationsEnabled
  );

  const form = useForm(() => {
    if (!form.valid) return;

    onResetFeedback();
    onSubmit({
      emailNotificationsEnabled: emailNotificationsEnabled,
      surveyEmailNotificationsEnabled: surveyEmailNotificationsEnabled
    });
  });

  return (
    <form {...form.bind}>
      <Grid container direction="column" spacing={6}>
        {error ? (
          <Grid item>
            <ErrorDisplay error={error} errorMessage="An error has occurred. Please try again." />
          </Grid>
        ) : null}

        {success ? (
          <Grid item>
            <SuccessDisplay
              display={success}
              successMessage="Your settings have been updated successfully!"
            />
          </Grid>
        ) : null}

        <Grid container spacing={2} item>
          <Grid item xs={12} md={2}>
            <Typography variant="h6">Notification Settings</Typography>
          </Grid>
          <Grid container item xs={12} md={9}>
            <Grid item xs={12} md={12}>
              <FormControlLabel
                label="Receive Notification Summary by Email?"
                control={
                  <Checkbox
                    name="notifications"
                    checked={emailNotificationsEnabled}
                    onChange={event => setEmailNotificationsEnabled(event.target.checked)}
                  />
                }
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <FormControlLabel
                label="Receive Survey Completion Notifications by Email?"
                control={
                  <Checkbox
                    name="surveyNotifications"
                    checked={surveyEmailNotificationsEnabled}
                    onChange={event => setSurveyEmailNotificationsEnabled(event.target.checked)}
                  />
                }
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={2} item>
          <Grid item xs={12} md={2}>
            <Button disabled={!form.valid} pending={pending}>
              Save Settings
            </Button>
          </Grid>
          <Grid item></Grid>
        </Grid>
      </Grid>
    </form>
  );
};

import { useEffect } from 'react';
import {
  answersQuery,
  defaultQuestionsQuery,
  questionTypesQuery,
  questionsQuery,
  submissionsQuery,
  surveysQuery,
  surveysService,
  surveyEmailTemplateQuery,
} from '../state/surveys';
import { useObservable } from '../shared';
import { companyQuery } from 'state';

export function useSurveys(managerId: string) {
  useEffect(() => {
    surveysService.getSurveysByManagerId(managerId);
  }, [managerId]);

  return {
    surveys: useObservable(surveysQuery.surveys),
    loading: useObservable(surveysQuery.isLoading),
  };
}

export function useActiveSurvey() {
  useEffect(() => surveysService.getActiveSurvey(), []);

  return {
    survey: useObservable(surveysQuery.activeSurvey),
    loading: useObservable(surveysQuery.isLoading),
  }
}

export function useSurveyLoading() {
  return useObservable(surveysQuery.isLoading);
}

export function useSurveyError() {
  return useObservable(surveysQuery.error);
}

export function useGetSurvey(surveyId: number) {
  useEffect(() => {
    surveysService.getSurveyById(surveyId);
  }, [surveyId]);

  return {
    survey: useObservable(surveysQuery.activeSurvey),
    loading: useObservable(surveysQuery.isLoading),
    error: useObservable(surveysQuery.error),
  };
}

export function useQuestions(surveyId: number) {
  useEffect(() => {
    surveysService.getQuestionsBySurveyId(surveyId);
  }, [surveyId]);

  return {
    questions: useObservable(questionsQuery.questions),
    loading: useObservable(questionsQuery.isLoading),
    error: useObservable(questionsQuery.error),
  };
}

export function useQuestionsBySubmissionLink(link: string) {
  useEffect(() => {
    surveysService.getQuestionsBySubmissionLink(link);
  }, [link]);

  return {
    questions: useObservable(questionsQuery.questions),
    loading: useObservable(questionsQuery.isLoading),
    error: useObservable(questionsQuery.error),
  };
}

export function useSubmissions(surveyId: number) {
  useEffect(() => {
    surveysService.GetSubmissionsBySurveyId(surveyId);
  }, [surveyId]);

  return {
    submissions: useObservable(submissionsQuery.submissions),
    loading: useObservable(submissionsQuery.isLoading),
  };
}

export function useCompanySubmissions(companyId?: number | undefined) {
  useEffect(() => {
    if (!!companyId) surveysService.GetSubmissionsByCompanyId(companyId);
  }, [companyId]);

  return {
    submissions: useObservable(submissionsQuery.submissions),
    loading: useObservable(submissionsQuery.isLoading),
  };
}

export function useSubmitted(surveyId: number) {
  useEffect(() => {
    surveysService.GetSubmissionsBySurveyId(surveyId);
  }, [surveyId]);

  return {
    submissions: useObservable(submissionsQuery.submitted),
    loading: useObservable(submissionsQuery.isLoading),
  };
}

export function useGetSubmission(submissionId: number) {
  useEffect(() => {
    surveysService.getSubmissionById(submissionId);
  }, [submissionId]);

  return {
    submission: useObservable(submissionsQuery.activeSubmission),
    loading: useObservable(submissionsQuery.isLoading),
  };
}

export function useAnswers(submissionId: number) {
  useEffect(() => {
    surveysService.getAnswersBySubmissionId(submissionId);
  }, [submissionId]);

  return {
    answers: useObservable(answersQuery.answers),
    loading: useObservable(answersQuery.isLoading),
  };
}

export function useQuestionTypes() {
  useEffect(() => {
    surveysService.getQuestionTypes();
  }, []);

  return {
    questionTypes: useObservable(questionTypesQuery.questionTypes),
    loading: useObservable(questionTypesQuery.isLoading),
  };
}

export function useDefaultQuestions() {
  useEffect(() => {
    surveysService.getDefaultQuestions();
  }, []);

  return {
    questions: useObservable(defaultQuestionsQuery.defaultQuestions),
    loading: useObservable(defaultQuestionsQuery.isLoading),
    error: useObservable(defaultQuestionsQuery.error),
  };
}

export function useCompanyBySurvey(link: string) {
  useEffect(() => surveysService.getCompanyBySurvey(link), [link]);

  return {
    company: useObservable(companyQuery.activeCompany),
    loading: useObservable(companyQuery.loading),
  }
}

export function useSurveyEmailTemplate() {
  useEffect(() => {
    surveysService.getCompanySurveyEmailTemplate();
  }, []);

  return {
    template: useObservable(surveyEmailTemplateQuery.template),
  }
}

import { QueryEntity } from '@datorama/akita';

import { ManagerState, managerStore } from './manager.store';

export class ManagerQuery extends QueryEntity<ManagerState> {
  activeManagerId = this.selectActiveId();
  activeManager = this.selectActive();

  managers = this.selectAll();
}

export const managerQuery = new ManagerQuery(managerStore);

import { useEffect } from 'react';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { useObservable } from '../utils/useObservable';
import { mlsService, mlsQuery } from '../../state';

const officeAgentVolumesQuery = combineLatest([
  mlsQuery.activeOfficeAgents,
  mlsQuery.activeOffices,
  mlsQuery.getAgentVolumeQuery({
    memberKeys: mlsQuery.activeOfficeAgents.pipe(map(arr => arr.map(a => a.memberKey))),
    officeKeys: mlsQuery.activeOffices.pipe(map(arr => arr.map(a => a.officeKey))),
  }),
  mlsQuery.marketStats,
  mlsQuery.listings,
]);

export function useOfficeVolume(officeCodes: string[]) {
  useEffect(() => {
    mlsService.getOfficeVolumes(officeCodes);
  }, [officeCodes]);

  const [agents, offices, agentVolumes, marketStats, listings] = useObservable(
    officeAgentVolumesQuery
  );

  return { agents, offices, agentVolumes, marketStats, listings };
}

import { useEffect, useState, useCallback } from 'react';
import { useObservable } from '../utils';
import { FormEventResponse, getFormEvents, resetFormUI } from '../../state/forms';

export const useFormEvents = <T = any>(formId: string): [FormEventResponse<T>, () => void] => {
  // Reset the form UI when the component loads and is destroyed.
  const reset = useCallback(() => resetFormUI(formId), [formId]);

  useEffect(() => {
    reset();
    return reset;
  }, [reset]);

  // Use the same observable unless formId changes.
  const [events, setEvents] = useState(() => ({
    observable: getFormEvents(formId),
    formId,
  }));

  if (events.formId !== formId) {
    setEvents({
      observable: getFormEvents(formId),
      formId,
    });
  }

  // Watch form events
  const formEvent = useObservable(events.observable);

  return [formEvent as FormEventResponse<T>, reset];
};

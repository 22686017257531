import { StoreConfig, EntityStore, EntityState, ActiveState } from '@datorama/akita';

import { User } from '.';

export interface UserState extends EntityState<User, User['id']>, ActiveState<User['id']> {
  ui: { validEmailProvided: boolean | null };
}

const initialState: UserState = { active: null, ui: { validEmailProvided: null } };

@StoreConfig({ name: 'user', resettable: true })
export class UserStore extends EntityStore<UserState> {
  constructor() {
    super(initialState);
  }
}

export const userStore = new UserStore();

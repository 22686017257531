import React from 'react';
import { makeStyles } from '@mui/styles';
import { format } from 'date-fns';
import { ThemeColors } from '../../../shared/theme';
import { Activity } from '../../../state/activities';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from 'shared/components/table';

interface Props {
  data: Array<Activity>;
  handleOpenDialog: (activity: Activity) => void;
}

const useStyles = makeStyles(theme => ({
  header: {
    fontWeight: 'bold',
    fontSize: '80%',
  },
  score: {
    padding: 0,
  },
  activityRow: {
    backgroundColor: theme.palette.common.white,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: ThemeColors.LightGray,
    },
  },
}));

export const ActivityHistoryTable: React.FC<Props> = ({ data, handleOpenDialog }) => {
  const styles = useStyles();
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={styles.header}>Date</TableCell>
            <TableCell className={styles.header}>Activity Type</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(row => (
            <TableRow
              className={styles.activityRow}
              key={row.id}
              onClick={() => handleOpenDialog(row)}
            >
              <TableCell>{format(new Date(row.recordedAt!), 'MMM dd, yyyy')}</TableCell>
              <TableCell>{row.type}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

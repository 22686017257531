import { surveysService } from '../../state/surveys';
import { LoadingIndicator } from 'shared/components';
import { Button } from 'shared/components/button';

interface Props {
  surveyId?: number;
  active?: boolean;
}

export function UpdateSurveyActiveButton({ surveyId, active }: Props) {
  if (!surveyId) return <LoadingIndicator loadingText="Loading..." />;

  const onClick = () => {
    surveysService.updateSurveyActive(surveyId);
  };

  return (
    <Button onClick={onClick} variant={active ? 'secondary' : 'primary'}>
      {active ? 'Deactivate' : 'Activate'}
    </Button>
  );
}

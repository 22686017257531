import {ActiveState, EntityState, EntityStore, StoreConfig} from "@datorama/akita";
import { TermsAcceptanceModel } from "api/app";
import { Interstitial } from "./interstitial.models";

export interface InterstitialState
  extends EntityState<Interstitial, Interstitial['id']>,
    ActiveState<Interstitial['id']> {
  ui: {};
}

@StoreConfig({ name: 'interstitials', resettable: true })
export class InterstitialStore extends EntityStore<InterstitialState> {
  constructor() {
    super({ active: null, ui: {} });
  }
}

export interface TermsAcceptanceState
  extends EntityState<TermsAcceptanceModel, TermsAcceptanceModel['interstitialId']>,
    ActiveState<TermsAcceptanceModel['interstitialId']> {
  ui: {};
}

@StoreConfig({ name: 'TermsAcceptance', resettable: true })
export class TermsAcceptanceStore extends EntityStore<TermsAcceptanceState> {
  constructor() {
    super({ active: null, ui: {} });
  }
}

export const interstitialStore = new InterstitialStore();

export const termsAcceptanceStore = new TermsAcceptanceStore();

import { SimpleUserModel } from 'api/auth/model';
import React from 'react';
import { sessionService } from 'state';
import { Button } from '../../shared';
import {Tooltip} from "@mui/material";

interface Props {
  user: SimpleUserModel;
}

export const ImpersonateButton: React.FC<Props> = ({user}) => {
  if (!user || !user.id || user.id.trim() === '') return (
    <Tooltip title="Unable to impersonate user due to invalid user info.">
      <Button disabled>Disabled</Button>
    </Tooltip>
  )

  return (
    <Button onClick={async () => await sessionService.impersonate(user.id!)}>Impersonate</Button>
  )
}

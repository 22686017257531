import { Order } from '@datorama/akita';
import { of, combineLatest, concat, Observable } from 'rxjs';
import { switchMap, map, shareReplay } from 'rxjs/operators';

import {
  ListingsQuery,
  AgentsQuery,
  AgentVolumeQuery,
  OfficesQuery,
  OfficeVolumeQuery,
} from './stores';
import { KeyFilter, ListingView } from './mls.models';

import { agentListingFilter, keyListingFilter } from './listing-filter';
import { createVolumeSummary, emptyVolumeSummary } from './volume-summary';
import { emptyLookupByAgent, createLookupByAgent } from './agent-volume-summary';
import { dateListingFilter } from './date-filter';
import { distinctUntilSetChanged } from './util';

export class MlsQuery {
  constructor(
    private readonly listingsQuery: ListingsQuery,
    private readonly agentsQuery: AgentsQuery,
    private readonly agentVolumesQuery: AgentVolumeQuery,
    private readonly officesQuery: OfficesQuery,
    private readonly officeVolumesQuery: OfficeVolumeQuery
  ) {}

  /**
   * Observable that returns true while any of the MLS Akita stores
   * are loading data
   */
  loading = combineLatest([
    this.agentsQuery.selectLoading(),
    this.listingsQuery.selectLoading(),
    this.officesQuery.selectLoading(),
    this.officeVolumesQuery.selectLoading(),
    this.agentVolumesQuery.selectLoading(),
  ]).pipe(
    map(loadingStates => {
      return loadingStates.some(item => item);
    })
  );

  activeDateRange = this.listingsQuery.select(({ ui }) => ui.dateRange);

  private activeOfficeKeySet = this.officeVolumesQuery.activeKeys.pipe(
    map(keys => new Set<string>(keys))
  );

  activeOffices = this.officeVolumesQuery.activeKeys.pipe(
    switchMap(officeKeys => this.officesQuery.selectMany(officeKeys))
  );

  activeOfficeAgents = this.activeOfficeKeySet.pipe(
    switchMap(officeKeys =>
      this.agentsQuery.selectAll({
        filterBy: agent => !!agent.officeKey && officeKeys.has(agent.officeKey),
      })
    )
  );

  activeAgent = this.agentsQuery.selectActive();

  // Used for market stats and transaction grid on Agent view
  activeAgentTransactions = this.agentsQuery.selectActiveId().pipe(
    switchMap(mk => {
      if (mk == null) return of([]);
      const agentFilter = agentListingFilter(mk);

      return this.listingsQuery.selectAll({
        filterBy: l => agentFilter.isAnySide(l),
        sortBy: 'listDate',
        sortByOrder: Order.DESC,
      });
    }),
    shareReplay(1)
  );

  agents = this.agentsQuery.lookup;

  offices = this.officesQuery.lookup;

  private officeListingData = this.filterListingData({
    officeKeys: this.officeVolumesQuery.activeKeys,
  });

  /**
   * Current Listings (excludes prior period) for the active office(s).
   * Used for maps in the retention and company views.
   */
  listings = this.officeListingData.pipe(
    map(([{ dateRange }, listings]) => {
      const dateFilter = dateListingFilter(dateRange);
      return listings.filter(l => dateFilter.filterAllCurrent(l));
    }),
    shareReplay(1)
  );

  /**
   * Summary of Volume for Market Stats for the active office(s).
   * Used for stats in the retention and company views.
   * @perf Results are calculated on a worker thread.
   */
  marketStats = concat(
    of(emptyVolumeSummary()),
    this.officeListingData.pipe(
      switchMap(([{ officeKeys, dateRange }, listings]) =>
        createVolumeSummary(listings, { officeKeys }, dateRange)
      ),
      shareReplay(1)
    )
  );

  // used for agent volume charts in the agent grid on Retention view
  agentVolumeSummaries = this.agentVolumesQuery.selectAll();

  // used for company volume charts in the comapny search view
  companyVolumeSummaries = this.officeVolumesQuery.selectAll();

  // used for office volume charts in the office search view
  officeVolumeSummaries = this.officeVolumesQuery.selectAll();

  getAgentVolumeQuery(view: ListingView) {
    const listingData = this.filterListingData(view);

    return concat(
      of(emptyLookupByAgent()),
      combineLatest([
        listingData,
        this.agentsQuery.selectAll(),
        this.agentVolumesQuery.selectAll(),
      ]).pipe(
        switchMap(([[{ memberKeys, dateRange }, listings], agents, summaries]) =>
          createLookupByAgent(listings, { memberKeys }, dateRange, agents, summaries)
        ),
        shareReplay(1)
      )
    );
  }

  private filterListingData({
    memberKeys: memberKeysInput,
    officeKeys: officeKeysInput,
  }: ListingView) {
    return combineLatest([
      getFilterObservable(memberKeysInput),
      getFilterObservable(officeKeysInput),
      this.activeDateRange,
      this.listingsQuery.selectAll(),
    ]).pipe(
      map(([memberKeys, officeKeys, dateRange, listings]) => {
        const start = performance.now();
        const filter = keyListingFilter({ memberKeys, officeKeys });
        const filteredListings = listings.filter(l => filter.isAnySide(l));
        const stop = performance.now();

        console.debug(`Filtered listing in ${Math.round(stop - start)}ms by`, {
          memberKeys,
          officeKeys,
        });
        return [{ memberKeys, officeKeys, dateRange }, filteredListings] as const;
      }),
      shareReplay(1)
    );
  }
}

function getFilterObservable(input: KeyFilter | undefined): Observable<string[] | undefined> {
  if (!input) return of(undefined);
  if (Array.isArray(input)) return of(input);
  return input.pipe(distinctUntilSetChanged());
}

export const mlsQuery = new MlsQuery(
  ListingsQuery.instance,
  AgentsQuery.instance,
  AgentVolumeQuery.instance,
  OfficesQuery.instance,
  OfficeVolumeQuery.instance
);

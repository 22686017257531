import React from 'react';

import { FormStatus, sessionQuery } from '../../state';
import { useObservable } from '../../shared';

import { Redirect } from 'react-router-dom';
import { RecoverPasswordForm } from '../components/recover-password.form';
import { userService } from '../../state/users/user.service';
import { useFormEvents } from '../../shared/forms';
import { ResetPasswordForm, UserForms } from '../../state/users';
import { ResetPasswordRequest } from '../../api/auth/model';

export const RecoverPasswordPage: React.FC<{ email: string; resetToken: string }> = ({
  email,
  resetToken,
}) => {
  const isLoggedIn = useObservable(sessionQuery.isLoggedIn, 'async');

  const [{ status, error }, resetFormUI] = useFormEvents(UserForms.ResetPassword);

  const submit = (form: ResetPasswordForm) => {
    if (form.newPassword !== '')
      userService.resetPassword({
        newPassword: form.newPassword,
        email: email,
        resetToken: resetToken,
      } as ResetPasswordRequest);
  };

  if (isLoggedIn) {
    return <Redirect to="/" />;
  }

  return (
    <RecoverPasswordForm
      onSubmit={submit}
      onResetFeedback={resetFormUI}
      error={error}
      success={status === FormStatus.Success}
      pending={status === FormStatus.Pending}
    />
  );
};

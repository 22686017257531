/* eslint-disable */
// tslint:disable
/**
 * MLS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';

import { GetCompaniesRequest } from '../model';
import { GetCompaniesResponse } from '../model';
import { GetFeedsResponse } from '../model';
import { GetMarketVolumeRequest } from '../model';
import { GetMarketVolumeResponse } from '../model';
import { GetOfficeAgentRequest } from '../model';
import { GetOfficeAgentResponse } from '../model';
import { GetOfficeVolumeRequest } from '../model';
import { GetOfficeVolumeResponse } from '../model';
import { GetOfficeVolumeSummaryRequest } from '../model';
import { GetOfficesRequest } from '../model';
import { GetOfficesResponse } from '../model';
import { GetRegionsRequest } from '../model';
import { GetRegionsResponse } from '../model';
import { OfficeRetentionExportRequest } from '../model';
import { OfficeRetentionModel } from '../model';
import { OfficeVolumeSummaryResponse } from '../model';
/**
 * OfficesApi - axios parameter creator
 * @export
 */
export const OfficesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {GetOfficeAgentRequest} [getOfficeAgentRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsOfficesAgentsPost(
      getOfficeAgentRequest?: GetOfficeAgentRequest,
      options: any = {}
    ): RequestArgs {
      const localVarPath = `/mls/offices/agents`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      //@ts-ignore
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof getOfficeAgentRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(getOfficeAgentRequest !== undefined ? getOfficeAgentRequest : {})
        : getOfficeAgentRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {GetCompaniesRequest} [getCompaniesRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsOfficesCompaniesPost(
      getCompaniesRequest?: GetCompaniesRequest,
      options: any = {}
    ): RequestArgs {
      const localVarPath = `/mls/offices/companies`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      //@ts-ignore
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof getCompaniesRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(getCompaniesRequest !== undefined ? getCompaniesRequest : {})
        : getCompaniesRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsOfficesFeedsGet(options: any = {}): RequestArgs {
      const localVarPath = `/mls/offices/feeds`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      //@ts-ignore
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {GetMarketVolumeRequest} [getMarketVolumeRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsOfficesMarketPost(
      getMarketVolumeRequest?: GetMarketVolumeRequest,
      options: any = {}
    ): RequestArgs {
      const localVarPath = `/mls/offices/market`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      //@ts-ignore
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof getMarketVolumeRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(getMarketVolumeRequest !== undefined ? getMarketVolumeRequest : {})
        : getMarketVolumeRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {GetOfficesRequest} [getOfficesRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsOfficesPost(getOfficesRequest?: GetOfficesRequest, options: any = {}): RequestArgs {
      const localVarPath = `/mls/offices`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      //@ts-ignore
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof getOfficesRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(getOfficesRequest !== undefined ? getOfficesRequest : {})
        : getOfficesRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {GetRegionsRequest} [getRegionsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsOfficesRegionsPost(getRegionsRequest?: GetRegionsRequest, options: any = {}): RequestArgs {
      const localVarPath = `/mls/offices/regions`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      //@ts-ignore
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof getRegionsRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(getRegionsRequest !== undefined ? getRegionsRequest : {})
        : getRegionsRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {OfficeRetentionExportRequest} [officeRetentionExportRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsOfficesRetentionExportPost(
      officeRetentionExportRequest?: OfficeRetentionExportRequest,
      options: any = {}
    ): RequestArgs {
      const localVarPath = `/mls/offices/retention/export`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      //@ts-ignore
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof officeRetentionExportRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(
            officeRetentionExportRequest !== undefined ? officeRetentionExportRequest : {}
          )
        : officeRetentionExportRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {OfficeRetentionExportRequest} [officeRetentionExportRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsOfficesRetentionPost(
      officeRetentionExportRequest?: OfficeRetentionExportRequest,
      options: any = {}
    ): RequestArgs {
      const localVarPath = `/mls/offices/retention`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      //@ts-ignore
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof officeRetentionExportRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(
            officeRetentionExportRequest !== undefined ? officeRetentionExportRequest : {}
          )
        : officeRetentionExportRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {GetOfficeVolumeSummaryRequest} [getOfficeVolumeSummaryRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsOfficesSummaryPost(
      getOfficeVolumeSummaryRequest?: GetOfficeVolumeSummaryRequest,
      options: any = {}
    ): RequestArgs {
      const localVarPath = `/mls/offices/summary`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      //@ts-ignore
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof getOfficeVolumeSummaryRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(
            getOfficeVolumeSummaryRequest !== undefined ? getOfficeVolumeSummaryRequest : {}
          )
        : getOfficeVolumeSummaryRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {GetOfficeVolumeRequest} [getOfficeVolumeRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsOfficesVolumePost(
      getOfficeVolumeRequest?: GetOfficeVolumeRequest,
      options: any = {}
    ): RequestArgs {
      const localVarPath = `/mls/offices/volume`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      //@ts-ignore
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof getOfficeVolumeRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(getOfficeVolumeRequest !== undefined ? getOfficeVolumeRequest : {})
        : getOfficeVolumeRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * OfficesApi - functional programming interface
 * @export
 */
export const OfficesApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {GetOfficeAgentRequest} [getOfficeAgentRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsOfficesAgentsPost(
      getOfficeAgentRequest?: GetOfficeAgentRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOfficeAgentResponse> {
      const localVarAxiosArgs = OfficesApiAxiosParamCreator(configuration).mlsOfficesAgentsPost(
        getOfficeAgentRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {GetCompaniesRequest} [getCompaniesRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsOfficesCompaniesPost(
      getCompaniesRequest?: GetCompaniesRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCompaniesResponse> {
      const localVarAxiosArgs = OfficesApiAxiosParamCreator(configuration).mlsOfficesCompaniesPost(
        getCompaniesRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsOfficesFeedsGet(
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetFeedsResponse> {
      const localVarAxiosArgs = OfficesApiAxiosParamCreator(configuration).mlsOfficesFeedsGet(
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {GetMarketVolumeRequest} [getMarketVolumeRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsOfficesMarketPost(
      getMarketVolumeRequest?: GetMarketVolumeRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMarketVolumeResponse> {
      const localVarAxiosArgs = OfficesApiAxiosParamCreator(configuration).mlsOfficesMarketPost(
        getMarketVolumeRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {GetOfficesRequest} [getOfficesRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsOfficesPost(
      getOfficesRequest?: GetOfficesRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOfficesResponse> {
      const localVarAxiosArgs = OfficesApiAxiosParamCreator(configuration).mlsOfficesPost(
        getOfficesRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {GetRegionsRequest} [getRegionsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsOfficesRegionsPost(
      getRegionsRequest?: GetRegionsRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRegionsResponse> {
      const localVarAxiosArgs = OfficesApiAxiosParamCreator(configuration).mlsOfficesRegionsPost(
        getRegionsRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {OfficeRetentionExportRequest} [officeRetentionExportRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsOfficesRetentionExportPost(
      officeRetentionExportRequest?: OfficeRetentionExportRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = OfficesApiAxiosParamCreator(
        configuration
      ).mlsOfficesRetentionExportPost(officeRetentionExportRequest, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {OfficeRetentionExportRequest} [officeRetentionExportRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsOfficesRetentionPost(
      officeRetentionExportRequest?: OfficeRetentionExportRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OfficeRetentionModel>> {
      const localVarAxiosArgs = OfficesApiAxiosParamCreator(configuration).mlsOfficesRetentionPost(
        officeRetentionExportRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {GetOfficeVolumeSummaryRequest} [getOfficeVolumeSummaryRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsOfficesSummaryPost(
      getOfficeVolumeSummaryRequest?: GetOfficeVolumeSummaryRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfficeVolumeSummaryResponse> {
      const localVarAxiosArgs = OfficesApiAxiosParamCreator(configuration).mlsOfficesSummaryPost(
        getOfficeVolumeSummaryRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {GetOfficeVolumeRequest} [getOfficeVolumeRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsOfficesVolumePost(
      getOfficeVolumeRequest?: GetOfficeVolumeRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOfficeVolumeResponse> {
      const localVarAxiosArgs = OfficesApiAxiosParamCreator(configuration).mlsOfficesVolumePost(
        getOfficeVolumeRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * OfficesApi - factory interface
 * @export
 */
export const OfficesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {GetOfficeAgentRequest} [getOfficeAgentRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsOfficesAgentsPost(
      getOfficeAgentRequest?: GetOfficeAgentRequest,
      options?: any
    ): AxiosPromise<GetOfficeAgentResponse> {
      return OfficesApiFp(configuration).mlsOfficesAgentsPost(getOfficeAgentRequest, options)(
        axios,
        basePath
      );
    },
    /**
     *
     * @param {GetCompaniesRequest} [getCompaniesRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsOfficesCompaniesPost(
      getCompaniesRequest?: GetCompaniesRequest,
      options?: any
    ): AxiosPromise<GetCompaniesResponse> {
      return OfficesApiFp(configuration).mlsOfficesCompaniesPost(getCompaniesRequest, options)(
        axios,
        basePath
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsOfficesFeedsGet(options?: any): AxiosPromise<GetFeedsResponse> {
      return OfficesApiFp(configuration).mlsOfficesFeedsGet(options)(axios, basePath);
    },
    /**
     *
     * @param {GetMarketVolumeRequest} [getMarketVolumeRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsOfficesMarketPost(
      getMarketVolumeRequest?: GetMarketVolumeRequest,
      options?: any
    ): AxiosPromise<GetMarketVolumeResponse> {
      return OfficesApiFp(configuration).mlsOfficesMarketPost(getMarketVolumeRequest, options)(
        axios,
        basePath
      );
    },
    /**
     *
     * @param {GetOfficesRequest} [getOfficesRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsOfficesPost(
      getOfficesRequest?: GetOfficesRequest,
      options?: any
    ): AxiosPromise<GetOfficesResponse> {
      return OfficesApiFp(configuration).mlsOfficesPost(getOfficesRequest, options)(
        axios,
        basePath
      );
    },
    /**
     *
     * @param {GetRegionsRequest} [getRegionsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsOfficesRegionsPost(
      getRegionsRequest?: GetRegionsRequest,
      options?: any
    ): AxiosPromise<GetRegionsResponse> {
      return OfficesApiFp(configuration).mlsOfficesRegionsPost(getRegionsRequest, options)(
        axios,
        basePath
      );
    },
    /**
     *
     * @param {OfficeRetentionExportRequest} [officeRetentionExportRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsOfficesRetentionExportPost(
      officeRetentionExportRequest?: OfficeRetentionExportRequest,
      options?: any
    ): AxiosPromise<void> {
      return OfficesApiFp(configuration).mlsOfficesRetentionExportPost(
        officeRetentionExportRequest,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {OfficeRetentionExportRequest} [officeRetentionExportRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsOfficesRetentionPost(
      officeRetentionExportRequest?: OfficeRetentionExportRequest,
      options?: any
    ): AxiosPromise<Array<OfficeRetentionModel>> {
      return OfficesApiFp(configuration).mlsOfficesRetentionPost(
        officeRetentionExportRequest,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {GetOfficeVolumeSummaryRequest} [getOfficeVolumeSummaryRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsOfficesSummaryPost(
      getOfficeVolumeSummaryRequest?: GetOfficeVolumeSummaryRequest,
      options?: any
    ): AxiosPromise<OfficeVolumeSummaryResponse> {
      return OfficesApiFp(configuration).mlsOfficesSummaryPost(
        getOfficeVolumeSummaryRequest,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {GetOfficeVolumeRequest} [getOfficeVolumeRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsOfficesVolumePost(
      getOfficeVolumeRequest?: GetOfficeVolumeRequest,
      options?: any
    ): AxiosPromise<GetOfficeVolumeResponse> {
      return OfficesApiFp(configuration).mlsOfficesVolumePost(getOfficeVolumeRequest, options)(
        axios,
        basePath
      );
    },
  };
};

/**
 * OfficesApi - object-oriented interface
 * @export
 * @class OfficesApi
 * @extends {BaseAPI}
 */
export class OfficesApi extends BaseAPI {
  /**
   *
   * @param {GetOfficeAgentRequest} [getOfficeAgentRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OfficesApi
   */
  public mlsOfficesAgentsPost(getOfficeAgentRequest?: GetOfficeAgentRequest, options?: any) {
    return OfficesApiFp(this.configuration).mlsOfficesAgentsPost(getOfficeAgentRequest, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {GetCompaniesRequest} [getCompaniesRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OfficesApi
   */
  public mlsOfficesCompaniesPost(getCompaniesRequest?: GetCompaniesRequest, options?: any) {
    return OfficesApiFp(this.configuration).mlsOfficesCompaniesPost(getCompaniesRequest, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OfficesApi
   */
  public mlsOfficesFeedsGet(options?: any) {
    return OfficesApiFp(this.configuration).mlsOfficesFeedsGet(options)(this.axios, this.basePath);
  }

  /**
   *
   * @param {GetMarketVolumeRequest} [getMarketVolumeRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OfficesApi
   */
  public mlsOfficesMarketPost(getMarketVolumeRequest?: GetMarketVolumeRequest, options?: any) {
    return OfficesApiFp(this.configuration).mlsOfficesMarketPost(getMarketVolumeRequest, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {GetOfficesRequest} [getOfficesRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OfficesApi
   */
  public mlsOfficesPost(getOfficesRequest?: GetOfficesRequest, options?: any) {
    return OfficesApiFp(this.configuration).mlsOfficesPost(getOfficesRequest, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {GetRegionsRequest} [getRegionsRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OfficesApi
   */
  public mlsOfficesRegionsPost(getRegionsRequest?: GetRegionsRequest, options?: any) {
    return OfficesApiFp(this.configuration).mlsOfficesRegionsPost(getRegionsRequest, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {OfficeRetentionExportRequest} [officeRetentionExportRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OfficesApi
   */
  public mlsOfficesRetentionExportPost(
    officeRetentionExportRequest?: OfficeRetentionExportRequest,
    options?: any
  ) {
    return OfficesApiFp(this.configuration).mlsOfficesRetentionExportPost(
      officeRetentionExportRequest,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {OfficeRetentionExportRequest} [officeRetentionExportRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OfficesApi
   */
  public mlsOfficesRetentionPost(
    officeRetentionExportRequest?: OfficeRetentionExportRequest,
    options?: any
  ) {
    return OfficesApiFp(this.configuration).mlsOfficesRetentionPost(
      officeRetentionExportRequest,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {GetOfficeVolumeSummaryRequest} [getOfficeVolumeSummaryRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OfficesApi
   */
  public mlsOfficesSummaryPost(
    getOfficeVolumeSummaryRequest?: GetOfficeVolumeSummaryRequest,
    options?: any
  ) {
    return OfficesApiFp(this.configuration).mlsOfficesSummaryPost(
      getOfficeVolumeSummaryRequest,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {GetOfficeVolumeRequest} [getOfficeVolumeRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OfficesApi
   */
  public mlsOfficesVolumePost(getOfficeVolumeRequest?: GetOfficeVolumeRequest, options?: any) {
    return OfficesApiFp(this.configuration).mlsOfficesVolumePost(getOfficeVolumeRequest, options)(
      this.axios,
      this.basePath
    );
  }
}

import { from } from 'rxjs';
import { map } from 'rxjs/operators';

import * as Zsg from 'api/app';
import { ContactToCreate } from '../../api/app';

export class KvCoreService {
  constructor(private readonly contactsApi: Zsg.ContactsApi) {}

  createContact(contact: ContactToCreate) {
    return from(this.contactsApi.contactsCreatecontactPost(contact))
    .pipe(
      map(x => x.data)
    );
  }

  checkToken() {
    return from(this.contactsApi.contactsHastokenGet()).pipe(map(({ data }) => data));
  }

  addContactAllowed() {
    return from(this.contactsApi.contactsAddcontactallowedGet()).pipe(map(({ data }) => data));
  }
}

export const kvCoreService = new KvCoreService(new Zsg.ContactsApi());

import React, { memo } from 'react';
import { makeStyles, WithStyles, withStyles, createStyles } from '@mui/styles';
import { ArrowUpward, ArrowDownward } from '@mui/icons-material';

import { valueDeltaColor } from '../../theme';
import { NumberFormatters, ValueFormatter } from '../../utils/formatters';
import { CaptionedValue, CaptionedValueProps } from '../captioned-value';
import themeService from 'theme/ThemeService';

interface Props {
  current: number;
  previous?: number;
  formatter?: ValueFormatter<number>;
  size?: string | number;
  align?: 'left' | 'center' | 'right';
  colorCurrent?: boolean;
  invertColor?: boolean;
  priceSum?: number;
}

interface StyleProps {
  colorCurrent?: boolean;
  invertColor?: boolean;
  previous?: number;
  delta?: number;
}

const styles = createStyles({
  value: {
    color: ({ colorCurrent, invertColor, previous, delta }: StyleProps) =>
      colorCurrent ? valueDeltaColor(previous ?? 0, delta ?? 0, invertColor ?? false) : undefined,
  },
  delta: {
    color: ({ previous, delta, invertColor }: StyleProps) =>
      valueDeltaColor(previous ?? 0, delta ?? 0, invertColor ?? false),
    display: 'inline-flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    fontWeight: 700,
  },
});

const palette = themeService.getPalette();

const useStyles = makeStyles(() => styles);

export type ValueComparisonProps = Props & WithStyles<typeof styles>;

const ValueComparisonComponent: React.FC<ValueComparisonProps> = ({
  current,
  previous,
  formatter = NumberFormatters.decimal,
  size,
  align = 'right' as CaptionedValueProps['align'],
  colorCurrent = false,
  invertColor = false,
  classes,
  priceSum,
}) => {
  const delta = !previous ? 0 : (current - previous) / previous;
  const hasChange = previous !== 0 && delta !== 0;
  const stylesHook = useStyles({
    colorCurrent,
    invertColor,
    previous,
    delta,
  });

  if (!previous && !priceSum) {
    return (
      <CaptionedValue
        value={formatter`${current}`}
        size={size}
        align={align}
        color={palette.neutralDark}
      >
        &nbsp; {/* required for equal height without delta */}
      </CaptionedValue>
    );
  }

  return (
    <CaptionedValue
      classes={{ value: classes.value } || { value: styles.value }}
      value={formatter`${current}`}
      size={size}
      align={align}
      color={palette.neutralDark}
    >
      {hasChange && (
        <span className={stylesHook.delta}>
          {delta > 0 ? <ArrowUpward fontSize="inherit" /> : <ArrowDownward fontSize="inherit" />}
          {NumberFormatters.percent`${delta}`}
        </span>
      )}

      {previous && <>&nbsp;{formatter`(${previous})`}</>}
      {priceSum && <>&nbsp;{formatter`\$${priceSum}`}</>}
    </CaptionedValue>
  );
};

export const ValueComparison = memo(withStyles(styles)(ValueComparisonComponent));

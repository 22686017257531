import { ReactNode, createContext, useContext } from 'react';
import { Theme } from 'theme/theme';

const ThemeContext = createContext<Theme>({} as Theme);

export function ThemeProvider({ children, theme }: { children: ReactNode; theme: Theme }) {
  return <ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>;
}

export function useTheme() {
  return useContext(ThemeContext);
}

import React from 'react';
import { Chip } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { Typography } from 'shared/components';

import { SearchForm, getOfficeDisplayName } from '../../state/search';
import { ResultContainerProps } from './results-list';
import { SalesChange } from '../../api/mls';

interface Props {
  form: SearchForm;
  offices: Array<{
    officeKey: string;
    officeNumber: string;
    officeName: string;
  }>;
  onSearch: ResultContainerProps['onSearch'];
}

interface ChipOption {
  key: keyof SearchForm;
  value: any;
  label: any;
}

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      listStyle: 'none',
      padding: theme.spacing(0.5),
      margin: 0,
    },
    chip: {
      margin: theme.spacing(0.5),
    },
  })
);

export const FilterDisplay: React.FC<Props> = ({ form, offices, onSearch }) => {
  const classes = useStyles();

  const chips: Array<ChipOption> = [];

  const officeMap = new Map(
    offices.map(({ officeKey, ...office }) => [officeKey, getOfficeDisplayName(office)])
  );

  const setActiveForm = (clearSearchField: keyof SearchForm) => {
    onSearch({ ...form, [clearSearchField]: undefined });
  };

  function addChip(key: keyof SearchForm, label?: (value: any) => any | undefined) {
    const value = form[key];

    if (!value) return;
    if (!label) label = v => v;

    if (Array.isArray(value)) {
      chips.push(...value.map(v => ({ key, value: v, label: label!(v) ?? v })));
    } else {
      chips.push({ key, value: value, label: label(value) ?? value });
    }
  }

  addChip('board');
  addChip('broker', o => officeMap.get(o));
  addChip('branch', o => officeMap.get(o));
  addChip('propertyType');
  addChip('county');
  addChip('area');
  addChip('city');
  addChip('postalCode');
  addChip('schoolDistrict');
  addChip('agentSearchTerm');
  addChip('newlyLicensedStartDate');
  addChip('newlyLicensedEndDate');
  addChip('newlyMovedStartDate');
  addChip('newlyMovedEndDate');
  addChip('productionStartValue');
  addChip('productionEndValue');
  addChip('daysSinceLastClose', d => `${d} days`);
  addChip('salesChange', s => (s !== SalesChange.None ? `${s} in sales` : undefined));
  addChip('percentageChange', c => `${c}%`);
  addChip('hotListType');

  if (chips.length === 0) {
    return <Typography>No Filters Applied</Typography>;
  }

  return (
    <ul className={classes.root}>
      {chips.map(({ label, value, key }, i) => (
        <li key={i}>
          <Chip
            label={label}
            className={classes.chip}
            title={value}
            onDelete={() => setActiveForm(key)}
          />
        </li>
      ))}
    </ul>
  );
};

import { ReactNode, ElementType } from 'react';
import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
  CircularProgress,
} from '@mui/material';
import { useTheme } from 'theme/ThemeContext';

type MuiVariant = MuiButtonProps['variant'];
type MuiColor = MuiButtonProps['color'];
type ButtonVariant = 'primary' | 'secondary' | 'subtle';
type ButtonSize = 'small' | 'medium' | 'large';

export type ButtonProps = {
  variant?: ButtonVariant;
  type?: 'button' | 'submit' | 'reset';
  size?: ButtonSize;
  disabled?: boolean;
  pending?: boolean;
  href?: string;
  children?: ReactNode;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  fullWidth?: boolean;
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  hideShadow?: boolean;
  /** Does not use that prop directly:
   * This prop should be used only
   * when creating a new component
   * that uses button as a
   * core component
   */
  _component?: ElementType<any>;
};

export function Button({
  children,
  variant = 'primary',
  startIcon,
  pending,
  disabled,
  fullWidth,
  size = 'medium',
  type = 'submit',
  _component = 'button',
  hideShadow = false,
  ...rest
}: ButtonProps) {
  const theme = useTheme();

  return (
    <MuiButton
      {...getVariantAndColor(variant)}
      {...rest}
      startIcon={pending ? <CircularProgress color="inherit" size={16} /> : startIcon}
      disabled={pending || disabled}
      fullWidth={fullWidth}
      type={type}
      component={type === 'button' ? _component : 'button'}
      sx={{
        minHeight: getHeight(size),
        fontSize: getFontSizeByButtonSize(size),
        fontFamily: 'Albert Sans',
        padding: '0 12px',
        minWidth: 'auto',
        lineHeight: '13px',
        boxShadow: hideShadow ? 'none' : 'inherit',
        '&.MuiButton-containedPrimary': {
          background: theme.button.primary.backgroundColor,
          color: theme.button.primary.textColor,
        },
        '&.MuiButton-containedPrimary:disabled': {
          background: theme.button.primary.disabled.backgroundColor,
          color: theme.button.primary.disabled.textColor,
        },
        '&.MuiButton-containedSecondary': {
          background: theme.button.secondary.backgroundColor,
          color: theme.button.secondary.textColor,
          border: '1px solid',
          borderColor: theme.button.secondary.borderColor,
        },
        '&.MuiButton-containedSecondary:disabled': {
          background: theme.button.secondary.disabled.backgroundColor,
          color: theme.button.secondary.disabled.textColor,
        },
        '&.MuiButton-textSecondary': {
          background: theme.button.subtle.backgroundColor,
          color: theme.button.subtle.textColor,
        },
        '&.MuiButton-textSecondary:disabled': {
          background: theme.button.subtle.disabled.backgroundColor,
          color: theme.button.subtle.disabled.textColor,
        },
        '&.MuiButton-textSecondary:hover': {
          background: theme.button.subtle.hover.backgroundColor,
          color: theme.button.subtle.hover.textColor,
        },
      }}
    >
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
        {children}
      </div>
    </MuiButton>
  );
}

function getFontSizeByButtonSize(size: ButtonSize) {
  if (size === 'small') return '12px';
  return '13px';
}

function getHeight(size: ButtonSize) {
  if (size === 'small') return '26px';
  if (size === 'large') return '52px';
  return '34px';
}

function getVariantAndColor(variant: ButtonVariant): {
  variant: MuiVariant;
  color: MuiColor;
} {
  if (variant === 'secondary') return { variant: 'contained', color: 'secondary' };
  if (variant === 'subtle') return { variant: 'text', color: 'secondary' };
  return { variant: 'contained', color: 'primary' };
}

import React from 'react';
import { Button, Typography, TextField } from 'shared/components';
import {
  FormProps,
  ErrorDisplay,
  useForm,
  useInput,
  required,
  useNumberInput,
} from '../../shared/forms';
import { RecruitingGoalForm as GoalForm } from '../../state/managers';
import { Grid, Box, InputAdornment } from '@mui/material';
import { SuccessDisplay } from '../../shared/forms/SuccessDisplay';
import { Alert } from 'shared/components/alert';

interface Props extends FormProps<GoalForm> {
  activeForm: GoalForm;
}

export const RecruitingGoalForm: React.FC<Props> = ({
  activeForm,
  pending,
  error,
  onSubmit,
  onResetFeedback,
  success,
}) => {
  const managerId = useInput(activeForm.managerId);
  const year = useInput(activeForm.year);
  const agents = useNumberInput(activeForm.agents, { validators: [required()] });
  const volume = useNumberInput(activeForm.volume, { validators: [required()] });
  const transactions = useNumberInput(activeForm.transactions, { validators: [required()] });
  const companyDollar = useNumberInput(activeForm.companyDollar * 100);

  const form = useForm(
    () => {
      if (!form.valid) return;

      onResetFeedback();
      onSubmit({
        managerId: managerId.value,
        year: year.value,
        agents: agents.value,
        volume: volume.value,
        transactions: transactions.value,
        companyDollar: companyDollar.value / 100,
      });
    },
    managerId,
    year,
    agents,
    volume,
    transactions,
    companyDollar
  );

  return (
    <form {...form.bind}>
      {!!error ? (
        <Box marginBottom={4}>
          <ErrorDisplay error={error} errorMessage="An error has occurred. Please try again." />
        </Box>
      ) : null}

      {success ? (
        <Box marginBottom={4}>
          <SuccessDisplay
            display={!!success}
            successMessage="Your account has been updated successfully!"
          />
        </Box>
      ) : null}

      <Grid container item xs={12} spacing={6}>
        <Grid container item xs={12} md={6} spacing={2}>
          <Grid item xs={12} md={3}>
            <Typography variant="h6">Recruiting Goal: {year.value}</Typography>
          </Grid>
          <Grid item container xs={12} md={9} spacing={2}>
            <Grid item xs={7}>
              <TextField
                type="number"
                name="agents"
                {...agents.bind}
                label="Agents"
                fullWidth
              />
            </Grid>
            <Grid item xs={7}>
              <TextField
                type="number"
                name="volume"
                {...volume.bind}
                label="Volume"
                fullWidth
              />
            </Grid>
            <Grid item xs={7}>
              <TextField
                type="number"
                name="transactions"
                {...transactions.bind}
                label="Transactions"
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={12} md={6} spacing={2}>
          <Grid item xs={6}>
            <TextField
              type="number"
              name="companyDollar"
              endAdornment={<InputAdornment position="end">%</InputAdornment>}
              {...companyDollar.bind}
              label="Company Dollar"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Alert title="Company Dollar">
              You may optionally enter a percentage as the "company dollar."
              <br />
              <br />
              This will measure progress towards the recruiting goal in terms of the volume retained
              by the company instead of the total close price of each listing.
            </Alert>
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Button
            disabled={!form.valid}
            pending={pending}
          >
            Save Recruiting Goal
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

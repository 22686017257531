import React from 'react';
import { useSurveyEmailTemplate, useSurveys } from '../hooks';
import { useManager } from '../../admin/hooks';
import { withManagerId } from '../../shared';
import { SurveysList } from 'surveys/components/surveys-list';
import { Box, Paper, Stack, Typography } from '@mui/material';
import { EmailTemplateEditor, Tag } from 'surveys/components';
import { CreateEmailTemplateRequest, SurveyApi } from 'api/app';

interface Props {
  managerId: string;
}

const availableTags: Tag[] = [
  {title: 'Transaction Address', value: 'TransactionAddress'},
  {title: 'Close Date', value: 'CloseDate'},
  {title: 'Agent First Name', value: 'AgentFirstName'},
  {title: 'Agent Last Name', value: 'AgentLastName'},
  {title: 'Manager First Name', value: 'ManagerFirstName'},
  {title: 'Manager Last Name', value: 'ManagerLastName'},
]

const Surveys: React.FC<Props> = ({ managerId }) => {
  const { surveys, loading } = useSurveys(managerId);
  const { template} = useSurveyEmailTemplate();
  const manager = useManager(managerId);
  const surveyApi =  new SurveyApi();

  const saveTemplate = (email: CreateEmailTemplateRequest) => {
    return surveyApi.surveyCompanyEmailtemplatePost(email);
  };

  const sendSampleEmail = (emailAddress: string, emailTemplate: CreateEmailTemplateRequest) => {
     return surveyApi.surveySenddemoemailPost(emailAddress, emailTemplate);
  }

  return (
    <>
      <SurveysList surveys={surveys} loading={loading} />
      {manager?.owner &&
        <EmailTemplateEditor
          template={template}
          saveTemplate={saveTemplate}
          sendSampleEmailPromise={sendSampleEmail}
          availableTags={availableTags}
        />
      }
    </>
  );
};

export const SurveysPage = withManagerId(Surveys);

import React from 'react';
import { InputAdornment, Input, FormControl, InputProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Search } from '@mui/icons-material';
import themeService from 'theme/ThemeService';

const useStyles = makeStyles(theme => ({
  searchInput: {
    padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
    backgroundColor: palette.neutralLightest,
    borderRadius: '8px',
    border: 'solid thin',
    borderColor: palette.neutral,
    borderBottomColor: palette.neutral,
    '&:before': {
      borderBottom: '0px',
    },
    '&:hover:not(.Mui-disabled):before': {
      borderBottom: '0px',
    },
  },
}));

const palette = themeService.getPalette();

export const SearchInput: React.FC<InputProps> = props => {
  const styles = useStyles();

  return (
    <FormControl variant="standard" size="small" fullWidth>
      <Input
        className={styles.searchInput}
        type="text"
        placeholder={props.placeholder || "Search..."}
        startAdornment={
          <InputAdornment position="start">
            <Search fontSize="small" style={{color: palette.magenta}}/>
          </InputAdornment>
        }
        {...props}
      />
    </FormControl>
  );
};

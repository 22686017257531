import { useEffect, useMemo } from 'react';
import { useActiveUserProfile } from './account/hooks';
import { useObservable } from './shared';
import { managerQuery, managerService } from './state';

declare global {
  interface Window {
    APP_ID: string;
    Intercom(command: string, settings?: any): void;
  }
}

export default function IntercomMessenger() {
  const settings = useIntercomSettings();

  useEffect(() => {
    if (!settings) return;

    // boot intercom with user profile information
    window.Intercom('boot', settings);

    // shutdown intercom when component unmounts (or profile is changed)
    return () => window.Intercom('shutdown');
  }, [settings]);

  return null;
}

function useIntercomSettings() {
  const profile = useActiveUserProfile();
  const manager = useObservable(managerQuery.activeManager, 'async');

  useEffect(() => {
    if (profile) {
      managerService.getManager(profile.id);
    } else {
      managerService.setActiveManagerId();
    }
  }, [profile]);

  return useMemo(() => {
    // profile and manager must be for the same user before booting intercom.
    if (!profile || !manager || profile.id !== manager.id) return;

    return {
      app_id: window.APP_ID,
      name: manager.name,
      email: manager.email,
      user_id: profile.id,
      created_at: profile.lastLogin,
      Product: 'Ampstats',
    };
  }, [profile, manager]);
}

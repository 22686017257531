import React, {useEffect, useRef, useState} from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Paper,
  Stack, Tooltip,
} from "@mui/material";
import DOMPurify from 'dompurify';
import { Button, Dialog, Typography, DialogActions, DialogContent, DialogTitle, TextField } from '../../shared';
import { CreateEmailTemplateRequest, EmailTemplateModel } from 'api/app';
import { SuccessDisplay, ErrorDisplay, useInput, email, required } from 'shared/forms';
import { AxiosPromise } from "axios";
import {InfoOutlined} from "@mui/icons-material";

interface Props {
  template: EmailTemplateModel,
  saveTemplate: ConfirmSave;
  sendSampleEmailPromise: SendSampleEmailPromise;
  availableTags: Tag[];
}

export type Tag = {
  title: string;
  value: string;
}

type ConfirmSave = (request: CreateEmailTemplateRequest) => AxiosPromise<void>;
type SendSampleEmailPromise = (emailAddress: string, request: CreateEmailTemplateRequest) => AxiosPromise<void>;

export const EmailTemplateEditor: React.FC<Props> = ({
  template,
  saveTemplate,
  sendSampleEmailPromise,
  availableTags,
}) => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [sampleEmailAddress, setSampleEmailAddress] = useState<string>("");
  const sampleEmailAddressInput = useInput<string>(sampleEmailAddress, {
    onChange: value => setSampleEmailAddress(value),
    validators: [email(), required()]
  });
  if (!sampleEmailAddressInput.touched) {
    sampleEmailAddressInput.setTouched(true);
  }

  const emailSubjectRef = useRef<HTMLDivElement>(null);
  const emailBodyRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (template) {
      if (emailSubjectRef.current) {
        emailSubjectRef.current.innerHTML = formatInitialInput(template.subject);
      }
      if (emailBodyRef.current) {
        emailBodyRef.current.innerHTML = formatInitialInput(template.body);
      }
    }
  }, [template]);

  const [error, setError] = useState<Error | undefined>(undefined);
  const [success, setSuccess] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [sampleEmailSuccess, setSampleEmailSuccess] = useState<boolean>(false);
  const [sampleEmailError, setSampleEmailError] = useState<Error | undefined>(undefined);
  const [sendSampleEmailLoading, setSendSampleEmailLoading] = useState<boolean>(false);

  const insertTag = (tag: Tag) => {
    const customTag = `<span contenteditable="false">{{${tag.value}}}</span>`;
    const selection = window.getSelection();
    const range = selection?.getRangeAt(0);

    if (selection && range) {
      const emailSubjectElement = emailSubjectRef.current;
      const emailBodyElement = emailBodyRef.current;

      const isCursorInEmailTemplate =
        (emailSubjectElement && emailSubjectElement.contains(range.startContainer)) ||
        (emailBodyElement && emailBodyElement.contains(range.startContainer));

      if (isCursorInEmailTemplate) {
        const newNode = range.createContextualFragment(customTag);
        range.insertNode(newNode);

        // Fix for non-chromium browsers to allow placing cursor at the end of input
        const textNode = document.createTextNode('\u00A0');
        range.collapse(false);

        range.insertNode(textNode);
        range.setStartAfter(textNode);

        selection.removeAllRanges();
        selection.addRange(range);
      }
    }
  };

  const parseContent = (content: string, allowedTags: string[]): string => {
    const div = document.createElement('div');
    const sanitizedResult = DOMPurify.sanitize(
      content.replaceAll("&lt;", "<").replaceAll("&gt;", ">"),
      { ALLOWED_TAGS: [...allowedTags, 'div', 'span'] }
    );
    div.innerHTML = sanitizedResult;

    // Remove spans from added tags
    const spans = div.getElementsByTagName('span');
    while (spans.length > 0) {
      const spanElement = spans[0];
      const parent = spanElement.parentNode;
      spanElement.childNodes.forEach(node => {
        parent?.insertBefore(node.cloneNode(true), spanElement);
      });
      parent?.removeChild(spanElement);
    }

    // fix line spacing
    const divs = div.getElementsByTagName('div');
    while (divs.length > 0) {
      const divElement = divs[0];
      const parent = divElement.parentNode;
      if (divElement.innerHTML.trim() === "<br>") {
        parent?.replaceChild(document.createElement('br'), divElement);
      } else if (divElement.innerHTML.trim() !== "") {
        parent?.insertBefore(document.createElement('br'), divElement);
        divElement.childNodes.forEach(node => {
          parent?.insertBefore(node.cloneNode(true), divElement);
        });
        parent?.removeChild(divElement);
      } else {
        parent?.removeChild(divElement);
      }
    }

    return div.innerHTML.replaceAll("&nbsp;", " ").trimEnd();
  };

  const submit = async () => {
    setError(undefined);
    setSuccess(false);
    setLoading(true);
    const parsedSubject = parseContent(emailSubjectRef.current?.innerHTML || '', []);
    const parsedBody = parseContent(emailBodyRef.current?.innerHTML || '', ['br']);
    const emailTemplate: CreateEmailTemplateRequest = {
      subject: parsedSubject,
      body: parsedBody,
    };
    await saveTemplate(emailTemplate).then(r => {
      setLoading(false);
      setSuccess(true);
      if (emailSubjectRef.current) {
        emailSubjectRef.current.innerHTML = formatInitialInput(parsedSubject);
      }
      if (emailBodyRef.current) {
        emailBodyRef.current.innerHTML = formatInitialInput(parsedBody);
      }
    }).catch(err => {
      setLoading(false);
      setError(new Error(err.response.data.Message || "Something went wrong. Try again later."));
    });
  };

  const handleSampleEmailCancel = () => {
    setOpenDialog(false);
    setSampleEmailSuccess(false);
    setSampleEmailError(undefined);
    setSampleEmailAddress("");
    sampleEmailAddressInput.setValue("");
  }

  const handleSampleEmailConfirm = async () => {
    setSampleEmailError(undefined);
    setSampleEmailSuccess(false);
    setSendSampleEmailLoading(true);
    const parsedSubject = parseContent(emailSubjectRef.current?.innerHTML || '', []);
    const parsedBody = parseContent(emailBodyRef.current?.innerHTML || '', ['br']);
    const emailTemplate: CreateEmailTemplateRequest = {
      subject: parsedSubject,
      body: parsedBody,
    };
    await sendSampleEmailPromise(sampleEmailAddressInput.value, emailTemplate).then(r => {
      setSendSampleEmailLoading(false);
      setSampleEmailSuccess(true);
    }).catch(err => {
      setSendSampleEmailLoading(false);
      setSampleEmailError(new Error(err.response.data.Message || "Something went wrong. Try again later."));
    });
  }

  return (
    <>
      <Dialog isOpen={openDialog}>
        <DialogTitle>Send Sample Email</DialogTitle>
        <DialogContent>
          <Typography>
            Enter the email address you would like the email sent to. A sample email will be sent with the current template. This does not save the changes so remember to save the changes if you wish to keep them.
          </Typography>
          <TextField
            name="sampleEmail"
            label="Email Address"
            autoComplete="email"
            type="email"
            sx={{marginTop: '10px'}}
            {...sampleEmailAddressInput.bind}
            fullWidth
          />
          {sampleEmailError &&
            <Typography color="error">
              {sampleEmailError.message}
            </Typography>
          }
          {sampleEmailSuccess &&
            <Typography>
              Success! Please check your junk/spam folder if you don’t see it in your inbox.
            </Typography>
          }
        </DialogContent>
        <DialogActions>
          <Grid container item xs={12} gap={2} m={2}>
            <Grid item>
              <Button
                type="button"
                onClick={handleSampleEmailConfirm}
                pending={sendSampleEmailLoading}
                disabled={!sampleEmailAddressInput.valid || sampleEmailSuccess}
              >
                {sampleEmailSuccess ? "Email Sent" : "Send"}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="secondary"
                disabled={sendSampleEmailLoading}
                onClick={handleSampleEmailCancel}
              >
                {sampleEmailSuccess ? "Close" : "Cancel"}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      <Card style={{marginTop: '10px'}}>
        <CardHeader
          title={
            <>
              <Typography variant="h6" fontWeight="bold">
                Survey Email Template
              </Typography>
              <Typography variant="caption">
                Edit your agent survey email template below
              </Typography>
            </>
          }
          action={
            <Grid container item xs={12} gap={2} m={2}>
              <Grid item>
                <Button onClick={submit} pending={loading}>Save</Button>
              </Grid>
              <Grid item>
                <Button variant="secondary" onClick={() => setOpenDialog(true)}>
                  Send Sample Email
                </Button>
              </Grid>
            </Grid>
          }
        />
        <CardContent>
          <Grid item>
            <ErrorDisplay
              error={error}
              errorMessage={error?.message}
            />
          </Grid>
          <Grid item>
            <SuccessDisplay
              display={success || false}
              successMessage="Changes saved successfully!"
            />
          </Grid>
          <Box display="flex" gap={2} style={{marginTop: '10px'}}>
            <Paper elevation={3} sx={{ padding: 2 }}>
              <Typography variant="h5">
                Available Fields
                <Tooltip
                  title="To insert one of these fields, place your cursor where you would like the field to be inserted and then click the corresponding button.
                  These fields will be replaced with the relevant transaction information when the email is sent.
                  To remove a tag, place your cursor at the end of the tag and hit delete/backspace to remove it.
                  If you are having trouble placing your cursor at the end of the tag, place it 1 or 2 characters past the tag,
                  and then backspace until your cursor is at the end of the tag, and then hit delete/backspace.
                  To see how the tags will be rendered in the email, click on the 'Send Sample Email' button."
                >
                  <InfoOutlined />
                </Tooltip>
              </Typography>
              <Stack style={{marginTop: '10px'}} direction="column" spacing={2}>
                {availableTags.map((tag) => (
                  <Button
                    key={`subject-${tag.value}`}
                    onClick={() => insertTag(tag)}
                  >
                    {tag.title}
                  </Button>
                ))}
              </Stack>
            </Paper>
            <Box display="flex" flexDirection="column" gap={2} flex={1}>
              <Paper elevation={3} sx={{ flex: 1, padding: 2 }}>
                <Typography variant="h6">Email Subject</Typography>
                <Box
                  ref={emailSubjectRef}
                  contentEditable
                  sx={{ minHeight: 50, outline: 'none', padding: 1 }}
                />
              </Paper>
              <Paper elevation={3} sx={{ flex: 1, padding: 2 }}>
                <Typography variant="h6">Email Body</Typography>
                <Box
                  ref={emailBodyRef}
                  contentEditable
                  sx={{ minHeight: 200, outline: 'none', padding: 1 }}
                />
              </Paper>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </>
  );
}

export const formatInitialInput = (input: string): string => {
  const tagRegex = /\{\{(.*?)\}\}/g;
  let result = '';
  let lastIndex = 0;

  const addSpan = (match: string, group1: string) => {
    const spanTag = `<span contenteditable="false">${match}</span>`;
    return spanTag;
  };

  for (const match of input.matchAll(tagRegex)) {
    const startIndex = match.index || 0;
    const endIndex = startIndex + match[0].length;

    result += input.slice(lastIndex, startIndex);
    result += addSpan(match[0], match[1]);

    lastIndex = endIndex;
  }

  result += input.slice(lastIndex);

  // Fix for non-chromium browsers to allow placing cursor at the end of text if last node is a span tag.
  return result + '&nbsp;';
};

import { QueryEntity } from '@datorama/akita';
import { FeedState, feedStore } from './feeds.store';

export class FeedsQuery extends QueryEntity<FeedState> {
  feeds = this.selectAll();
  loading = this.selectLoading();
  error = this.selectError();
}

export const feedsQuery = new FeedsQuery(feedStore);

import React from 'react';
import { Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  content: {
    minHeight: '100vh',
    marginLeft: '25%',
    marginRight: '25%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
}));

export const CenteredLayout: React.FC = ({ children }) => {
  const styles = useStyles();

  return (
    <>
      <Paper className={styles.content}>{children}</Paper>
    </>
  );
};

import React, { useEffect, useState } from 'react';
import {
  NumberFormatters,
  Link,
  ThemeColors,
  icons,
  useCurrentUserId,
  LoadingFade,
  useCurrentUser,
} from '../../shared';
import { DataTable, DataTableColumn } from 'shared/components/data-table';
import { CoBrokeSales, DateRangeOptions, getStartAndEndDates, mlsService } from '../../state';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import { Button } from 'shared/components/button';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format } from 'date-fns';
import { dateFormat } from '../../state/search';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRecruit } from '../../recruiting';
import { RecruitAgent } from '../../shared/components/recruit-agent';
import { useGetCobrokeSales } from '../hooks';
import { AgentModel } from 'api/mls';
import { SubmissionDetailsDialogButton } from 'surveys/components/submission-details-dialog-button';
import {
  useCompanySubmissions,
  SubmissionScore,
  SendSurveyButton,
  ErrorDisplay,
  useSurveyError,
  resetError,
} from 'surveys';
import themeService from 'theme/ThemeService';
import {makeStyles} from "@mui/styles";

interface Props {
  feedKeys: Array<string>;
  dateRange?: DateRangeOptions;
  paginationModel: {page: number, pageSize: number};
  onPaginationModelChange: ({page, pageSize}: {page: number, pageSize: number}) => void;
}

const palette = themeService.getPalette();

const useStyles = makeStyles({
  link: {
    marginRight: "10px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: palette.teal,
    maxWidth: "calc(100% - 32px)",
  }
});

export const CobrokeList = ({
  feedKeys,
  dateRange,
  paginationModel,
  onPaginationModelChange
}: Props) => {
  const styles = useStyles();
  const { coBrokes, loading } = useGetCobrokeSales(feedKeys, dateRange);
  const manager = useCurrentUser();
  const managerId = manager ? manager.id : undefined;
  const companyId = manager ? manager.companyId : undefined;
  const { isRecruit, isRecruited, toggleRecruit } = useRecruit(managerId);
  const { submissions } = useCompanySubmissions(companyId);
  const [errorOpen, setErrorOpen] = useState(false);
  const surveyError = useSurveyError();
  useEffect(() => {
    if (surveyError) {
      setErrorOpen(true);
    }
  }, [surveyError]);

  const [load, setLoading] = useState(false);

  const clearError = () => {
    resetError();
    setErrorOpen(false);
  };

  const onExport = async () => {
    setLoading(true);
    try {
      if (dateRange) {
        const { startDate, endDate } = getStartAndEndDates(dateRange);
        await mlsService.exportOfficeRetention(
          feedKeys,
          format(startDate, dateFormat),
          format(endDate, dateFormat)
        );
      }
    } catch (error) {
      // Handle error during download here
    }

    setLoading(false);
  };

  const columns: DataTableColumn<CoBrokeSales>[] = [
    {
      renderHeader: () => <Typography noWrap fontWeight="bold">WATCHING</Typography>,
      headerAlign: 'center',
      align: 'center',
      field: 'watching',
      sortable: false,
      filterable: false,
      renderCell: ({ row }) => (
        <RecruitAgent
          memberKey={row.coBrokeAgent?.memberKey!}
          isRecruit={isRecruit(row.coBrokeAgent?.memberKey!)}
          isRecruited={isRecruited(row.coBrokeAgent?.memberKey!)}
          toggleRecruit={toggleRecruit}
        />
      ),
    },
    {
      renderHeader: () => <Typography noWrap fontWeight="bold">COOPERATING AGENT</Typography>,
      headerAlign: 'center',
      field: 'coBrokeAgent',
      renderCell: ({ row }) => (
        <>
          <Link
            className={styles.link}
            to={{
              pathname: `/agents/${row.coBrokeAgent?.memberKey}`,
              state: {
                pageNum: paginationModel.page,
                rowsPerPage: paginationModel.pageSize,
                dateRange: dateRange,
                prevPath: window.location.pathname,
              },
            }}
            color="primary"
          >
            {row.coBrokeAgent?.fullName ?? 'N/A'}
          </Link>
          {row.coBrokeAgent?.fullName && <a
              href={`https://www.google.com/search?q=${row.coBrokeAgent?.fullName} Realtor ${row.coBrokeAgent?.officeName}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: palette.primary, margin: '0 .25rem' }}
            >
              <FontAwesomeIcon icon={icons.searchGlass} size="sm" />
            </a>
          }
        </>
      ),
      flex: 2,
      sortable: true,
      valueGetter: (value, row) => row.coBrokeAgent?.fullName
    },
    {
      renderHeader: () => <Typography noWrap fontWeight="bold">OUR AGENT</Typography>,
      headerAlign: 'center',
      field: 'ourAgent',
      renderCell: ({ row }) => (
        <>
          <Link
            className={styles.link}
            to={`/agents/${row.ourAgent?.memberKey}`}
            color="primary">
            {row.ourAgent?.fullName ?? 'N/A'}
          </Link>
          <>
          {row.ourAgent?.fullName && <a
              href={`https://www.google.com/search?q=${row.ourAgent?.fullName} Realtor ${row.ourAgent?.officeName}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: palette.primary, margin: '0 .25rem' }}
            >
              <FontAwesomeIcon icon={icons.searchGlass} size="sm" />
            </a>}
          </>
        </>
      ),
      flex: 2,
      sortable: true,
      valueGetter: (value, row) => row.ourAgent?.fullName
    },
    {
      renderHeader: () => <Typography noWrap fontWeight="bold">SOLD DATE</Typography>,
      headerAlign: 'center',
      field: 'closedDate',
      renderCell: ({ row }) =>
        row.closedDate ? new Date(row.closedDate).toLocaleDateString() : undefined,
      sortable: true,
      filterable: false,
      valueGetter: (value, row) => {
        if (row.closedDate) return new Date(row.closedDate);
        return null;
      },
      type: "date"
    },
    {
      renderHeader: () => <Typography noWrap fontWeight="bold">COOPERATING BROKER</Typography>,
      headerAlign: 'center',
      field: 'officeName',
      renderCell: ({ row }) => row.coBrokeAgent?.officeName,
      flex: 2,
      sortable: true,
      valueGetter: (value, row) => row.coBrokeAgent?.officeName,
    },
    {
      renderHeader: () => <Typography noWrap fontWeight="bold">EMAIL</Typography>,
      headerAlign: 'center',
      field: 'email',
      renderCell: ({ row }) => <div>{row.coBrokeAgent?.email}</div>,
      flex: 1.5,
      sortable: true,
      valueGetter: (value, row) => row.coBrokeAgent?.email,
    },
    {
      renderHeader: () => <Typography noWrap fontWeight="bold">CELL</Typography>,
      headerAlign: 'center',
      field: 'phone',
      renderCell: ({ row }) => row.coBrokeAgent?.phone,
      sortable: true,
      valueGetter: (value, row) => row.coBrokeAgent?.phone,
    },
    {
      renderHeader: () => <Typography noWrap fontWeight="bold">SIDE REPRESENTED</Typography>,
      headerAlign: 'center',
      field: 'sellType',
      sortable: true,
      valueGetter: (value, row) => row.sellType
    },
    {
      renderHeader: () => <Typography noWrap fontWeight="bold">ADDRESS</Typography>,
      headerAlign: 'center',
      field: 'propertyAddress',
      flex: 3,
      sortable: true,
      valueGetter: (value, row) => row.propertyAddress
    },
    {
      renderHeader: () => <Typography noWrap fontWeight="bold">SOLD PRICE</Typography>,
      headerAlign: 'center',
      align: 'left',
      field: 'propertySalePrice',
      renderCell: ({ row }) => (
        <>&nbsp;{NumberFormatters.decimal`\$${row.propertySalePrice ?? 0}`}</>
      ),
      sortable: true,
      valueGetter: (value, row) => row.propertySalePrice,
      type: 'number'
    },
    {
      renderHeader: () => <Typography noWrap fontWeight="bold">SURVEYS</Typography>,
      headerAlign: 'center',
      align: 'center',
      field: 'surveys',
      flex: 1.5,
      sortable: false,
      filterable: false,
      renderCell: ({ row }) => {
        const submission = submissions.find(s => s.listingKey === row.listingKey);
        const submitted = !!submission?.submissionDate;

        if (submission && submitted && submission.id && submission.surveyId) {
          return (
            <Grid container spacing={1}>
              <Grid item>
                <Link to={`survey/${submission.id}/submission`}>
                  <SubmissionScore
                    totalPoints={submission.totalPoints || 0}
                    possiblePoints={submission.possiblePoints || 0}
                  />
                </Link>
              </Grid>
              <Grid item>
                <SubmissionDetailsDialogButton
                  surveyId={submission.surveyId}
                  submissionId={submission.id}
                />
              </Grid>
            </Grid>
          );
        }

        return (
          <SendSurveyButton
            listingKey={row.listingKey || ''}
            ourAgent={row.ourAgent || ({} as AgentModel)}
            coOpAgent={row.coBrokeAgent || ({} as AgentModel)}
            address={row.propertyAddress || ''}
            closeDate={row.closedDate ? new Date(row.closedDate).toLocaleDateString() : ''}
            surveySent={!!submission}
          />
        );
      },
    },
  ];

  return (
    <LoadingFade isContentLoading={loading}>
      <ErrorDisplay open={errorOpen} onClose={clearError} error={surveyError} />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Card>
          <CardContent>
            <LoadingFade
              isContentLoading={loading}
              content={() => (
                <Grid item container direction="column" gap={2}>
                  <Grid
                    item
                    container
                    xs={12}
                    justifyContent="flex-end"
                  >
                    <Grid item>
                      <Button
                        onClick={onExport}
                        pending={load}
                        startIcon={<FontAwesomeIcon icon={icons.download} size="lg" />}
                      >
                        Export Results
                      </Button>
                    </Grid>
                  </Grid>
                  <DataTable
                    getRowId={(row) => row.listingKey!}
                    rows={coBrokes}
                    columns={columns}
                    progressPending={loading}
                    showPagination
                    paginationModel={paginationModel}
                    onPaginationModelChange={onPaginationModelChange}
                  />
                </Grid>
              )}
            />
          </CardContent>
        </Card>
      </LocalizationProvider>
    </LoadingFade>
  );
};

import { Listing } from './mls.models';
import { DateListingFilter, DateRangeOptions, dateSelector } from './date-filter';
import { keyListingFilter, ListingFilter, ListingFilterKeys } from './listing-filter';

export enum ListingGroup {
  Months = 'Months',
  Offices = 'Offices',
}

interface GroupOptions {
  listingFilterKeys: ListingFilterKeys;
  listingFilter: ListingFilter;
  dateRange: DateRangeOptions;
  dateFilter: DateListingFilter;
}
interface GroupSelector {
  getFilters: (key: any, options: GroupOptions) => [ListingFilter, DateListingFilter];
  keySelector: (listing: Listing) => any[];
}

export function getGroupSelector(grouping: ListingGroup): GroupSelector {
  if (grouping === ListingGroup.Months) {
    return {
      getFilters: (key, { listingFilter, dateFilter }) => [listingFilter, dateFilter],
      // grab the month (zero-based) from an ISO string
      keySelector: listing => [
        dateSelector<Listing, number>(dateStr => parseInt(dateStr.substr(5, 2)) - 1)(
          listing,
          l => l.closeDate
        ),
      ],
    };
  }

  if (grouping === ListingGroup.Offices) {
    return {
      // construct a new filter for this office only to get appropriate listing share per group
      getFilters: (key, { listingFilterKeys, dateFilter }) => [
        keyListingFilter({ ...listingFilterKeys, officeKeys: [key] }),
        dateFilter,
      ],
      // grab distinct office keys
      keySelector: listing => [...new Set([...listing.listOfficeKeys, ...listing.sellOfficeKeys])],
    };
  }

  throw new Error(`Unsupported grouping: ${grouping}`);
}

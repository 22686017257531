import React from 'react';
import { Redirect, Route, Switch, RouteComponentProps } from 'react-router-dom';

import { CobrokePage } from './pages';

export const CoBrokeRoutes: React.FC<RouteComponentProps> = ({ match }) => (
  <Switch>
    <Route path={`${match.path}`} component={CobrokePage} />
    <Redirect to={match.path} />
  </Switch>
);

import { Grid } from '@mui/material';
import { Dialog, DialogTitle, DialogActions, DialogContent, DialogProps } from './../dialog';
import { Button } from './../button';

export type ConfirmationDialogProps = DialogProps & {
  title: string;
  isPending?: boolean;
  onConfirm: () => void;
  onCancel?: () => void;
  confirmButtonProps?: ButtonProps;
  cancelButtonProps?: ButtonProps;
};

type ButtonProps = {
  label?: string;
  isDisabled?: boolean;
};

const defaultConfirmButtonProps: ButtonProps = {
  label: 'Confirm',
};

const defaultCancelButtonProps: ButtonProps = {
  label: 'Cancel',
};

export function ConfirmationDialog({
  title,
  isPending,
  children,
  confirmButtonProps,
  cancelButtonProps,
  onConfirm,
  onCancel,
  ...rest
}: ConfirmationDialogProps) {
  const mergedConfirmButtonProps = { ...defaultConfirmButtonProps, ...confirmButtonProps };
  const mergedCancelButtonProps = { ...defaultCancelButtonProps, ...cancelButtonProps };

  return (
    <Dialog {...rest}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Grid container item xs={12} gap={2} m={2}>
          <Grid item>
            <Button
              type="button"
              onClick={onConfirm}
              pending={isPending}
              disabled={mergedConfirmButtonProps.isDisabled}
            >
              {mergedConfirmButtonProps.label}
            </Button>
          </Grid>

          {onCancel ? (
            <Grid item>
              <Button
                variant="secondary"
                onClick={onCancel}
                disabled={mergedCancelButtonProps.isDisabled}
              >
                {mergedCancelButtonProps.label}
              </Button>
            </Grid>
          ) : null}
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

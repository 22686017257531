import React, { useState, useEffect } from 'react';
import { useFormEvents } from '../../forms';

import {
  activitiesService,
  Activity,
  ActivityType,
  recruitsService,
  ActivitiesForms,
  FormStatus,
} from '../../../state';
import { AddEditActivityDialog } from 'shared/components/activity-history/add-edit-activity-dialog';

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  recruitId: number;
  managerId?: string;
  activity: Activity | null;
  kvCoreId: string | null | undefined;
}

export const AddEditActivity: React.FC<Props> = ({
  open,
  setOpen,
  recruitId,
  managerId,
  activity,
  kvCoreId,
}) => {
  const [activeId, setActiveId] = useState<number | null>(null);
  const [type, setType] = useState<ActivityType>(ActivityType.Notes);
  const [recordedAt, setRecordedAt] = useState('');
  const [notes, setNotes] = useState('');
  const [{ status }] = useFormEvents(ActivitiesForms.Create);


  useEffect(() => {
    if (recruitId !== null && activity !== null) {
      setActiveId(activity.id ?? null);
      setType(activity.type ?? ActivityType.Notes);
      setRecordedAt(activity.recordedAt ?? '');
      setNotes(activity.notes ?? '');
    }
  }, [recruitId, activity, setActiveId, setType, setRecordedAt, setNotes, setOpen]);

  useEffect(() => {
    if (!!managerId && status === FormStatus.Success) {
      activitiesService.getManagerRecruitActivities(managerId);
      recruitsService.getManagerRecruits(managerId);
    }
  }, [managerId, status]);
  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = (activity: Activity) => {
    if (activity.id == null) {
      activitiesService.createActivity(recruitId, activity);
    } else {
      activitiesService.updateActivity(recruitId, activity);
    }
    setOpen(false);
  };

  const handleDelete = (id: number) => {
    activitiesService.deleteActivity(id);
    setOpen(false);
  };

  return (
    <>
      <AddEditActivityDialog
        open={open}
        handleClose={handleClose}
        handleSave={handleSave}
        handleDelete={handleDelete}
        id={activeId}
        type={type}
        recordedAt={recordedAt}
        notes={notes}
        setType={setType}
        setRecordedAt={setRecordedAt}
        setNotes={setNotes}
        readOnly={kvCoreId !== null}
      />
    </>
  );
};

import { applyTransaction } from '@datorama/akita';
import { combineLatest, from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { feedQuery } from 'state/admin/mlsoffices/feeds/feeds.query';
import { OfficesApi } from 'api/mls/api/offices-api';
import { mlsofficesQuery } from './mlsoffices.query';
import { MlsofficesStore, mlsofficesStore } from './mlsoffices.store';

export class MlsofficesService {
  constructor(
    private readonly store: MlsofficesStore,
    private readonly mlsofficesApi: OfficesApi
  ) {}

  getCompanies() {
    from(combineLatest([mlsofficesQuery.feeds, feedQuery.feeds]))
      .pipe(
        switchMap(([activeIds, allFeeds]) => {
          const feedIds = !!activeIds && activeIds.length > 0 ? activeIds : allFeeds.map(f => f.id);
          return this.mlsofficesApi.mlsOfficesCompaniesPost({ feedIds });
        }),
        map(response => response.data.data)
      )
      .subscribe(companies =>
        applyTransaction(() => {
          this.store.upsertMany(companies);
        })
      );
  }

  getOffices(officeKeys: string[] | undefined) {
    if (!officeKeys) return;
    from(this.mlsofficesApi.mlsOfficesPost({ officeKeys, includeBranches: true }))
      .pipe(map(response => response.data.data))
      .subscribe(offices => this.store.upsertMany(offices));
  }

  setCompanyKeys(companyKeys: string[] | undefined) {
    this.store.update(({ ui }) => ({
      ui: { ...ui, companyKeys },
    }));
  }

  setFeeds(feeds: number[] | undefined) {
    this.store.update(({ ui }) => ({
      ui: { ...ui, feeds },
    }));
  }
}

export const mlsofficesService = new MlsofficesService(mlsofficesStore, new OfficesApi());

import { QueryEntity } from '@datorama/akita';
import { ProcessLogState, processLogStore } from './logs.store';

export class ProcessLogQuery extends QueryEntity<ProcessLogState> {
  processLogs = this.selectAll();
  loading = this.selectLoading();
  error = this.selectError();
}

export const processLogQuery = new ProcessLogQuery(processLogStore);

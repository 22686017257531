import axios from 'axios';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { JwtToken } from 'api/auth/auth-api';
import * as Zsg from 'api/app';
import { AppConfig } from '../../config';

export class AuthService {
  constructor(private readonly authApi: Zsg.AuthApi) {}

  public async getKvCoreTokenRequest(
    code: string,
    setCookies: (token: string, refreshToken: string | undefined, tokenExpiry: number) => void
  ): Promise<void> {
    const params = new URLSearchParams({
      grant_type: 'authorization_code',
      code,
      redirect_uri: AppConfig.KvCore.OAuth.CallbackUrl,
    });
    const { data } = await axios.post<JwtToken>(AppConfig.KvCore.OAuth.TokenEndpoint, params);

    setCookies(data.access_token, data.refresh_token, data.expires_in);
  }

  public async refreshKvCoreTokenRequest(
    refreshToken: string,
    setTokens: (token: string, refreshToken: string | undefined, tokenExpiry: number) => void
  ): Promise<void> {
    const params = new URLSearchParams({
      grant_type: 'refresh_token',
      refresh_token: refreshToken,
      redirect_uri: AppConfig.KvCore.OAuth.CallbackUrl,
    });
    const { data } = await axios.post<JwtToken>(
      AppConfig.KvCore.OAuth.RefreshTokenEndpoint,
      params
    );

    setTokens(data.access_token, data.refresh_token, data.expires_in);
  }

  public kvCoreSync(): Observable<any> {
    return from(this.authApi.authAuthKvcoresyncGet('')).pipe(map(({ data }) => data));
  }
}

export const authService = new AuthService(new Zsg.AuthApi());

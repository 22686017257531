import { applyTransaction } from '@datorama/akita';
import { from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import * as Zsg from 'api/app';
import { RecruitsStore, recruitsStore } from './recruits.store';
import { Recruit } from './recruits.models';
import { MlsService, mlsService } from '../mls';

export class RecruitsService {
  constructor(
    private readonly store: RecruitsStore,
    private readonly recruitingApi: Zsg.RecruitingApi,
    private readonly mls: MlsService
  ) {}

  getManagerRecruits(managerId: string) {
    from(this.recruitingApi.recruitingManagerIdGet(managerId))
      .pipe(
        map(response => response.data.data),
        switchMap(data =>
          this.mls
            .loadAgentVolumes(
              data.map(x => x.memberKey),
              true
            )
            .pipe(map(() => data))
        )
      )
      .subscribe(recruits =>
        applyTransaction(() => {
          this.store.update(({ ui }) => ({ ui: { ...ui, managerId } }));
          this.store.set(recruits);
        })
      );
  }

  getRecruit(managerId: string, id: number) {
    from(this.recruitingApi.recruitingManagerIdIdGet(managerId, id))
      .pipe(map(response => response.data.data))
      .subscribe(recruit =>
        applyTransaction(() => {
          this.store.update(({ ui }) => ({ ui: { ...ui, managerId } }));
          this.store.upsert(id, recruit);
        })
      );
  }

  deleteRecruit(managerId: string, id: number) {
    from(this.recruitingApi.recruitingManagerIdIdDelete(managerId, id)).subscribe(() =>
      applyTransaction(() => {
        this.store.remove(id);
      })
    );
  }

  createRecruit(managerId: string, memberKey: string) {
    from(this.recruitingApi.recruitingManagerIdRecruitMemberKeyPut(managerId, memberKey))
      .pipe(
        map(response => {
          return { ...response.data.data } || {};
        }),
        switchMap(data =>
          this.mls
            .loadAgentVolumes(
              [data.memberKey],
              true, //load in the background
              true //force reload of volumes
            )
            .pipe(map(() => data))
        )
      )
      .subscribe(rec => {
        this.store.upsert(rec.id, rec);
      });
  }

  selectRecruit(id: Recruit['id']) {
    this.store.setActive(id ?? null);
  }
}

export const recruitsService = new RecruitsService(
  recruitsStore,
  new Zsg.RecruitingApi(),
  mlsService
);

import React from 'react';
import { Avatar } from '@mui/material';
import { Room, HomeWork } from '@mui/icons-material';
import { ThemeColors } from '../theme';

interface MarkerProps {
  name?: string;
  lat?: number | null;
  lng?: number | null;
  color?: string;
}

export const ListingMarker: React.FC<MarkerProps> = ({ color = ThemeColors.DarkGrey }) => {
  return <Room style={{ color: color }} />;
};

export const OfficeMarker: React.FC<MarkerProps> = ({ color = ThemeColors.Blue }) => {
  return (
    <Avatar
      style={{ backgroundColor: 'white', borderColor: color, borderStyle: 'solid', borderWidth: 3 }}
    >
      <HomeWork style={{ color: color }} />
    </Avatar>
  );
};

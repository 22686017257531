import React from 'react';
import { MarketStat, NumberFormatters, ThemeColors, ValueFormatter } from '../../shared';
import { Box, Grid } from '@mui/material';

interface Props {
  chartColor: ThemeColors;
  label: string;
  formatter?: ValueFormatter<number>;
  company: { current: number; previous: number | undefined };
  market: { current: number; previous: number | undefined };
}

const convertToPercent = (numerator: number | undefined, denominator: number | undefined) => {
  if (!numerator || !denominator) return 0;
  if (isNaN(numerator) || isNaN(denominator)) return 0;
  if (denominator === 0) return 0;
  if (numerator < 0 || denominator < 0) return 0;

  return numerator / denominator;
};

export const MarketShare: React.FC<Props> = ({
  label,
  company,
  market,
  formatter = NumberFormatters.number,
}) => {
  const percent = {
    current: convertToPercent(company.current, market.current),
    previous: convertToPercent(company.previous, market.previous),
  };

  return (
    <Box height="100%">
      <Grid container item xs={12} spacing={2}>
        <Grid item xs={12} xl={6}>
          <MarketStat
            title={'Total Market ' + label}
            current={market.current}
            previous={market.previous}
            formatter={formatter}
          />
        </Grid>
        <Grid item xs={12} xl={6}>
          <MarketStat
            title={'Share of Market ' + label}
            current={percent.current}
            previous={percent.previous}
            formatter={NumberFormatters.decimalPercent}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

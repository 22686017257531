/* eslint-disable */
// tslint:disable
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';

import { AddAgentFavoriteResponse } from '../model';
import { ChangeNotificationSettingsRequest } from '../model';
import { ChangeNotificationSettingsResponse } from '../model';
import { CheckManagerExistsRequest } from '../model';
import { CheckManagerExistsResponse } from '../model';
import { GetManagerAgentFavoriteResponse } from '../model';
import { GetManagerNotificationResponse } from '../model';
import { GetManagerResponse } from '../model';
import { GetOfficeManagersResponse } from '../model';
import { UpsertManagerRequest } from '../model';
import { UpsertManagerResponse } from '../model';
/**
 * ManagersApi - axios parameter creator
 * @export
 */
export const ManagersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CheckManagerExistsRequest} [checkManagerExistsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        managersExistsPost(checkManagerExistsRequest?: CheckManagerExistsRequest, options: any = {}): RequestArgs {
            const localVarPath = `/managers/exists`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof checkManagerExistsRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(checkManagerExistsRequest !== undefined ? checkManagerExistsRequest : {}) : (checkManagerExistsRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        managersGet(options: any = {}): RequestArgs {
            const localVarPath = `/managers`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        managersIdDelete(id: string, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling managersIdDelete.');
            }
            const localVarPath = `/managers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        managersIdFavoritesGet(id: string, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling managersIdFavoritesGet.');
            }
            const localVarPath = `/managers/{id}/favorites`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} memberKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        managersIdFavoritesMemberKeyDelete(id: string, memberKey: string, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling managersIdFavoritesMemberKeyDelete.');
            }
            // verify required parameter 'memberKey' is not null or undefined
            if (memberKey === null || memberKey === undefined) {
                throw new RequiredError('memberKey','Required parameter memberKey was null or undefined when calling managersIdFavoritesMemberKeyDelete.');
            }
            const localVarPath = `/managers/{id}/favorites/{memberKey}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"memberKey"}}`, encodeURIComponent(String(memberKey)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} memberKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        managersIdFavoritesMemberKeyPut(id: string, memberKey: string, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling managersIdFavoritesMemberKeyPut.');
            }
            // verify required parameter 'memberKey' is not null or undefined
            if (memberKey === null || memberKey === undefined) {
                throw new RequiredError('memberKey','Required parameter memberKey was null or undefined when calling managersIdFavoritesMemberKeyPut.');
            }
            const localVarPath = `/managers/{id}/favorites/{memberKey}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"memberKey"}}`, encodeURIComponent(String(memberKey)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        managersIdGet(id: string, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling managersIdGet.');
            }
            const localVarPath = `/managers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpsertManagerRequest} [upsertManagerRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        managersIdPost(id: string, upsertManagerRequest?: UpsertManagerRequest, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling managersIdPost.');
            }
            const localVarPath = `/managers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof upsertManagerRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(upsertManagerRequest !== undefined ? upsertManagerRequest : {}) : (upsertManagerRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} fromDate 
         * @param {string} toDate 
         * @param {number} limit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        managersNotificationsGet(fromDate: string, toDate: string, limit: number, options: any = {}): RequestArgs {
            // verify required parameter 'fromDate' is not null or undefined
            if (fromDate === null || fromDate === undefined) {
                throw new RequiredError('fromDate','Required parameter fromDate was null or undefined when calling managersNotificationsGet.');
            }
            // verify required parameter 'toDate' is not null or undefined
            if (toDate === null || toDate === undefined) {
                throw new RequiredError('toDate','Required parameter toDate was null or undefined when calling managersNotificationsGet.');
            }
            // verify required parameter 'limit' is not null or undefined
            if (limit === null || limit === undefined) {
                throw new RequiredError('limit','Required parameter limit was null or undefined when calling managersNotificationsGet.');
            }
            const localVarPath = `/managers/notifications`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fromDate !== undefined) {
                localVarQueryParameter['FromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['ToDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }

            if (limit !== undefined) {
                localVarQueryParameter['Limit'] = limit;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ChangeNotificationSettingsRequest} [changeNotificationSettingsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        managersNotificationsPost(changeNotificationSettingsRequest?: ChangeNotificationSettingsRequest, options: any = {}): RequestArgs {
            const localVarPath = `/managers/notifications`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof changeNotificationSettingsRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(changeNotificationSettingsRequest !== undefined ? changeNotificationSettingsRequest : {}) : (changeNotificationSettingsRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ManagersApi - functional programming interface
 * @export
 */
export const ManagersApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CheckManagerExistsRequest} [checkManagerExistsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        managersExistsPost(checkManagerExistsRequest?: CheckManagerExistsRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckManagerExistsResponse> {
            const localVarAxiosArgs = ManagersApiAxiosParamCreator(configuration).managersExistsPost(checkManagerExistsRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        managersGet(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOfficeManagersResponse> {
            const localVarAxiosArgs = ManagersApiAxiosParamCreator(configuration).managersGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        managersIdDelete(id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ManagersApiAxiosParamCreator(configuration).managersIdDelete(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        managersIdFavoritesGet(id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetManagerAgentFavoriteResponse> {
            const localVarAxiosArgs = ManagersApiAxiosParamCreator(configuration).managersIdFavoritesGet(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} memberKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        managersIdFavoritesMemberKeyDelete(id: string, memberKey: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ManagersApiAxiosParamCreator(configuration).managersIdFavoritesMemberKeyDelete(id, memberKey, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} memberKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        managersIdFavoritesMemberKeyPut(id: string, memberKey: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddAgentFavoriteResponse> {
            const localVarAxiosArgs = ManagersApiAxiosParamCreator(configuration).managersIdFavoritesMemberKeyPut(id, memberKey, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        managersIdGet(id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetManagerResponse> {
            const localVarAxiosArgs = ManagersApiAxiosParamCreator(configuration).managersIdGet(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpsertManagerRequest} [upsertManagerRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        managersIdPost(id: string, upsertManagerRequest?: UpsertManagerRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpsertManagerResponse> {
            const localVarAxiosArgs = ManagersApiAxiosParamCreator(configuration).managersIdPost(id, upsertManagerRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} fromDate 
         * @param {string} toDate 
         * @param {number} limit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        managersNotificationsGet(fromDate: string, toDate: string, limit: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetManagerNotificationResponse> {
            const localVarAxiosArgs = ManagersApiAxiosParamCreator(configuration).managersNotificationsGet(fromDate, toDate, limit, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {ChangeNotificationSettingsRequest} [changeNotificationSettingsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        managersNotificationsPost(changeNotificationSettingsRequest?: ChangeNotificationSettingsRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChangeNotificationSettingsResponse> {
            const localVarAxiosArgs = ManagersApiAxiosParamCreator(configuration).managersNotificationsPost(changeNotificationSettingsRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ManagersApi - factory interface
 * @export
 */
export const ManagersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {CheckManagerExistsRequest} [checkManagerExistsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        managersExistsPost(checkManagerExistsRequest?: CheckManagerExistsRequest, options?: any): AxiosPromise<CheckManagerExistsResponse> {
            return ManagersApiFp(configuration).managersExistsPost(checkManagerExistsRequest, options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        managersGet(options?: any): AxiosPromise<GetOfficeManagersResponse> {
            return ManagersApiFp(configuration).managersGet(options)(axios, basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        managersIdDelete(id: string, options?: any): AxiosPromise<void> {
            return ManagersApiFp(configuration).managersIdDelete(id, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        managersIdFavoritesGet(id: string, options?: any): AxiosPromise<GetManagerAgentFavoriteResponse> {
            return ManagersApiFp(configuration).managersIdFavoritesGet(id, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} memberKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        managersIdFavoritesMemberKeyDelete(id: string, memberKey: string, options?: any): AxiosPromise<void> {
            return ManagersApiFp(configuration).managersIdFavoritesMemberKeyDelete(id, memberKey, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} memberKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        managersIdFavoritesMemberKeyPut(id: string, memberKey: string, options?: any): AxiosPromise<AddAgentFavoriteResponse> {
            return ManagersApiFp(configuration).managersIdFavoritesMemberKeyPut(id, memberKey, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        managersIdGet(id: string, options?: any): AxiosPromise<GetManagerResponse> {
            return ManagersApiFp(configuration).managersIdGet(id, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpsertManagerRequest} [upsertManagerRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        managersIdPost(id: string, upsertManagerRequest?: UpsertManagerRequest, options?: any): AxiosPromise<UpsertManagerResponse> {
            return ManagersApiFp(configuration).managersIdPost(id, upsertManagerRequest, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} fromDate 
         * @param {string} toDate 
         * @param {number} limit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        managersNotificationsGet(fromDate: string, toDate: string, limit: number, options?: any): AxiosPromise<GetManagerNotificationResponse> {
            return ManagersApiFp(configuration).managersNotificationsGet(fromDate, toDate, limit, options)(axios, basePath);
        },
        /**
         * 
         * @param {ChangeNotificationSettingsRequest} [changeNotificationSettingsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        managersNotificationsPost(changeNotificationSettingsRequest?: ChangeNotificationSettingsRequest, options?: any): AxiosPromise<ChangeNotificationSettingsResponse> {
            return ManagersApiFp(configuration).managersNotificationsPost(changeNotificationSettingsRequest, options)(axios, basePath);
        },
    };
};

/**
 * ManagersApi - object-oriented interface
 * @export
 * @class ManagersApi
 * @extends {BaseAPI}
 */
export class ManagersApi extends BaseAPI {
    /**
     * 
     * @param {CheckManagerExistsRequest} [checkManagerExistsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagersApi
     */
    public managersExistsPost(checkManagerExistsRequest?: CheckManagerExistsRequest, options?: any) {
        return ManagersApiFp(this.configuration).managersExistsPost(checkManagerExistsRequest, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagersApi
     */
    public managersGet(options?: any) {
        return ManagersApiFp(this.configuration).managersGet(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagersApi
     */
    public managersIdDelete(id: string, options?: any) {
        return ManagersApiFp(this.configuration).managersIdDelete(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagersApi
     */
    public managersIdFavoritesGet(id: string, options?: any) {
        return ManagersApiFp(this.configuration).managersIdFavoritesGet(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} id 
     * @param {string} memberKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagersApi
     */
    public managersIdFavoritesMemberKeyDelete(id: string, memberKey: string, options?: any) {
        return ManagersApiFp(this.configuration).managersIdFavoritesMemberKeyDelete(id, memberKey, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} id 
     * @param {string} memberKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagersApi
     */
    public managersIdFavoritesMemberKeyPut(id: string, memberKey: string, options?: any) {
        return ManagersApiFp(this.configuration).managersIdFavoritesMemberKeyPut(id, memberKey, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagersApi
     */
    public managersIdGet(id: string, options?: any) {
        return ManagersApiFp(this.configuration).managersIdGet(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} id 
     * @param {UpsertManagerRequest} [upsertManagerRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagersApi
     */
    public managersIdPost(id: string, upsertManagerRequest?: UpsertManagerRequest, options?: any) {
        return ManagersApiFp(this.configuration).managersIdPost(id, upsertManagerRequest, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} fromDate 
     * @param {string} toDate 
     * @param {number} limit 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagersApi
     */
    public managersNotificationsGet(fromDate: string, toDate: string, limit: number, options?: any) {
        return ManagersApiFp(this.configuration).managersNotificationsGet(fromDate, toDate, limit, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {ChangeNotificationSettingsRequest} [changeNotificationSettingsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagersApi
     */
    public managersNotificationsPost(changeNotificationSettingsRequest?: ChangeNotificationSettingsRequest, options?: any) {
        return ManagersApiFp(this.configuration).managersNotificationsPost(changeNotificationSettingsRequest, options)(this.axios, this.basePath);
    }

}

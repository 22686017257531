/* eslint-disable */
// tslint:disable
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';

import { ContactToCreate } from '../model';
import { RecruitModel } from '../model';
import { SearchHashtagResult } from '../model';
/**
 * ContactsApi - axios parameter creator
 * @export
 */
export const ContactsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactsAddcontactallowedGet(options: any = {}): RequestArgs {
            const localVarPath = `/contacts/addcontactallowed`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            //@ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ContactToCreate} [contactToCreate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactsCreatecontactPost(contactToCreate?: ContactToCreate, options: any = {}): RequestArgs {
            const localVarPath = `/contacts/createcontact`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            //@ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof contactToCreate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(contactToCreate !== undefined ? contactToCreate : {}) : (contactToCreate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} hashtag 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactsHashtagsHashtagGet(hashtag: string, options: any = {}): RequestArgs {
            // verify required parameter 'hashtag' is not null or undefined
            if (hashtag === null || hashtag === undefined) {
                throw new RequiredError('hashtag','Required parameter hashtag was null or undefined when calling contactsHashtagsHashtagGet.');
            }
            const localVarPath = `/contacts/hashtags/{hashtag}`
                .replace(`{${"hashtag"}}`, encodeURIComponent(String(hashtag)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            //@ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactsHastokenGet(options: any = {}): RequestArgs {
            const localVarPath = `/contacts/hastoken`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            //@ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContactsApi - functional programming interface
 * @export
 */
export const ContactsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactsAddcontactallowedGet(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean> {
            const localVarAxiosArgs = ContactsApiAxiosParamCreator(configuration).contactsAddcontactallowedGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {ContactToCreate} [contactToCreate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactsCreatecontactPost(contactToCreate?: ContactToCreate, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecruitModel> {
            const localVarAxiosArgs = ContactsApiAxiosParamCreator(configuration).contactsCreatecontactPost(contactToCreate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} hashtag 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactsHashtagsHashtagGet(hashtag: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SearchHashtagResult>> {
            const localVarAxiosArgs = ContactsApiAxiosParamCreator(configuration).contactsHashtagsHashtagGet(hashtag, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactsHastokenGet(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean> {
            const localVarAxiosArgs = ContactsApiAxiosParamCreator(configuration).contactsHastokenGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ContactsApi - factory interface
 * @export
 */
export const ContactsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactsAddcontactallowedGet(options?: any): AxiosPromise<boolean> {
            return ContactsApiFp(configuration).contactsAddcontactallowedGet(options)(axios, basePath);
        },
        /**
         * 
         * @param {ContactToCreate} [contactToCreate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactsCreatecontactPost(contactToCreate?: ContactToCreate, options?: any): AxiosPromise<RecruitModel> {
            return ContactsApiFp(configuration).contactsCreatecontactPost(contactToCreate, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} hashtag 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactsHashtagsHashtagGet(hashtag: string, options?: any): AxiosPromise<Array<SearchHashtagResult>> {
            return ContactsApiFp(configuration).contactsHashtagsHashtagGet(hashtag, options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactsHastokenGet(options?: any): AxiosPromise<boolean> {
            return ContactsApiFp(configuration).contactsHastokenGet(options)(axios, basePath);
        },
    };
};

/**
 * ContactsApi - object-oriented interface
 * @export
 * @class ContactsApi
 * @extends {BaseAPI}
 */
export class ContactsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactsApi
     */
    public contactsAddcontactallowedGet(options?: any) {
        return ContactsApiFp(this.configuration).contactsAddcontactallowedGet(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {ContactToCreate} [contactToCreate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactsApi
     */
    public contactsCreatecontactPost(contactToCreate?: ContactToCreate, options?: any) {
        return ContactsApiFp(this.configuration).contactsCreatecontactPost(contactToCreate, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} hashtag 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactsApi
     */
    public contactsHashtagsHashtagGet(hashtag: string, options?: any) {
        return ContactsApiFp(this.configuration).contactsHashtagsHashtagGet(hashtag, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactsApi
     */
    public contactsHastokenGet(options?: any) {
        return ContactsApiFp(this.configuration).contactsHastokenGet(options)(this.axios, this.basePath);
    }

}

import React, { useState, useEffect, useCallback } from 'react';
import { Grid, InputAdornment, RadioGroup, Radio, FormControlLabel } from '@mui/material';
import { CustomCurrencyInputMasker } from '../../shared/components/custom-currency-input-masker';
import { Typography, TextField } from 'shared/components';

export enum QuickProductionFilter {
  OneMillion,
  TwoMillion,
  FiveMillion,
  SevenMillion,
  OverSevenMillion,
  Custom,
}

interface ProductionRangeProps {
  title?: string;
  enabledFilters: QuickProductionFilter[];
  disabled?: boolean;
  defaultFilter?: QuickProductionFilter | null;
  setProduction(
    startProductionValue: number | 0,
    endProductionValue: number | 0,
    selectedFilter: QuickProductionFilter | null
  ): void;
}

export function FilterProductionRange({
  title,
  enabledFilters,
  disabled = false,
  defaultFilter,
  setProduction,
}: ProductionRangeProps): React.ReactElement {
  const [filterSelection, setFilterSelection] = useState<null | QuickProductionFilter>(
    defaultFilter ?? null
  );
  const [startProductionValue, setStartProductionValue] = useState<number | 0>(0);
  const [endProductionValue, setEndProductionValue] = useState<number | 0>(0);

  const getLabel = (filter: QuickProductionFilter): string => {
    if (filter === QuickProductionFilter.OneMillion) return '$0-1 Million';
    else if (filter === QuickProductionFilter.TwoMillion) return '$1,000,001-$2,500,000';
    else if (filter === QuickProductionFilter.FiveMillion) return '$2,500,001-$5,000,000';
    else if (filter === QuickProductionFilter.SevenMillion) return '$5,000,001-$7,500,000';
    else if (filter === QuickProductionFilter.OverSevenMillion) return 'Over $7,500,001';
    else if (filter === QuickProductionFilter.Custom) return 'Custom';
    return '';
  };

  useEffect(() => {
    if (filterSelection !== null)
      setProduction(startProductionValue, endProductionValue, filterSelection);
  }, [startProductionValue, endProductionValue, filterSelection]);

  const onSelection = useCallback((filter: QuickProductionFilter): void => {
    setEndProductionValue(0);

    if (filter === QuickProductionFilter.Custom) setStartProductionValue(0);
    else if (filter === QuickProductionFilter.OneMillion) {
      setStartProductionValue(0);
      setEndProductionValue(1000000);
    } else if (filter === QuickProductionFilter.TwoMillion) {
      setStartProductionValue(1000001);
      setEndProductionValue(2500000);
    } else if (filter === QuickProductionFilter.FiveMillion) {
      setStartProductionValue(2500001);
      setEndProductionValue(5000000);
    } else if (filter === QuickProductionFilter.SevenMillion) {
      setStartProductionValue(5000001);
      setEndProductionValue(7500000);
    } else if (filter === QuickProductionFilter.OverSevenMillion) {
      setStartProductionValue(7500001);
      setEndProductionValue(100000000000);
    }
    setFilterSelection(filter);
  }, []);
  return (
    <>
      <Grid container spacing={2} justifyContent="space-between">
        {title ? (
          <Grid item>
            <Typography variant="subtitle" align="center">
              {title}
            </Typography>
          </Grid>
        ) : null}
        <Grid item md={12}>
          <RadioGroup
            value={filterSelection}
            onChange={event => onSelection(Number(event.target.value) as unknown as QuickProductionFilter)}
          >
             {enabledFilters.map(filter => (
               <FormControlLabel key={filter} value={filter} control={<Radio />} label={getLabel(filter)} />
             ))}
          </RadioGroup>
        </Grid>
      </Grid>

      {filterSelection === QuickProductionFilter.Custom ? (
        <Grid container spacing={2}>
          <Grid item md={12}></Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              name="startProductionValue"
              label="Start Value"
              onChange={e => {
                setStartProductionValue(Number(e.target.value));
              }}
              startAdornment={<InputAdornment position="start">$</InputAdornment>}
              _component={CustomCurrencyInputMasker as any}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              name="endProductionValue"
              label="End Value"
              onChange={e => {
                setEndProductionValue(Number(e.target.value));
              }}
              startAdornment={<InputAdornment position="start">$</InputAdornment>}
              _component={CustomCurrencyInputMasker as any}
            />
          </Grid>
        </Grid>
      ) : null}
    </>
  );
}

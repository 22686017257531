import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { applyTransaction } from '@datorama/akita';

import { NotificationSettingsForm, RecruitingGoalForm } from './manager.models';
import { ManagerStore, managerStore } from './manager.store';
import { ManagerForms } from './manager.forms';
import { dispatchForm } from '../forms';
import { OfficeStore, officeStore } from '../offices';

import * as Zsg from 'api/app';
import { UpdateRecruitingGoalRequest } from '../../api/app';

export class ManagerService {
  constructor(
    private readonly store: ManagerStore,
    private readonly officeStore: OfficeStore,
    private readonly managersApi: Zsg.ManagersApi,
    private readonly recruitingApi: Zsg.RecruitingApi
  ) {}

  getManagers() {
    from(this.managersApi.managersGet())
      .pipe(map(({ data }) => data))
      .subscribe(({ data, offices }) =>
        applyTransaction(() => {
          this.officeStore.upsertMany(offices);
          this.store.upsertMany(data);
        })
      );
  }

  getManager(managerId: string) {
    from(this.managersApi.managersIdGet(managerId))
      .pipe(map(({ data }) => data.data))
      .subscribe(active =>
        applyTransaction(() => {
          this.store.upsert(active.id, active);
          this.store.setActive(active.id);
        })
      );
  }

  setActiveManagerId(managerId?: string) {
    this.store.setActive(managerId);
  }

  updateRecruitingGoal(goalForm: RecruitingGoalForm) {
    from(
      this.recruitingApi.recruitingManagerIdGoalYearPost(
        goalForm.managerId,
        goalForm.year,
        goalForm as UpdateRecruitingGoalRequest
      )
    )
      .pipe(
        map(x => x.data.data),
        dispatchForm(ManagerForms.RecruitingGoal)
      )
      .subscribe(recruitingGoal =>
        applyTransaction(() => {
          this.store.updateActive({ recruitingGoal });
        })
      );
  }

  changeNotificationSettings(form: NotificationSettingsForm) {
    from(this.managersApi.managersNotificationsPost(form))
      .pipe(
        map(x => x.data),
        dispatchForm(ManagerForms.NotificationSettings)
      )
      .subscribe(settings =>
        applyTransaction(() => {
          this.store.updateActive(settings);
        })
      );
  }
}

export const managerService = new ManagerService(
  managerStore,
  officeStore,
  new Zsg.ManagersApi(),
  new Zsg.RecruitingApi()
);

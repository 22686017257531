export enum FormStatus {
  Initial = 'initial',
  Pending = 'pending',
  Success = 'success',
  Error = 'error',
}

export interface FormEvent {
  readonly formId: string;

  readonly status: FormStatus;

  readonly error?: Error;

  readonly submitted: boolean;
}

export interface FormEventResponse<T = any> extends FormEvent {
  readonly response?: T;
}

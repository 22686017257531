import React, { useState } from 'react';
import { PageHeader, PeriodPicker, OfficePicker, useObservable, PaginationProps, icons } from '../../shared';
import { DateRangeOptions, officeQuery } from '../../state';
import { CobrokeList } from '../components';
import { Grid } from '@mui/material';
import { useLocation } from "react-router-dom";

export const CobrokePage = () => {
  const activeOfficeCodes = useObservable(officeQuery.activeOfficeCodes);
  const location = useLocation<PaginationProps>();

  const [dateRange, setDateRange] = useState(() => {
    return location.state?.dateRange ?? DateRangeOptions.Last365Days;
  });

  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 25,
    page: 0,
  });

  const updateDateRange = (newRange: DateRangeOptions) => {
    setPaginationModel(prevPagModel => ({...prevPagModel, page: 0}));
    setDateRange(newRange);
  };

  return (
    <>
      <PageHeader
        title="CO-BROKE SALES"
        icon={icons.searchGlass}
        filters={[
          <PeriodPicker activeRange={dateRange} updateRange={updateDateRange} />,
          <OfficePicker />,
        ]}
      />
      <Grid container spacing={2}>
        <Grid item xs>
          <CobrokeList
            dateRange={dateRange}
            feedKeys={activeOfficeCodes}
            paginationModel={paginationModel}
            onPaginationModelChange={(newPaginationModel) => setPaginationModel(newPaginationModel)}
          />
        </Grid>
      </Grid>
    </>
  );
};

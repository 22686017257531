import { StoreConfig, ActiveState, EntityState, EntityStore } from '@datorama/akita';

import { Manager } from './manager.models';

export interface ManagerState
  extends EntityState<Manager, Manager['id']>,
    ActiveState<Manager['id']> {}

const initialState: ManagerState = {
  active: null,
};

@StoreConfig({ name: 'manager', resettable: true })
export class ManagerStore extends EntityStore<ManagerState> {
  constructor() {
    super(initialState);
  }
}

export const managerStore = new ManagerStore();

import React, { useEffect } from 'react';
import { deferred, useObservable } from '..';
import { mlsQuery, mlsService } from '../../state';
import { VolumeSummaryTooltip } from './volume-summary-tooltip';

interface Props {
  companyKey: string;
  officeName: string;
}

const CompanyVolume: React.FC<Props> = ({ companyKey, officeName }) => {
  const companyVolumes = useObservable(mlsQuery.companyVolumeSummaries);
  const volume = companyVolumes.find(v => v.officeKey === companyKey); //Office key can be the Main officekey also
  useEffect(() => mlsService.getCompanyVolumeSummary(companyKey), [companyKey]);

  if (!volume?.periods) {
    return null;
  }

  return <VolumeSummaryTooltip title={officeName} periods={volume.periods} />;
};

export const CompanyVolumeSummaryTooltip = deferred(CompanyVolume);

import { EntityStore } from '@datorama/akita';
import { Observable } from 'rxjs';
import { setLoading } from '@datorama/akita';
import { makeCancellable } from '.';

/**
 * This function should be used in place of `setLoading` when using a cancellable request.
 * @param store The entity store the request is using to store data
 * @see {@link setLoading}
 * @see {@link makeCancellable}
 */
export function determineStoreLoading<T>(
  store: EntityStore<any>
): (source: Observable<T>) => Observable<T> {
  return (source: Observable<T>) => {
    return new Observable<T>(observer => {
      let lastValue: T;
      store.setLoading(true);
      return source.pipe().subscribe({
        next(value: T) {
          observer.next((lastValue = value));
        },
        error(err) {
          store.reset();
          if (err !== 'cancelled') store.setLoading(false);
          observer.error(err);
        },
        complete() {
          store.setLoading(false);
          observer.complete();
        },
      });
    });
  };
}

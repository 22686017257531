import { QueryEntity } from '@datorama/akita';
import { switchMap } from 'rxjs/operators';

import { ManagersState, managersStore } from './managers.store';

export class ManagersQuery extends QueryEntity<ManagersState> {
  activeManager = this.selectActive();
  managers = this.selectAll();

  companyManagers = this.select(({ ui }) => ui).pipe(
    switchMap(({ companyId }) =>
      this.selectAll({
        filterBy: m => m.companyId === companyId,
      })
    )
  );
}

export const managersQuery = new ManagersQuery(managersStore);

import React from 'react';
import { Button, ButtonProps } from 'shared/components/button';
import { ErrorDisplay } from '../../shared/forms';
import { SuccessDisplay } from '../../shared/forms/SuccessDisplay';
import { FormStatus } from '../../state';


interface Props extends Omit<ButtonProps, 'action' | 'onClick'> {
  error?: Error;
  status: FormStatus;
  handleResetPassword: () => void;
}

export const ResetPasswordForm: React.FC<Props> = ({
  error,
  status,
  handleResetPassword,
  ...props
}) => {
  return (
    <>
      <Button {...props} disabled={status === FormStatus.Pending} onClick={handleResetPassword}>
        Reset Password
      </Button>
      <ErrorDisplay error={error} errorMessage="An error has occurred. Please try again." />
      <SuccessDisplay
        display={status === FormStatus.Success || false}
        successMessage="An email has been sent to the user's email with instructions on how to reset their password."
      />
    </>
  );
};

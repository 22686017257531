import { Query, selectPersistStateInit } from '@datorama/akita';
import { map, switchMapTo } from 'rxjs/operators';
import { RoleTypes } from 'api/auth/model/role-types';

import { SessionState, sessionStore } from './session.store';

export class SessionQuery extends Query<SessionState> {
  isLoggedIn = selectPersistStateInit().pipe(switchMapTo(this.select(x => x.auth.authenticated)));

  currentUser = this.select(x => x.user);

  /**
   * Gets a user's normalized roles as an array.
   * Identity Server returns either a string or an array of strings
   * representing a user's roles. The latter being if they have more than one role
   */
  getUserRoles = this.select(x => x.user?.role).pipe(
    map(role => {
      const roles = Array<RoleTypes>();
      if (!role) return roles;
      if (Array.isArray(role)) {
        role.forEach(r => {
          roles.push(r as RoleTypes);
        });
      } else {
        roles.push(role as RoleTypes);
      }
      return roles;
    })
  );

  isActiveUser = this.select(x => x.isActiveUser);

  isKvCoreUser = this.select(x => x.isKvCoreUser);

  isAdmin = this.getUserRoles.pipe(
    map(ur => ur.some(r => [RoleTypes.Developer, RoleTypes.SysAdmin].indexOf(r) >= 0))
  );

  isCompanyAccountActive = this.select(x => x.companyData?.accountStatus !== 'Cancelled' ?? false);

  currentCompany = this.select(x => x.companyData);

  isImpersonating = this.select(x => x.auth.isImpersonating);
}

export const sessionQuery = new SessionQuery(sessionStore);

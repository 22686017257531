import { EntityState, EntityStore, StoreConfig, ActiveState } from '@datorama/akita';

import { Manager } from './managers.models';

export interface ManagersState
  extends EntityState<Manager, Manager['id']>,
    ActiveState<Manager['id']> {
  ui: {
    companyId?: number;
  };
}

@StoreConfig({ name: 'managers', resettable: true })
export class ManagersStore extends EntityStore<ManagersState> {
  constructor() {
    super({ active: null, ui: { companyId: undefined } });
  }
}

export const managersStore = new ManagersStore();

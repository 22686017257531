/* eslint-disable */
// tslint:disable
/**
 * MLS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';

import { AgentSearchResponse } from '../model';
import { CompanySearchResponse } from '../model';
import { GetSearchOptionsRequest } from '../model';
import { GetSearchOptionsResponse } from '../model';
import { OfficeSearchResponse } from '../model';
import { SearchRequest } from '../model';
/**
 * SearchApi - axios parameter creator
 * @export
 */
export const SearchApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {SearchRequest} [searchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsSearchAgentsExportPost(searchRequest?: SearchRequest, options: any = {}): RequestArgs {
      const localVarPath = `/mls/search/agents/export`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      //@ts-ignore
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof searchRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(searchRequest !== undefined ? searchRequest : {})
        : searchRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {SearchRequest} [searchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsSearchAgentsPost(searchRequest?: SearchRequest, options: any = {}): RequestArgs {
      const localVarPath = `/mls/search/agents`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      //@ts-ignore
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof searchRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(searchRequest !== undefined ? searchRequest : {})
        : searchRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {SearchRequest} [searchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsSearchCompaniesExportPost(searchRequest?: SearchRequest, options: any = {}): RequestArgs {
      const localVarPath = `/mls/search/companies/export`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      //@ts-ignore
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof searchRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(searchRequest !== undefined ? searchRequest : {})
        : searchRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {SearchRequest} [searchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsSearchCompaniesPost(searchRequest?: SearchRequest, options: any = {}): RequestArgs {
      const localVarPath = `/mls/search/companies`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      //@ts-ignore
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof searchRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(searchRequest !== undefined ? searchRequest : {})
        : searchRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {SearchRequest} [searchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsSearchOfficesExportPost(searchRequest?: SearchRequest, options: any = {}): RequestArgs {
      const localVarPath = `/mls/search/offices/export`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      //@ts-ignore
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof searchRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(searchRequest !== undefined ? searchRequest : {})
        : searchRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {SearchRequest} [searchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsSearchOfficesPost(searchRequest?: SearchRequest, options: any = {}): RequestArgs {
      const localVarPath = `/mls/search/offices`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      //@ts-ignore
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof searchRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(searchRequest !== undefined ? searchRequest : {})
        : searchRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {GetSearchOptionsRequest} [getSearchOptionsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsSearchOptionsPost(
      getSearchOptionsRequest?: GetSearchOptionsRequest,
      options: any = {}
    ): RequestArgs {
      const localVarPath = `/mls/search/options`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      //@ts-ignore
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof getSearchOptionsRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(getSearchOptionsRequest !== undefined ? getSearchOptionsRequest : {})
        : getSearchOptionsRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SearchApi - functional programming interface
 * @export
 */
export const SearchApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {SearchRequest} [searchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsSearchAgentsExportPost(
      searchRequest?: SearchRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = SearchApiAxiosParamCreator(configuration).mlsSearchAgentsExportPost(
        searchRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {SearchRequest} [searchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsSearchAgentsPost(
      searchRequest?: SearchRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentSearchResponse> {
      const localVarAxiosArgs = SearchApiAxiosParamCreator(configuration).mlsSearchAgentsPost(
        searchRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {SearchRequest} [searchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsSearchCompaniesExportPost(
      searchRequest?: SearchRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = SearchApiAxiosParamCreator(
        configuration
      ).mlsSearchCompaniesExportPost(searchRequest, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {SearchRequest} [searchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsSearchCompaniesPost(
      searchRequest?: SearchRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanySearchResponse> {
      const localVarAxiosArgs = SearchApiAxiosParamCreator(configuration).mlsSearchCompaniesPost(
        searchRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {SearchRequest} [searchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsSearchOfficesExportPost(
      searchRequest?: SearchRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = SearchApiAxiosParamCreator(
        configuration
      ).mlsSearchOfficesExportPost(searchRequest, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {SearchRequest} [searchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsSearchOfficesPost(
      searchRequest?: SearchRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfficeSearchResponse> {
      const localVarAxiosArgs = SearchApiAxiosParamCreator(configuration).mlsSearchOfficesPost(
        searchRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {GetSearchOptionsRequest} [getSearchOptionsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsSearchOptionsPost(
      getSearchOptionsRequest?: GetSearchOptionsRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSearchOptionsResponse> {
      const localVarAxiosArgs = SearchApiAxiosParamCreator(configuration).mlsSearchOptionsPost(
        getSearchOptionsRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * SearchApi - factory interface
 * @export
 */
export const SearchApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {SearchRequest} [searchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsSearchAgentsExportPost(searchRequest?: SearchRequest, options?: any): AxiosPromise<void> {
      return SearchApiFp(configuration).mlsSearchAgentsExportPost(searchRequest, options)(
        axios,
        basePath
      );
    },
    /**
     *
     * @param {SearchRequest} [searchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsSearchAgentsPost(
      searchRequest?: SearchRequest,
      options?: any
    ): AxiosPromise<AgentSearchResponse> {
      return SearchApiFp(configuration).mlsSearchAgentsPost(searchRequest, options)(
        axios,
        basePath
      );
    },
    /**
     *
     * @param {SearchRequest} [searchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsSearchCompaniesExportPost(searchRequest?: SearchRequest, options?: any): AxiosPromise<void> {
      return SearchApiFp(configuration).mlsSearchCompaniesExportPost(searchRequest, options)(
        axios,
        basePath
      );
    },
    /**
     *
     * @param {SearchRequest} [searchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsSearchCompaniesPost(
      searchRequest?: SearchRequest,
      options?: any
    ): AxiosPromise<CompanySearchResponse> {
      return SearchApiFp(configuration).mlsSearchCompaniesPost(searchRequest, options)(
        axios,
        basePath
      );
    },
    /**
     *
     * @param {SearchRequest} [searchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsSearchOfficesExportPost(searchRequest?: SearchRequest, options?: any): AxiosPromise<void> {
      return SearchApiFp(configuration).mlsSearchOfficesExportPost(searchRequest, options)(
        axios,
        basePath
      );
    },
    /**
     *
     * @param {SearchRequest} [searchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsSearchOfficesPost(
      searchRequest?: SearchRequest,
      options?: any
    ): AxiosPromise<OfficeSearchResponse> {
      return SearchApiFp(configuration).mlsSearchOfficesPost(searchRequest, options)(
        axios,
        basePath
      );
    },
    /**
     *
     * @param {GetSearchOptionsRequest} [getSearchOptionsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsSearchOptionsPost(
      getSearchOptionsRequest?: GetSearchOptionsRequest,
      options?: any
    ): AxiosPromise<GetSearchOptionsResponse> {
      return SearchApiFp(configuration).mlsSearchOptionsPost(getSearchOptionsRequest, options)(
        axios,
        basePath
      );
    },
  };
};

/**
 * SearchApi - object-oriented interface
 * @export
 * @class SearchApi
 * @extends {BaseAPI}
 */
export class SearchApi extends BaseAPI {
  /**
   *
   * @param {SearchRequest} [searchRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SearchApi
   */
  public mlsSearchAgentsExportPost(searchRequest?: SearchRequest, options?: any) {
    return SearchApiFp(this.configuration).mlsSearchAgentsExportPost(searchRequest, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {SearchRequest} [searchRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SearchApi
   */
  public mlsSearchAgentsPost(searchRequest?: SearchRequest, options?: any) {
    return SearchApiFp(this.configuration).mlsSearchAgentsPost(searchRequest, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {SearchRequest} [searchRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SearchApi
   */
  public mlsSearchCompaniesExportPost(searchRequest?: SearchRequest, options?: any) {
    return SearchApiFp(this.configuration).mlsSearchCompaniesExportPost(searchRequest, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {SearchRequest} [searchRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SearchApi
   */
  public mlsSearchCompaniesPost(searchRequest?: SearchRequest, options?: any) {
    return SearchApiFp(this.configuration).mlsSearchCompaniesPost(searchRequest, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {SearchRequest} [searchRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SearchApi
   */
  public mlsSearchOfficesExportPost(searchRequest?: SearchRequest, options?: any) {
    return SearchApiFp(this.configuration).mlsSearchOfficesExportPost(searchRequest, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {SearchRequest} [searchRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SearchApi
   */
  public mlsSearchOfficesPost(searchRequest?: SearchRequest, options?: any) {
    return SearchApiFp(this.configuration).mlsSearchOfficesPost(searchRequest, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {GetSearchOptionsRequest} [getSearchOptionsRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SearchApi
   */
  public mlsSearchOptionsPost(getSearchOptionsRequest?: GetSearchOptionsRequest, options?: any) {
    return SearchApiFp(this.configuration).mlsSearchOptionsPost(getSearchOptionsRequest, options)(
      this.axios,
      this.basePath
    );
  }
}

import {from} from "rxjs";
import {applyTransaction, setLoading} from "@datorama/akita";
import {map} from "rxjs/operators";
import { Mls } from '../../api';
import { FeedStore, feedStore } from './feeds.store';

export class FeedsService {
  constructor(
    private readonly api: Mls.FeedsApi,
    private readonly store: FeedStore
  ) {}

  getAllFeeds() {
    from(this.api.mlsFeedsGet())
    .pipe(
      setLoading(feedStore),
      map(response => response.data.data)
    )
    .subscribe(data =>
      applyTransaction(() => {
        this.store.set({ ...data });
      })
    );
  }

  updateFeed(id: number, feed: Mls.UpdateFeedRequest) {
    from(this.api.mlsFeedsIdPost(id, feed))
    .pipe(
      map(x => x.data),
    )
    .subscribe(({ feed }) =>
      applyTransaction(() => {
        this.store.upsert(feed.id, feed);
      })
    );
  }
}

export const feedsService = new FeedsService(new Mls.FeedsApi(), feedStore);

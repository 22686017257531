import { EntityState, EntityStore, StoreConfig, ActiveState } from '@datorama/akita';

import { Company } from './companies.models';

export interface CompaniesState
  extends EntityState<Company, Company['id']>,
    ActiveState<Company['id']> {
  ui: {};
}

@StoreConfig({ name: 'companies', resettable: true })
export class CompaniesStore extends EntityStore<CompaniesState> {
  constructor() {
    super({ active: null, ui: {} });
  }
}

export const companiesStore = new CompaniesStore();

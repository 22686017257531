import React from 'react';

import { FormControl, MenuItem, Select } from '@mui/material';
import { DateRangeOptions } from '../../state/mls/date-filter';

const dateRangeOptions = Object.values(DateRangeOptions);

export interface Props {
  activeRange?: DateRangeOptions;
  updateRange: (newRange: DateRangeOptions) => void;
}

export const PeriodPicker: React.FC<Props> = ({
  activeRange = DateRangeOptions.Last365Days,
  updateRange,
}) => {
  return (
    <FormControl>
      <Select
        variant="outlined"
        value={activeRange}
        onChange={$event => updateRange($event.target.value as DateRangeOptions)}

      >
        {dateRangeOptions.map(o => (
          <MenuItem key={o} value={o}>
            {o}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

import React, { useState, useLayoutEffect } from 'react';
import { Fade } from '@mui/material';
import { AppConfig } from '../../config';
import { LoadingIndicator } from 'shared/components/loading-indicator';

interface Props {
  isContentLoading: boolean;
  content?: () => React.ReactNode;
}

/**
 * This component wraps the material-ui &lt;Fade&gt; component,
 * and via the isContentLoading
 * paramter, will fade in content when isContentLoading=true.
 *
 * Note: the &lt;div&gt; is made to be the direct child of &lt;Fade&gt;
 * because of https://stackoverflow.com/questions/57078732/material-ui-fade-component-does-not-show-hide-or-fade-components/57081211
 * @param {boolean} isContentLoading - will fade in contents/children when the parameter is true
 */
export const LoadingFade: React.FC<Props> = ({ isContentLoading, content, children }) => {
  // always start hidden to avoid jank
  const [hidden, setHidden] = useState(true);

  useLayoutEffect(() => {
    if (hidden === isContentLoading) return;

    if (isContentLoading) {
      // immediately hide
      setHidden(true);
    } else {
      // debounce unhide
      const handle = setTimeout(() => setHidden(false), AppConfig.FadeLoadingTimeout / 2);
      return () => clearTimeout(handle);
    }
  }, [hidden, isContentLoading]);

  if (hidden) {
    return <LoadingIndicator loadingText="Loading..." />;
  }

  if (!!content) {
    return (
      <Fade in={!hidden} timeout={AppConfig.FadeLoadingTimeout}>
        <div style={{ width: '100%' }}>{!hidden && content()}</div>
      </Fade>
    );
  }

  return (
    <Fade in={!hidden} timeout={AppConfig.FadeLoadingTimeout}>
      <div style={{ width: '100%' }}>{children}</div>
    </Fade>
  );
};

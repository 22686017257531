import { RefObject, ChangeEventHandler, ReactNode } from 'react';
import { Button } from 'shared/components/button';

interface FileUploadButtonProps {
  id?: string;
  accept?: string;
  children?: ReactNode;
  inputFieldRef?: RefObject<HTMLInputElement>;
  onChange?: ChangeEventHandler<HTMLInputElement>;
}

export function FileUploadButton({
  id,
  accept,
  children,
  inputFieldRef,
  onChange,
}: FileUploadButtonProps) {
  return (
    <Button type="button" _component="label">
      {children}
      <input type="file" id={id} accept={accept} onChange={onChange} ref={inputFieldRef} hidden />
    </Button>
  );
}

import React from 'react';
import { AgentList } from 'retention/components';
import { Grid } from '@mui/material';
import {
  MarketStats,
  useObservable,
  PeriodPicker,
  useFavorites,
  useLoadingIndicator,
  PageHeader,
  useOfficeVolume,
  OfficePicker,
  icons,
} from '../../shared';
import { officeQuery, mlsService, mlsQuery, DateRangeOptions } from '../../state';
import { SalesVolume, SalesMap } from '../components';
import { withManagerId } from '../../shared/utils/withManagerId';
import { LoadingFade } from '../../shared/components/loading-fade';

interface Props {
  managerId: string;
}

const Overview: React.FC<Props> = ({ managerId }) => {
  let loading = useLoadingIndicator();
  const officeCodes = useObservable(officeQuery.activeOfficeCodes);

  const dateRange = useObservable(mlsQuery.activeDateRange);
  const updateDateRange = (newRange: DateRangeOptions) => {
    mlsService.setActiveDateRange(newRange);
  };

  const { agents, agentVolumes, offices, marketStats, listings } = useOfficeVolume(officeCodes);

  const { isFavorite, toggleFavorite } = useFavorites(managerId);

  return (
    <>
      <PageHeader
        title="AGENT RETENTION"
        icon={icons.dollar}
        filters={[
          <PeriodPicker activeRange={dateRange} updateRange={updateDateRange} />,
          <OfficePicker />,
        ]}
      />

      <LoadingFade
        isContentLoading={loading}
        content={() => (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <MarketStats label="Office" volume={marketStats} />
            </Grid>
            <Grid item xs={12}>
              <SalesVolume volume={marketStats} hideTransactionTile />
            </Grid>
            <Grid item xs={12}>
              <AgentList
                agents={agents}
                volume={agentVolumes}
                toggleFavorite={toggleFavorite}
                isFavorite={isFavorite}
              />
            </Grid>

            <Grid item xs={12}>
              <SalesMap listings={listings} offices={offices} />
            </Grid>
          </Grid>
        )}
      />
    </>
  );
};

export const OverviewPage = withManagerId(Overview);

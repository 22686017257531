import { Dialog, DialogTitle, DialogContent, LoadingFade } from 'shared/components';
import { useAnswers, useQuestions } from 'surveys/hooks';
import { AnswerList } from './answer-list';

export function SubmissionDetailsDialog({
  submissionId,
  surveyId,
  onClose,
}: {
  submissionId: number;
  surveyId: number;
  onClose: () => void;
}) {
  const { answers, loading: isAnswersLoading } = useAnswers(submissionId);
  const { questions, loading: isQuestionsLoading } = useQuestions(surveyId);

  return (
    <Dialog isOpen={true} onClose={() => onClose()} fullWidth>
      <DialogTitle>Survey results</DialogTitle>
      <DialogContent>
        <LoadingFade isContentLoading={isAnswersLoading && isQuestionsLoading}>
          <AnswerList questions={questions} answers={answers} />
        </LoadingFade>
      </DialogContent>
    </Dialog>
  );
}

import { StoreConfig } from '@datorama/akita';
import { ResettableStore } from '../../util';
import { getDefaultMarketShare, MarketShare } from './market.models';

export interface MarketState {
  market: MarketShare;
  office: MarketShare;
}

export const initialState: MarketState = {
  market: getDefaultMarketShare(),
  office: getDefaultMarketShare(),
};

@StoreConfig({ name: 'market', resettable: true })
export class MarketStore extends ResettableStore<MarketState> {
  constructor() {
    super(initialState);
  }
}

export const marketStore = new MarketStore();

import { Rating } from '@mui/material';
import React from 'react';

interface Props {
  totalPoints: number;
  possiblePoints: number;
}

export function SubmissionScore({ totalPoints, possiblePoints }: Props) {
  const totalRating = totalPoints / (possiblePoints / 5);

  return <Rating value={totalRating} precision={0.5} readOnly />;
}

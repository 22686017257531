import React, { useEffect, useState } from 'react';
import { Button, ButtonProps } from './button';
import { Typography } from './typography';
import { ConfirmationDialog } from './confirmation-dialog';
import { ErrorDisplay } from 'shared/forms/ErrorDisplay';
import { FormStatus } from '../../state';

interface Props extends Omit<ButtonProps, 'action' | 'onClick'> {
  action: string;
  onConfirm: () => void;
  status: FormStatus;
  error: Error | undefined;
  buttonText?: string;
}

export const ConfirmedButton: React.FC<Props> = ({
  action,
  onConfirm,
  buttonText,
  status,
  error,
  children,
  ...props
}) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (status === FormStatus.Success) setOpen(false);
  }, [status]);

  const onCancel = () => {
    setOpen(false);
  };

  return (
    <>
      <Button type="button" onClick={() => setOpen(true)} {...props}>
        {children ?? buttonText ?? action}
      </Button>
      <ConfirmationDialog
        isOpen={open}
        title={action}
        onConfirm={onConfirm}
        isPending={!!status && status === FormStatus.Pending}
        onCancel={onCancel}
        onClose={() => setOpen(false)}
      >
        <>
          <ErrorDisplay error={error} />
          <Typography>Are you sure you want to {action}?</Typography>
        </>
      </ConfirmationDialog>
    </>
  );
};

import React, { useCallback, useEffect, useState } from 'react';
import {
  Grid,
  RadioGroup,
  Radio,
  FormControlLabel,
  Card,
} from '@mui/material';
import { Button, Typography, TextField } from 'shared/components';
import { withStyles } from '@mui/styles';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { FeedModel, OfficeModel, SalesChange, SearchType } from '../../api/mls';

import {
  FormProps,
  useInput,
  useForm,
  ErrorDisplay,
  useBooleanInput,
} from '../../shared/forms';
import {
  SearchForm,
  FilterOptions,
  dateFormat,
  getOfficeDisplayName,
  emptyForm,
} from '../../state/search';
import { format, startOfToday, addMonths, addDays, addYears } from 'date-fns';
import { ReportType, Filter } from '../../state/search/reportType';
import { FilterGroup } from './filter-group';
import { Picker } from './filter-picker';
import { Datepicker } from 'shared/components/datepicker';
import { FilterDateRange, QuickFilter } from './filter-date-range';
import { FilterProductionRange, QuickProductionFilter } from './filter-production-range';
import { DayFilter, FilterHotList, HotListType } from './filter-hotlist';
import {
  startDate as startDateValidator,
  endDate as endDateValidator,
  feedSelected,
} from '../../shared/forms/validators';

interface Props extends FormProps<SearchForm> {
  searchForm: SearchForm;
  setForm(form: Partial<SearchForm>, reloadOptions?: boolean): void;
  activeFilterOptions?: FilterOptions;
  reportType: ReportType;
}


export const AgentSearch: React.FC<Props> = ({
  searchForm,
  setForm,
  activeFilterOptions = {
    feeds: [],
    boards: [],
    brokers: [],
    branches: [],
    areas: [],
    cities: [],
    counties: [],
    propertyTypes: [],
    schoolDistricts: [],
  },
  reportType,
  pending,
  error,
  onSubmit,
  onResetFeedback,
}) => {
  const {
    feeds,
    boards,
    brokers,
    branches,
    counties,
    areas,
    cities,
    schoolDistricts: districts,
    propertyTypes,
  } = activeFilterOptions;

  const startDate = useInput(searchForm.startDate, {
    validators: [startDateValidator(searchForm.endDate)],
    onChange: value => setForm({ startDate: value }),
  });
  const endDate = useInput(searchForm.endDate, {
    validators: [endDateValidator(searchForm.startDate)],
    onChange: value => setForm({ endDate: value }),
  });

  const newlyLicensedStartDate = useInput(searchForm.newlyLicensedStartDate, {
    validators: [],
    onChange: value => setForm({ newlyLicensedStartDate: value }),
  });
  const newlyLicensedEndDate = useInput(searchForm.newlyLicensedEndDate, {
    validators: [],
    onChange: value => setForm({ newlyLicensedEndDate: value }),
  });

  const productionStartValue = useInput(searchForm.productionStartValue, {
    validators: [],
    onChange: value => setForm({ productionStartValue: value }),
  });
  const productionEndValue = useInput(searchForm.productionEndValue, {
    validators: [],
    onChange: value => setForm({ productionEndValue: value }),
  });

  const newlyMovedStartDate = useInput(searchForm.newlyMovedStartDate, {
    validators: [],
    onChange: value => setForm({ newlyMovedStartDate: value }),
  });
  const newlyMovedEndDate = useInput(searchForm.newlyMovedEndDate, {
    validators: [],
    onChange: value => setForm({ newlyMovedEndDate: value }),
  });

  const initialFeedsValue = (!searchForm?.feed || searchForm.feed.length <= 0) && feeds.length === 1
    ? feeds.map(f => String(f.id))
    : searchForm.feed;
  const feed = useInput(initialFeedsValue, {
    onChange: value => setForm({ feed: value }, true),
    validators: [feedSelected()],
  });

  const board = useInput(searchForm.board, {
    validators: [],
    onChange: value => setForm({ board: value }, true),
  });
  const broker = useInput(searchForm.broker, {
    validators: [],
    onChange: value => handleBrokerValueChange(value, searchForm.branch),
  });
  const branch = useInput(searchForm.branch, {
    validators: [],
    onChange: value => handleBrokerValueChange(searchForm.broker, value),
  });
  const county = useInput(searchForm.county, {
    validators: [],
    onChange: value => setForm({ county: value }, true),
  });
  const area = useInput(searchForm.area, {
    validators: [],
    onChange: value => setForm({ area: value }, true),
  });
  const city = useInput(searchForm.city, {
    validators: [],
    onChange: value => setForm({ city: value }, true),
  });
  const postalCode = useInput(searchForm.postalCode, {
    validators: [],
    onChange: value => setForm({ postalCode: value }, true),
  });
  const district = useInput(searchForm.schoolDistrict, {
    validators: [],
    onChange: value => setForm({ schoolDistrict: value }, true),
  });
  const propertyType = useInput(searchForm.propertyType, {
    validators: [],
    onChange: value => setForm({ propertyType: value }, true),
  });
  const agentSearchTerm = useInput(searchForm.agentSearchTerm, {
    onChange: value => setForm({ agentSearchTerm: value }),
  });

  const productionFilterValue = useInput(searchForm.productionFilterValue, {
    onChange: value => setForm({ productionFilterValue: value }),
  });

  const agentHistoryFilterValue = useInput(searchForm.agentHistoryFilterValue, {
    validators: [],
    onChange: value => setForm({ agentHistoryFilterValue: value }),
  });

  const agentHistoryDateFilterValue = useInput(searchForm.agentHistoryDateFilterValue, {
    validators: [],
    onChange: value => setForm({ agentHistoryDateFilterValue: value }),
  });

  const hasActiveOrPendingListings = useBooleanInput(searchForm.hasActiveOrPendingListings, {
    validators: [],
    onChange: value => setForm({ hasActiveOrPendingListings: convertToBoolean(value) }),
  });

  const daysSinceLastClose = useInput(searchForm.daysSinceLastClose, {
    validators: [],
    onChange: value => setForm({ daysSinceLastClose: value }),
  });

  const percentageChange = useInput(searchForm.percentageChange, {
    validators: [],
    onChange: value => setForm({ percentageChange: value }),
  });

  const salesChange = useInput(searchForm.salesChange, {
    validators: [],
    onChange: value => setForm({ salesChange: value }),
  });

  const hotListType = useInput(searchForm.hotListType, {
    validators: [],
    onChange: value => setForm({ hotListType: value }),
  });

  const form = useForm(
    () => {
      if (!form.valid) return;

      onResetFeedback();
      onSubmit({
        startDate: startDate.value,
        endDate: endDate.value,
        newlyLicensedStartDate: newlyLicensedStartDate.value,
        newlyLicensedEndDate: newlyLicensedEndDate.value,
        newlyMovedStartDate: newlyMovedStartDate.value,
        newlyMovedEndDate: newlyMovedEndDate.value,
        feed: feed.value,
        board: board.value,
        broker: broker.value,
        branch: branch.value,
        county: county.value,
        area: area.value,
        city: city.value,
        postalCode: postalCode.value,
        schoolDistrict: district.value,
        propertyType: propertyType.value,
        agentSearchTerm: agentSearchTerm.value,
        productionStartValue: productionStartValue.value,
        productionEndValue: productionEndValue.value,
        hasActiveOrPendingListings: convertToBoolean(hasActiveOrPendingListings.value),
        daysSinceLastClose: daysSinceLastClose.value,
        percentageChange: percentageChange.value,
        salesChange: salesChange.value,
      });
    },
    startDate,
    endDate,
    newlyLicensedStartDate,
    newlyLicensedEndDate,
    newlyMovedStartDate,
    newlyMovedEndDate,
    feed,
    board,
    broker,
    branch,
    county,
    area,
    city,
    postalCode,
    district,
    propertyType,
    agentSearchTerm,
    productionStartValue,
    productionEndValue,
    daysSinceLastClose,
    percentageChange,
    salesChange
  );
  const Picker = reportTypePicker(reportType);

  const reset = (reportTypeChanged: boolean) => {
    startDate.setValue(format(addDays(addYears(startOfToday(), -1), 1), dateFormat));
    endDate.setValue(format(startOfToday(), dateFormat));
    newlyLicensedStartDate.setValue(undefined);
    newlyLicensedEndDate.setValue(undefined);
    newlyMovedStartDate.setValue(undefined);
    newlyMovedEndDate.setValue(undefined);
    if (!reportTypeChanged) {
      feed.setValue(feeds.length === 1 ? feeds.map(f => String(f.id)) : []);
    }
    board.setValue([]);
    broker.setValue([]);
    branch.setValue([]);
    county.setValue([]);
    area.setValue([]);
    city.setValue([]);
    postalCode.setValue([]);
    district.setValue([]);
    propertyType.setValue([]);
    agentSearchTerm.setValue(undefined);
    productionStartValue.setValue(0);
    productionEndValue.setValue(0);
    productionFilterValue.setValue(undefined);
    productionFilterValue.reset();
    agentHistoryFilterValue.setValue('');
    agentHistoryDateFilterValue.setValue(undefined);
    hotListType.setValue(undefined);
    hasActiveOrPendingListings.setValue(undefined);
    daysSinceLastClose.setValue(undefined);
    salesChange.setValue(undefined);
    percentageChange.setValue(undefined);
    newlyLicensedStartDate.setValue(undefined);
    newlyLicensedEndDate.setValue(undefined);
    newlyMovedStartDate.setValue(undefined);
    newlyMovedEndDate.setValue(undefined);
    setForm(emptyForm);
  };

  const [priorReportType, setPriorReportType] = useState(reportType);

  // reset form during report type change
  useEffect(() => {
    if (reportType != priorReportType) {
      setPriorReportType(reportType);
      reset(true);
      if (reportType.type == SearchType.HotList) hotListOnChange(HotListType.Inactive);
    }
  }, [reportType, priorReportType, setPriorReportType]);

  const agentDateOnChange = useCallback(
    (startDate: string | undefined, endDate: string | undefined, selectedFilter?: QuickFilter) => {
      agentHistoryDateFilterValue.setValue(selectedFilter);
      if (agentHistoryFilterValue.value === 'Moved') {
        newlyLicensedStartDate.setValue(undefined);
        newlyLicensedEndDate.setValue(undefined);
        newlyMovedStartDate.setValue(startDate);
        newlyMovedEndDate.setValue(endDate);
      } else if (agentHistoryFilterValue.value === 'Licensed') {
        newlyLicensedStartDate.setValue(startDate);
        newlyLicensedEndDate.setValue(endDate);
        newlyMovedStartDate.setValue(undefined);
        newlyMovedEndDate.setValue(undefined);
      } else {
        newlyLicensedStartDate.setValue(undefined);
        newlyLicensedEndDate.setValue(undefined);
        newlyMovedStartDate.setValue(undefined);
        newlyMovedEndDate.setValue(undefined);
      }
    },
    [agentHistoryDateFilterValue]
  );

  const agentProductionOnChange = (
    startProductionValue: number | 0,
    endProductionValue: number | 0,
    selectedFilter: QuickProductionFilter | null
  ) => {
    productionStartValue.setValue(startProductionValue);
    productionEndValue.setValue(endProductionValue);
    productionFilterValue.setValue(selectedFilter ?? undefined);
  };

  const hotListOnChange = (filterValue: HotListType | null) => {
    hasActiveOrPendingListings.setValue(undefined);
    daysSinceLastClose.setValue(undefined);
    salesChange.setValue(undefined);
    percentageChange.setValue(undefined);
    newlyLicensedStartDate.setValue(undefined);
    newlyLicensedEndDate.setValue(undefined);
    newlyMovedStartDate.setValue(undefined);
    newlyMovedEndDate.setValue(undefined);
    startDate.setValue(format(addDays(addYears(startOfToday(), -1), 1), dateFormat));
    hotListType.setValue(filterValue ?? undefined);

    switch (filterValue) {
      case HotListType.Inactive:
        hasActiveOrPendingListings.setValue(String(false));
        daysSinceLastClose.setValue(60);
        salesChange.setValue(SalesChange.Decrease);
        percentageChange.setValue(20);
        break;
      case HotListType.New:
        newlyLicensedStartDate.setValue(format(addMonths(startOfToday(), -12), dateFormat));
        newlyLicensedEndDate.setValue(format(addMonths(startOfToday(), -10), dateFormat));
        break;
      case HotListType.Moved:
        newlyMovedStartDate.setValue(format(addMonths(startOfToday(), -12), dateFormat));
        newlyMovedEndDate.setValue(format(addMonths(startOfToday(), -10), dateFormat));
        hasActiveOrPendingListings.setValue(String(true));
        salesChange.setValue(SalesChange.Decrease);
        percentageChange.setValue(10);
        break;
    }
  };

  const handleBrokerValueChange = (
    brokerValue: string[] | undefined,
    branchValue: string[] | undefined
  ) => {
    const remainingOffices = branches
      .filter(o => branchValue?.includes(o.officeKey) ?? false)
      .filter(o => brokerValue?.includes(o.mainOfficeKey!) ?? false)
      .map(o => o.officeKey);
    setForm({ broker: brokerValue, branch: remainingOffices }, true);
  };

  const disablePicker = pending || feed.value?.length === 0;

  return (
    <form {...form.bind}>
      <ErrorDisplay error={error} />

      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Card style={{ padding: '0', marginBottom: '1rem' }}>
          <FilterGroup title="Date Range" filters={Filter.DateRange} reportType={reportType}>
            <Grid container>
              <Grid item xs={6} sm={3} md={2}>
                <Datepicker
                  format="MM/dd/yyyy"
                  label="Start Date"
                  value={startDate.value}
                  onChange={(date?: Date) => {
                    if (date === undefined) {
                      startDate.setValue('');
                      return;
                    }
                    startDate.setValue(format(date, dateFormat));
                  }}
                  sx={{ width: '100%' }}
                  error={startDate.errors.length > 0}
                  helperText={startDate.errors.length > 0 ? startDate.errors[0] : ''}
                />
              </Grid>
              <Grid item xs={6} sm={3} md={2}>
                <Datepicker
                  format="MM/dd/yyyy"
                  label="End Date"
                  value={endDate.value}
                  onChange={(date?: Date) => {
                    if (date === undefined) {
                      endDate.setValue('');
                      return;
                    }
                    endDate.setValue(format(date, dateFormat));
                  }}
                  sx={{ width: '100%' }}
                />
              </Grid>
            </Grid>
          </FilterGroup>
          <FilterGroup title="MLS / Board" filters={Filter.Board} reportType={reportType}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <Picker
                  label="MLS Feed"
                  input={feed}
                  options={feeds}
                  optionValue={(o: FeedModel) => String(o.id)}
                  optionText={(o: FeedModel) => o.name}
                  error={feed.errors.length > 0}
                  errorText={feed.errors[0]}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Picker label="MLS Board" input={board} options={boards} disabled={disablePicker}/>
              </Grid>
            </Grid>
          </FilterGroup>
          <FilterGroup
            title="Company Info"
            filters={[Filter.Company, Filter.Office]}
            reportType={reportType}
          >
            <Grid container spacing={2}>
              {reportType.filters.includes(Filter.Company) && (
                <Grid item xs={12} md={6}>
                  <Picker
                    label="Company"
                    input={broker}
                    options={brokers}
                    optionValue={(o: OfficeModel) => o.officeKey}
                    optionText={(o: OfficeModel) => getOfficeDisplayName(o)}
                    disabled={disablePicker}
                  />
                </Grid>
              )}
              {reportType.filters.includes(Filter.Office) && (
                <Grid item xs={12} md={6}>
                  <Picker
                    label="Office"
                    input={branch}
                    options={branches}
                    optionValue={(o: OfficeModel) => o.officeKey}
                    optionText={(o: OfficeModel) => getOfficeDisplayName(o)}
                    disabled={branches.length === 0 || disablePicker}
                  />
                  {branches.length === 0
                    && feed.value
                    && feed.value.length > 0
                    && (
                    <Typography variant="caption">
                      Select a company to filter by its offices
                    </Typography>
                  )}
                </Grid>
              )}
            </Grid>
          </FilterGroup>
          <FilterGroup title="Geography" filters={Filter.Geography} reportType={reportType}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Picker label="County" input={county} options={counties} disabled={disablePicker}/>
              </Grid>
              <Grid item xs={12} md={6}>
                <Picker label="Area" input={area} options={areas} disabled={disablePicker}/>
              </Grid>
              <Grid item xs={12} md={4}>
                <Picker label="City/Town" input={city} options={cities} disabled={disablePicker}/>
              </Grid>
              <Grid item xs={12} md={4}>
                <Picker label="Postal Code" input={postalCode} freeSolo disabled={disablePicker}/>
              </Grid>
              <Grid item xs={12} md={4}>
                <Picker
                  label="School District"
                  input={district}
                  options={districts}
                  disabled={disablePicker}
                />
              </Grid>
            </Grid>
          </FilterGroup>
          <FilterGroup title="Listing Options" filters={Filter.Listing} reportType={reportType}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Picker
                  label="Property Type"
                  input={propertyType}
                  options={propertyTypes}
                  disabled={disablePicker}
                />
              </Grid>
            </Grid>
          </FilterGroup>
          <FilterGroup title="Hot List Options" filters={Filter.HotList} reportType={reportType}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FilterHotList
                  enabledFilters={[HotListType.Inactive, HotListType.New, HotListType.Moved]}
                  defaultFilter={HotListType.Inactive}
                  setHotList={hotListOnChange}
                  enabledDays={[DayFilter.Days30, DayFilter.Days60, DayFilter.Custom]}
                  daysSinceLastClose={daysSinceLastClose}
                />
              </Grid>
            </Grid>
          </FilterGroup>
          <FilterGroup
            title="Production Options"
            filters={Filter.Production}
            reportType={reportType}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle">
                  <strong>Filter by Production</strong>
                </Typography>
                <FilterProductionRange
                  enabledFilters={[
                    QuickProductionFilter.OneMillion,
                    QuickProductionFilter.TwoMillion,
                    QuickProductionFilter.FiveMillion,
                    QuickProductionFilter.SevenMillion,
                    QuickProductionFilter.OverSevenMillion,
                    QuickProductionFilter.Custom,
                  ]}
                  defaultFilter={productionFilterValue.value}
                  setProduction={agentProductionOnChange}
                />
              </Grid>
            </Grid>
          </FilterGroup>

          <FilterGroup title="Agent Options" filters={Filter.AgentInfo} reportType={reportType}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle">
                  <strong>Search by Agent Name</strong>
                </Typography>
                <TextField
                  name="agentSearchTerm"
                  label="Type agent name here"
                  {...agentSearchTerm.bind}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle">
                  <strong>Filter by New Agents/Agent Moves</strong>
                </Typography>
                <RadioGroup
                  row
                  aria-label="agentDateSelector"
                  name="agentDateSelector"
                  value={agentHistoryFilterValue.value ?? ''}
                  onChange={event => agentHistoryFilterValue.setValue(event.target.value)}
                >
                  <FormControlLabel value={''} control={<Radio />} label="None" />
                  <FormControlLabel value={'Moved'} control={<Radio />} label="Agent Moves" />
                  <FormControlLabel
                    value={'Licensed'}
                    control={<Radio />}
                    label="Newly Licensed Agents"
                  />
                </RadioGroup>
                {agentHistoryFilterValue.value === '' ? null : (
                  <FilterDateRange
                    enabledFilters={[QuickFilter.Last7, QuickFilter.Last30, QuickFilter.Custom]}
                    defaultFilter={agentHistoryDateFilterValue.value}
                    setDates={agentDateOnChange}
                    defaultStart={newlyMovedStartDate.value ?? newlyLicensedStartDate.value}
                    defaultEnd={newlyMovedEndDate.value ?? newlyLicensedEndDate.value}
                  />
                )}
              </Grid>
            </Grid>
          </FilterGroup>
          <Grid container p={4} spacing={2} justifyContent='flex-end'>
            <Grid item>
              <Button
                variant="secondary"
                onClick={() => reset(false)}
                type='button'
              >
                Clear
              </Button>
            </Grid>
            <Grid item>
              <Button disabled={!form.valid || disablePicker}>
                Search
              </Button>
            </Grid>
          </Grid>
        </Card>
      </LocalizationProvider>
    </form>
  );
};

const reportTypePicker = ({ color }: ReportType) =>
  withStyles({
    tag: {
      color: 'white',
      backgroundColor: color,
    },
  })(Picker);

function convertToBoolean(input: string | undefined): boolean | undefined {
  if (!input) return undefined;
  try {
    return JSON.parse(input.toLowerCase());
  } catch {
    return undefined;
  }
}

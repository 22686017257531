import { Query } from '@datorama/akita';

import { MarketState, marketStore } from './market.store';

export class MarketQuery extends Query<MarketState> {
  market = this.select('market');
  office = this.select('office');
}

export const marketQuery = new MarketQuery(marketStore);

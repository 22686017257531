import { applyTransaction, setLoading } from '@datorama/akita';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppOffice } from '../..';
import { Zsg } from '../../../api';
import { AppofficesStore, appofficesStore } from './appoffices.store';

export class AppofficesService {
  constructor(
    private readonly store: AppofficesStore,
    private readonly companiesApi: Zsg.CompaniesApi
  ) {}

  getCompanyOffices(companyId: number) {
    from(this.companiesApi.companiesIdOfficesGet(companyId))
      .pipe(
        map(response => response.data.data),
        setLoading(this.store)
      )
      .subscribe(offices =>
        applyTransaction(() => {
          this.store.reset();
          this.store.upsertMany(offices);
        })
      );
  }

  updateCompanyOffices(offices: AppOffice[]) {
    this.store.upsertMany(offices);
  }
}

export const appofficesService = new AppofficesService(appofficesStore, new Zsg.CompaniesApi());

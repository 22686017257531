import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';

import {
  asErrorBoundry,
  useObservable,
  useFavorites,
  AgentMarketStats,
  useAgentVolume,
} from '../../shared';

import {
  AgentHeader,
  AgentInfo,
  AgentTransactions,
  AgentActivityHistory,
  useRecruitTransactions,
} from '../../agents/components';
import { activitiesService, activitiesQuery, mlsQuery } from '../../state';
import { useRecruit } from 'recruiting/useRecruit';
import { withManagerId } from '../../shared/utils/withManagerId';
import { AgentVolumeSummaryAnnualTrends } from '../../shared/components/agent-volume-summary-annual-trends';
import { ActiveAgentContext } from '../../shared/components/activity-history';

interface Props {
  managerId: string;
}

const AgentDashboard: React.FC<Props> = ({ managerId }) => {
  const { recruitId } = useParams<{ recruitId: string }>();

  const id = parseInt(recruitId);
  const dateRange = useObservable(mlsQuery.activeDateRange);
  const { recruit, agent, transactions, volume } = useRecruitTransactions(managerId, id, dateRange);

  const activities = useObservable(activitiesQuery.recruitActivities);

  const { isFavorite, toggleFavorite } = useFavorites(managerId);
  const { isRecruit, isRecruited, toggleRecruit } = useRecruit(managerId);

  const summary = useAgentVolume(agent?.memberKey);

  useEffect(() => {
    activitiesService.getRecruitActivities(id);
  }, [managerId, id]);

  const officeKey = agent?.officeKey;

  if (!agent) return null;

  return (
    <React.Fragment>
      <ActiveAgentContext.Provider value={{ agent: agent }}>
        <AgentHeader
          recruit={recruit}
          dateRange={dateRange}
          isFavorite={isFavorite}
          toggleFavorite={toggleFavorite}
          isRecruit={isRecruit}
          isRecruited={isRecruited}
          toggleRecruit={toggleRecruit}
        />
        <Grid container direction="column" spacing={2}>
          <Grid container spacing={2} item>
            <Grid item xs={12} sm={12} md={5} lg={4}>
              <AgentInfo />
            </Grid>
            <Grid item xs={12} sm={12} md={7} lg={8}>
              {volume && <AgentMarketStats label="Agent" volume={volume} />}
            </Grid>
          </Grid>
          <Grid container spacing={2} item>
            <Grid item xs={12}>
              {summary && <AgentVolumeSummaryAnnualTrends summary={summary} />}
            </Grid>
          </Grid>
          <Grid container spacing={2} direction="row-reverse" item>
            <Grid item xs={12} lg={3}>
              <AgentActivityHistory
                recruitId={id}
                kvCoreId={recruit?.kvCoreContactId}
                data={activities || []}
                memberId={agent?.memberKey}
              />
            </Grid>
            <Grid item xs={12} lg={9}>
              <AgentTransactions {...transactions} officeKey={officeKey} />
            </Grid>
          </Grid>
        </Grid>
      </ActiveAgentContext.Provider>
    </React.Fragment>
  );
};

export const AgentDashboardPage = withManagerId(asErrorBoundry(AgentDashboard));

import React from 'react';

import { FormStatus, sessionQuery } from '../../state';
import { useObservable } from '../../shared';

import { Redirect } from 'react-router-dom';
import { ForgotPasswordForm } from '../components/forgot-password.form';
import { userService } from '../../state/users/user.service';
import { ForgotPaswordForm, UserForms } from '../../state/users';
import { useFormEvents } from '../../shared/forms';

export const ForgotPasswordPage: React.FC = () => {
  const isLoggedIn = useObservable(sessionQuery.isLoggedIn, 'async');

  const [{ status, error }, resetFormUI] = useFormEvents(UserForms.ForgotPassword);

  const submit = (form: ForgotPaswordForm) => {
    if (form.email !== '') userService.forgotPassword(form.email);
  };

  if (isLoggedIn) {
    return <Redirect to="/" />;
  }

  return (
    <ForgotPasswordForm
      onSubmit={submit}
      onResetFeedback={resetFormUI}
      error={error}
      success={status === FormStatus.Success}
      pending={status === FormStatus.Pending}
    />
  );
};

import { useEffect } from 'react';
import { notificationsService, notificationsQuery } from '../../state';
import { useObservable } from './useObservable';

export function useNotifications(limit: number) {
  useEffect(() => {
    notificationsService.getNotifications(limit);
  }, [limit]);

  const notifications = useObservable(notificationsQuery.userNotifications);

  const members = useObservable(notificationsQuery.agents);

  const listings = useObservable(notificationsQuery.listings);

  return { notifications, memberLookup: members, listingLookup: listings };
}

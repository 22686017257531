/* eslint-disable @typescript-eslint/no-useless-constructor */
import { QueryEntity } from '@datorama/akita';
import {
  AnswersState,
  AnswersStore,
  DefaultQuestionsState,
  DefaultQuestionsStore,
  QuestionTypesState,
  QuestionTypesStore,
  QuestionsState,
  QuestionsStore,
  SubmissionsStore,
  SurveySubmissionState,
  SurveysState,
  SurveysStore,
  answersStore,
  defaultQuestionsStore,
  questionTypesStore,
  questionsStore,
  submissionsStore,
  surveysStore,
  SurveyEmailTemplateState,
  SurveyEmailTemplateStore,
  surveyEmailTemplateStore,
} from './surveys.store';
import { map } from 'rxjs/operators';

export class SurveysQuery extends QueryEntity<SurveysState> {
  constructor(store: SurveysStore) {
    super(store);
  }

  activeSurvey = this.selectActive();

  surveys = this.selectAll();

  isLoading = this.selectLoading();

  error = this.selectError();
}

export class QuestionsQuery extends QueryEntity<QuestionsState> {
  constructor(store: QuestionsStore) {
    super(store);
  }

  questions = this.selectAll();

  isLoading = this.selectLoading();

  error = this.selectError();
}

export class SubmissionsQuery extends QueryEntity<SurveySubmissionState> {
  constructor(store: SubmissionsStore) {
    super(store);
  }

  activeSubmission = this.selectActive();

  submissions = this.selectAll();

  submitted = this.submissions.pipe(map(sub => {
    return sub.filter(s => {
      return s.submissionDate?.length ?? 0 > 0
    })
  }));

  isLoading = this.selectLoading();
}

export class AnswersQuery extends QueryEntity<AnswersState> {
  constructor(store: AnswersStore) {
    super(store);
  }

  answers = this.selectAll();

  isLoading = this.selectLoading();
}

export class QuestionTypesQuery extends QueryEntity<QuestionTypesState> {
  constructor(store: QuestionTypesStore) {
    super(store);
  }

  questionTypes = this.selectAll();

  isLoading = this.selectLoading();
}

export class DefaultQuestionsQuery extends QueryEntity<DefaultQuestionsState> {
  constructor(store: DefaultQuestionsStore) {
    super(store);
  }

  defaultQuestions = this.selectAll();

  isLoading = this.selectLoading();

  error = this.selectError();
}

export class SurveyEmailTemplateQuery extends QueryEntity<SurveyEmailTemplateState> {
  constructor(store: SurveyEmailTemplateStore) {
    super(store);
  }

  template = this.selectFirst();
}

export const surveysQuery = new SurveysQuery(surveysStore);
export const questionsQuery = new QuestionsQuery(questionsStore);
export const submissionsQuery = new SubmissionsQuery(submissionsStore);
export const answersQuery = new AnswersQuery(answersStore);
export const questionTypesQuery = new QuestionTypesQuery(questionTypesStore);
export const defaultQuestionsQuery = new DefaultQuestionsQuery(defaultQuestionsStore);
export const surveyEmailTemplateQuery = new SurveyEmailTemplateQuery(surveyEmailTemplateStore);

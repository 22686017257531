import { EntityState, StoreConfig } from '@datorama/akita';

import { AppOffice } from './offices.model';
import { ResettableEntityStore } from '../util/resettableStore';

export interface OfficeState extends EntityState<AppOffice, AppOffice['officeId']> {
  ui: {
    brokerId?: AppOffice['officeId'];
    branchId?: AppOffice['officeId'];
  };
}

const defaultState: OfficeState = {
  ui: {},
};

@StoreConfig({ name: 'offices', resettable: true, idKey: 'officeId' })
export class OfficeStore extends ResettableEntityStore<
  OfficeState,
  AppOffice,
  AppOffice['officeId']
> {
  constructor() {
    super(defaultState);
  }
}

export const officeStore = new OfficeStore();

import React from 'react';

import {
  Grid,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel,
} from '@mui/material';
import { Button } from 'shared/components/button';
import { ResultType } from '../../state/search';

import { ReportType, reportTypes } from '../../state/search/reportType';
import { SearchType } from '../../api/mls';

const resultTypeOptions = Object.values(ResultType);

interface ReportSelectorProps {
  reportType: ReportType;
  resultType: ResultType;
  setReportType(reportType: ReportType): void;
  setResultType(resultType: ResultType): void;
}

export const ReportSelector: React.FC<ReportSelectorProps> = ({
  reportType,
  resultType,
  setReportType,
  setResultType,
}) => {
  return (
    <Box mb={2}>
      <Grid container spacing={2}>
        <Grid item container xs={12}>
          <FormControl variant="standard">
            <FormLabel component="legend">Please choose a report type below</FormLabel>
            <Grid container marginY={1} spacing={1}>
              {reportTypes.map((r, i) => (
                <Grid item key={i}>
                  <ReportTypeTile
                    reportType={r}
                    active={r === reportType}
                    onSelect={() => setReportType(r)}
                  />
                </Grid>
              ))}
            </Grid>
          </FormControl>
        </Grid>
        {reportType.type !== SearchType.HotList ? (
          <Grid item container xs={12} alignItems="center" spacing={2}>
            <Grid item>
              <FormControl variant="standard">
                <FormLabel component="legend">Show results by:</FormLabel>
              </FormControl>
            </Grid>
            <Grid item>
              <RadioGroup
                row
                aria-label="position"
                value={resultType}
                onChange={$event => setResultType($event.target.value as ResultType)}
                name="position"
                defaultValue="top"
              >
                {resultTypeOptions.map(o => (
                  <FormControlLabel
                    value={o}
                    key={o}
                    control={<Radio color="primary" />}
                    label={o}
                  />
                ))}
              </RadioGroup>
            </Grid>
          </Grid>
        ) : undefined}
      </Grid>
    </Box>
  );
};

interface ReportTypeTileProps {
  reportType: ReportType;
  active: boolean;
  onSelect(): void;
}

const ReportTypeTile: React.FC<ReportTypeTileProps> = ({ reportType, active, onSelect }) => {
  return (
    <Button
      onClick={() => onSelect()}
      variant={active ? 'primary' : 'secondary'}
      size='medium'
    >
      {reportType.label}
    </Button>
  );
};

import axios, { AxiosInstance } from 'axios';

import { AppConfig } from '../../config';
import { withAuthentication } from './withAuthentication';

export interface JwtToken {
  access_token: string;
  refresh_token?: string;
  expires_in: number;
  token_type: string;
  scope: string;
  is_impersonating?: boolean;
}

export interface UserInfo {
  sub: string;
  role: string;
  preferred_username: string;
  name: string;
  [key: string]: string;
}

export class AuthApi {
  private readonly client: AxiosInstance;

  constructor() {
    this.client = withAuthentication(axios.create({ baseURL: AppConfig.Api.Auth.BaseUrl }));
  }

  async login(username: string, password: string) {
    const params = new URLSearchParams({
      client_id: AppConfig.Api.Auth.ClientId,
      grant_type: AppConfig.Api.Auth.GrantType,
      scope: AppConfig.Api.Auth.Scope,
      username,
      password,
    });

    return await this.client.post<JwtToken>('/account/login', params);
  }

  async logout(access_token: string, refresh_token: string | null | undefined) {
    const params = new URLSearchParams({
      client_id: AppConfig.Api.Auth.ClientId,
      grant_type: AppConfig.Api.Auth.RefreshGrantType,
      scope: AppConfig.Api.Auth.Scope,
      refresh_token: refresh_token ?? "",
      access_token,
    });

    return await this.client.post<JwtToken>('/account/logout', params);
  }

  async refreshToken(refresh_token: string, access_token: string) {
    const params = new URLSearchParams({
      client_id: AppConfig.Api.Auth.ClientId,
      grant_type: AppConfig.Api.Auth.RefreshGrantType,
      scope: AppConfig.Api.Auth.Scope,
      refresh_token,
      token: access_token,
    });

    return await this.client.post<JwtToken>('/account/token', params);
  }

  async userInfo(accessToken: string) {
    return await this.client.get<UserInfo>('/account/userinfo', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  async kvCoreLogin(token: string) {
    const params = new URLSearchParams({
      client_id: AppConfig.Api.Auth.ClientId,
      grant_type: 'token',
      token,
    });

    return await this.client.post<JwtToken>('/account/sso', params);
  }

  async impersonate(id: string) {
    const params = new URLSearchParams({
      userId: id
    });

    return await this.client.post<JwtToken>('account/impersonate', params);
  }
}

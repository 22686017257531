import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography } from 'shared/components/typography';
import { Alert } from 'shared/components/alert';

export const SuccessDisplay: React.FC<{ display: boolean; successMessage?: string }> = ({
  display = false,
  successMessage = 'Your request completed successfully!',
}) => {
  if (!display) return <></>;
  return (
    <Alert variant="success">
      <Typography>
        <FormattedMessage id={successMessage} defaultMessage={successMessage} />
      </Typography>
    </Alert>
  );
};

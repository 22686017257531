import React, {createRef, RefObject, useState} from 'react';
import {Card, CardContent, Grid, CardMedia } from '@mui/material';
import { Typography, FileUploadButton } from 'shared/components';
import {NotificationSettingsForm} from '../components';
import {
  ManagerForms,
  managerService,
  NotificationSettingsForm as NotificationSettingsFormModel,
} from '../../state/managers';
import {ErrorDisplay, SuccessDisplay, useFormEvents} from '../../shared/forms';
import {FormStatus, sessionQuery, sessionService} from '../../state';
import {useActiveUserProfile} from '../hooks';

import {useHistory} from 'react-router-dom';
import {AvatarEditorDialog, useManager, useObservable, withManagerId} from '../../shared';
import {CompaniesApi} from 'api/app';

interface Props {
  managerId: string;
}

const NotificationSettings: React.FC<Props> = ({managerId}) => {
  const user = useActiveUserProfile();
  const history = useHistory();
  const currentCompany = useObservable(sessionQuery.currentCompany);

  const {manager, recruitingGoal} = useManager(managerId);

  const [notificationSettingsEvents, resetNotificationSettingsFormUI] = useFormEvents(
    ManagerForms.NotificationSettings
  );

  const submitNotificationSettings = (form: NotificationSettingsFormModel) => {
    managerService.changeNotificationSettings(form);
  };

  const companiesApi = new CompaniesApi();
  const inputRef: RefObject<HTMLInputElement> = createRef();
  const [openDialog, setOpenDialog] = useState(false);
  const [logoError, setLogoError] = useState<Error>();
  const [logoSuccess, setLogoSuccess] = useState(false);
  const [image, setImage] = useState<string | File>('');

  if (!user || !currentCompany) return null;

  const handleAdd = (event: React.ChangeEvent<any>) => {
    setLogoError(undefined);
    setLogoSuccess(false);
    setOpenDialog(true);
    setImage(event.target.files[0]);
  }

  const clearInputValue = () => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  }

  const handleSubmit = async (file: File) => {
    const urlResponse = await companiesApi.companiesLogoUrlIdGet(currentCompany.id);
    const url = urlResponse.data.url;
    const headers = new Headers({'Content-Type': 'image/png'});
    const response = await fetch(url, {
      method: 'PUT',
      headers: headers,
      body: file
    })
    .then(response => handleUploadSuccess(response))
    .catch(error => setLogoError(new Error()));
    clearInputValue();
  }

  const handleUploadSuccess = async (response: Response) => {
    if (response.ok) {
      setLogoSuccess(true);
      await companiesApi.companiesHasLogoIdPost(currentCompany.id, true);
      await sessionService.refreshCompanyData();
    } else {
      setLogoError(new Error());
    }
  }

  return (
    <>
      <AvatarEditorDialog
        image={image}
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        onSubmit={handleSubmit}
        onCancel={clearInputValue}
      />
      <Grid container item xs={12} spacing={2}>
        {!!manager && (
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <NotificationSettingsForm
                  activeForm={manager as NotificationSettingsFormModel}
                  onSubmit={submitNotificationSettings}
                  onResetFeedback={resetNotificationSettingsFormUI}
                  error={notificationSettingsEvents.error}
                  success={notificationSettingsEvents.status === FormStatus.Success}
                  pending={notificationSettingsEvents.status === FormStatus.Pending}
                />
              </CardContent>
            </Card>
            <Card
              style={{
                marginTop: '20px',
                paddingTop: '35px',
                paddingBottom: '40px',
                paddingLeft: '35px',
              }}
            >
              <Grid container direction="column" spacing={3}>
                {logoError ? (
                  <Grid item marginBottom={3}>
                    <ErrorDisplay
                      error={logoError}
                      errorMessage="An error has occurred. Please try again."
                    />
                  </Grid>
                ) : null}

                {logoSuccess ? (
                  <Grid item marginBottom={3}>
                    <SuccessDisplay
                      display={logoSuccess}
                      successMessage="Your logo has been uploaded successfully!"
                    />
                  </Grid>
                ) : null}

                <Grid container spacing={2} item>
                  <Grid item xs={12} md={2}>
                    <Typography variant="h6">Company Logo</Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography>Upload your logo to customize your emails.</Typography>
                    <Typography>Logo will appear on your email notifications.</Typography>
                  </Grid>
                </Grid>

                <Grid container spacing={2} item>
                  <Grid item sm={4} md={2} marginTop={4}>
                    <FileUploadButton inputFieldRef={inputRef} onChange={handleAdd} accept="image/*">
                      Choose Image
                    </FileUploadButton>
                  </Grid>

                  <Grid item xs={12} md={2}>
                      {currentCompany.logoUrl && <CardMedia
                        sx={{ height: 60, width: 200 }}
                        image={currentCompany.logoUrl}
                        title="company logo"
                      />}
                    {!currentCompany.logoUrl && <Typography fontWeight="bold">No Logo Uploaded</Typography>}
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export const NotificationSettingsPage = withManagerId(NotificationSettings);

import React, { useMemo } from 'react';
import { Card, CardContent, CardHeader, Box } from '@mui/material';

import { AgentVolumeLookup } from '../../state';
import { Block } from './block';
import { Typography } from './typography';
import { ValueComparison } from 'shared/components/value-comparison';
import { NumberFormatters } from '../utils/formatters';
import { RecruitModel } from '../../api/app';

interface PipelineStats {
  agents: number;
  volume: { current: number; previous: number };
  transactions: { current: number; previous: number };
}

interface Props {
  recruits: RecruitModel[];
  volume: AgentVolumeLookup;
}

export const RecruitingPipeline: React.FC<Props> = ({ recruits, volume }) => {
  const pipelineStats: PipelineStats = useMemo(() => {
    const activeRecruits = recruits.filter(r => !r.recruitedDate);

    const volumeTotal = { current: 0, previous: 0 };
    const transactionTotal = { current: 0, previous: 0 };

    activeRecruits.forEach(r => {
      const vol = volume.get(r.memberKey ?? '');
      volumeTotal.current += vol?.totalVolume.current ?? 0;
      volumeTotal.previous += vol?.totalVolume.previous ?? 0;
      transactionTotal.current += vol?.totalTransactions.current ?? 0;
      transactionTotal.previous += vol?.totalTransactions.previous ?? 0;
    });

    return {
      agents: activeRecruits.length,
      volume: volumeTotal,
      transactions: transactionTotal,
    };
  }, [recruits, volume]);

  return (
    <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }} >
      <Card sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
        <CardHeader title="Active Recruiting Pipeline" />
        <CardContent sx={{ flexGrow: 1, display: 'flex', height: '100%', flexDirection: 'column', padding: '8px' }}>
          <Block>
            <Typography fontWeight="bold">{pipelineStats.agents ?? 0} pipelines active</Typography>
            <ValueComparison
              {...pipelineStats.volume}
              formatter={NumberFormatters.currency}
              size="175%"
            />
          </Block>
        </CardContent>
        <CardContent sx={{ flexGrow: 1, display: 'flex', height: '100%', flexDirection: 'column', padding: '8px' }}>
          <Block>
            <Typography fontWeight="bold">Transactions</Typography>
            <ValueComparison {...pipelineStats.transactions} size="200%" align="center" />
          </Block>
        </CardContent>
      </Card>
    </Box>
  );
};

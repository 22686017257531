import { EntityState, EntityStore, StoreConfig, ActiveState } from '@datorama/akita';

import { Favorite } from './favorites.models';

export interface FavoritesState extends EntityState<Favorite>, ActiveState<Favorite> {
  ui: { managerId: string | null };
}

@StoreConfig({ name: 'favorites', resettable: true, idKey: 'memberKey' })
export class FavoritesStore extends EntityStore<FavoritesState> {
  constructor() {
    super({ ui: { managerId: null } });
  }
}

export const favoritesStore = new FavoritesStore();

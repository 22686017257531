import { Observable } from 'rxjs';
import * as MlsModels from 'api/mls/model';

export type Agent = MlsModels.AgentModel;
export type AgentVolume = MlsModels.AgentVolumeSummary;
export type AgentVolumePeriod = MlsModels.AgentVolumePeriod;

export type Listing =
  | MlsModels.ListingModel
  | MlsModels.AgentVolumeModel
  | MlsModels.OfficeVolumeModel;

export type Office = MlsModels.OfficeModel;
export type OfficeVolume = MlsModels.OfficeVolumeSummary;

export type KeyFilter = string[] | Observable<string[]>;
export type ListingView = {
  memberKeys?: KeyFilter;
  officeKeys?: KeyFilter;
};

export function hasAddress(volume: Listing): volume is MlsModels.ListingModel {
  return !!(volume as any).address;
}

export type EditAgentForm = {
  alternateEmail?: string;
  alternatePhone?: string;
};

import React from 'react';
import { Card, CardHeader, CardContent } from '@mui/material';

import { Listing, Office } from '../../state';
import { AgentTransactionMap } from '../../agents/components';

interface Props {
  listings: Listing[];
  offices?: Office[];
}

export const SalesMap: React.FC<Props> = ({ listings, offices }) => {
  return (
    <Card>
      <CardHeader title="Sales Map" />
      <CardContent>
        <AgentTransactionMap listings={listings} offices={offices} heatmap />
      </CardContent>
    </Card>
  );
};

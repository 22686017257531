import { applyTransaction } from '@datorama/akita';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { Mls } from '../../../../api';
import { FeedStore, feedStore } from './feeds.store';

export class FeedService {
  constructor(private readonly store: FeedStore, private readonly mlsofficesApi: Mls.OfficesApi) {}

  getFeeds() {
    from(this.mlsofficesApi.mlsOfficesFeedsGet())
      .pipe(map(response => response.data.data))
      .subscribe(feeds =>
        applyTransaction(() => {
          this.store.upsertMany(feeds);
        })
      );
  }
}

export const feedService = new FeedService(feedStore, new Mls.OfficesApi());

import { Store, EntityStore, EntityState, getEntityType, getIDType } from '@datorama/akita';
import { Subject } from 'rxjs';

export interface IResettable {
  readonly storeReset: Subject<void>;
}

export abstract class ResettableStore<State = any> extends Store<State> implements IResettable {
  readonly storeReset = new Subject<void>();

  reset(): void {
    this.storeReset.next();
    super.reset();
  }
}

export abstract class ResettableEntityStore<
  TState extends EntityState<any, any> = any,
  TEntity = getEntityType<TState>,
  TId = getIDType<TState>
> extends EntityStore<TState, TEntity, TId> implements IResettable {
  readonly storeReset = new Subject<void>();

  reset(): void {
    this.storeReset.next();
    super.reset();
  }
}

import { ComponentProps, ReactNode } from 'react';
import { MessageDescriptor, FormattedMessage } from 'react-intl';
import isEmail from 'sane-email-validation';
import { FormControl } from './controls';

export type ValidatorFn<T> = (value: T) => ReactNode;

export function validator<T>(
  check: (value: T) => boolean,
  message: ComponentProps<typeof FormattedMessage>
): ValidatorFn<T> {
  return (value: T) => (check(value) ? undefined : <FormattedMessage {...message} />);
}

export function required<T>(
  message: MessageDescriptor = {
    id: 'validation.required.message',
    defaultMessage: 'This field is required.',
  }
) {
  return validator<T>(
    val =>
      val != null &&
      !((typeof val === 'string' && val === '') || (val instanceof Array && val.length < 1)),
    message
  );
}

export function email(
  message: ComponentProps<typeof FormattedMessage> = {
    id: 'validation.notAnEmail.message',
    defaultMessage: 'Please enter a valid email address.',
  }
) {
  return validator<string | undefined>(str => !str || isEmail(str), message);
}

export function matches<T>(
  other: FormControl,
  message: ComponentProps<typeof FormattedMessage> = {
    id: 'validation.matches.message',
    defaultMessage: 'Values must match.',
  }
) {
  return validator<T>(value => value === other.value, message);
}

export function minimum(
  minValue: number,
  message: ComponentProps<typeof FormattedMessage> = {
    id: 'validation.minimum.message',
    defaultMessage: 'Value must be greater than or equal to {minValue}.',
  }
) {
  return validator<number | undefined>(x => x === undefined || x >= minValue, {
    ...message,
    values: { minValue },
  });
}

export function maximum(
  maxValue: number,
  message: ComponentProps<typeof FormattedMessage> = {
    id: 'validation.maximum.message',
    defaultMessage: 'Value must be less than or equal to {maxValue}.',
  }
) {
  return validator<number | undefined>(x => x === undefined || x <= maxValue, {
    ...message,
    values: { maxValue },
  });
}

export function startDate(
  endDate: string | undefined,
  message: ComponentProps<typeof FormattedMessage> = {
    id: 'validation.startDate.message',
    defaultMessage: 'Start date must be before or equal to end date.',
  }
) {
  return validator<string | undefined>(
    startDate =>
      startDate !== undefined &&
      (endDate === undefined || new Date(startDate).getTime() <= new Date(endDate).getTime()),
    message
  );
}

export function endDate(
  startDate: string | undefined,
  message: ComponentProps<typeof FormattedMessage> = {
    id: 'validation.endDate.message',
    defaultMessage: 'End date must be equal to or after start date.',
  }
) {
  return validator<string | undefined>(
    endDate =>
      endDate !== undefined &&
      (startDate === undefined || new Date(startDate).getTime() <= new Date(endDate).getTime()),
    message
  );
}

export function requiredCompanyOffices(
  message: ComponentProps<typeof FormattedMessage> = {
    id: 'validation.companyOffices.message',
    defaultMessage: 'Company must have at least one assigned office.',
  }
) {
  return validator<string[] | undefined>(
    offices =>
      offices !== undefined && offices.length > 0,
    message
  );
}

export function feedSelected(
  message: ComponentProps<typeof FormattedMessage> = {
    id: 'validation.feedSelected.message',
    defaultMessage: 'Select an MLS feed before selecting other filters.',
  }
) {
  return validator<string[] | undefined>(
    feeds =>
      feeds !== undefined && feeds.length > 0,
    message
  );
}

export function seatCountValidator(
  minSeats: number,
  kvCore: boolean,
  message: ComponentProps<typeof FormattedMessage> = {
    id: 'validation.seatCount.message',
    defaultMessage: minSeats === 0
      ? "Must specify a number of 0 or greater."
      : "Company has " + minSeats + " users. Number must be greater than or equal to this value.",
  }
) {
  return validator<number>(
    seatCount => kvCore || seatCount >= minSeats,
    message
  );
}

export function kvCoreSid(
  accountStatus: string,
  isKvCore: boolean,
  message: ComponentProps<typeof FormattedMessage> = {
    id: 'validation.kvCoreSid.message',
    defaultMessage: "This field is required.",
  }
) {
  return validator<any>(
    val =>
      !isKvCore
      || accountStatus !== "Active"
      || (val != null && !((typeof val === 'string' && val === '') || (val instanceof Array && val.length < 1))),
    message
  );
}

export function ampstatsSid(
  accountStatus: string,
  message: ComponentProps<typeof FormattedMessage> = {
    id: 'validation.ampstatsSid.message',
    defaultMessage: "This field is required.",
  }
) {
  return validator<any>(
    val =>
      accountStatus !== "Active"
      || (val != null && !((typeof val === 'string' && val === '') || (val instanceof Array && val.length < 1))),
    message
  );
}


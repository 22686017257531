import React, { ComponentProps } from 'react';

import { Autocomplete } from '@mui/lab';
import { Picker } from '../../search';
import { useInput } from '../../shared/forms';

interface Props<T = string> {
  input: string[] | undefined;
  options: T[] | null | undefined;
  onChange: (value: string[]) => void;

  label: string;
  classes?: ComponentProps<typeof Autocomplete>['classes'];
  disabled?: boolean;
  optionValue?(item: T): string;
  optionText?(item: T): string;
}

export function OfficeRegionPicker<T = string>({ input, onChange, ...props }: Props<T>) {
  const pickerInput = useInput(input);
  return <Picker input={pickerInput} onChange={onChange} {...props} />;
}

import React from "react";
import { FeedModel } from "api/mls";
import { DataTable, DataTableColumn, LoadingFade } from "shared";
import { Card, CardContent, Grid } from "@mui/material";

interface Props {
  tableData: FeedModel[],
  columns: DataTableColumn<FeedModel>[],
  loading: boolean,
};

export const FeedDisclaimersTable: React.FC<Props> = ({
  tableData,
  columns,
  loading,
}) => {
  return (
    <>
      <LoadingFade isContentLoading={loading}>
        <Card>
          <CardContent>
            <LoadingFade
              isContentLoading={loading}
              content={() => (
                <Grid item container direction="column" gap={2}>
                  <DataTable
                    getRowId={(row) => row.id!}
                    rows={tableData}
                    columns={columns}
                    progressPending={loading}
                    showPagination
                  />
                </Grid>
              )}
            />
          </CardContent>
        </Card>
      </LoadingFade>
    </>
  );
};

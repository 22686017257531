import React from 'react';
import { FormControl, InputLabel, MenuItem, Select, Box } from '@mui/material';
import { ResultType, SortField, SortOrder } from '../../state/search';

export interface SortInputProps {
  sortField: SortField;
  sortOrder: SortOrder;
  resultType: ResultType;
  onChange(sortField: SortField, sortOrder: SortOrder): void;
}

export const SortInput: React.FC<SortInputProps> = ({
  sortField,
  sortOrder,
  onChange,
  resultType,
}) => {
  return (
    <Box>
      <FormControl variant="standard">
        <InputLabel>Sort By</InputLabel>
        <Select
          value={sortField}
          onChange={event => onChange(event.target.value as SortField, sortOrder)}
        >
          <MenuItem value={SortField.TotalVolume}>Total Volume</MenuItem>
          <MenuItem value={SortField.TotalCount}>Total Count</MenuItem>
          <MenuItem value={SortField.ListingVolume}>Listing Volume</MenuItem>
          <MenuItem value={SortField.ListingCount}>Listing Count</MenuItem>
          <MenuItem value={SortField.SellingVolume}>Selling Volume</MenuItem>
          <MenuItem value={SortField.SellingCount}>Selling Count</MenuItem>
          {resultType === ResultType.Agent && (
            <MenuItem value={SortField.PreviousOffice}>Previous Office</MenuItem>
          )}
          {resultType === ResultType.Agent && (
            <MenuItem value={SortField.CurrentOffice}>Current Office</MenuItem>
          )}
        </Select>
      </FormControl>
      <FormControl variant="standard">
        <InputLabel>Sort Order</InputLabel>
        <Select
          value={sortOrder}
          onChange={event => onChange(sortField, event.target.value as SortOrder)}
        >
          <MenuItem value={SortOrder.Ascending}>Ascending</MenuItem>
          <MenuItem value={SortOrder.Descending}>Descending</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

import { QueryEntity } from '@datorama/akita';

import { AppofficesState, appofficesStore } from './appoffices.store';

export class AppofficesQuery extends QueryEntity<AppofficesState> {
  offices = this.selectAll();

  loading = this.selectLoading();
}

export const appofficesQuery = new AppofficesQuery(appofficesStore);

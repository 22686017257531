import { QueryEntity } from '@datorama/akita';
import { CoBrokeState, CoBrokesStore, coBrokesStore } from './cobroke.store';

export class CoBrokesQuery extends QueryEntity<CoBrokeState> {
  constructor(store: CoBrokesStore) {
    super(store);
  }

  coBrokes = this.selectAll();

  loading = this.selectLoading();
}

export const coBrokesQuery = new CoBrokesQuery(coBrokesStore);

import React from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import { FeedModel } from "api/mls";
import { Button, TextField } from "shared";

interface Props {
  open: boolean,
  setOpen:  React.Dispatch<React.SetStateAction<boolean>>,
  onConfirm: () => void
  feed: FeedModel | null,
  disclaimer: string,
  setDisclaimer: React.Dispatch<React.SetStateAction<string>>,
}

export const EditDisclaimerDialog: React.FC<Props> = ({
  open,
  setOpen,
  onConfirm,
  feed,
  disclaimer,
  setDisclaimer,
}) => {
  if (!feed) return null;

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>{feed.name} - Edit Disclaimer</DialogTitle>
      <DialogContent>
        <TextField
          value={disclaimer}
          onChange={event => setDisclaimer(event.target.value)}
          fullWidth
          multiline
          minRows={3}
          placeholder="Enter value..."
        />
      </DialogContent>
      <DialogActions>
        <Grid container item xs={12} gap={2} m={2}>
          <Grid item>
            <Button onClick={onConfirm} type="button">
              Save
            </Button>
          </Grid>
          <Grid item>
            <Button variant="secondary" onClick={() => setOpen(false)} type="button">
              Cancel
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>  );
};

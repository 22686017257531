import React from 'react';
import { IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Star } from '@mui/icons-material';
import { ThemeColors } from '../theme';

const useStyles = makeStyles({
  favoriteIndicator: {
    color: ThemeColors.Green,
    fontSize: '175%',
    cursor: (props: Props) => props.setFavorite && 'pointer',
  },
});

interface Props {
  favorite?: boolean;
  setFavorite?: (favorite: boolean) => void;
}

export const FavoriteIndicator: React.FC<Props> = ({ favorite, setFavorite }) => {
  const styles = useStyles({ favorite, setFavorite });

  return (
    <IconButton
      className={styles.favoriteIndicator}
      type="button"
      size="small"
      onClick={() => setFavorite?.(!favorite)}
    >
      <Star />
    </IconButton>
  );
};

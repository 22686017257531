import React, { useMemo } from 'react';
import { Grid, Card, CardHeader, CardContent } from '@mui/material';

import {
  asErrorBoundry,
  useObservable,
  PeriodPicker,
  CategorizedSalesVolumeModel,
  BalanceReportHelper,
  useFavorites,
  BalanceReport,
  RecruitingPipeline,
  MarketStats,
  RecruitingGoal,
  useManager,
  BalanceReportMode,
  LoadingFade,
  PageHeader,
  useOfficeVolume,
  OfficePicker,
  icons,
} from '../../shared';

import { mlsService, mlsQuery, DateRangeOptions, officeQuery } from '../../state';
import { SalesVolume } from '../../retention';
import { CompanySalesVolumeTable, CompanyMarketShare } from '../components';
import { AgentTransactionMap } from '../../agents/components';
import { withManagerId } from '../../shared/utils/withManagerId';
import { useMarketVolume } from '../hooks';
import { useLoadingIndicator } from '../../shared/indicators/isLoading';

const orderAndCategorize = (data: CategorizedSalesVolumeModel[], mode: BalanceReportMode) => {
  return BalanceReportHelper.orderAndCategorize(data, mode);
};

interface Props {
  managerId: string;
}

const CompanyDashboard: React.FC<Props> = ({ managerId }) => {
  let loading = useLoadingIndicator();

  const dateRange = useObservable(mlsQuery.activeDateRange);
  const updateDateRange = (newRange: DateRangeOptions) => {
    mlsService.setActiveDateRange(newRange);
  };
  const officeCodes = useObservable(officeQuery.activeOfficeCodes);
  const marketRegion = useObservable(officeQuery.activeMarketRegion);

  const { agents, agentVolumes, marketStats, offices, listings } = useOfficeVolume(officeCodes);

  const { market: marketTotalVolume, office: marketOfficeVolume } = useMarketVolume(
    marketRegion,
    officeCodes
  );

  const { recruitingGoal, recruits, volume } = useManager(managerId);

  const { isFavorite, toggleFavorite } = useFavorites(managerId);

  const orderedData = useMemo(
    () =>
      orderAndCategorize(
        agents
          .filter(agent => agent.active)
          .map(
            (agent, i) =>
              ({
                id: agent.memberKey,
                index: i,
                agent,
                volume: agentVolumes?.get(agent.memberKey),
                isFavorite,
                toggleFavorite,
              } as CategorizedSalesVolumeModel)
          ),
        'totalVolume'
      ),
    [agents, agentVolumes, isFavorite, toggleFavorite]
  );

  return (
    <>
      <PageHeader
        title="DASHBOARD"
        icon={icons.poll}
        iconSize='large'
        filters={[
          <PeriodPicker activeRange={dateRange} updateRange={updateDateRange} />,
          <OfficePicker />,
        ]}
      />
      <LoadingFade
        isContentLoading={loading}
        content={() => (
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Grid container spacing={3} sx={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
                <Grid item xs={12}>
                  <MarketStats label="Company" volume={marketStats} />
                </Grid>
                <Grid item xs={12} lg={7} xl={7} sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Grid item xs={12} sx={{ flexGrow: 1 }}>
                    <CompanyMarketShare
                      region={marketRegion}
                      office={marketOfficeVolume}
                      market={marketTotalVolume}
                    />
                  </Grid>
                  <Grid container spacing={3} sx={{ flexGrow: 1, mt: 1 }}>
                    <Grid item xs={12} sm={6} sx={{ display: 'flex', flexDirection: 'column' }}>
                      <RecruitingPipeline
                        recruits={recruits}
                        volume={volume}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{ display: 'flex', flexDirection: 'column' }}>
                      <RecruitingGoal
                        recruits={recruits}
                        volume={volume}
                        goal={recruitingGoal}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} lg={5} xl={5} sx={{ display: 'flex' }}>
                  <BalanceReport orderedData={orderedData} dataGroupingMode={'totalVolume'} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <SalesVolume volume={marketStats} hideTransactionTile={true} />
            </Grid>
            <Grid item xs={12}>
              <CompanySalesVolumeTable volume={marketStats} />
            </Grid>
            <Grid item xs={12}>
              <Card>
                <CardHeader title="Sales Map" />
                <CardContent>
                  <AgentTransactionMap listings={listings} offices={offices} heatmap />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        )}
      />
    </>
  );
};

export const CompanyDashboardPage = withManagerId(asErrorBoundry(CompanyDashboard));

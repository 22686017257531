/* eslint-disable */
// tslint:disable
/**
 * MLS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';

import { ProcessLogsResult } from '../model';
/**
 * FeedStatusApi - axios parameter creator
 * @export
 */
export const FeedStatusApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsFeedstatusGet(options: any = {}): RequestArgs {
      const localVarPath = `/mls/feedstatus`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      //@ts-ignore
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * FeedStatusApi - functional programming interface
 * @export
 */
export const FeedStatusApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsFeedstatusGet(
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProcessLogsResult> {
      const localVarAxiosArgs = FeedStatusApiAxiosParamCreator(configuration).mlsFeedstatusGet(
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * FeedStatusApi - factory interface
 * @export
 */
export const FeedStatusApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsFeedstatusGet(options?: any): AxiosPromise<ProcessLogsResult> {
      return FeedStatusApiFp(configuration).mlsFeedstatusGet(options)(axios, basePath);
    },
  };
};

/**
 * FeedStatusApi - object-oriented interface
 * @export
 * @class FeedStatusApi
 * @extends {BaseAPI}
 */
export class FeedStatusApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FeedStatusApi
   */
  public mlsFeedstatusGet(options?: any) {
    return FeedStatusApiFp(this.configuration).mlsFeedstatusGet(options)(this.axios, this.basePath);
  }
}

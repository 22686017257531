import React from 'react';
import { RouteComponentProps, Switch, Route, Redirect } from 'react-router-dom';

import { SignInPage, SignOutPage } from './pages';
import { ForgotPasswordPage } from './pages/forgot-password.page';
import { RecoverPasswordPage } from './pages/recover-password.page';
import { InactiveAccountPage } from './pages/inactive-account.page';

const RecoverPasswordComponent: React.FC<RouteComponentProps> = ({ location }) => {
  //nerf the starting question mark (?)
  const queryParamsString = location?.search.substring(1);

  const searchParams = new URLSearchParams(queryParamsString);
  const resetToken = searchParams.get('token');
  const email = searchParams.get('email');

  if (!email || !resetToken) {
    return <Redirect to={`/account/signin`} />;
  }
  return <RecoverPasswordPage email={email} resetToken={resetToken} />;
};

export const AccountRoutes: React.FC<RouteComponentProps> = ({ match }) => (
  <Switch>
    <Route path={`${match.path}/signin`} component={SignInPage} />
    <Route path={`${match.path}/signout`} component={SignOutPage} />
    <Route path={`${match.path}/forgotpassword`} component={ForgotPasswordPage} />
    <Route path={`${match.path}/recover`} component={RecoverPasswordComponent} />
    <Route path={`${match.path}/account-disabled`} component={InactiveAccountPage} />
    <Redirect to={`${match.path}/signin`} />
  </Switch>
);

import React from 'react';
import { Redirect, Route, Switch, RouteComponentProps } from 'react-router-dom';
import { KvCoreCode, KvCoreRefresh, KvCoreSSO } from './pages';

export const KvCoreRoutes: React.FC<RouteComponentProps> = ({ match }) => (
  <Switch>
    <Route path={`${match.path}`} exact component={KvCoreCode} />
    <Route path={`${match.path}/sso`} component={KvCoreSSO} />
    <Route path={`${match.path}/refresh/:refreshToken`} component={KvCoreRefresh} />
    <Redirect to={match.path} />
  </Switch>
);

import React, { ReactNode, memo } from 'react';
import { createStyles, WithStyles, withStyles } from '@mui/styles';

interface Props {
  value: ReactNode;

  color?: string;
  size?: number | string;
  align?: 'left' | 'center' | 'right';
}

const styles = createStyles({
  root: {
    lineHeight: '1em',
    textAlign: (props: Props) => props.align ?? 'center',
    fontSize: (props: Props) => props.size ?? '1em',
    maxHeight: '100%',
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  value: {
    color: (props: Props) => props.color ?? 'inherit',
  },
  caption: {
    fontSize: '0.675em',
    marginTop: '0.5em', // Add some spacing between value and caption
  },
});

export type CaptionedValueProps = Props & WithStyles<typeof styles>;

const CaptionedValueComponent: React.FC<CaptionedValueProps> = ({ value, classes, children }) => (
  <div className={classes.root}>
    <div className={classes.value}><strong>{value}</strong></div>
    <div className={classes.caption}>{children}</div>
  </div>
);

export const CaptionedValue = memo(withStyles(styles)(CaptionedValueComponent));

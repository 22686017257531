import React from 'react';
import {
  IconButton,
  Badge,
  Popover,
  Grid,
  List,
  Divider,
  Box,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
} from '@mui/material';
import  {ClassNameMap, makeStyles } from '@mui/styles';
import { Notifications, AttachMoney, House, Alarm, PeopleAlt } from '@mui/icons-material';
import themeService from 'theme/ThemeService';
import { useNotifications } from 'shared/utils/useNotifications';
import { printListingAddress } from 'shared/utils/printListingAddress';
import { useObservable } from 'shared/utils/useObservable';
import { Typography } from 'shared/components/typography';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { notificationsQuery, NotificationModel } from '../../state';

const palette = themeService.getPalette();

const getNotificationIcon = (type: string) => {
  return (
    <>
      {type === 'sold' && <AttachMoney color="secondary" />}
      {type === 'listed' && <House color="primary" />}
      {type === 'pending' && <Alarm color="action" />}
      {type === 'recruited' && <PeopleAlt color="primary" />}
      {type === 'changeoffice' && <PeopleAlt color="secondary" />}
    </>
  );
};

interface Props {
  styles: ClassNameMap;
}

const initialLimit = 10;

export const NotificationsMenu: React.FC<Props> = ({styles}) => {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [limit, setLimit] = React.useState<number>(initialLimit);

  const totalNotifications = useObservable(notificationsQuery.totalNotifications);
  const loadedNotifications = useObservable(notificationsQuery.loadedNotifications);

  const { notifications, memberLookup, listingLookup } = useNotifications(limit);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const increaseLimit = () => {
    if (loadedNotifications < totalNotifications) setLimit(limit + initialLimit);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setLimit(initialLimit);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const goToAgent = (memberKey: string | null | undefined) => {
    if (!!memberKey) history.push('/agents/' + memberKey);
  };

  if (!notifications) return null;

  const notificationPrimaryText = ({ memberKey }: NotificationModel) => {
    if (!memberKey) return null;

    return (!!memberKey ? memberLookup.get(memberKey)?.fullName : '') ?? '...';
  };

  const notificationSecondaryText = ({ message, listingKey }: NotificationModel) => {
    if (!listingKey) return message;

    const listing = listingLookup.get(listingKey);

    return [message, '•', !listing ? 'Loading listing...' : printListingAddress(listing)].join(' ');
  };

  return (
    <>
      <IconButton
        aria-describedby={id}
        size="small"
        className={styles.button}
        onClick={event => handleClick(event as React.MouseEvent<HTMLButtonElement>)}
      >
        <Badge
          classes={{badge: styles.badge}}
          badgeContent={notifications.length}
          max={99}
        >
          <Notifications fontSize="small" />
        </Badge>
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12}>
            <Box bgcolor={palette.neutralLight} style={{ width: '100%' }}>
              <Typography align="center" variant="h4">
                Notifications
              </Typography>
              <Divider />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <List style={{ width: '100%' }}>
              {notifications.map(notification => (
                <React.Fragment key={notification.id}>
                  <ListItem
                    button
                    onClick={() => goToAgent(notification.memberKey)}
                    style={{ paddingRight: '120px' }}
                  >
                    <ListItemIcon>{getNotificationIcon(notification.type)}</ListItemIcon>
                    <ListItemText
                      primary={notificationPrimaryText(notification)}
                      secondary={notificationSecondaryText(notification)}
                    />
                    <ListItemSecondaryAction>
                      <Typography variant="caption">
                        {format(new Date(notification.date), 'MMM do yyyy')}
                      </Typography>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider component="li" />
                </React.Fragment>
              ))}
              {loadedNotifications < totalNotifications && (
                <ListItem button onClick={() => increaseLimit()}>
                  <ListItemText primary="See more..." />
                </ListItem>
              )}
              {notifications.length <= 0 && (
                <ListItem>
                  <ListItemText primary="There's no new notifications" />
                </ListItem>
              )}
            </List>
          </Grid>
        </Grid>
      </Popover>
    </>
  );
};

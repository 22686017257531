import React, { useState } from 'react';
import { differenceInHours } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import { Dialog, DialogTitle, DialogContent, Typography } from 'shared/components';

import { Log, ProcessLog } from '../../state/process-logs';
import { DataTable, DataTableColumn, Button } from 'shared/components';

interface Props {
  processLog: ProcessLog;
}

const MAX_HOURS_BETWEEN_FEED_SYNCS = 26;

export function ProcessLogList({ processLog }: Props) {
  const columns: DataTableColumn<Log>[] = [
    {
      renderHeader: () => <Typography noWrap fontWeight="bold">PROCESS</Typography>,
      headerAlign: 'center',
      align: 'left',
      field: 'processName',
      sortable: true,
    },
    {
      renderHeader: () => <Typography noWrap fontWeight="bold">RECORD COUNT</Typography>,
      headerAlign: 'center',
      align: 'center',
      field: 'recordCount',
      sortable: true,
    },
    {
      renderHeader: () => <Typography noWrap fontWeight="bold">START TIME (${getLocalTimeOffset()})</Typography>,
      headerAlign: 'center',
      align: 'center',
      renderCell: ({ row }) =>
        row.processStartTime ? new Date(row.processStartTime).toLocaleString() : undefined,
      field: 'processStartTime',
      sortable: true,
    },
    {
      renderHeader: () => <Typography noWrap fontWeight="bold">END TIME (${getLocalTimeOffset()})</Typography>,
      headerAlign: 'center',
      align: 'center',
      renderCell: ({ row }) =>
        row.processEndTime ? new Date(row.processEndTime).toLocaleString() : undefined,
      field: 'processEndTime',
      sortable: true,
    },
    {
      renderHeader: () => <Typography noWrap fontWeight="bold">EXCEPTION</Typography>,
      headerAlign: 'center',
      align: 'center',
      display: 'flex',
      renderCell: ({ row }) => {
        logHasException(row.processException || '', row.processStartTime || '') && (
          <ExceptionPreview log={row} />
        );
      },
      field: 'processException',
      sortable: true,
    },
    {
      headerName: '',
      field: 'hasException',
      align: 'center',
      display: 'flex',
      flex: undefined,
      renderCell: ({ row }) =>
        logHasException(row.processException || '', row.processStartTime || '') && (
          <ExceptionDetailButton log={row} />
        ),
    },
  ];

  return (
    <>
      <h2>{processLog.feed?.name}</h2>
      <DataTable
        columns={columns}
        rows={processLog.processLogs ?? []}
        getRowId={row => row.processId!}
        conditionalRowStyles={row =>
          logHasException(row.processException || '', row.processStartTime || '') ? 'red' : 'none'
        }
        initialState={{
          sorting: {
            sortModel: [
              {
                field: 'processName',
                sort: 'asc',
              },
            ],
          },
        }}
      />
    </>
  );
}

function ExceptionPreview({ log }: { log: Log }) {
  return (
    <div>
      <ul style={{ padding: 0, margin: 0 }}>
        {!!log.processException && <li>{log.processException.substring(0, 80)}...</li>}
        {isLogProcessOutdated(log.processStartTime || '') && <li>Outdated feed data</li>}
      </ul>
    </div>
  );
}

function ExceptionDetailButton({ log }: { log: Log }) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <>
      <Button type="button" size="small" variant="secondary" onClick={() => setIsDialogOpen(true)}>
        <FontAwesomeIcon icon={faCircleQuestion} size="lg" />
      </Button>

      <Dialog isOpen={isDialogOpen} onClose={() => setIsDialogOpen(false)} fullWidth>
        <DialogTitle>Exception details</DialogTitle>
        <DialogContent>
          <ul style={{ padding: 0, margin: 0 }}>
            {!!log.processException && <li>{log.processException}</li>}
            {isLogProcessOutdated(log.processStartTime || '') && (
              <li>
                Outdated feed data: The process was started more than {MAX_HOURS_BETWEEN_FEED_SYNCS}{' '}
                hours ago
              </li>
            )}
          </ul>
        </DialogContent>
      </Dialog>
    </>
  );
}

function logHasException(exception: string, processStartTime: string) {
  return !!exception || isLogProcessOutdated(processStartTime);
}

function isLogProcessOutdated(processStartTime: string) {
  const difference = differenceInHours(new Date(), new Date(processStartTime));
  return difference >= MAX_HOURS_BETWEEN_FEED_SYNCS;
}

function getLocalTimeOffset() {
  return new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2];
}

import React, { useContext } from 'react';

import { Recruit, DateRangeOptions, mlsService, activitiesService } from '../../state';
import { PageHeader, PeriodPicker, ThemeColors } from '../../shared';
import { ButtonLink, Typography } from 'shared/components';
import { FavoriteAgent } from 'shared/components/favorite-agent';
import { RecruitAgent } from '../../shared/components/recruit-agent';
import { ActiveAgentContext } from '../../shared/components/activity-history';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icons } from '../../shared';

interface Props {
  recruit?: Recruit;
  dateRange: DateRangeOptions;
  isFavorite: (memberKey: string) => boolean;
  toggleFavorite: (memberKey: string) => void;
  isRecruit: (memberKey: string) => boolean;
  isRecruited: (memberKey: string) => boolean;
  toggleRecruit: (memberKey: string) => void;
}

export const AgentHeader: React.FC<Props> = ({
  recruit,
  dateRange,
  isFavorite,
  toggleFavorite,
  isRecruit,
  isRecruited,
  toggleRecruit,
}) => {
  const agentContext = useContext(ActiveAgentContext);
  const agent = agentContext.agent;
  const updateDateRange = (newRange: DateRangeOptions) => {
    mlsService.setActiveDateRange(newRange);
    activitiesService.setActiveDateRange(newRange);
  };
  return (
    <PageHeader
      title={
        <>
          <>
            {agent.fullName}{' '}
            <Typography component="small" variant="subtitle">
              {agent.memberNumber}
            </Typography>
          </>
          <a
            href={`https://www.google.com/search?q=${agent.fullName} Realtor ${agent.officeName}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: ThemeColors.Teal }}
          >
            <FontAwesomeIcon icon={icons.searchGlass} size="xs" style={{ margin: '0 .5rem' }} />
          </a>
        </>
      }
      backButton
      navigation={[
        <RecruitAgent
          memberKey={agent.memberKey}
          isRecruit={isRecruit(agent.memberKey)}
          isRecruited={isRecruited(agent.memberKey)}
          toggleRecruit={toggleRecruit}
        />,
        <FavoriteAgent
          memberKey={agent.memberKey}
          isFavorite={isFavorite(agent.memberKey)}
          toggleFavorite={toggleFavorite}
        />,
      ]}
      filters={[
        <PeriodPicker activeRange={dateRange} updateRange={updateDateRange} />,
        recruit ? (
          <ButtonLink
            to={`/pipeline/${recruit.id}/${agent.memberKey}/valuation`}
            variant="secondary"
          >
            Net Income Valuation
          </ButtonLink>
        ) : (
          <ButtonLink
            to={`/pipeline/0/${agent.memberKey}/valuation`}
            variant="secondary"
          >
            Net Income Valuation
          </ButtonLink>
        ),
      ]}
    ></PageHeader>
  );
};

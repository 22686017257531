import axios, { AxiosResponse, Cancel, CancelTokenSource } from 'axios';
import { Observable } from 'rxjs';

/**
 * Decorator to enable request cancellation on subsequent invocations of a method.
 * Method must return an observable and have a final parameter that is passed
 * to the api method `options` parameter.
 *
 * @returns Decorator that injects a cancellation token.
 */
export function RequestCancellation() {
  let tokenSource: CancelTokenSource;

  return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
    const original = descriptor.value;

    if (typeof original !== 'function') {
      throw new Error('RequestCancellation can only be used as a method decorator.');
    }

    descriptor.value = function (...args: any[]) {
      // Cancel inflight requests and replace cancel token source.
      tokenSource?.cancel();
      tokenSource = axios.CancelToken.source();

      // Ensure we have the correct number of arguments.
      // If the method has multiple optional parameters,
      // the number of passed arguments might be lower
      // than expected.
      if (original.length > args.length) {
        const needed = Array(original.length - args.length);
        args = args.concat(needed);
      }

      args[args.length - 1] = { ...(args[args.length - 1] ?? {}), cancelToken: tokenSource.token };

      return new Observable<AxiosResponse<any>>(sub => {
        original.apply(this, args).subscribe({
          next(value: any) {
            sub.next(value);
          },
          error(err: any) {
            // Swallow cancellations but emit other errors.
            if (isCancel(err)) return;

            sub.error(err);
          },
          complete() {
            sub.complete();
          },
        });

        return () => tokenSource.cancel();
      });
    };

    return descriptor;
  };
}

function isCancel(obj: any): obj is Cancel {
  return !!obj?.__CANCEL__;
}

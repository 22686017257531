/* eslint-disable */
// tslint:disable
/**
 * MLS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';

import { GetTransactionsByListingKeysRequest } from '../model';
import { GetTransactionsResponse } from '../model';
/**
 * TransactionsApi - axios parameter creator
 * @export
 */
export const TransactionsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} memberKey
     * @param {string} [fromDate]
     * @param {string} [toDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsTransactionsGet(
      memberKey: string,
      fromDate?: string,
      toDate?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'memberKey' is not null or undefined
      if (memberKey === null || memberKey === undefined) {
        throw new RequiredError(
          'memberKey',
          'Required parameter memberKey was null or undefined when calling mlsTransactionsGet.'
        );
      }
      const localVarPath = `/mls/transactions`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (memberKey !== undefined) {
        localVarQueryParameter['MemberKey'] = memberKey;
      }

      if (fromDate !== undefined) {
        localVarQueryParameter['FromDate'] =
          (fromDate as any) instanceof Date ? (fromDate as any).toISOString() : fromDate;
      }

      if (toDate !== undefined) {
        localVarQueryParameter['ToDate'] =
          (toDate as any) instanceof Date ? (toDate as any).toISOString() : toDate;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      //@ts-ignore
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {GetTransactionsByListingKeysRequest} [getTransactionsByListingKeysRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsTransactionsPost(
      getTransactionsByListingKeysRequest?: GetTransactionsByListingKeysRequest,
      options: any = {}
    ): RequestArgs {
      const localVarPath = `/mls/transactions`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      //@ts-ignore
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof getTransactionsByListingKeysRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(
            getTransactionsByListingKeysRequest !== undefined
              ? getTransactionsByListingKeysRequest
              : {}
          )
        : getTransactionsByListingKeysRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TransactionsApi - functional programming interface
 * @export
 */
export const TransactionsApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} memberKey
     * @param {string} [fromDate]
     * @param {string} [toDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsTransactionsGet(
      memberKey: string,
      fromDate?: string,
      toDate?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTransactionsResponse> {
      const localVarAxiosArgs = TransactionsApiAxiosParamCreator(configuration).mlsTransactionsGet(
        memberKey,
        fromDate,
        toDate,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {GetTransactionsByListingKeysRequest} [getTransactionsByListingKeysRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsTransactionsPost(
      getTransactionsByListingKeysRequest?: GetTransactionsByListingKeysRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTransactionsResponse> {
      const localVarAxiosArgs = TransactionsApiAxiosParamCreator(configuration).mlsTransactionsPost(
        getTransactionsByListingKeysRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * TransactionsApi - factory interface
 * @export
 */
export const TransactionsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {string} memberKey
     * @param {string} [fromDate]
     * @param {string} [toDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsTransactionsGet(
      memberKey: string,
      fromDate?: string,
      toDate?: string,
      options?: any
    ): AxiosPromise<GetTransactionsResponse> {
      return TransactionsApiFp(configuration).mlsTransactionsGet(
        memberKey,
        fromDate,
        toDate,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {GetTransactionsByListingKeysRequest} [getTransactionsByListingKeysRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mlsTransactionsPost(
      getTransactionsByListingKeysRequest?: GetTransactionsByListingKeysRequest,
      options?: any
    ): AxiosPromise<GetTransactionsResponse> {
      return TransactionsApiFp(configuration).mlsTransactionsPost(
        getTransactionsByListingKeysRequest,
        options
      )(axios, basePath);
    },
  };
};

/**
 * TransactionsApi - object-oriented interface
 * @export
 * @class TransactionsApi
 * @extends {BaseAPI}
 */
export class TransactionsApi extends BaseAPI {
  /**
   *
   * @param {string} memberKey
   * @param {string} [fromDate]
   * @param {string} [toDate]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransactionsApi
   */
  public mlsTransactionsGet(memberKey: string, fromDate?: string, toDate?: string, options?: any) {
    return TransactionsApiFp(this.configuration).mlsTransactionsGet(
      memberKey,
      fromDate,
      toDate,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {GetTransactionsByListingKeysRequest} [getTransactionsByListingKeysRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransactionsApi
   */
  public mlsTransactionsPost(
    getTransactionsByListingKeysRequest?: GetTransactionsByListingKeysRequest,
    options?: any
  ) {
    return TransactionsApiFp(this.configuration).mlsTransactionsPost(
      getTransactionsByListingKeysRequest,
      options
    )(this.axios, this.basePath);
  }
}

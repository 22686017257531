import { ReactNode, ElementType } from 'react';
import {SxProps, Theme, Typography as MuiTypography } from '@mui/material';

type TypographyVariant = 'body' | 'caption' | 'subtitle' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

type TypographyProps = {
  variant?: TypographyVariant;
  align?: 'left' | 'center' | 'right';
  color?: 'default' | 'error';
  fontWeight?: 'normal' | 'bold';
  noWrap?: boolean;
  children: ReactNode;
  component?: ElementType<any>;
  sx?: SxProps<Theme>;
};

export function Typography({
  children,
  variant = 'body',
  color = 'default',
  fontWeight = 'normal',
  sx,
  ...rest
}: TypographyProps) {
  return (
    <MuiTypography
      color={color}
      fontWeight={fontWeight}
      variant={variantMappingToMui(variant)}
      sx={sx}
      {...rest}
    >
      {children}
    </MuiTypography>
  );
}

function variantMappingToMui(variant: TypographyVariant) {
  if (variant === 'body') return 'body1';
  if (variant === 'subtitle') return 'subtitle1';

  return variant;
}

import { map, shareReplay } from 'rxjs/operators';
import { EntityState, EntityStore, StoreConfig, QueryEntity } from '@datorama/akita';

import { distinctUntilArrayChanged } from '../util';
import { Office } from '../mls.models';

export interface OfficesState extends EntityState<Office, Office['officeKey']> {}

@StoreConfig({ name: 'offices', resettable: true, idKey: 'officeKey' })
export class OfficesStore extends EntityStore<OfficesState> {
  static instance: OfficesStore;

  constructor() {
    super({
      //Akita already initializes the store with loading set to true.
      //we need it to be false to drive our progress indicators
      //which rely on this flag starting off as false
      //https://github.com/datorama/akita/issues/22#issuecomment-408659859
      loading: false,
    });
  }
}

export class OfficesQuery extends QueryEntity<OfficesState> {
  static instance: OfficesQuery;

  lookup = this.selectAll().pipe(
    distinctUntilArrayChanged(),
    map(offices => new Map<string, Office>(offices.map(x => [x.officeKey, x]))),
    shareReplay(1)
  );
}

OfficesStore.instance = new OfficesStore();
OfficesQuery.instance = new OfficesQuery(OfficesStore.instance);

import React, { useEffect } from 'react';
import { AppConfig } from '../../config';

export function LinkToSso() {
  const oauth = AppConfig.KvCore.OAuth;
  const ssoUrl = `${oauth.AuthorizeEndpoint}?client_id=${
    oauth.ClientId
  }&redirect_uri=${encodeURIComponent(
    oauth.CallbackUrl
  )}&response_type=token&scope=crm&state=kvCore`;

  useEffect(() => {
    window.location.href = ssoUrl;
  }, []);

  return <></>;
}

import React from 'react';
import { Grid } from '@mui/material';
import { MapStat } from 'shared/components/map-stat';
import { Listing } from '../../state';
import { MapStatsColor } from 'shared/components/map-stat';

interface Props {
  buckets: Array<{ label: string; color: MapStatsColor; listings: Array<Listing> }>;
}

export const AgentTransactionStatsMap: React.FC<Props> = ({ buckets }) => {
  return (
    <Grid container spacing={2}>
      {buckets.map(({ label, color, listings }) => (
        <Grid key={label} item xs={12} sm={6} md={3} style={{ display: 'flex' }}>
          <MapStat title={label} listings={listings.length} color={color} />
        </Grid>
      ))}
    </Grid>
  );
};

import themeService from 'theme/ThemeService';

const palette = themeService.getPalette();

export const theme = {
  mapStat: {
    colors: {
      teal: palette.teal,
      yellow: palette.yellow,
      purple: palette.purple,
      red: palette.red,
    }
  },
};

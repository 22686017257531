import { SimpleUserModel } from 'api/auth/model';
import React, {useEffect, useState} from 'react';
import { DataTableColumn, icons, PageHeader, SearchInput } from 'shared';
import {Card, CardContent, Grid, Typography} from "@mui/material";
import { makeStyles } from '@mui/styles';
import { ImpersonateButton, UsersList } from 'admin/components';
import { useUsers } from 'admin/hooks';

export const UserImpersonationPage: React.FC = () => {
  const [filterValue, setFilterValue] = useState<string>("");
  const [debouncedFilterValue, setDebouncedFilterValue] = useState<string>("");
  const [tableData, setTableData] = useState<SimpleUserModel[]>([]);

  const users = useUsers(debouncedFilterValue);

  useEffect(() => {
    setTableData(users);
  }, [users]);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedFilterValue(filterValue), 500)

    return () => clearTimeout(timer)
  }, [filterValue]);

  return (
    <>
      <PageHeader
        title="USER IMPERSONATION"
        icon={icons.admin}
      />
      <Card>
        <CardContent>
          <Grid container item spacing={2} xs={12}>
            <Grid item xs={12}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={8}>
                  <div style={{ width: '500px' }}>
                    <SearchInput
                      value={filterValue}
                      placeholder="Search by name, email, or company..."
                      onChange={(event) => setFilterValue(event.target.value)}
                      fullWidth
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <UsersList users={users} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}

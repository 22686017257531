import { useEffect } from 'react';
import { userService } from '../state/users/user.service';
import { useObservable } from '../shared';
import { userQuery } from '../state/users';

export function useActiveUserProfile() {
  useEffect(() => {
    userService.getProfile();
  }, []);

  const user = useObservable(userQuery.activeUser);

  return user;
}

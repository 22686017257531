import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { SurveyForm, ErrorDisplay } from '../components';
import { CreateSurveyRequest } from '../../api/app';
import { LoadingFade, PageHeader } from '../../shared';
import {
  ConvertDefaultToSurveyQuestions,
  SurveyForms,
  SurveyQuestion,
  surveysService,
} from '../../state/surveys';
import { useActiveSurvey, useDefaultQuestions } from '../hooks';
import { useFormEvents } from '../../shared/forms';
import { FormStatus } from '../../state';
import { getError } from '../../api';
import { ConfirmationDialog } from '../components/confirmation-dialog';

export function CreateSurveyPage() {
  const history = useHistory();
  const [{ status, error: formError }] = useFormEvents(SurveyForms.Create);
  const { survey: activeSurvey } = useActiveSurvey();
  const {
    questions,
    loading: contentLoading,
    error: defaultQuestionsError,
  } = useDefaultQuestions();
  const [open, setOpen] = useState(false);
  const [survey, setSurvey] = useState<CreateSurveyRequest | undefined>(undefined);

  const onSubmit = (title: string, questions: Array<SurveyQuestion>) => {
    setSurvey({ surveyTitle: title, questions, active: true });
    setOpen(true);
  };

  const setActive = useCallback(
    (active: boolean) => {
      if (survey) {
        let newSurvey = survey;
        survey.active = active;
        setSurvey(newSurvey);
      }
    },
    [setSurvey, survey]
  );

  const handleClose = useCallback(() => {
    setOpen(false);
    if (survey) {
      surveysService.createSurvey(survey);
    }
  }, [setOpen, survey]);

  const onConfirm = useCallback(() => {
    setActive(true);
    handleClose();
  }, [setActive, handleClose]);

  const onDeny = useCallback(() => {
    setActive(false);
    handleClose();
  }, [setActive, handleClose]);

  const onErrorExit = () => {
    history.goBack();
  };

  useEffect(() => {
    if (status === FormStatus.Success) history.goBack();
  }, [status, history]);

  return (
    <LoadingFade isContentLoading={contentLoading}>
      <>
        <ErrorDisplay
          open={!!defaultQuestionsError}
          onClose={onErrorExit}
          message={'Error loading default questions!\nPlease contact your office admin!'}
        />
        <ConfirmationDialog
          open={open}
          handleClose={() => setOpen(false)}
          onConfirm={onConfirm}
          onDeny={onDeny}
          activeSurvey={!!activeSurvey}
        />
        <PageHeader title="Create Survey" backButton />
        <SurveyForm
          defaultQuestions={ConvertDefaultToSurveyQuestions(questions)}
          onSubmit={onSubmit}
          error={!!formError ? getError(formError) : undefined}
        />
      </>
    </LoadingFade>
  );
}

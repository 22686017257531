/* eslint-disable */
// tslint:disable
/**
 * Auth API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';

import { CreateUserRequest } from '../model';
import { GetUsersResponse } from '../model';
import { UpdateUserRequest } from '../model';
import { UserModel } from '../model';
/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateUserRequest} [createUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersCreatePost(createUserRequest?: CreateUserRequest, options: any = {}): RequestArgs {
            const localVarPath = `/users/create`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            //@ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof createUserRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createUserRequest !== undefined ? createUserRequest : {}) : (createUserRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersIdPasswordResetPost(id: string, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling usersIdPasswordResetPost.');
            }
            const localVarPath = `/users/{id}/password/reset`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            //@ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateUserRequest} [updateUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersIdPut(id: string, updateUserRequest?: UpdateUserRequest, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling usersIdPut.');
            }
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            //@ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof updateUserRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateUserRequest !== undefined ? updateUserRequest : {}) : (updateUserRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersListGet(searchTerm?: string, options: any = {}): RequestArgs {
            const localVarPath = `/users/list`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            //@ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateUserRequest} [createUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersCreatePost(createUserRequest?: CreateUserRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserModel> {
            const localVarAxiosArgs = UsersApiAxiosParamCreator(configuration).usersCreatePost(createUserRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersIdPasswordResetPost(id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = UsersApiAxiosParamCreator(configuration).usersIdPasswordResetPost(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateUserRequest} [updateUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersIdPut(id: string, updateUserRequest?: UpdateUserRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserModel> {
            const localVarAxiosArgs = UsersApiAxiosParamCreator(configuration).usersIdPut(id, updateUserRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersListGet(searchTerm?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUsersResponse> {
            const localVarAxiosArgs = UsersApiAxiosParamCreator(configuration).usersListGet(searchTerm, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {CreateUserRequest} [createUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersCreatePost(createUserRequest?: CreateUserRequest, options?: any): AxiosPromise<UserModel> {
            return UsersApiFp(configuration).usersCreatePost(createUserRequest, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersIdPasswordResetPost(id: string, options?: any): AxiosPromise<void> {
            return UsersApiFp(configuration).usersIdPasswordResetPost(id, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateUserRequest} [updateUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersIdPut(id: string, updateUserRequest?: UpdateUserRequest, options?: any): AxiosPromise<UserModel> {
            return UsersApiFp(configuration).usersIdPut(id, updateUserRequest, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersListGet(searchTerm?: string, options?: any): AxiosPromise<GetUsersResponse> {
            return UsersApiFp(configuration).usersListGet(searchTerm, options)(axios, basePath);
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @param {CreateUserRequest} [createUserRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersCreatePost(createUserRequest?: CreateUserRequest, options?: any) {
        return UsersApiFp(this.configuration).usersCreatePost(createUserRequest, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersIdPasswordResetPost(id: string, options?: any) {
        return UsersApiFp(this.configuration).usersIdPasswordResetPost(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateUserRequest} [updateUserRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersIdPut(id: string, updateUserRequest?: UpdateUserRequest, options?: any) {
        return UsersApiFp(this.configuration).usersIdPut(id, updateUserRequest, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [searchTerm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersListGet(searchTerm?: string, options?: any) {
        return UsersApiFp(this.configuration).usersListGet(searchTerm, options)(this.axios, this.basePath);
    }

}

import { Mls } from '../../../api';
import { CompareValue } from '../../compare-value';

export type MarketShare = {
  volume: CompareValue<number>;
  activeListings: CompareValue<number, undefined>;
};

export function getDefaultMarketShare(): MarketShare {
  return {
    activeListings: { current: 0, previous: undefined },
    volume: { current: 0, previous: 0 },
  };
}

export function getMarketShare(model?: Mls.MarketVolumeComparisonModel) {
  if (!model) return getDefaultMarketShare();

  const { active, current, previous } = model;

  return {
    activeListings: {
      current: active.count ?? 0,
      previous: undefined,
    },
    volume: {
      current: current.total ?? 0,
      previous: previous.total ?? 0,
    },
  };
}

export interface CompareValue<TCurrent, TPrevious = TCurrent> {
  current: TCurrent;
  previous: TPrevious;
}

function streamingAverage() {
  let count = 0;
  let sum = 0;
  return (s: number, c = 1): number => {
    count += c;
    sum += s;

    return Math.round(count === 0 ? 0 : sum / count);
  };
}

export class AvgCompareValue implements CompareValue<number> {
  current = 0;
  previous = 0;

  private currentAvg = streamingAverage();
  private previousAvg = streamingAverage();

  add(prop: 'current' | 'previous', partial: number, count = 1) {
    if (prop === 'current') {
      this.current = this.currentAvg(partial, count);
    } else {
      this.previous = this.previousAvg(partial, count);
    }
  }

  serialize(): CompareValue<number> {
    return { current: this.current, previous: this.previous };
  }
}

import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider as MuiThemeProvider, StyledEngineProvider, CssBaseline } from '@mui/material';
import { ThemeProvider as AmpstatsThemeProvider } from 'theme/ThemeContext';
import { AppConfig } from './config';
import themeService from 'theme/ThemeService';

import { theme } from 'theme/material-theme';
import { GoogleAnalytics } from './shared/components';
import { AppRoutes } from './app.routes';
import { IntlProvider } from 'react-intl';
import { CookiesProvider } from 'react-cookie';

export const App = () => (
  <AppProviders>
    <AppRoutes />
    {AppConfig.GoogleAnalytics.Required ? <GoogleAnalytics /> : null}
  </AppProviders>
);

export const AppProviders: React.FC = ({ children }) => {
  return (
    <CookiesProvider>
      <StyledEngineProvider injectFirst>
        <AmpstatsThemeProvider theme={themeService.getTheme()}>
          <MuiThemeProvider theme={{...theme, ampstats: themeService.getTheme()}}>
            <CssBaseline />
            <IntlProvider locale={'en'}>
              <Router>{children}</Router>
            </IntlProvider>
          </MuiThemeProvider>
        </AmpstatsThemeProvider>
      </StyledEngineProvider>
    </CookiesProvider>
  );
};

import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { FormControl } from '../../shared/forms/controls';
import { QuickFilterButton } from '../../shared/forms';
import { Typography, TextField } from 'shared/components';

export enum HotListType {
  Inactive = 'Agents with 0 Active',
  New = 'Newly Licensed Agents',
  Moved = 'Agents who Moved',
}

export enum DayFilter {
  Days30,
  Days60,
  Custom,
}

interface HotListProps {
  title?: string;
  enabledFilters: HotListType[];
  disabled?: boolean;
  defaultFilter: HotListType;
  setHotList(selectedFilter: HotListType | null): void;
  enabledDays: DayFilter[];
  daysSinceLastClose: FormControl;
}

export function FilterHotList({
  title,
  enabledFilters,
  disabled = false,
  defaultFilter,
  setHotList,
  enabledDays,
  daysSinceLastClose,
}: HotListProps): React.ReactElement {
  const [filterSelection, setFilterSelection] = useState(defaultFilter);
  const [daySelection, setDaySelection] = useState(DayFilter.Days60);

  const getLabel = (filter: HotListType): string => {
    if (filter === HotListType.Inactive)
      return 'Agents with 0 active, pending.No closings in last 60 days and YOY sales volume down 20%+';
    else if (filter === HotListType.New)
      return 'Newly licensed agents from 10-12 months ago with at least 3 deals or $1 million in sales';
    else if (filter === HotListType.Moved)
      return 'Agents who moved 10-12 months ago and business is down 10% +';
    return '';
  };

  const getDays = (filter: DayFilter): string => {
    if (filter === DayFilter.Custom) return 'Custom';
    else if (filter === DayFilter.Days30) return '30 Days';
    else if (filter === DayFilter.Days60) return '60 Days';

    return '';
  };

  useEffect(() => {
    if (filterSelection !== null) setHotList(filterSelection);
  }, [filterSelection]);

  useEffect(() => {
    switch (daySelection) {
      case DayFilter.Days30:
        daysSinceLastClose.setValue(30);
        break;
      case DayFilter.Days60:
        daysSinceLastClose.setValue(60);
        break;
    }
  }, [daySelection]);
  return (
    <>
      <Grid container spacing={2}>
        {title ? (
          <Grid item>
            <Typography variant="subtitle" align="center">
              {title}
            </Typography>
          </Grid>
        ) : undefined}
        {enabledFilters.map(filter => (
          <Grid item md={4} key={filter} style={{ display: 'flex', width: '100%' }}>
            <QuickFilterButton
              label={getLabel(filter)}
              filter={filter}
              active={filterSelection === filter}
              disabled={disabled}
              onSelection={setFilterSelection}
            />
          </Grid>
        ))}
        {/* Keeping code available in case client changes mind for options */}
        {/* To re-enable, just replace `false` with `filterSelection === HotListType.Inactive` */}
        {false ? (
          <>
            <Typography variant="subtitle" align="center">
              Select Days since last closed transaction
            </Typography>
            <Grid item md={12} style={{ display: 'flex', width: '100%' }}>
              {enabledDays.map(filter => (
                <React.Fragment key={filter}>
                  <QuickFilterButton
                    label={getDays(filter)}
                    filter={filter}
                    active={daySelection === filter}
                    disabled={disabled}
                    onSelection={setDaySelection}
                  />
                </React.Fragment>
              ))}
            </Grid>
            {daySelection === DayFilter.Custom ? (
              <Grid item md={12} style={{ display: 'flex', width: '100%' }}>
                <TextField
                  name="daysSinceLastClose"
                  label="Days since last close"
                  type="number"
                  {...daysSinceLastClose.bind}
                />
              </Grid>
            ) : undefined}
          </>
        ) : undefined}
      </Grid>
    </>
  );
}

/* eslint-disable */
// tslint:disable
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';

import { CreateRecruitResponse } from '../model';
import { GetManagerRecruitsResponse } from '../model';
import { GetRecruitResponse } from '../model';
import { UpdateRecruitingGoalRequest } from '../model';
import { UpdateRecruitingGoalResponse } from '../model';
/**
 * RecruitingApi - axios parameter creator
 * @export
 */
export const RecruitingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} managerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recruitingManagerIdGet(managerId: string, options: any = {}): RequestArgs {
            // verify required parameter 'managerId' is not null or undefined
            if (managerId === null || managerId === undefined) {
                throw new RequiredError('managerId','Required parameter managerId was null or undefined when calling recruitingManagerIdGet.');
            }
            const localVarPath = `/recruiting/{managerId}`
                .replace(`{${"managerId"}}`, encodeURIComponent(String(managerId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            //@ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} managerId 
         * @param {number} year 
         * @param {UpdateRecruitingGoalRequest} [updateRecruitingGoalRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recruitingManagerIdGoalYearPost(managerId: string, year: number, updateRecruitingGoalRequest?: UpdateRecruitingGoalRequest, options: any = {}): RequestArgs {
            // verify required parameter 'managerId' is not null or undefined
            if (managerId === null || managerId === undefined) {
                throw new RequiredError('managerId','Required parameter managerId was null or undefined when calling recruitingManagerIdGoalYearPost.');
            }
            // verify required parameter 'year' is not null or undefined
            if (year === null || year === undefined) {
                throw new RequiredError('year','Required parameter year was null or undefined when calling recruitingManagerIdGoalYearPost.');
            }
            const localVarPath = `/recruiting/{managerId}/goal/{year}`
                .replace(`{${"managerId"}}`, encodeURIComponent(String(managerId)))
                .replace(`{${"year"}}`, encodeURIComponent(String(year)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            //@ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof updateRecruitingGoalRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateRecruitingGoalRequest !== undefined ? updateRecruitingGoalRequest : {}) : (updateRecruitingGoalRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} managerId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recruitingManagerIdIdDelete(managerId: string, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'managerId' is not null or undefined
            if (managerId === null || managerId === undefined) {
                throw new RequiredError('managerId','Required parameter managerId was null or undefined when calling recruitingManagerIdIdDelete.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling recruitingManagerIdIdDelete.');
            }
            const localVarPath = `/recruiting/{managerId}/{id}`
                .replace(`{${"managerId"}}`, encodeURIComponent(String(managerId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            //@ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} managerId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recruitingManagerIdIdGet(managerId: string, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'managerId' is not null or undefined
            if (managerId === null || managerId === undefined) {
                throw new RequiredError('managerId','Required parameter managerId was null or undefined when calling recruitingManagerIdIdGet.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling recruitingManagerIdIdGet.');
            }
            const localVarPath = `/recruiting/{managerId}/{id}`
                .replace(`{${"managerId"}}`, encodeURIComponent(String(managerId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            //@ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} managerId 
         * @param {string} memberKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recruitingManagerIdRecruitMemberKeyPut(managerId: string, memberKey: string, options: any = {}): RequestArgs {
            // verify required parameter 'managerId' is not null or undefined
            if (managerId === null || managerId === undefined) {
                throw new RequiredError('managerId','Required parameter managerId was null or undefined when calling recruitingManagerIdRecruitMemberKeyPut.');
            }
            // verify required parameter 'memberKey' is not null or undefined
            if (memberKey === null || memberKey === undefined) {
                throw new RequiredError('memberKey','Required parameter memberKey was null or undefined when calling recruitingManagerIdRecruitMemberKeyPut.');
            }
            const localVarPath = `/recruiting/{managerId}/recruit/{memberKey}`
                .replace(`{${"managerId"}}`, encodeURIComponent(String(managerId)))
                .replace(`{${"memberKey"}}`, encodeURIComponent(String(memberKey)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            //@ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RecruitingApi - functional programming interface
 * @export
 */
export const RecruitingApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} managerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recruitingManagerIdGet(managerId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetManagerRecruitsResponse> {
            const localVarAxiosArgs = RecruitingApiAxiosParamCreator(configuration).recruitingManagerIdGet(managerId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} managerId 
         * @param {number} year 
         * @param {UpdateRecruitingGoalRequest} [updateRecruitingGoalRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recruitingManagerIdGoalYearPost(managerId: string, year: number, updateRecruitingGoalRequest?: UpdateRecruitingGoalRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateRecruitingGoalResponse> {
            const localVarAxiosArgs = RecruitingApiAxiosParamCreator(configuration).recruitingManagerIdGoalYearPost(managerId, year, updateRecruitingGoalRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} managerId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recruitingManagerIdIdDelete(managerId: string, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = RecruitingApiAxiosParamCreator(configuration).recruitingManagerIdIdDelete(managerId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} managerId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recruitingManagerIdIdGet(managerId: string, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRecruitResponse> {
            const localVarAxiosArgs = RecruitingApiAxiosParamCreator(configuration).recruitingManagerIdIdGet(managerId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} managerId 
         * @param {string} memberKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recruitingManagerIdRecruitMemberKeyPut(managerId: string, memberKey: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateRecruitResponse> {
            const localVarAxiosArgs = RecruitingApiAxiosParamCreator(configuration).recruitingManagerIdRecruitMemberKeyPut(managerId, memberKey, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * RecruitingApi - factory interface
 * @export
 */
export const RecruitingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} managerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recruitingManagerIdGet(managerId: string, options?: any): AxiosPromise<GetManagerRecruitsResponse> {
            return RecruitingApiFp(configuration).recruitingManagerIdGet(managerId, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} managerId 
         * @param {number} year 
         * @param {UpdateRecruitingGoalRequest} [updateRecruitingGoalRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recruitingManagerIdGoalYearPost(managerId: string, year: number, updateRecruitingGoalRequest?: UpdateRecruitingGoalRequest, options?: any): AxiosPromise<UpdateRecruitingGoalResponse> {
            return RecruitingApiFp(configuration).recruitingManagerIdGoalYearPost(managerId, year, updateRecruitingGoalRequest, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} managerId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recruitingManagerIdIdDelete(managerId: string, id: number, options?: any): AxiosPromise<void> {
            return RecruitingApiFp(configuration).recruitingManagerIdIdDelete(managerId, id, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} managerId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recruitingManagerIdIdGet(managerId: string, id: number, options?: any): AxiosPromise<GetRecruitResponse> {
            return RecruitingApiFp(configuration).recruitingManagerIdIdGet(managerId, id, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} managerId 
         * @param {string} memberKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recruitingManagerIdRecruitMemberKeyPut(managerId: string, memberKey: string, options?: any): AxiosPromise<CreateRecruitResponse> {
            return RecruitingApiFp(configuration).recruitingManagerIdRecruitMemberKeyPut(managerId, memberKey, options)(axios, basePath);
        },
    };
};

/**
 * RecruitingApi - object-oriented interface
 * @export
 * @class RecruitingApi
 * @extends {BaseAPI}
 */
export class RecruitingApi extends BaseAPI {
    /**
     * 
     * @param {string} managerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecruitingApi
     */
    public recruitingManagerIdGet(managerId: string, options?: any) {
        return RecruitingApiFp(this.configuration).recruitingManagerIdGet(managerId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} managerId 
     * @param {number} year 
     * @param {UpdateRecruitingGoalRequest} [updateRecruitingGoalRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecruitingApi
     */
    public recruitingManagerIdGoalYearPost(managerId: string, year: number, updateRecruitingGoalRequest?: UpdateRecruitingGoalRequest, options?: any) {
        return RecruitingApiFp(this.configuration).recruitingManagerIdGoalYearPost(managerId, year, updateRecruitingGoalRequest, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} managerId 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecruitingApi
     */
    public recruitingManagerIdIdDelete(managerId: string, id: number, options?: any) {
        return RecruitingApiFp(this.configuration).recruitingManagerIdIdDelete(managerId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} managerId 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecruitingApi
     */
    public recruitingManagerIdIdGet(managerId: string, id: number, options?: any) {
        return RecruitingApiFp(this.configuration).recruitingManagerIdIdGet(managerId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} managerId 
     * @param {string} memberKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecruitingApi
     */
    public recruitingManagerIdRecruitMemberKeyPut(managerId: string, memberKey: string, options?: any) {
        return RecruitingApiFp(this.configuration).recruitingManagerIdRecruitMemberKeyPut(managerId, memberKey, options)(this.axios, this.basePath);
    }

}

import { useEffect, useState } from 'react';
import { useObservable } from '../shared';
import {
  companiesQuery,
  companiesService,
  appofficesService,
  appofficesQuery,
  managersQuery,
  managersService,
  mlsofficesQuery,
  mlsofficesService,
  regionsQuery,
  regionsService,
  feedQuery,
  feedService,
  interstitialService,
  interstitialQuery,
  termsAcceptanceQuery,
  usersService,
  usersQuery,
} from '../state/admin';
import { processLogQuery, processLogService } from '../state/process-logs';

export function useOffices() {
  useEffect(() => {
    mlsofficesService.getCompanies();
    feedService.getFeeds();
  }, []);

  const companyKeys = useObservable(mlsofficesQuery.companyKeys);

  useEffect(() => {
    mlsofficesService.getOffices(companyKeys);
  }, [companyKeys]);

  const companies = useObservable(mlsofficesQuery.companies);
  const offices = useObservable(mlsofficesQuery.offices);
  const feedOptions = useObservable(feedQuery.feeds);
  return { companies, offices, feedOptions };
}

export function useCompanies(filterValue?: string) {
  useEffect(() => {
    companiesService.getCompanies(filterValue);
  }, [filterValue]);
  const companies = useObservable(companiesQuery.companies);
  return companies;
}

export function useCompany(companyId: number) {
  useEffect(() => {
    companiesService.getCompany(companyId);
  }, [companyId]);

  const company = useObservable(companiesQuery.activeCompany);

  useEffect(() => {
    if (!!company && !!company.companyCodes) mlsofficesService.setCompanyKeys(company.companyCodes);
  }, [company]);

  return { company, loading: useObservable(companiesQuery.loading) };
}

export function useRegions() {
  useEffect(() => {
    regionsService.getRegions();
  }, []);
  const cities = useObservable(regionsQuery.cities);
  return { cities };
}

export function useCompanyManagers(companyId: number) {
  useEffect(() => {
    managersService.getManagers(companyId);
  }, [companyId]);

  const managers = useObservable(managersQuery.companyManagers);

  return managers;
}

export function useCompanyOffices(companyId: number) {
  useEffect(() => {
    appofficesService.getCompanyOffices(companyId);
  }, [companyId]);

  const companyOffices = useObservable(appofficesQuery.offices);
  return { companyOffices, loading: useObservable(appofficesQuery.loading) };
}

export function useManager(managerId: string) {
  useEffect(() => {
    managersService.getManager(managerId);
  }, [managerId]);

  const manager = useObservable(managersQuery.activeManager);
  return manager;
}

export function useProcessLogs() {
  useEffect(() => {
    processLogService.getProcessLogs();
  }, []);

  return {
    processLogs: useObservable(processLogQuery.processLogs),
    loading: useObservable(processLogQuery.loading),
    error: useObservable(processLogQuery.error),
  };
}

export function useFeeds() {
  useEffect(() => {
    feedService.getFeeds();
  }, []);

  return {
    feeds: useObservable(feedQuery.feeds),
    loading: useObservable(feedQuery.loading),
    error: useObservable(feedQuery.error),
  }
}

export function useInterstitial() {
  useEffect(() => {
    interstitialService.getInterstitial();
  }, []);

  return useObservable(interstitialQuery.interstitial);
}

export function useTermsAcceptance() {
  useEffect(() => {
    interstitialService.getTermsAcceptance();
  }, []);

  return useObservable(termsAcceptanceQuery.acceptanceList);
}

export function useUsers(filterValue?: string) {
  useEffect(() => {
    usersService.getUsers(filterValue);
  }, [filterValue]);
  const users = useObservable(usersQuery.users);
  return users;
}

import React from 'react';
import { Card, Grid, CardContent, CardHeader } from '@mui/material';

import { VolumeSummary } from '../../state';

import { NumberFormatters } from 'shared/utils/formatters';
import { MarketStat } from 'shared/components/market-stat';

interface Props {
  label: string;
  volume: VolumeSummary;
}

export const AgentMarketStats: React.FC<Props> = ({ label, volume }) => {
  return (
    <Card>
      <CardHeader title={label + ' Market Stats'} />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xl lg={4} xs={12} sm={6}>
            <MarketStat
              title="Total Active Listings"
              {...volume.activeListings}
              priceSum={volume.activePriceSum.current}
            />
          </Grid>
          <Grid item xl lg={4} xs={12} sm={6}>
            <MarketStat
              title="Total Pending Sales"
              {...volume.pendingListings}
              priceSum={volume.pendingPriceSum.current}
            />
          </Grid>
          <Grid item xl lg={4} xs={12} sm={6}>
            <MarketStat
              title="Total Sales Volume"
              {...volume.totalVolume}
              formatter={NumberFormatters.currency}
            />
          </Grid>
          <Grid item xl lg={4} xs={12} sm={6}>
            <MarketStat title="Expired Listings" invertColor {...volume.expiredListings} />
          </Grid>
          <Grid item xl lg={4} xs={12} sm={6}>
            <MarketStat title="DOM over 60 Days" invertColor {...volume.avgDaysOnMarket} />
          </Grid>
          <Grid item xl lg={4} xs={12} sm={6}>
            <MarketStat
              title="Avg Sales Price"
              {...volume.avgSalesPrice}
              formatter={NumberFormatters.currency}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

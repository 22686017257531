import React from 'react';
import { Redirect } from 'react-router-dom';
import { CookieNames, LoadingFade, useObservable } from '../../shared';
import { sessionQuery } from '../../state';
import { useCookies } from 'react-cookie';
import { LinkToSso } from './link-to-sso';
import { AppConfig } from '../../config';

export const KvCoreSSO: React.FC = () => {
  const [cookies] = useCookies([CookieNames.kvCoreToken, CookieNames.kvCoreRefreshToken]);
  const kvCoreToken = cookies.kvCoreToken;
  const kvCoreRefreshToken = cookies.kvCoreRefreshToken;
  const isLoggedIn = useObservable(sessionQuery.isLoggedIn, 'async');

  if (!isLoggedIn) return <Redirect to="/" />;
  if (!kvCoreToken && kvCoreRefreshToken)
    return <Redirect to={`/kvcore/refresh/${kvCoreRefreshToken}`} />;
  if (!kvCoreToken && !kvCoreRefreshToken) return <LinkToSso />;

  window.location.href = `${AppConfig.KvCore.OAuth.Login}?token=${kvCoreToken}`;
  return <LoadingFade isContentLoading={true} />;
};

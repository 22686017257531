import { Survey } from '../../state/surveys';
import { Link } from '../../shared';
import { LinearProgress, Card, CardHeader, CardContent, Typography } from '@mui/material';
import { Button } from 'shared/components';
import { DataTable, DataTableColumn } from 'shared/components/data-table';
interface Props {
  surveys: Array<Survey>;
  loading: boolean;
}

export function SurveysList({ surveys, loading }: Props) {
  const columns: DataTableColumn<Survey>[] = [
    {
      headerName: 'Title',
      field: 'title',
      renderCell: ({ row }) => (
        <Link to={`/survey/${row.id}`} color="primary">
          {row.title}
        </Link>
      ),
      sortable: true,
      flex: 2,
    },
    {
      headerName: 'Created Date',
      field: 'createdDate',
      renderCell: ({ row }) =>
        row.createdDate ? new Date(row.createdDate).toLocaleDateString() : undefined,
      sortable: true,
      filterable: false,
      valueGetter: (value, row) => {
        if (row.createdDate) return new Date(row.createdDate);
        return null;
      },
      type: "date"
    },
    {
      headerName: 'Active',
      field: 'active',
      renderCell: ({ row }) => (row.active ? 'Active' : 'Inactive'),
      sortable: true,
    },
  ];

  return (
    <>
      {loading && <LinearProgress />}
      <Card>
        <CardHeader
          title={
            <Typography variant="h6" fontWeight="bold">
              Surveys
            </Typography>
          }
          action={
            <Link to={'/survey/create'}>
              <Button>Create Survey</Button>
            </Link>
          }
        />
        <CardContent>
          <DataTable
            columns={columns}
            rows={surveys}
            initialState={{
              sorting: {
                sortModel: [
                  {
                    field: 'active',
                    sort: 'asc',
                  },
                ],
              },
            }}
          />
        </CardContent>
      </Card>
    </>
  );
}

import themeService from 'theme/ThemeService';

const palette = themeService.getPalette();

export const theme = {
  dataTable: {
    fontSize: '12px',
    cellPadding: '6px',
    header: {
      backgroundColor: palette.neutralLight,
      fontSize: '12px',
    },
    accentRowColors: {
      orange: palette.orange,
      green: palette.green,
      red: palette.red,
      magenta: palette.magenta,
    },
  },
};

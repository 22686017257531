import React, { useState, ChangeEvent } from 'react';
import {
  FormProps,
  useInput,
  useForm,
  email as emailValidator,
  ErrorDisplay,
} from '../../shared/forms';
import { Box, Grid } from '@mui/material';
import { ConfirmationDialog, TextField } from 'shared/components';
import { Agent, EditAgentForm } from '../../state';

interface Props extends FormProps<EditAgentForm> {
  handleClose: () => void;
  agent: Agent;
}
export const AgentEditDialog: React.FC<Props> = ({
  handleClose,
  onResetFeedback,
  onSubmit,
  agent,
  pending,
  error,
}) => {
  const alternateEmail = useInput(agent.alternateEmail ?? undefined, {
    validators: [emailValidator()],
  });
  const [alternatePhone, setAlternatePhone] = useState(agent.alternatePhone ?? undefined);

  const [phoneValid, setPhoneValid] = useState(true);
  const regexPhone = new RegExp(/^[2-9]\d{2}-\d{3}-\d{4}$/);

  const cancel = () => {
    handleClose();
  };

  const form = useForm(() => {}, alternateEmail);

  const onPhoneChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const value = e.target.value;
    setPhoneValid(!value || regexPhone.test(value!));
    setAlternatePhone(value);
  };

  function handleSubmit() {
    if (!form.valid || !phoneValid) return;

    onResetFeedback();
    onSubmit({
      alternateEmail: alternateEmail.value,
      alternatePhone: alternatePhone,
    });
  }

  return (
    <Box>
      <ConfirmationDialog
        isOpen={true}
        title={`Edit Agent - ${agent.fullName}`}
        confirmButtonProps={{
          label: 'Save',
        }}
        onClose={cancel}
        onConfirm={() => handleSubmit()}
        onCancel={() => cancel()}
        isPending={pending}
        fullWidth
      >
        <form {...form.bind}>
          <>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <ErrorDisplay
                  error={error}
                  errorMessage="An error has occurred. Please try again."
                />
              </Grid>
              <Grid item>
                <TextField
                  name="alternateEmail"
                  label="Alternate Email"
                  {...alternateEmail.bind}
                  autoComplete="email"
                  type="email"
                  fullWidth
                />
              </Grid>
              <Grid item>
                <TextField
                  name="alternatePhone"
                  label="Alternate Phone"
                  placeholder="xxx-xxx-xxxx"
                  type="phone"
                  fullWidth
                  value={agent.alternatePhone}
                  onChange={e => onPhoneChange(e)}
                  error={!phoneValid}
                  helperText={phoneValid ? '' : 'Invalid phone number xxx-xxx-xxxx'}
                />
              </Grid>
            </Grid>
          </>
        </form>
      </ConfirmationDialog>
    </Box>
  );
};

import React from 'react';
import {Star, StarBorder} from '@mui/icons-material';
import { Button } from '../button';
import themeService from 'theme/ThemeService';

interface Props {
  memberKey: string;
  isFavorite: boolean;
  toggleFavorite(memberKey: string): void;
}

const palette = themeService.getPalette();

export const FavoriteAgent: React.FC<Props> = ({ memberKey, isFavorite, toggleFavorite }) => {

  return (
    <Button onClick={() => toggleFavorite(memberKey)} variant='subtle'>
      {isFavorite ? (
        <Star sx={{ color: palette.primary }} />
      ) : (
        <StarBorder sx={{ color: palette.primary }} />
      )}
    </Button>
  );
};

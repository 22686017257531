import { Box, Divider } from '@mui/material';
import React from 'react';

interface RowProps {
  label: string | React.ReactNode;
  value: any;
  styles?: React.CSSProperties;
}

export const InfoRow: React.FC<RowProps> = ({ label, value, styles }) => {
  return (
    <Box>
      <Box fontSize="90%" display="flex" my={1}>
        <Box flexGrow={1}>{label}</Box>
        <Box
          fontWeight="fontWeightBold"
          style={{
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
            wordBreak: 'break-all',
            textAlign: 'right',
            ...styles
          }}
        >
          {value}
        </Box>
      </Box>
      <Divider />
    </Box>
  );
};

import { from } from 'rxjs';
import { map } from 'rxjs/operators';

import * as Mls from 'api/mls';
import { MarketRegion } from '../../offices';
import { RequestCancellation } from '../../util/requestCancellation';
import { DateRangeOptions, getStartAndEndDates } from '../date-filter';
import { getMarketShare } from './market.models';
import { initialState, MarketStore, marketStore } from './market.store';

export class MarketService {
  constructor(
    private readonly store: MarketStore,
    private readonly mlsofficesApi: Mls.OfficesApi
  ) {}

  getMarketVolume(dateRange: DateRangeOptions, region: MarketRegion, offices?: string[]) {
    this.store.update(initialState);

    return this.requestMarketVolume(dateRange, region, offices).subscribe(({ market, office }) =>
      this.store.update({
        market: getMarketShare(market),
        office: getMarketShare(office),
      })
    );
  }

  @RequestCancellation()
  requestMarketVolume(
    dateRange: DateRangeOptions,
    region: MarketRegion,
    offices?: string[],
    options?: any
  ) {
    const { startDate, endDate, priorStartDate, priorEndDate } = getStartAndEndDates(dateRange);
    return from(
      this.mlsofficesApi.mlsOfficesMarketPost(
        {
          ...region,
          officeKeys: offices,
          previousFromDate: priorStartDate.toISOString(),
          previousToDate: priorEndDate.toISOString(),
          currentFromDate: startDate.toISOString(),
          currentToDate: endDate.toISOString(),
        },
        options
      )
    ).pipe(map(response => response.data));
  }
}

export const marketService = new MarketService(marketStore, new Mls.OfficesApi());

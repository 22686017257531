import { ConfirmationDialog, DialogContent, TextField } from 'shared/components';
import React, { useState } from 'react';
import { Autocomplete } from '@mui/lab';
import { useSearchHashtags } from 'state/kvCore/kvcore.hooks';
import {Chip, CircularProgress, Grid } from '@mui/material';
import { useInput } from 'shared/forms';

interface AddHashtagsDialogProps {
  open: boolean;
  onClose: (hashtags: string, confirmed: boolean) => void;
}

export const AddHashTagsDialog: React.FC<AddHashtagsDialogProps> = ({ open, onClose }) => {
  const { data: searchResults, setSearch, isLoading } = useSearchHashtags();
  const [inputValue, setInputValue] = useState<string>('');
  const [selectedHashTags, setSelectedHashTags] = useState<string[]>([]);

  const handleInputChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setInputValue(newValue);
    setSearch(newValue);
  };

  const handleOptionSelect = (event: React.ChangeEvent<{}>, newValue: string | null) => {
    if (newValue) {
      setSelectedHashTags([...selectedHashTags, '#' + newValue]);
      setInputValue('');
    }
  };

  const removeHashTag = (selectedHashTag: string) => {
    setSelectedHashTags(selectedHashTags.filter(h => h !== selectedHashTag));
  };

  const handleConfirm = () => {
    onClose(selectedHashTags.join(','), true);
    setSelectedHashTags([]);
  };

  const handleCancel = () => {
    setSelectedHashTags([]);
    setInputValue('');
    onClose('', false);
  };

  return (
    <ConfirmationDialog
      isOpen={open}
      onClose={handleCancel}
      title='Add Hashtags To Recruit'
      onConfirm={handleConfirm}
      onCancel={handleCancel}
      fullWidth
    >
      <DialogContent>
        <Grid container spacing={0.8} style={{marginBottom: '10px'}}>
          {selectedHashTags.map(text => (
            <Grid item key={text}>
              <Chip label={text} onDelete={() => removeHashTag(text)} />
            </Grid>
          ))}
        </Grid>
        <Autocomplete
          freeSolo
          options={searchResults.map(r => r.name!)}
          inputValue={inputValue}
          onInputChange={handleInputChange}
          onChange={handleOptionSelect}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Enter hashtag"
              endAdornment={isLoading ? <CircularProgress size={20} /> : null}
            />
          )}
        />
      </DialogContent>
    </ConfirmationDialog>
  );
};

import { useState } from 'react';
import { AgentModel } from '../../api/mls';
import { surveysService } from '../../state/surveys';
import { Button, ConfirmationDialog, Dialog, DialogActions, DialogContent, DialogTitle } from 'shared/components';
import { sessionQuery } from 'state';
import { email, required, useInput } from 'shared/forms';
import { Grid, TextField, Typography } from '@mui/material';
import { useObservable } from 'shared';

interface Props {
  listingKey: string;
  ourAgent: AgentModel;
  coOpAgent?: AgentModel;
  address?: string;
  closeDate?: string;
  surveySent: boolean;
}

export function SendSurveyButton({ listingKey, ourAgent, coOpAgent, address, closeDate, surveySent }: Props) {
  const [openDialog, setOpenDialog] = useState(false);
  const [buttonSent, setButtonSent] = useState(false);
  const dialogContent = `This will send a survey to ${ourAgent.fullName}. Would you like to proceed?`;

  const handleConfirm = (confirmed: boolean) => {
    if (!coOpAgent) {
      return;
    }
    if (confirmed) {
      surveysService.sendSurvey(
        listingKey,
        ourAgent,
        coOpAgent,
        address,
        closeDate,
      );
      setButtonSent(true);
    }
    setOpenDialog(false);
  };

  const currentCompany = useObservable(sessionQuery.currentCompany);
  const [openOverrideEmailDialog, setOpenOverrideEmailDialog] = useState(false);
  const [overrideEmail, setOverrideEmail] = useState("");
  const overrideEmailInput = useInput<string>(overrideEmail, {
    onChange: value => setOverrideEmail(value),
    validators: [email(), required()]
  });
  if (!overrideEmailInput.touched) {
    overrideEmailInput.setTouched(true);
  }

  const handleOverrideCancel = () => {
    setOpenOverrideEmailDialog(false);
    setOverrideEmail("");
    overrideEmailInput.setValue("");
  };

  const handleOverrideConfirm = () => {
    if (!overrideEmailInput.valid || !coOpAgent) return;

    surveysService.sendSurvey(
      listingKey,
      {...ourAgent, email: overrideEmailInput.value},
      coOpAgent,
      address,
      closeDate
    );
    setButtonSent(true);
    handleOverrideCancel();
  };

  const selectDialog = () => {
    // check if logged in user is with Crown Realty demo company
    if (currentCompany?.id === 12) {
      setOpenOverrideEmailDialog(true);
    } else {
      setOpenDialog(true)
    }
  }

  return (
    <>
      <Button onClick={selectDialog} disabled={surveySent || buttonSent} size="small">
        {surveySent || buttonSent ? 'Survey Sent' : 'Send Survey'}
      </Button>

      <ConfirmationDialog
        isOpen={openDialog}
        title="Are you sure?"
        onClose={() => handleConfirm(false)}
        onConfirm={() => handleConfirm(true)}
        onCancel={() => handleConfirm(false)}
      >
        {dialogContent}
      </ConfirmationDialog>

      <Dialog isOpen={openOverrideEmailDialog}>
        <DialogTitle>Demo Email Override</DialogTitle>
        <DialogContent>
          <Typography>
            Enter override email. Survey will be sent to this email instead of the email on file for the agent.
          </Typography>
          <TextField
            variant="standard"
            name="overrideEmail"
            label="Override Email"
            autoComplete="email"
            type="email"
            {...overrideEmailInput.bind}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Grid container item xs={12} gap={2} m={2}>
            <Grid item>
              <Button
                type="button"
                onClick={handleOverrideConfirm}
                disabled={!overrideEmailInput.valid}
              >
                OK
              </Button>
            </Grid>

            <Grid item>
              <Button
                variant="secondary"
                onClick={handleOverrideCancel}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
}

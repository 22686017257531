import React, { AriaAttributes } from 'react';
import { useState } from 'react';
import { ValidatorFn } from './validators';
import { asArray, aria } from './util';

export interface AbstractControl<TBindingProps> {
  valid: boolean;
  touched: boolean;
  reset: () => void;
  bind: Partial<AriaAttributes & TBindingProps>;
}

export interface FormControl<T = any, TElement = any> extends AbstractControl<TElement> {
  value: T;
  setValue: (value: T) => void;
  setTouched: (touched: boolean) => void;
  errors: string[];
}

export interface FormControlOptions<T> {
  validators: ValidatorFn<T> | ValidatorFn<T>[];
  onChange: (value: T) => void;
}

const defaultOptions: FormControlOptions<any> = {
  validators: [],
  onChange: () => {},
};

export const useControl = <T, TBindingProps>(
  initialValue: T,
  options: Partial<FormControlOptions<T>>,
  bind: (instance: FormControl<T, TBindingProps>) => Partial<TBindingProps>
): FormControl<T, TBindingProps> => {
  const [value, setValueImpl] = useState(initialValue);
  const [touched, setTouched] = useState(false);

  const { validators, onChange } = { ...defaultOptions, ...options };

  const errors = asArray(validators)
    .map(check => check(value))
    .filter(err => !!err) as string[];

  const valid = errors.length === 0;

  if (!valid && !touched) {
    setTouched(true);
  }

  const setValue = (next: T) => {
    setValueImpl(next);
    onChange(next);
  };

  const instance: FormControl<T, TBindingProps> = {
    value,
    setValue,
    valid,
    touched,
    setTouched,
    errors,
    reset: () => {
      setValue(initialValue);
      setTouched(false);
    },
    bind: {},
  };

  Object.assign(instance.bind, {
    ...aria(valid, touched, ...errors),
    ...bind(instance),
  });

  return instance;
};

type Primitive = string | number | string[] | undefined;

export type InputLike<T extends Primitive> = Pick<
  React.HTMLProps<{
    error?: boolean;
    helperText?: string;
    value: T | unknown;
  }>,
  'value' | 'onChange' | 'onBlur'
>;

export const useInput = <T extends Primitive>(
  initialValue: T,
  options: Partial<FormControlOptions<T>> = {}
) => {
  return useControl<T, InputLike<T>>(
    initialValue,
    options,
    ({ value, setValue, valid, errors, touched, setTouched }) => ({
      value: value || '',
      error: !valid && touched,
      onChange: e => setValue((e.target as any).value),
      onBlur: () => setTouched(true),
      ...(!valid && touched ? { helperText: errors[0] } : null),
    })
  );
};

export const useNumberInput = (
  initialValue: number,
  options: Partial<FormControlOptions<number>> = {}
) => {
  return useControl<number, InputLike<number>>(
    initialValue,
    options,
    ({ value, setValue, valid, errors, touched, setTouched }) => ({
      value: value || '',
      error: !valid && touched,
      onChange: e => {
        const parsed = parseInt((e.target as any).value);
        const value = (isNaN(parsed) ? '' : parsed) as number;
        setValue(value);
      },
      onBlur: () => setTouched(true),
      ...(!valid && touched ? { helperText: errors[0] } : null),
    })
  );
};

export const useBooleanInput = (
  initialValue: boolean | undefined,
  options: Partial<FormControlOptions<string | undefined>> = {}
) => {
  const initValue: string | undefined = String(initialValue);

  return useControl<string | undefined, InputLike<string | undefined>>(
    initValue,
    options,
    ({ value, setValue, valid, errors, touched, setTouched }) => ({
      value: value ?? '',
      error: !valid && touched,
      onChange: e => setValue((e.target as any).value),
      onBlur: () => setTouched(true),
      ...(!valid && touched ? { helperText: errors[0] } : null),
    })
  );
};

type SelectBindProps<T extends Primitive> = {
  control: { error?: boolean };
  input: InputLike<T>;
  helperText?: string;
};

export const useSelect = <T extends Primitive>(
  initialValue: T,
  options: Partial<FormControlOptions<T>> = {}
) => {
  return useControl<T, SelectBindProps<T>>(
    initialValue,
    options,
    ({ value, setValue, valid, errors, touched, setTouched }) => ({
      control: { error: !valid && touched },
      input: {
        value: value || '',
        onChange: e => setValue((e.target as any).value),
        onBlur: () => setTouched(true),
      },
      ...(!valid && touched ? { helperText: errors[0] } : null),
    })
  );
};

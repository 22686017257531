import fp from 'lodash/fp';

import { AgentVolumeSummary, Agent } from '../../state';
import { ThemeColors } from 'shared/theme';

export interface CategorizedSalesVolumeModel {
  id: Agent['memberKey'];
  index: number;
  agent: Agent;
  volume?: AgentVolumeSummary;
  isFavorite: (memberKey: string) => boolean;
  toggleFavorite: (memberKey: string) => void;
  borderColor: ThemeColors;
}

export interface CategorizedSalesVolumeDataWrapper {
  top: CategorizedSalesVolumeModel[];
  middle: CategorizedSalesVolumeModel[];
  bottom: CategorizedSalesVolumeModel[];
}

export type BalanceReportMode = 'totalTransactions' | 'totalVolume';

export class BalanceReportHelper {
  static orderAndCategorize(
    data: CategorizedSalesVolumeModel[],
    mode: BalanceReportMode = 'totalVolume'
  ): CategorizedSalesVolumeDataWrapper {
    let i = 0;
    //get all the transactions that have numbers we can work with
    let totalTransactions: CategorizedSalesVolumeModel[] = fp.pipe(
      fp.filter((item: CategorizedSalesVolumeModel) => item.volume !== undefined),
      fp.orderBy(`volume.${mode}.current`, 'desc'),
      fp.map(
        (v: CategorizedSalesVolumeModel) => ({ ...v, index: i++ } as CategorizedSalesVolumeModel)
      )
    )(data);

    //add up the total of all transactions
    const totalTransactionsForAll = fp.pipe(
      fp.map((item: CategorizedSalesVolumeModel) => item?.volume?.[mode].current || 0),
      fp.reduce((a, b) => a + b, 0)
    )(totalTransactions);

    const TWENTY_PERCENT = Math.floor(totalTransactionsForAll * 0.2);

    //find those in the top 20th percentile
    let topAccum = 0;
    const top20 = fp.pipe(
      fp.takeWhile((item: CategorizedSalesVolumeModel) => {
        if (topAccum > TWENTY_PERCENT) {
          return false;
        }
        topAccum += item?.volume?.[mode].current || 0;
        item.borderColor = ThemeColors.Green;
        return true;
      })
    )(totalTransactions);

    //remove the top 20th percentile
    totalTransactions = totalTransactions.filter((item: CategorizedSalesVolumeModel) => {
      return !top20.includes(item);
    });

    //find those in the bottom 20th percentile
    let bottomAccum = 0;
    const bottom20 = fp.pipe(
      fp.takeRightWhile((item: CategorizedSalesVolumeModel) => {
        if (bottomAccum > TWENTY_PERCENT) {
          return false;
        }
        bottomAccum += item?.volume?.[mode].current || 0;
        item.borderColor = ThemeColors.Magenta;
        return true;
      }),
      fp.orderBy(`volume.${mode}.current`, 'desc'),
      fp.map((v: CategorizedSalesVolumeModel) => v)
    )(totalTransactions);

    //remove the bottom 20th percentile
    totalTransactions = totalTransactions.filter((item: CategorizedSalesVolumeModel) => {
      return !bottom20.includes(item);
    });

    const middle = fp.map(
      (item: CategorizedSalesVolumeModel) =>
        ({ ...item, borderColor: ThemeColors.Orange } as CategorizedSalesVolumeModel)
    )(totalTransactions);

    return { top: top20, middle, bottom: bottom20 };
  }
}

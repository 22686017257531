import React, { useEffect } from 'react';
import { deferred, useObservable } from '..';
import { mlsQuery, mlsService } from '../../state';
import { VolumeSummaryTooltip } from './volume-summary-tooltip';

interface Props {
  memberKey: string;
  agentName: string;
}

const AgentVolume: React.FC<Props> = ({ memberKey, agentName }) => {
  const agentVolumes = useObservable(mlsQuery.agentVolumeSummaries);
  const volume = agentVolumes.find(v => v.memberKey === memberKey); //Office key can be the Main officekey also
  useEffect(() => mlsService.getAgentVolumeSummary(memberKey), [memberKey]);

  if (!volume?.periods) {
    return null;
  }

  return <VolumeSummaryTooltip title={agentName} periods={volume.periods} />;
};

export const AgentVolumeSummaryTooltip = deferred(AgentVolume);

import { useEffect } from 'react';
import { useObservable } from '../shared';
import { managerQuery, managerService } from '../state/managers';

export function useManagers() {
  useEffect(() => {
    managerService.getManagers();
  }, []);

  return useObservable(managerQuery.managers);
}

import { StoreConfig } from '@datorama/akita';

import { ResettableStore } from '../../util';

export interface RegionsState {
  cities: string[];
}

const initialState: RegionsState = {
  cities: [],
};

@StoreConfig({ name: 'regions', resettable: true })
export class RegionsStore extends ResettableStore<RegionsState> {
  constructor() {
    super(initialState);
  }
}

export const regionsStore = new RegionsStore();

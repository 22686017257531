import { QueryEntity } from '@datorama/akita';

import { ActivitiesState, activitiesStore } from './activities.store';
import { switchMap } from 'rxjs/operators';
import { dateActivityFilter } from './date-filter';

export class ActivitiesQuery extends QueryEntity<ActivitiesState> {
  recruitActivities = this.select(({ ui }) => ui).pipe(
    switchMap(({ recruitId, dateRange }) => {
      const dateFilter = dateActivityFilter(dateRange);
      return this.selectAll({
        filterBy: x =>
          x.recruitId === recruitId &&
          (dateFilter.currentPeriod(x, a => a.recordedAt) ||
            dateFilter.futurePeriod(x, a => a.recordedAt)),
      });
    })
  );

  managerActivities = this.select(({ ui }) => ui).pipe(
    switchMap(({ managerId, dateRange }) => {
      const dateFilter = dateActivityFilter(dateRange);
      return this.selectAll({
        filterBy: x =>
          x.managerId === managerId &&
          (dateFilter.currentPeriod(x, a => a.recordedAt) ||
            dateFilter.futurePeriod(x, a => a.recordedAt)),
      });
    })
  );
}

export const activitiesQuery = new ActivitiesQuery(activitiesStore);

import { makeStyles, createStyles } from "@mui/styles";
import { surveysStore } from "state/surveys";

export const useQuestionStyles = makeStyles(() =>
  createStyles({
    table: {
      '& .rdt_Table': {},
      '& .rdt_TableHeadRow': {
        minHeight: 'auto',
      },
      '& .rdt_TableRow': {
        border: '1px solid lightgrey',
        borderRadius: '8px',
        marginBottom: '1em',
      },
    },
    header: {
      fontSize: '150%',
    },
    addQuestionGrid: {
      border: '1px solid lightgrey',
      borderRadius: '8px',
      marginBottom: '1em',
    },
    answerQuestionGrid: {
      border: '1px solid lightgrey',
      borderRadius: '8px',
      marginBottom: '1em',
    },
    childAnswerGrid: {
      margin: '1em'
    },
  })
);

export function resetError() {
  surveysStore.setError(undefined);
}

import { from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { UsersApi } from 'api/auth/api/users-api';
import * as Zsg from 'api/app';
import * as Mls from 'api/mls';
import { dispatchForm } from '../../forms';
import { UpsertManagerForm } from './managers.models';
import { ManagerForms } from './managers.forms';
import { ManagersStore, managersStore } from './managers.store';
import { applyTransaction } from '@datorama/akita';
import { EditUserForm } from '../../users';

export class ManagersService {
  constructor(
    private readonly store: ManagersStore,
    private readonly managersApi: Zsg.ManagersApi,
    private readonly companiesApi: Zsg.CompaniesApi,
    private readonly userFeedApi: Mls.UsersApi,
    private readonly usersApi: UsersApi
  ) {}

  createManager(request: UpsertManagerForm, officeCodes: string[]) {
    from(this.managersApi.managersExistsPost({ email: request.email! }))
      .pipe(
        map(x => x.data.exists ?? false),
        switchMap(exists =>
          this.usersApi.usersCreatePost({ email: request.email!, hasManagerAssignment: exists })
        ),
        map(x => x.data),
        switchMap(user =>
          this.userFeedApi.mlsUsersPost({ userId: user.id, feedKeys: officeCodes })
        ),
        map(x => x.data),
        switchMap(user => this.managersApi.managersIdPost(user.userId, request)),
        map(x => x.data.data),
        dispatchForm(ManagerForms.Create)
      )
      .subscribe(manager =>
        applyTransaction(() => {
          this.store.upsert(manager.id, manager);
        })
      );
  }

  getManager(id: string) {
    this.store.setActive(id);

    // reload data for the selected manager
    from(this.managersApi.managersIdGet(id))
      .pipe(map(x => x.data.data))
      .subscribe(manager =>
        applyTransaction(() => {
          this.store.upsert(manager.id, manager);
        })
      );
  }

  updateManager(id: string, request: UpsertManagerForm, officeCodes: string[]) {
    from(this.usersApi.usersIdPut(id, request as EditUserForm))
      .pipe(
        switchMap(() => this.userFeedApi.mlsUsersUserIdPost(id, { feedKeys: officeCodes })),
        map(x => x.data),
        switchMap(user => this.managersApi.managersIdPost(user.userId, request)),
        map(x => x.data.data),
        dispatchForm(ManagerForms.Edit)
      )
      .subscribe(manager =>
        applyTransaction(() => {
          this.store.upsert(manager.id, manager);
        })
      );
  }

  resetPassword(id: string) {
    from(this.usersApi.usersIdPasswordResetPost(id))
      .pipe(dispatchForm(ManagerForms.Reset))
      .subscribe();
  }

  getManagers(companyId: number) {
    from(this.companiesApi.companiesIdManagersGet(companyId))
      .pipe(map(x => x.data.data))
      .subscribe(managers => {
        this.store.update(({ ui }) => ({ ui: { ...ui, companyId } }));
        this.store.upsertMany(managers);
      });
  }

  deleteManager(id: string) {
    from(this.userFeedApi.mlsUsersUserIdPost(id, { feedKeys: [] }))
      .pipe(
        switchMap(() => this.managersApi.managersIdDelete(id)),
        dispatchForm(ManagerForms.Delete)
      )
      .subscribe(() =>
        applyTransaction(() => {
          this.store.remove(id);
        })
      );
  }
}

export const managersService = new ManagersService(
  managersStore,
  new Zsg.ManagersApi(),
  new Zsg.CompaniesApi(),
  new Mls.UsersApi(),
  new UsersApi()
);

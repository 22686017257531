import { applyTransaction } from '@datorama/akita';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { subDays, startOfToday } from 'date-fns';

import * as Zsg from 'api/app';
import { distinctStrings } from '../util';
import { MlsService, mlsService } from '../mls';
import { NotificationsStore, notificationsStore } from './notifications.store';

export class NotificationsService {
  constructor(
    private readonly store: NotificationsStore,
    private readonly managersApi: Zsg.ManagersApi,
    private readonly mlsService: MlsService
  ) {}

  getNotifications(limit: number) {
    const fromDate = subDays(startOfToday(), 3).toISOString();
    const toDate = startOfToday().toISOString();

    from(this.managersApi.managersNotificationsGet(fromDate, toDate, limit))
      .pipe(map(x => x.data))
      .subscribe(({ data, totalRecords }) => {
        const memberKeys = distinctStrings(data.map(n => n.memberKey));
        const listingKeys = distinctStrings(data.map(n => n.listingKey));

        applyTransaction(() => {
          this.store.upsertMany(data);
          this.store.update(({ ui }) => ({ ui: { ...ui, totalRecords, memberKeys, listingKeys } }));
        });

        this.mlsService.getAgents(memberKeys);
        this.mlsService.getListings(listingKeys);
      });
  }
}

export const notificationsService = new NotificationsService(
  notificationsStore,
  new Zsg.ManagersApi(),
  mlsService
);

import { useEffect } from 'react';
import { DateRangeOptions, getStartAndEndDates, mlsService } from '../state/mls';
import { coBrokesQuery } from '../state/cobroke/cobroke.query';
import { useObservable } from '../shared';
import { format } from 'date-fns';
import { dateFormat } from '../state/search';

export function useGetCobrokeSales(feedKeys: Array<string>, dateRange?: DateRangeOptions) {
  useEffect(() => {
    if (dateRange && feedKeys && feedKeys.length > 0) {
      var { startDate, endDate } = getStartAndEndDates(dateRange);
      mlsService.getOfficeRetention({
        feedKeys,
        startDate: format(startDate, dateFormat),
        endDate: format(endDate, dateFormat),
      });
    }
  }, [feedKeys, dateRange]);

  return {
    coBrokes: useObservable(coBrokesQuery.coBrokes),
    loading: useObservable(coBrokesQuery.loading),
  };
}

import { QueryEntity, EntityActions, filterNil } from '@datorama/akita';
import { Subject, of } from 'rxjs';
import { switchMap, mergeMap } from 'rxjs/operators';

import { FormEvent } from './form.models';
import { FormState, FormStore, formStore } from './form.store';

export class FormQuery extends QueryEntity<FormState> {
  private readonly _eventStream = new Subject<FormEvent>();

  constructor(protected store: FormStore) {
    super(store);

    this.selectEntityAction(EntityActions.Add)
      .pipe(
        switchMap(ids => of(...ids)),
        mergeMap(formId => this.selectEntity(formId as string).pipe(filterNil))
      )
      .subscribe(this._eventStream);
  }

  events = this._eventStream.asObservable();
}

export const formQuery = new FormQuery(formStore);

import React from 'react';
import { Card, CardContent } from '@mui/material';
import { useFormEvents } from '../../shared/forms';
import { FormStatus } from '../../state';
import { EditCompanyForm } from '../components/edit-company.form';
import {
  companiesService,
  CompanyForms,
  EditCompanyForm as EditCompanyFormModel,
} from '../../state/admin';
import { useCompany, useCompanyOffices, useOffices, useRegions } from '../hooks';
import { useParams } from 'react-router-dom';
import { UpdateCompanyRequest, UpdateOfficeRequest } from '../../api/app';
import { LoadingFade } from 'shared';

export const EditCompanyPage: React.FC = () => {
  const companyId = parseInt(useParams<{ companyId: string }>().companyId);
  const [{ status, error }, resetFormUI] = useFormEvents(CompanyForms.Edit);

  const { companies, offices, feedOptions } = useOffices();
  const { company, loading: companyLoading } = useCompany(companyId);
  const { companyOffices, loading: officesLoading } = useCompanyOffices(companyId);
  const { cities } = useRegions();

  const activeForm: UpdateCompanyRequest = {
    companyCodes: company?.companyCodes ?? [],
    feeds: company?.companyFeeds ?? [],
    name: company?.name ?? '',
    hashTags: company?.hashTags,
    kvCoreAccountId: company?.kvCoreAccountId,
    kvCoreAccessToken: company?.kvCoreAccessToken,
    accountStatus: company?.accountStatus ?? 'Active',
    kvCore: company?.kvCore,
    seatCount: company?.seatCount ?? 0,
    kvCoreSid: company?.kvCoreSid,
    ampstatsSid: company?.ampstatsSid,
    kvCoreMainEmail: company?.kvCoreMainEmail,
    offices: companyOffices,
  };

  const submit = (form: EditCompanyFormModel) => {
    companiesService.updateCompany(companyId, form);
  };

  if (!company) return null;

  return (
    <Card>
      <CardContent>
        <LoadingFade isContentLoading={companyLoading || officesLoading}>
          <EditCompanyForm
            activeForm={activeForm}
            companies={companies}
            offices={offices}
            feedOptions={feedOptions}
            cityOptions={cities}
            onSubmit={submit}
            onResetFeedback={resetFormUI}
            error={error}
            success={status === FormStatus.Success}
            pending={status === FormStatus.Pending}
            numOfUsers={company?.managers ?? 0}
          />
        </LoadingFade>
      </CardContent>
    </Card>
  );
};

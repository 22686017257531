import themeService from 'theme/ThemeService';

const palette = themeService.getPalette();

export const theme = {
  alert: {
    borderRadius: '8px',
    info: {
      backgroundColor: palette.blueLight,
      iconColor: palette.blueDark,
      textColor: palette.blueDark,
    },
    success: {
      backgroundColor: palette.green,
      iconColor: palette.neutralLightest,
      textColor: palette.neutralLightest,
    },
    warning: {
      backgroundColor: palette.yellowLight,
      iconColor: palette.yellowDark,
      textColor: palette.yellowDark,
    },
    error: {
      backgroundColor: palette.red,
      iconColor: palette.neutralLightest,
      textColor: palette.neutralLightest,
    },
  },
};

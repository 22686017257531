import React, { useState, useEffect } from 'react';
import { Card, Box, CardContent, CardHeader } from '@mui/material';
import { Button } from 'shared/components/button';
import { makeStyles } from '@mui/styles';

import { Activity } from '../../state/activities';
import { ThemeColors } from '../../shared/theme';
import {
  AddEditActivity,
  ActivityHistoryChart,
  ActivityHistoryTable,
} from '../../shared/components/activity-history';
const useStyles = makeStyles({
  link: {
    textDecoration: 'none',
    color: ThemeColors.Teal,
  },
});

interface Props {
  recruitId: number;
  data: Array<Activity>;
  memberId: string | null;
  kvCoreId: string | null | undefined;
}

export const AgentActivityHistory: React.FC<Props> = ({ recruitId, data, memberId, kvCoreId }) => {
  const [open, setOpen] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState<Activity | null>(null);

  const handleOpenActivityDialog = (activity: Activity) => {
    setSelectedActivity(activity);
    setOpen(true);
  };

  return (
    <>
      <Card>
        <CardHeader title="Activity History" />
        <CardContent>
          <ActivityHistoryChart data={data} />
          <ActivityHistoryTable data={data} handleOpenDialog={handleOpenActivityDialog} />
          {kvCoreId === null && (
            <Box mt={4} textAlign="center">
              <Button onClick={() => handleOpenActivityDialog({})}>
                Add Activity
              </Button>
            </Box>
          )}
        </CardContent>
      </Card>
      <AddEditActivity
        open={open}
        setOpen={setOpen}
        recruitId={recruitId}
        activity={selectedActivity}
        kvCoreId={kvCoreId}
      />
    </>
  );
};

import { ReactNode } from 'react';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import { Button as MuiButton, ButtonProps as MuiButtonProps } from '@mui/material';
import { useTheme } from 'theme/ThemeContext';

type MuiVariant = MuiButtonProps['variant'];
type MuiColor = MuiButtonProps['color'];
type ButtonVariant = 'primary' | 'secondary' | 'subtle';
type ButtonSize = 'small' | 'medium' | 'large';

interface ButtonLinkProps extends RouterLinkProps {
  variant?: ButtonVariant;
  children?: ReactNode;
  size?: ButtonSize;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  target?: string;
  fullWidth?: boolean;
  hideShadow?: boolean;
}

export function ButtonLink({
  children,
  fullWidth,
  size = 'medium',
  variant = 'primary',
  hideShadow = false,
  ...rest
}: ButtonLinkProps) {
  const theme = useTheme();
  return (
    <MuiButton
      {...rest}
      {...getVariantAndColor(variant)}
      type="button"
      fullWidth={fullWidth}
      component={RouterLink}
      sx={{
        justifyContent: fullWidth ? 'space-between' : 'center',
        minHeight: getHeight(size),
        fontSize: getFontSizeByButtonSize(size),
        gap: '4px',
        fontFamily: 'Albert Sans',
        padding: '0 12px',
        minWidth: 'auto',
        boxShadow: hideShadow ? 'none' : 'inherit',
        '&.MuiButton-containedPrimary': {
          background: theme.buttonLink.primary.backgroundColor,
          color: theme.buttonLink.primary.textColor,
          border: '1px solid',
          borderColor: theme.buttonLink.primary.backgroundColor,
        },
        '&.MuiButton-containedPrimary:disabled': {
          background: theme.buttonLink.primary.disabled.backgroundColor,
          color: theme.buttonLink.primary.disabled.textColor,
        },
        '&.MuiButton-containedSecondary': {
          background: theme.buttonLink.secondary.backgroundColor,
          color: theme.buttonLink.secondary.textColor,
          border: '1px solid',
          borderColor: theme.buttonLink.secondary.borderColor,
        },
        '&.MuiButton-containedSecondary:disabled': {
          background: theme.buttonLink.secondary.disabled.backgroundColor,
          color: theme.buttonLink.secondary.disabled.textColor,
        },
        '&.MuiButton-textSecondary': {
          background: theme.buttonLink.subtle.backgroundColor,
          color: theme.buttonLink.subtle.textColor,
        },
        '&.MuiButton-textSecondary:disabled': {
          background: theme.buttonLink.subtle.disabled.backgroundColor,
          color: theme.buttonLink.subtle.disabled.textColor,
        },
      }}
    >
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
        {children}
      </div>
    </MuiButton>
  );
}

function getFontSizeByButtonSize(size: ButtonSize){
  if (size === 'small') return '12px';
  return '13px';
}

function getHeight(size: ButtonSize) {
  if (size === 'small') return '26px';
  if (size === 'large') return '52px';
  return '34px';
}

function getVariantAndColor(variant: ButtonVariant): {
  variant: MuiVariant;
  color: MuiColor;
} {
  if (variant === 'secondary') return { variant: 'contained', color: 'secondary' };
  if (variant === 'subtle') return { variant: 'text', color: 'secondary' };
  return { variant: 'contained', color: 'primary' };
}

import { QueryEntity } from '@datorama/akita';

import { CompaniesState, companiesStore } from './companies.store';

export class CompaniesQuery extends QueryEntity<CompaniesState> {
  activeCompany = this.selectActive();

  companies = this.selectAll();
  loading = this.selectLoading();
}

export const companiesQuery = new CompaniesQuery(companiesStore);

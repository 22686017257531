import React from 'react';
import { Redirect, Route, Switch, RouteComponentProps } from 'react-router-dom';
import { SurveyDetailsPage, CreateSurveyPage, SubmissionDetailsPage, SurveysPage } from './pages';
import { UpdateSurveyPage } from './pages/update-survey.page';

export const SurveysRoutes: React.FC<RouteComponentProps> = ({ match }) => (
  <Switch>
    <Route path={`${match.path}`} exact component={SurveysPage} />
    <Route path={`${match.path}/create`} exact component={CreateSurveyPage} />
    <Route path={`${match.path}/update/:surveyId`} exact component={UpdateSurveyPage} />
    <Route path={`${match.path}/:surveyId`} exact component={SurveyDetailsPage} />
    <Route path={`${match.path}/:submissionId/submission`} component={SubmissionDetailsPage} />
    <Redirect to={match.path} />
  </Switch>
);

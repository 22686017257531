import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Grid,
  Box,
  Card,
  CardHeader,
  CardContent,
  InputAdornment,
  Typography,
} from '@mui/material';
import { Button, TextField } from 'shared/components';
import {icons, PageHeader, ThemeColors } from '../../shared';
import FeeCalculator from '../../recruiting/components/net-income-calculator/FeeCalculator';
import {
  House,
  ArrowBackIosNew,
  Person,
  AttachMoney
} from '@mui/icons-material';
import { CustomCurrencyInputMasker } from '../../shared/components/custom-currency-input-masker';
import { RecruitingComparisonCalculatorBarComponent } from '../../recruiting/components/net-income-calculator/recruiting-comparison-calculator-bar';
import { RecruitingFixedFeeScheduleTableComponent } from '../../recruiting/components/net-income-calculator/recruiting-fixed-fee-schedule-table';
import { RecruitingVariableFeeScheduleTableComponent } from '../../recruiting/components/net-income-calculator/recruiting-variable-fee-schedule-table';
import { NetIncomeSummaryFooterComponent } from '../../recruiting/components/net-income-calculator/recruiting-calculator-summary-footer';
import themeService from 'theme/ThemeService';

export interface FeeScheduleItem {
  type: FeeType;
  label: string;
  totalCostOurs?: number;
  totalCostCurrent?: number;
  monthlyCostOurs?: number;
  monthlyCostCurrent?: number;
  feeGroup: 'fixed' | 'variable';
  calculate?: (calculator: FeeCalculator) => number;
  customMultiplier?: number; //this one applies for TransactionFees for now, just to flag it, which is multiplied or divided by totalTransactions
}

export enum FeeCategory {
  Ours,
  Current,
}

export enum FeeType {
  //Fixed
  AgentGrossCommission,
  LessFranchiseFee,
  AgentAdjustedGross,
  LessCompanyDollarCap,
  AfterCapCompanyDollar,

  //Variable
  TransactionFees,
  MonthlyFeesTech,
  EOInsurance,
  DeskOfficeFee,
  LeadGeneration,
  AdminExpense,
  Copies,
  Advertising,
  Signs,
  Postage,
  MarketingMaterials,
  Other,
}

const FIXED_FEES: FeeScheduleItem[] = [
  {
    type: FeeType.AgentGrossCommission,
    label: 'Agent Gross GCI',
    feeGroup: 'fixed',
    calculate: (calculator: FeeCalculator) => calculator.calculateAgentGrossCommission(),
  },
  {
    type: FeeType.LessFranchiseFee,
    label: 'Less Franchise Fee',
    feeGroup: 'fixed',
    calculate: (calculator: FeeCalculator) => calculator.calculateFranchiseFee(),
  },
  {
    type: FeeType.AgentAdjustedGross,
    label: 'Agent Adjusted Gross',
    feeGroup: 'fixed',
    calculate: (calculator: FeeCalculator) => calculator.calculateAgentAdjustedGross(),
  },
  {
    type: FeeType.LessCompanyDollarCap,
    label: 'Less Company Dollar (Cap)',
    feeGroup: 'fixed',
    calculate: (calculator: FeeCalculator) => calculator.calculateLessCompanyDollarCap(),
  },
  {
    type: FeeType.AfterCapCompanyDollar,
    label: 'After Cap Company Dollar',
    feeGroup: 'fixed',
    calculate: (calculator: FeeCalculator) => calculator.calculateAfterCompanyCap(),
  },
];

const VARIABLE_FEES: FeeScheduleItem[] = [
  { type: FeeType.MonthlyFeesTech, label: 'Monthly Fees (tech)', feeGroup: 'variable' },
  { type: FeeType.EOInsurance, label: 'E&O Insurance', feeGroup: 'variable' },
  { type: FeeType.DeskOfficeFee, label: 'Desk or office fee', feeGroup: 'variable' },
  { type: FeeType.LeadGeneration, label: 'Lead Generation', feeGroup: 'variable' },

  { type: FeeType.AdminExpense, label: 'Admin Expense', feeGroup: 'variable' },
  { type: FeeType.Copies, label: 'Copies', feeGroup: 'variable' },
  { type: FeeType.Advertising, label: 'Advertising', feeGroup: 'variable' },
  { type: FeeType.Signs, label: 'Signs', feeGroup: 'variable' },
  { type: FeeType.Postage, label: 'Postage', feeGroup: 'variable' },
  { type: FeeType.MarketingMaterials, label: 'Marketing Materials', feeGroup: 'variable' },
  { type: FeeType.Other, label: 'Other', feeGroup: 'variable' },
];

const palette = themeService.getPalette();

export const GenericNetIncomeValuation: React.FC = () => {
  const [submitted, setSubmitted] = useState(false);

  const [agent, setAgent] = useState({ fullName: '', memberKey: '' });
  //const volume = { totalVolume: { current: 0 }, totalTransactions: { current: 0 } };
  const [salesVolume, setSalesVolume] = useState(0);
  const [transactionCount, setTransactionCount] = useState(0);

  const history = useHistory();
  const backButtonClick = () => {
    history.goBack();
  };

  //datasources for the fees in the two tables
  const [fixedFeeSchedule, setFixedFeeSchedule] = useState<FeeScheduleItem[]>([...FIXED_FEES]);
  const [variableFeeSchedule, setVariableFeeSchedule] = useState<FeeScheduleItem[]>([
    ...VARIABLE_FEES,
    {
      type: FeeType.TransactionFees,
      label: 'Transaction Fees',
      feeGroup: 'variable',
      customMultiplier: transactionCount || 0,
    },
  ]);

  useEffect(() => {
    //need to have some way to treat this one special, since it's fee * total transactions
    if (transactionCount) {
      setVariableFeeSchedule(prevVariableFees =>
        prevVariableFees.map(variableFee => {
          if (variableFee.type === FeeType.TransactionFees) {
            return {
              ...variableFee,
              customMultiplier: transactionCount || 0,
            };
          }

          return variableFee;
        })
      );
    }
  }, [transactionCount]);

  //shows the calculated numbers in the footer
  const [currentTrueNetIncome, setCurrentTrueNetIncome] = useState(0);
  const [currentTrueValueSplit, setCurrentTrueValueSplit] = useState(0);
  const [oursTrueNetIncome, setOursTrueNetIncome] = useState(0);
  const [oursTrueValueSplit, setOursTrueValueSplit] = useState(0);

  //fee totals from fixed fees, used to calculate numbers in the footer
  const [fixedCurrentFeeTotals, setFixedCurrentFeeTotals] = useState(0);
  const [fixedOursFeeTotals, setFixedOursFeeTotals] = useState(0);

  //fee totals from variable fees, used to calculate numbers in the footer
  const [variableCurrentFeeTotals, setVariableCurrentFeeTotals] = useState(0);
  const [variableOursFeeTotals, setVariableOursFeeTotals] = useState(0);

  const [notes, setNotes] = useState('');

  useEffect(() => {
    //if we have a total of the fixed 'Current' fees
    if (fixedCurrentFeeTotals) {
      const trueNetIncomeDollars = fixedCurrentFeeTotals - variableCurrentFeeTotals;
      const trueNetIncomePercent =
        (fixedCurrentFeeTotals - variableCurrentFeeTotals) /
        (fixedFeeSchedule.find(fee => fee.type === FeeType.AgentGrossCommission)!
          .totalCostCurrent || 0);
      setCurrentTrueNetIncome(trueNetIncomeDollars);
      setCurrentTrueValueSplit(trueNetIncomePercent);
    }

    //if we at least have a total of fixed 'Our' fees
    if (fixedOursFeeTotals) {
      const trueNetIncomeDollars = fixedOursFeeTotals - variableOursFeeTotals;
      const trueNetIncomePercent =
        (fixedOursFeeTotals - variableOursFeeTotals) /
        (fixedFeeSchedule.find(fee => fee.type === FeeType.AgentGrossCommission)!.totalCostOurs ||
          0);
      setOursTrueNetIncome(trueNetIncomeDollars);
      setOursTrueValueSplit(trueNetIncomePercent);
    }
  }, [
    fixedFeeSchedule,
    fixedCurrentFeeTotals,
    fixedOursFeeTotals,
    variableCurrentFeeTotals,
    variableOursFeeTotals,
  ]);

  const formCheck = () => {
    setSubmitted(true);
    if (!agent.fullName || !salesVolume || !transactionCount) {
      return false;
    }
    return true;
  };

  return (
    <>
      <PageHeader
        title="NET INCOME VALUATION CALCULATOR"
        icon={icons.calculator}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={1} style={{ minWidth: '65px' }}>
          {history.length > 0 && (
            <Button onClick={() => backButtonClick()} variant='secondary'>
              <ArrowBackIosNew />
            </Button>
          )}
        </Grid>
        <Grid container item xs={12} sm={11}>
          <Box mb={2}>
            <Box flexGrow={1}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    error={submitted && !agent.fullName}
                    helperText={submitted && !agent.fullName ? 'This field is required' : ''}
                    required
                    fullWidth
                    value={agent.fullName}
                    sx={{ backgroundColor: palette.neutralLightest }}
                    onChange={(e) => {
                      setAgent({ ...agent, fullName: e.target.value });
                    }}
                    startAdornment={(
                      <InputAdornment position="start">
                        <Person style={{ color: palette.magenta }} />
                        <Typography style={{ marginLeft: '5px' }}>Agent Name:</Typography>
                      </InputAdornment>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    error={submitted && !salesVolume}
                    helperText={submitted && !salesVolume ? 'This field is required' : ''}
                    required
                    fullWidth
                    value={salesVolume}
                    sx={{ backgroundColor: palette.neutralLightest }}
                    onChange={e => {
                      setSalesVolume(Number(e.target.value));
                    }}
                    startAdornment={(
                      <InputAdornment position="start">
                        <AttachMoney style={{ color: palette.magenta }} />
                        <Typography style={{ marginLeft: '5px' }}>Sales Volume:</Typography>
                      </InputAdornment>
                    )}
                    _component={CustomCurrencyInputMasker as any}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    error={submitted && !transactionCount}
                    helperText={submitted && !transactionCount ? 'This field is required' : ''}
                    required
                    fullWidth
                    value={transactionCount}
                    sx={{ backgroundColor: palette.neutralLightest }}
                    onChange={e => {
                      setTransactionCount(Number(e.target.value));
                    }}
                    startAdornment={(
                      <InputAdornment position="start">
                        <House style={{ color: palette.magenta }} />
                        <Typography style={{ marginLeft: '5px' }}>Transaction Count:</Typography>
                      </InputAdornment>
                    )}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} container spacing={1}>
          <Grid item xs={12}>
            <Box mb={2}>
              <RecruitingComparisonCalculatorBarComponent
                formCheck={formCheck}
                salesVolume={salesVolume || 0}
                barTitle="Current Company"
                color={ThemeColors.Red}
                feeSchedule={fixedFeeSchedule}
                onCalculate={setFixedFeeSchedule}
                feeCategory={FeeCategory.Current}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box mb={2}>
              <RecruitingComparisonCalculatorBarComponent
                formCheck={formCheck}
                salesVolume={salesVolume || 0}
                color={ThemeColors.Green}
                barTitle="Our Company"
                feeSchedule={fixedFeeSchedule}
                onCalculate={setFixedFeeSchedule}
                feeCategory={FeeCategory.Ours}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <RecruitingFixedFeeScheduleTableComponent
            feeSchedule={fixedFeeSchedule}
            bubbleFixedCurrentFeeTotals={setFixedCurrentFeeTotals}
            bubbleFixedOursFeeTotals={setFixedOursFeeTotals}
          />
        </Grid>
        <Grid item xs={12}>
          <RecruitingVariableFeeScheduleTableComponent
            feeSchedule={variableFeeSchedule}
            setFeeSchedule={setVariableFeeSchedule}
            bubbleVariableCurrentFeeTotals={setVariableCurrentFeeTotals}
            bubbleVariableOursFeeTotals={setVariableOursFeeTotals}
          />
        </Grid>
        <Grid item xs={12}>
          <Box>
            <Card>
              <CardHeader title={'Additional Notes'} />
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box>
                      <TextField
                        id="outlined-multiline-static"
                        type="textarea"
                        rows={4}
                        value={notes}
                        onChange={e => setNotes(e.target.value)}
                        fullWidth
                      />
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <p>&nbsp;</p>

          <NetIncomeSummaryFooterComponent
            currentTrueNetIncome={currentTrueNetIncome}
            currentTrueValueSplit={currentTrueValueSplit}
            oursTrueNetIncome={oursTrueNetIncome}
            oursTrueValueSplit={oursTrueValueSplit}
            agent={agent}
            fixedFeeSchedule={fixedFeeSchedule}
            variableFeeSchedule={variableFeeSchedule}
            fixedCurrentFeeTotals={fixedCurrentFeeTotals}
            fixedOursFeeTotals={fixedOursFeeTotals}
            variableCurrentFeeTotals={variableCurrentFeeTotals}
            variableOursFeeTotals={variableOursFeeTotals}
            notes={notes}
            salesVolume={salesVolume}
            totalTransactions={transactionCount}
          />
        </Grid>
      </Grid>
    </>
  );
};

import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useCompanyBySurvey, useQuestionsBySubmissionLink } from '../hooks';
import { SurveyAnswer, surveysService } from '../../state/surveys';
import { QuestionSubmissionList } from '../components/question-submission-list';
import { LoadingFade } from '../../shared';
import LogoImage from '../../assets/BT-Recruit-Horizontal-Dark.png';
import { SubmissionMessage } from '../components';
import { Box, Container } from '@mui/material';
import { Typography } from 'shared/components';

export function TakeSurveyPage() {
  const { submissionLink } = useParams<{ submissionLink: string }>();
  const { questions, loading, error } = useQuestionsBySubmissionLink(submissionLink);
  const { company, loading: companyLoading } = useCompanyBySurvey(submissionLink);
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = (answers: Array<SurveyAnswer>) => {
    surveysService.submitAnswers(submissionLink, answers);
    setSubmitted(true);
  };

  const logoUrl = company?.hasLogo ? company.logoUrl : LogoImage;

  if (error) return <SubmissionMessage message={error} error={true} />;

  if (submitted) return <SubmissionMessage message="Thank you for completing the survey!" />;

  return (
    <LoadingFade isContentLoading={loading || companyLoading}>
      <Container fixed maxWidth="md">
        <Box marginBottom={4}>
          <img
            src={logoUrl}
            alt={`${company?.hasLogo ? company?.name : 'Ampstat'}s Logo`}
            width="100%"
          />
        </Box>
        <Box marginBottom={4}>
          <Typography
            variant="subtitle"
            align="center"
          >
            Please answer the following questions.
          </Typography>
        </Box>
        <Box>
          <QuestionSubmissionList questions={questions} onSubmit={handleSubmit} />
        </Box>
      </Container>
    </LoadingFade>
  );
}

import React from 'react';
import { Card, CardContent } from '@mui/material';
import { EditUserForm as EditUserFormModel } from '../../state/users';
import { ManagerForms, UpsertManagerForm, managersService } from '../../state/admin';
import { useFormEvents } from '../../shared/forms';
import { FormStatus } from '../../state';
import { EditUserForm } from '../components';
import { useParams } from 'react-router-dom';
import { useCompanyOffices } from '../hooks';
import { getError } from '../../api';

export const CreateUserPage: React.FC = () => {
  const companyId = parseInt(useParams<{ companyId: string }>().companyId);
  const [{ status, error }, resetFormUI] = useFormEvents(ManagerForms.Create);

  const { companyOffices: offices } = useCompanyOffices(companyId);

  const submit = (form: EditUserFormModel) => {
    form.companyId = companyId;
    const officeCodes = offices
      .filter(o => form.owner || form.officeIds?.some(id => id === o.officeId))
      .flatMap(o => o.codes ?? []);
    managersService.createManager(form as UpsertManagerForm, officeCodes);
  };

  return (
    <Card>
      <CardContent>
        <EditUserForm
          offices={offices}
          onSubmit={submit}
          onResetFeedback={resetFormUI}
          error={!!error ? getError(error) : undefined}
          success={status === FormStatus.Success}
          pending={status === FormStatus.Pending}
        />
      </CardContent>
    </Card>
  );
};

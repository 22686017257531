import { applyTransaction, setLoading, Store } from '@datorama/akita';
import { of, from, zip, MonoTypeOperatorFunction } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { startOfToday, subYears } from 'date-fns';
import { saveAs } from 'file-saver';

import { batchedRequest } from './util';
import { DateRangeOptions } from './date-filter';

import { AgentsApi } from 'api/mls/api/agents-api';
import { TransactionsApi } from 'api/mls/api/transactions-api';
import { OfficesApi } from 'api/mls/api/offices-api';

import { OfficeVolumeResultType, UpdateAgentRequest } from '../../api/mls/model';
import { dispatchForm } from '../forms';
import { MlsForms } from 'state/mls/mls.forms';
import {
  AgentsStore,
  ListingsStore,
  OfficesStore,
  AgentVolumeStore,
  OfficeVolumeStore,
} from './stores';
import { CoBrokeRequest, coBrokesStore, CoBrokesStore } from '../cobroke';
import { CancellablePromise, makeCancellable } from '../util/makeCancellable';
import { determineStoreLoading } from '../util';
import { Mls } from 'api';

function trace(...params: any[]) {
  console.debug(...params);
}

export class MlsService {
  static instance: MlsService;

  private readonly agentVolumeSummaries = batchedRequest<string>();
  private readonly OfficeVolumeSummaries = batchedRequest<string>();
  private readonly CompanyVolumeSummaries = batchedRequest<string>();
  private readonly cancellableOfficeRetentionRequest: CancellablePromise<
    CoBrokeRequest | undefined,
    Mls.OfficeRetentionModel[]
  >;

  constructor(
    private readonly agentsStore: AgentsStore,
    private readonly agentVolumeStore: AgentVolumeStore,
    private readonly listingsStore: ListingsStore,
    private readonly officesStore: OfficesStore,
    private readonly officeVolumeStore: OfficeVolumeStore,
    private readonly coBrokesStore: CoBrokesStore,
    private readonly agentsApi: AgentsApi,
    private readonly transactionsApi: TransactionsApi,
    private readonly officesApi: OfficesApi
  ) {
    this.agentVolumeSummaries.subscribe(next =>
      this.loadAgentVolumeSummaries(next, true).subscribe()
    );
    this.OfficeVolumeSummaries.subscribe(next =>
      this.loadOfficeVolumeSummaries(next, OfficeVolumeResultType.Office, true).subscribe()
    );
    this.CompanyVolumeSummaries.subscribe(next =>
      this.loadOfficeVolumeSummaries(next, OfficeVolumeResultType.Company, true).subscribe()
    );
    this.cancellableOfficeRetentionRequest = makeCancellable(
      this.officesApi,
      this.officesApi.mlsOfficesRetentionPost
    );
  }

  getAgent(memberKey: string) {
    trace('Get Agent', memberKey);
    
    from(this.agentsApi.mlsAgentsPost({ memberKeys: [memberKey] }))
      .pipe(map(x => x.data.data))
      .subscribe(data => 
        {
          this.agentsStore.upsertMany(data);
          this.agentsStore.setActive(data[0].memberKey);
        }
      );
  }

  getAgents(memberKeys: string[]) {
    trace('Get Agents', memberKeys);

    from(this.agentsApi.mlsAgentsPost({ memberKeys }))
      .pipe(map(x => x.data.data))
      .subscribe(data => this.agentsStore.upsertMany(data));
  }

  getOffices(officeKeys: string[], includeBranches = false) {
    trace('Get Offices', officeKeys);

    from(this.officesApi.mlsOfficesPost({ officeKeys, includeBranches }))
      .pipe(map(x => x.data.data))
      .subscribe(data => this.officesStore.upsertMany(data));
  }

  getOfficeRetention(exportRequest: CoBrokeRequest) {
    from(this.cancellableOfficeRetentionRequest(exportRequest))
      .pipe(
        determineStoreLoading(coBrokesStore),
        map(response => response.data)
      )
      .subscribe(coBrokes =>
        applyTransaction(() => {
          this.agentsStore.upsertMany([
            ...coBrokes.filter(c => !!c.ourAgent).map(c => c.ourAgent!),
            ...coBrokes.filter(c => !!c.coBrokeAgent).map(c => c.coBrokeAgent!),
          ]);
          this.coBrokesStore.set({ ...coBrokes });
        })
      );
  }

  exportOfficeRetention(keys: Array<string>, startDate: string, endDate: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      from(
        this.officesApi.mlsOfficesRetentionExportPost(
          {
            endDate: endDate,
            startDate: startDate,
            feedKeys: keys,
          },
          { responseType: 'blob' }
        )
      ).subscribe(
        response => {
          saveAs(response.data as any, 'CoBroke.csv');
          resolve(); // Resolve the promise when the download is complete
        },
        error => {
          reject(error); // Reject the promise if there's an error
        }
      );
    });
  }

  getListings(listingKeys: string[]) {
    trace('Get Listings', listingKeys);

    from(this.transactionsApi.mlsTransactionsPost({ listingKeys }))
      .pipe(map(x => x.data.data))
      .subscribe(data => this.listingsStore.upsertMany(data));
  }

  getAgentTransactions(memberKey: string) {
    this.agentsStore.setActive(memberKey);

    from(
      this.transactionsApi.mlsTransactionsGet(
        memberKey,
        subYears(startOfToday(), 2).toISOString(),
        startOfToday().toISOString()
      )
    )
      .pipe(
        loading(false, this.listingsStore, this.agentsStore, this.officesStore),
        map(response => response.data),
        setLoading(this.listingsStore),
      )
      .subscribe(({ data, agents, offices }) =>
        applyTransaction(() => {
          this.listingsStore.upsertMany(data);
          this.agentsStore.upsertMany(agents);
          this.officesStore.upsertMany(offices);
        })
      );
  }

  getAgentVolumes(memberKeys: Array<string>) {
    this.loadAgentVolumes(memberKeys, false).subscribe();
  }

  getAgentVolumeSummary(memberKey: string) {
    this.agentVolumeSummaries.request(memberKey);
  }

  getOfficeVolumeSummary(officeKey: string) {
    this.OfficeVolumeSummaries.request(officeKey);
  }

  getCompanyVolumeSummary(companyKey: string) {
    this.CompanyVolumeSummaries.request(companyKey);
  }

  /**
   * Loads agent volume data for specified member keys
   * @param memberKeys agent member keys to retrieve listing and volume stats
   */
  loadAgentVolumes(memberKeys: Array<string>, background = true, force = false) {
    const loadedKeySet = new Set<string>(
      Object.keys(this.agentVolumeStore.getValue().entities ?? {})
    );
    const newKeySet = memberKeys.filter(k => force || !loadedKeySet.has(k));

    this.agentVolumeStore.setActive(memberKeys);

    if (newKeySet.length === 0) {
      trace(`Skip Agent Volumes (already loaded)`);
      return of(memberKeys);
    }

    trace(`Load Agent Volumes (${newKeySet.length} of ${memberKeys.length})`, memberKeys);

    return zip(
      this.agentsApi.mlsAgentsVolumePost({
        memberKeys: newKeySet,
        fromDate: subYears(startOfToday(), 2).toISOString(),
        toDate: startOfToday().toISOString(),
      }),
      this.agentsApi.mlsAgentsSummaryPost({
        memberKeys: newKeySet,
      })
    ).pipe(
      loading(background, this.listingsStore, this.agentsStore, this.agentVolumeStore),
      map(([volumeResponse, volumeSummaryResponse]) => {
        //forkjoin/zip returns the items in order requested
        return {
          volumeResponseData: volumeResponse.data,
          volumeSummaryResponseData: volumeSummaryResponse.data,
        };
      }),
      tap(({ volumeResponseData, volumeSummaryResponseData }) => {
        applyTransaction(() => {
          this.listingsStore.upsertMany(volumeResponseData.data);
          this.agentsStore.upsertMany(volumeResponseData.agents);
          this.agentVolumeStore.upsertMany(volumeSummaryResponseData.data);
        });
      }),
      map(() => memberKeys)
    );
  }

  /**
   * Loads agent volume data for specified member keys
   * @param memberKeys agent member keys to retrieve listing and volume stats
   */
  loadAgentVolumeSummaries(memberKeys: Array<string>, background = true) {
    const loadedKeySet = new Set<string>(
      Object.keys(this.agentVolumeStore.getValue().entities ?? {})
    );
    const newKeySet = memberKeys.filter(k => !loadedKeySet.has(k));

    this.agentVolumeStore.setActive(memberKeys);

    if (newKeySet.length === 0) {
      trace(`Skip Agent Volume Summaries (already loaded)`);
      return of(memberKeys);
    }

    trace(`Load Agent Volume Summaries (${newKeySet.length} of ${memberKeys.length})`, memberKeys);

    return from(
      this.agentsApi.mlsAgentsSummaryPost({
        memberKeys: newKeySet,
      })
    ).pipe(
      loading(background, this.listingsStore, this.agentsStore, this.agentVolumeStore),
      map(volumeSummaryResponse => {
        return {
          volumeSummaryResponseData: volumeSummaryResponse.data,
        };
      }),
      tap(({ volumeSummaryResponseData }) => {
        this.agentVolumeStore.upsertMany(volumeSummaryResponseData.data);
      }),
      map(() => memberKeys)
    );
  }

  /**
   * Loads office volume data for specified office keys
   * @param officeKeys office keys to retrieve listing and volume stats
   */
  loadOfficeVolumeSummaries(
    officeKeys: Array<string>,
    officeVolumeResultType: OfficeVolumeResultType,
    background = true
  ) {
    const loadedKeySet = new Set<string>(
      Object.keys(this.officeVolumeStore.getValue().entities ?? {})
    );
    const newKeySet = officeKeys.filter(k => !loadedKeySet.has(k));

    this.officeVolumeStore.setActive(officeKeys);

    if (newKeySet.length === 0) {
      trace(`Skip Office Volume Summaries (already loaded)`);
      return of(officeKeys);
    }

    trace(`Load Office Volume Summaries (${newKeySet.length} of ${officeKeys.length})`, officeKeys);

    return from(
      this.officesApi.mlsOfficesSummaryPost({
        officeKeys: newKeySet,
        officeVolumeResultType: officeVolumeResultType,
      })
    ).pipe(
      loading(background, this.listingsStore, this.officesStore, this.officeVolumeStore),
      map(volumeSummaryResponse => {
        return {
          volumeSummaryResponseData: volumeSummaryResponse.data,
        };
      }),
      tap(({ volumeSummaryResponseData }) => {
        this.officeVolumeStore.upsertMany(volumeSummaryResponseData.data);
      }),
      map(() => officeKeys)
    );
  }

  /**
   * Loads agents for the given offices and their related agent volume data.
   * @param officeKeys Office keys to load.
   */
  getOfficeVolumes(officeKeys: string[]) {
    this.loadOfficeVolumes(officeKeys, false).subscribe();
  }

  /**
   * Loads office volume data for specified office keys
   * @param officeKeys office keys to retrieve listing and volume stats
   */
  loadOfficeVolumes(officeKeys: Array<string>, background = true) {
    const loadedKeySet = new Set<string>(
      Object.keys(this.officeVolumeStore.getValue().entities ?? {})
    );
    const newKeySet = officeKeys.filter(k => !loadedKeySet.has(k));

    this.officeVolumeStore.setActive(officeKeys);

    if (newKeySet.length === 0) {
      trace(`Skip Office Volumes (already loaded)`);
      return of(officeKeys);
    }

    trace(`Load Office Volumes (${newKeySet.length} of ${officeKeys.length})`, officeKeys);

    return zip(
      this.officesApi.mlsOfficesAgentsPost({ officeKeys: officeKeys }),
      this.officesApi.mlsOfficesVolumePost({
        officeKeys: newKeySet,
        fromDate: subYears(startOfToday(), 2).toISOString(),
        toDate: startOfToday().toISOString(),
      })
      // .. we can add summaries here once needed
      // this.officesApi.mlsOfficesSummaryPost({
      //   officeKeys: newKeySet,
      // })
    ).pipe(
      loading(
        background,
        this.listingsStore,
        this.agentsStore,
        this.officesStore,
        this.officeVolumeStore
      ),
      map(([agents, volumes]) => {
        //forkjoin/zip returns the items in order requested
        return {
          agents: agents.data,
          volumes: volumes.data,
        };
      }),
      tap(({ volumes, agents }) => {
        applyTransaction(() => {
          this.listingsStore.upsertMany(volumes.data);
          this.agentsStore.upsertMany(agents.data);
          this.officesStore.upsertMany(volumes.offices);
        });
      }),
      map(() => officeKeys)
    );
  }

  filterAgents(searchTerm: string) {
    // append the searchTerm to the ui state
    this.agentsStore.update(({ ui }) => ({ ui: { ...ui, searchTerm } }));
  }

  setActiveDateRange(dateRange: DateRangeOptions) {
    this.listingsStore.update(({ ui }) => ({ ui: { ...ui, dateRange } }));
  }

  updateAgent(request: UpdateAgentRequest) {
    from(this.agentsApi.mlsAgentsUpdatePost(request))
      .pipe(dispatchForm(MlsForms.AgentEdit))
      .subscribe(() =>
        applyTransaction(() => {
          this.agentsStore.upsert(request.memberKey, {
            alternateEmail: request.alternateEmail,
            alternatePhone: request.alternatePhone,
          });
        })
      );
  }
}

function loading<T>(background: boolean, ...stores: Store<any>[]): MonoTypeOperatorFunction<T> {
  if (background) {
    return source => source;
  }

  return source => stores.reduce((s, store) => s.pipe(setLoading(store)), source);
}

export const mlsService = new MlsService(
  AgentsStore.instance,
  AgentVolumeStore.instance,
  ListingsStore.instance,
  OfficesStore.instance,
  OfficeVolumeStore.instance,
  coBrokesStore,
  new AgentsApi(),
  new TransactionsApi(),
  new OfficesApi()
);

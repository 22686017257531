import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ErrorDisplay, SurveyForm } from '../components';
import { UpdateSurveyRequest } from '../../api/app';
import { LoadingFade, PageHeader } from '../../shared';
import { SurveyForms, SurveyQuestion, surveysService } from '../../state/surveys';
import { useGetSurvey, useQuestions, useSubmitted } from '../hooks';
import { useFormEvents } from '../../shared/forms';
import { FormStatus } from '../../state';
import { getError } from '../../api';
import { ConfirmationDialog } from '../components/confirmation-dialog';

export function UpdateSurveyPage() {
  const { surveyId } = useParams<{ surveyId: string }>();

  const history = useHistory();
  const [{ status, error: formError }, resetFormUI] = useFormEvents(SurveyForms.Update);
  const {
    survey: surveyToUpdate,
    loading: contentLoading,
    error: getSurveyError,
  } = useGetSurvey(+surveyId);
  const {
    questions,
    loading: questionsLoading,
    error: getQuestionsError,
  } = useQuestions(+surveyId);

  const { submissions, loading: submissionsLoading } = useSubmitted(+surveyId);
  const [open, setOpen] = useState(false);
  const [survey, setSurvey] = useState<UpdateSurveyRequest | undefined>();

  const onSubmit = (title: string, questions: Array<SurveyQuestion>) => {
    setSurvey({
      id: +surveyId,
      surveyTitle: title,
      questions,
    });
    setOpen(true);
  };

  const onConfirm = () => {
    if (survey) {
      surveysService.updateSurvey(survey);
    }
    setOpen(false);
  };

  const onErrorExit = () => {
    history.goBack();
  };

  useEffect(() => {
    if (status === FormStatus.Success) history.goBack();
  }, [status, history]);

  return (
    <LoadingFade isContentLoading={contentLoading && questionsLoading && submissionsLoading}>
      <>
        <ErrorDisplay
          open={!!getSurveyError}
          onClose={onErrorExit}
          message={'Error loading survey!\nPlease contact your office admin!'}
        />
        <ErrorDisplay
          open={!!getQuestionsError}
          onClose={onErrorExit}
          message={'Error loading questions!\nPlease contact your office admin!'}
        />
        <ConfirmationDialog open={open} handleClose={() => setOpen(false)} onConfirm={onConfirm} />
        <PageHeader title="Edit Survey" backButton />
        <SurveyForm
          surveyTitle={surveyToUpdate?.title ?? ''}
          defaultQuestions={questions}
          onSubmit={onSubmit}
          error={!!formError ? getError(formError) : undefined}
          canDeleteQuestions={submissions.length === 0}
        />
      </>
    </LoadingFade>
  );
}

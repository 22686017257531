import { QueryEntity } from '@datorama/akita';
import { map } from 'rxjs/operators';

import { CompanyState, companyStore } from './company.store';

export class CompanyQuery extends QueryEntity<CompanyState> {
  /** List of all loaded companies for the manager. */
  companies = this.selectAll();

  activeCompany = this.selectActive();

  companyCodes = this.selectAll().pipe(
    map(companies => companies.flatMap(company => company.companyCodes))
  );

  loading = this.selectLoading();
}

export const companyQuery = new CompanyQuery(companyStore);

import themeService from 'theme/ThemeService';

const palette = themeService.getPalette();

export const theme = {
  navDrawer: {
    background: palette.neutralLightest,
    textColor: palette.neutralDark,
    listItem: {
      paddingTop: '12px',
      paddingBottom: '12px',
    },
  },
};

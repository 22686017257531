import React, { useState } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import {
  Drawer,
  List,
  ListItemIcon,
  ListItemText,
  ListItemProps,
  ListItem,
  Collapse,
} from '@mui/material';
import { ExpandLess, ExpandMore, Launch } from '@mui/icons-material';
import { useTheme } from 'theme/ThemeContext';
import { Logo } from '../logo';
import { useObservable } from 'shared/utils/useObservable';
import { sessionQuery } from '../../../state';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icons } from '../../icons';
import themeService from 'theme/ThemeService';

export const drawerWidth = 260;
const palette = themeService.getPalette();

const iconStyling = {
  color: 'currentColor',
  minWidth: '2.5rem',
}

const collapseStyle = {
  borderTop: 'solid thin',
  borderColor: palette.neutral,
}

interface NavDrawerProps {
  mobileOpen: boolean;
  setMobileOpen(open: boolean): void;
  appBarHeight: number;
}

export const NavDrawer: React.FC<NavDrawerProps> = ({
  mobileOpen,
  setMobileOpen,
  appBarHeight,
}: NavDrawerProps) => {
  const theme = useTheme();
  const user = useObservable(sessionQuery.currentUser);
  const isAdmin = useObservable(sessionQuery.isAdmin);
  const isKvCoreUser = useObservable(sessionQuery.isKvCoreUser);
  const [prospectingOpen, setProspectingOpen] = useState(false);
  const [retentionOpen, setRetentionOpen] = useState(false);
  const [adminOpen, setAdminOpen] = useState(false);

  const closeAll = () => {
    setRetentionOpen(false);
    setProspectingOpen(false);
    setAdminOpen(false);
  };

  const handlePropspectingClick = () => {
    closeAll();
    setProspectingOpen(!prospectingOpen);
  };

  const handleRetentionClick = () => {
    closeAll();
    setRetentionOpen(!retentionOpen);
  };

  const handleAdminClick = () => {
    closeAll();
    setAdminOpen(!adminOpen);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <>
      <Logo imgStyle={{height: appBarHeight}}/>
      <List component="nav">
        <ListItemLink to="/company">
          <ListItemIcon sx={iconStyling}>
            <FontAwesomeIcon icon={icons.poll} size="lg" />
          </ListItemIcon>
          <ListItemText>Dashboard</ListItemText>
        </ListItemLink>
        <ListItem style={collapseStyle} button onClick={handlePropspectingClick}>
          <ListItemIcon sx={iconStyling}>
            <FontAwesomeIcon icon={icons.searchGlass} size="lg" />
          </ListItemIcon>
          <ListItemText>Prospecting</ListItemText>
          {prospectingOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={prospectingOpen} timeout="auto" unmountOnExit>
          <List component="div">
            <ListItemLink to="/search">
              <ListItemText style={{ paddingLeft: '4em' }}>Recruit Search</ListItemText>
            </ListItemLink>
            <ListItemLink to="/cobroke">
              <ListItemText style={{ paddingLeft: '4em' }}>Co-Broke Sales</ListItemText>
            </ListItemLink>
          </List>
        </Collapse>
        <ListItemLink to="/pipeline">
          <ListItemIcon sx={iconStyling}>
            <FontAwesomeIcon icon={icons.filter} size="lg" />
          </ListItemIcon>
          <ListItemText>Pipeline</ListItemText>
        </ListItemLink>
        <ListItem style={collapseStyle} button onClick={handleRetentionClick}>
          <ListItemIcon sx={iconStyling}>
            <FontAwesomeIcon icon={icons.dollar} size="lg" />
          </ListItemIcon>
          <ListItemText>Retention</ListItemText>
          {retentionOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={retentionOpen} timeout="auto" unmountOnExit>
          <ListItemLink to="/retention">
            <ListItemText style={{ paddingLeft: '4em' }}>Agent Retention</ListItemText>
          </ListItemLink>
        </Collapse>
        <ListItemLink to="/tools">
          <ListItemIcon sx={iconStyling}>
            <FontAwesomeIcon icon={icons.tools} size="lg" />
          </ListItemIcon>
          <ListItemText>Resources</ListItemText>
        </ListItemLink>
        {isKvCoreUser && (
          <ListItemLink to="/kvcore/sso" >
            <ListItemIcon sx={iconStyling}>
              <Launch />
            </ListItemIcon>
            <ListItemText>BoldTrail</ListItemText>
          </ListItemLink>
        )}
        {user && isAdmin && (
          <>
            <ListItem style={collapseStyle} button onClick={handleAdminClick}>
              <ListItemIcon sx={iconStyling}>
                <FontAwesomeIcon icon={icons.admin} size="lg" />
              </ListItemIcon>
              <ListItemText>Admin / Settings</ListItemText>
              {adminOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={adminOpen} timeout="auto" unmountOnExit>
              <ListItemLink to="/admin/companies">
                <ListItemText style={{ paddingLeft: '4em' }}>Company Management</ListItemText>
              </ListItemLink>
              <ListItemLink to="/admin/status">
                <ListItemText style={{ paddingLeft: '4em' }}>Feed Status</ListItemText>
              </ListItemLink>
              <ListItemLink to="/admin/disclaimers">
                <ListItemText style={{ paddingLeft: '4em' }}>Feed Disclaimers</ListItemText>
              </ListItemLink>
              <ListItemLink to="/admin/user-impersonation">
                <ListItemText style={{ paddingLeft: '4em' }}>User Impersonation</ListItemText>
              </ListItemLink>
            </Collapse>
          </>
        )}
      </List>
    </>
  );

  return (
    <Drawer
      variant="temporary"
      anchor="left"
      open={mobileOpen}
      onClose={handleDrawerToggle}
      sx={{
        '.MuiPaper-root':{
          backgroundColor: theme.navDrawer.background,
          color: theme.navDrawer.textColor,
          width: drawerWidth,
          border: 'none',
        }
      }}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
    >
      {drawer}
    </Drawer>
  );
};

type ListItemLinkProps = Omit<ListItemProps, 'button'> & NavLinkProps;

const ListItemLink: React.FC<ListItemLinkProps> = ({ ...props }) => {
  const theme = useTheme();

  return (
    <ListItem
      component={NavLink}
      sx={{
        textDecoration: 'none',
        color: 'inherit',
        borderTop: 'solid thin',
        borderColor: palette.neutral,
        '&.active': {
          backgroundColor: palette.primary,
          color: palette.neutralLight,
          borderRadius: '12px',
          margin: '.4rem',
          maxWidth: '95%',
        },
      }}
      {...props}
    />
  );
};

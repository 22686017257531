import NumberFormat from 'react-number-format';
import React from 'react';

interface CustomCurrencyInputMaskerProps {
  inputRef: (instance: typeof NumberFormat | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

export const CustomCurrencyInputMasker: React.FC<CustomCurrencyInputMaskerProps> = (
  props: CustomCurrencyInputMaskerProps
) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
        const event = { ...evt };

        //This is needed to ensure what we pass down to our change event handler
        //can be used with the Number constructor, and commas and spaces are a no-no
        event.target.value = event.target.value.replace(/[ ,]/g, '');

        onChange(evt);
      }}
      thousandSeparator
      isNumericString
    />
  );
};

import {Box, Card, CardHeader, CardContent, Grid} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { NumberFormatters } from '../../../shared';
import { FeeScheduleItem, FeeType } from 'recruiting/pages/net-income-valuation.types';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
} from 'shared/components';
import themeService from 'theme/ThemeService';

interface RecruitingFixedFeeScheduleInterface {
  feeSchedule: FeeScheduleItem[];

  bubbleFixedCurrentFeeTotals: React.Dispatch<React.SetStateAction<number>>;
  bubbleFixedOursFeeTotals: React.Dispatch<React.SetStateAction<number>>;
}

const palette = themeService.getPalette();

const RecruitingFixedFeeScheduleTable: React.FC<RecruitingFixedFeeScheduleInterface> = ({
  feeSchedule,
  bubbleFixedCurrentFeeTotals,
  bubbleFixedOursFeeTotals,
}) => {
  //Totals
  const [fixedCurrentFeeTotals, setFixedCurrentFeeTotals] = useState(0);
  const [fixedCurrentFeeTotalsPercent, setFixedCurrentFeeTotalsPercent] = useState(0);

  const [fixedOursFeeTotals, setFixedOursFeeTotals] = useState(0);
  const [fixedOursFeeTotalsPercent, setFixedOursFeeTotalsPercent] = useState(0);

  useEffect(() => {
    const fixedFees = feeSchedule
      .filter((fee: FeeScheduleItem) =>
        [
          FeeType.AgentAdjustedGross,
          FeeType.LessCompanyDollarCap,
          FeeType.AfterCapCompanyDollar,
        ].includes(fee.type)
      )
      .sort((a, b) => a.type - b.type);

    //calculate current totals
    const currentFees = fixedFees.map(fee => fee.totalCostCurrent || 0).reduce((a, b) => a - b);

    setFixedCurrentFeeTotals(currentFees);

    //inform parent component of new Current total
    if (bubbleFixedCurrentFeeTotals) bubbleFixedCurrentFeeTotals(currentFees);

    //calculate current true split %
    const currentPercentFees =
      currentFees /
      feeSchedule.find(fee => fee.type === FeeType.AgentGrossCommission)?.totalCostCurrent!;
    setFixedCurrentFeeTotalsPercent(currentPercentFees);

    //calculate ours total
    const ourFees = fixedFees.map(fee => fee.totalCostOurs || 0).reduce((a, b) => a - b);
    setFixedOursFeeTotals(ourFees);

    //inform parent component of new Ours total
    if (bubbleFixedOursFeeTotals) bubbleFixedOursFeeTotals(ourFees);

    //calculate ours true split %
    const oursPercentFees =
      ourFees / feeSchedule.find(fee => fee.type === FeeType.AgentGrossCommission)?.totalCostOurs!;
    setFixedOursFeeTotalsPercent(oursPercentFees);
  }, [feeSchedule, bubbleFixedCurrentFeeTotals, bubbleFixedOursFeeTotals]);

  return (
    <Box>
      <Card>
        <CardHeader title={'Additional Agent Expenses'} />

        <CardContent>
          <Grid item xs={12}>
            <Box>
              <TableContainer sx={{border: 'solid thin', borderRadius: '8px', borderColor: palette.neutral}}>
                <Table size="medium">
                  <TableHead>
                    <TableRow style={{ backgroundColor: palette.neutralLight }}>
                      <TableCell style={{ border: '0px none' }}></TableCell>
                      <TableCell
                        align="left"
                        style={{
                          color: palette.neutralDark,
                          fontSize: '12px'
                        }}
                      >
                        <strong>CURRENT COMPANY</strong>
                      </TableCell>

                      <TableCell
                        align="left"
                        style={{
                          color: palette.neutralDark,
                          fontSize: '12px'
                        }}
                      >
                        <strong>OUR COMPANY</strong>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {feeSchedule.map(row => {
                      return (
                        <TableRow tabIndex={-1} key={row.type}>
                          <TableCell>{row.label}</TableCell>
                          <TableCell align="left">
                            {NumberFormatters.currency`${row.totalCostCurrent || 0}`}
                          </TableCell>

                          <TableCell align="left">
                            {NumberFormatters.currency`${row.totalCostOurs || 0}`}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TableCell
                        style={{
                          border: '0px none',
                          fontSize: '0.85rem',
                      }}
                        colSpan={1}
                        align="right"
                      >
                        Agent Net GCI:
                      </TableCell>
                      <TableCell
                        style={{
                          border: '0px none',
                          fontWeight: 'bold',
                          fontSize: '1rem'
                        }}
                        align="left"
                      >
                        {NumberFormatters.currency`${fixedCurrentFeeTotals}`}
                      </TableCell>

                      <TableCell
                        style={{
                          border: '0px none',
                          fontWeight: 'bold',
                          fontSize: '1rem'
                        }}
                        align="left"
                      >
                        {NumberFormatters.currency`${fixedOursFeeTotals}`}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{ border: '0px none', fontSize: '0.85rem' }}
                        colSpan={1}
                        align="right"
                      >
                        True Split:
                      </TableCell>
                      <TableCell
                        style={{
                          border: '0px none',
                          fontWeight: 'bold',
                          fontSize: '1rem'
                        }}
                        align="left"
                      >
                        {fixedCurrentFeeTotalsPercent
                          ? NumberFormatters.decimalPercent`${fixedCurrentFeeTotalsPercent}`
                          : '0%'}
                      </TableCell>

                      <TableCell
                        style={{
                          border: '0px none',
                          fontWeight: 'bold',
                          fontSize: '1rem'
                        }}
                        align="left"
                      >
                        {fixedOursFeeTotalsPercent
                          ? NumberFormatters.decimalPercent`${fixedOursFeeTotalsPercent}`
                          : '0%'}
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </Box>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

export const RecruitingFixedFeeScheduleTableComponent = React.memo(RecruitingFixedFeeScheduleTable);

import { ReactNode } from 'react';
import { Dialog as MuiDialog } from '@mui/material';

export { DialogTitle, DialogContent, DialogActions } from '@mui/material';

export type DialogProps = {
  isOpen: boolean;
  fullWidth?: boolean;
  children: ReactNode;
  onClose?: (event: Object, reason: string) => void;
  disableEscapeKeyDown?: boolean;
};

export function Dialog({ isOpen, fullWidth, children, onClose, disableEscapeKeyDown }: DialogProps) {
  return (
    <MuiDialog open={isOpen} onClose={onClose} fullWidth={fullWidth} disableEscapeKeyDown={disableEscapeKeyDown}>
      {children}
    </MuiDialog>
  );
}

import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import { Button } from 'shared/components';
import { SubmissionDetailsDialog } from './submission-details-dialog';

export function SubmissionDetailsDialogButton({
  submissionId,
  surveyId,
}: {
  submissionId: number;
  surveyId: number;
}) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  return (
    <>
      <Button type="button" size="small" variant="secondary" onClick={() => setIsDialogOpen(true)}>
        <FontAwesomeIcon icon={faCircleQuestion} size="lg" />
      </Button>
      {isDialogOpen && (
        <SubmissionDetailsDialog
          submissionId={submissionId}
          surveyId={surveyId}
          onClose={() => setIsDialogOpen(false)}
        />
      )}
    </>
  );
}

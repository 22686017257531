import React from 'react';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Button } from 'shared/components/button';
import { DateFormatters, NumberFormatters } from '../../shared/utils';
import { parseISO } from 'date-fns';
import { ViewModel } from './recruiting-watch-list';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icons } from '../../shared';

interface Props {
  csvData: ViewModel[];
  fileName: string;
}

export const RecruitingExportCSV: React.FC<Props> = ({ csvData, fileName }) => {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.csv';

  const exportCSV = (csvData: any[], fileName: string) => {
    const ws = XLSX.utils.json_to_sheet(
      csvData.map(x => ({
        MemberNumber: x.volume?.agent.memberNumber,
        MemberStatus: x.volume?.agent.memberStatus,
        MemberType: x.volume?.agent.memberType,
        FirstName: x.volume?.agent.firstName,
        LastName: x.volume?.agent.lastName,
        Email: x.volume?.agent.email,
        Mobile: x.volume?.agent.mobilePhone,
        Phone: x.volume?.agent.phone,
        AlternateEmail: x.volume?.agent.alternateEmail,
        AlternatePhone: x.volume?.agent.alternatePhone,
        Address: x.volume?.agent.memberAddress,
        City: x.volume?.agent.memberCity,
        State: x.volume?.agent.memberState,
        PostalCode: x.volume?.agent.memberPostalCode,
        OfficeName: x.volume?.agent.officeName,
        OfficeNumber: x.volume?.agent.officeNumber,
        'Last Activity': x.recruit.lastActivity
          ? DateFormatters.shortDate`${parseISO(x.recruit.lastActivity?.recordedAt!)}`
          : '',
        Active: x.volume?.activeListings.current,
        Pending: x.volume?.pendingListings.current,
        'List Trans': x.volume?.listTransactions.current,
        'List Trans Vol': NumberFormatters.currency`${x.volume?.listTransactionsVol.current}`,
        'Sell Trans': x.volume?.sellTransactions.current,
        'Sell Trans Vol': NumberFormatters.currency`${x.volume?.sellTransactionsVol.current}`,
        'Total Trans': x.volume?.totalTransactions.current,
        'Total Vol': NumberFormatters.currency`${x.volume?.totalVolume.current}`,
      }))
    );
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'csv', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <Button
      onClick={() => exportCSV(csvData, fileName)}
      startIcon={<FontAwesomeIcon icon={icons.download} size="lg" />}
    >
      Export Results
    </Button>
  );
};

import { persistState, akitaDevtools } from '@datorama/akita';
import { SessionState } from './session';

export function configureStores(dev: boolean = true) {
  if (dev) {
    akitaDevtools();
  }

  persistState({
    storage: new PersistedStateStorage(),
    include: ['session'],
  });
}

class PersistedStateStorage {
  getItem(key: string) {
    const storedValue = localStorage.getItem(key) ?? '{}';
    return JSON.parse(storedValue);
  }

  setItem(key: string, value: any) {
    const storage = this._getStorage(key, value);
    storage.setItem(key, JSON.stringify(value));
  }

  clear(): void {
    localStorage.clear();
  }

  private _getStorage(key: string, value: any) {
    //If you don't want to stay signed in, wipe any existing
    //storage, and start anew.
    if (!mustStaySignedIn(value.session)) {
      localStorage.removeItem(key);
    }

    return localStorage;
  }
}

function mustStaySignedIn(obj: any): obj is { session: { staySignedIn: true } } {
  const session = obj as SessionState;

  return session.staySignedIn === true;
}

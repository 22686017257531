import { AccountApi } from 'api/auth/api/account-api';

import { UserStore, userStore } from './user.store';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  UpdateProfileRequest,
  ResetPasswordRequest,
  ChangePasswordRequest,
} from '../../api/auth/model';
import { applyTransaction } from '@datorama/akita';
import { dispatchForm } from '..';
import { UserForms } from 'state/users/user.forms';
import { AdminApi } from 'api/app';

export class UserService {
  constructor(private readonly store: UserStore, private readonly accountApi: AccountApi, private readonly adminApi: AdminApi) {}

  getProfile() {
    from(this.accountApi.accountProfileGet())
      .pipe(map(x => x.data))
      .subscribe(x =>
        applyTransaction(() => {
          this.store.upsert(x.id, x);
          this.store.setActive(x.id);
        })
      );
  }

  updateProfile(updateForm: UpdateProfileRequest) {
    from(this.accountApi.accountProfilePut(updateForm))
      .pipe(
        map(x => x.data),
        dispatchForm(UserForms.Profile)
      )
      .subscribe(x => this.store.updateActive(x));
  }

  patchProfile(updateForm: UpdateProfileRequest) {
    from(this.accountApi.accountProfilePatch(updateForm))
      .pipe(map(x => x.data))
      .subscribe(x => {
        this.store.updateActive(x);
      });
  }

  changePassword(changePasswordForm: ChangePasswordRequest) {
    from(this.accountApi.accountPasswordPost(changePasswordForm))
      .pipe(
        map(x => x.data),
        dispatchForm(UserForms.Profile)
      )
      .subscribe(_ => {
        this.store.updateActive({ mustChangePassword: false });
      });
  }

  forgotPassword(email: string) {
    from(this.accountApi.accountPasswordForgotPost({ email }))
      .pipe(dispatchForm(UserForms.ForgotPassword))
      .subscribe(_ => {
        this.store.update(({ ui }) => ({ ui: { ...ui, validEmailProvided: true } }));
      });
  }

  resetPassword(resetForm: ResetPasswordRequest) {
    from(this.accountApi.accountPasswordResetPost(resetForm))
      .pipe(dispatchForm(UserForms.ResetPassword))
      .subscribe();
  }

  acceptTermsOfUse(id: number) {
    from(this.adminApi.adminAcceptPost(id))
      .subscribe();
  }
}

export const userService = new UserService(userStore, new AccountApi(), new AdminApi());

import React, { useMemo } from 'react';
import { LinearProgress } from '@mui/material';
import { Company } from '../../state/admin';
import { FormStatus } from '../../state';
import { Link, ConfirmedButton } from '../../shared';
import { useFormEvents } from '../../shared/forms';
import { DataTable, DataTableColumn } from 'shared/components/data-table';
import { useLoadingIndicator } from '../../shared/indicators/isLoading';
import { LoadingFade } from '../../shared/components/loading-fade';
import { Manager, managersService, ManagerForms } from '../../state/admin/managers';

import { Delete } from '@mui/icons-material';

interface Props {
  company: Company;
  managers: Array<Manager>;
}

export const ManagerList: React.FC<Props> = React.memo(({ managers, company }) => {
  const loading = useLoadingIndicator();
  const [{ status, error }] = useFormEvents(ManagerForms.Delete);

  const deleteManager = (id: string) => {
    managersService.deleteManager(id);
  };

  const data = useMemo(
    () =>
      managers.map(manager => {
        return { manager, company, deleteManager, status, error };
      }),
    [managers, company, status, error]
  );

  return (
    <>
      {loading && <LinearProgress />}
      <LoadingFade isContentLoading={loading}>
        <DataTable
          rows={data}
          columns={columns}
          progressPending={loading}
          getRowId={(row) => row.manager.id!}
          initialState={{
            sorting: {
              sortModel: [
                {
                  field: 'managerName',
                  sort: 'asc',
                },
              ],
            },
          }}
        />
      </LoadingFade>
    </>
  );
});

export interface ViewModel {
  company: Company;
  manager: Manager;
  deleteManager: (id: string) => void;
  status: FormStatus;
  error: Error | undefined;
}

const columns: DataTableColumn<ViewModel>[] = [
  {
    headerName: 'Name',
    renderCell: ({ row }) => (
      <div style={{ padding: '.5em 0' }}>
        <Link to={'/admin/companies/' + row.company.id + '/users/' + row.manager.id} color="primary">
          {row.manager.name}
        </Link>
      </div>
    ),
    flex: 2,
    sortable: true,
    field: 'managerName',
  },
  {
    headerName: '',
    field: 'manager.id',
    renderCell: ({ row }) => (
      <ConfirmedButton
        size="small"
        variant="subtle"
        onConfirm={() => row.deleteManager(row.manager.id!)}
        action="delete"
        status={row.status}
        error={row.error}
      >
        <Delete />
      </ConfirmedButton>
    ),
  },
];

import React, { useEffect, useMemo, useState } from 'react';
import {
  Card,
  CardContent,
  Grid,
  IconButton,
  Chip,
  LinearProgress,
  Tooltip,
} from '@mui/material';
import { Button, ConfirmationDialog, Typography } from 'shared/components';
import { makeStyles } from '@mui/styles';
import {Forum, NotInterested} from '@mui/icons-material';
import { parseISO } from 'date-fns';

import { Recruit, AgentVolumeLookup, AgentVolumeSummary, Agent, kvCoreService } from '../../state';
import {
  CaptionedValue,
  DateFormatters,
  Link,
  NumberFormatters,
  SearchInput,
  useObservable,
  ThemeColors,
  icons,
} from '../../shared';
import { DataTable, DataTableColumn } from 'shared/components/data-table';
import { ValueComparison } from 'shared/components/value-comparison';
import { RecruitingExportCSV } from './recruiting-export';
import { useHistory } from 'react-router-dom';
import { useLoadingIndicator } from '../../shared/indicators/isLoading';
import { LoadingFade } from '../../shared/components/loading-fade';
import { DATETIME_MIN_VALUE } from '../../shared'
import { AgentsQuery } from '../../state/mls/stores';
import { useInput } from '../../shared/forms';
import { AgentVolumeSummaryTooltip } from '../../shared/components/agent-volume-summary-tooltip';
import { AddEditActivity, ActiveAgentContext } from '../../shared/components/activity-history';
import { useAgentLookup } from '../../agents/components/useAgentLookup';
import { RecruitAgent } from '../../shared/components/recruit-agent';
import { ContactToCreate } from '../../api/app';
import { authService } from '../../state/auth/auth.service';
import { AddHashTagsDialog } from '../../shared/components/add-hashtags-dialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import themeService from 'theme/ThemeService';
import { GridSortItem } from '@mui/x-data-grid';

interface Props {
  recruits: Array<Recruit>;
  volume: AgentVolumeLookup;
  managerId: string;
  isRecruited: (memberKey: string) => boolean;
  toggleRecruit: (memberKey: string, id: number) => void;
  isRecruit: (memberKey: string) => boolean;
  setRecruits: React.Dispatch<React.SetStateAction<Array<Recruit>>>
}

const palette = themeService.getPalette();

const useStyles = makeStyles(theme => ({
  grid: {
    '& .rdt_TableHead': {
      backgroundColor: ThemeColors.LightGray,
      padding: '0 1rem',
      '& .rdt_TableHeadRow': {
        backgroundColor: ThemeColors.LightGray,
      },
    },
    '& .rdt_TableRow': {
      padding: '.5rem 1rem',
    },
  },
  link: {
    marginRight: "10px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: palette.teal,
    maxWidth: "calc(100% - 32px)",
  },
}));

export const RecruitingWatchList: React.FC<Props> = React.memo(
  ({ recruits, volume, managerId, isRecruited, toggleRecruit, isRecruit, setRecruits }) => {
    const history = useHistory();
    const agentLookup = useAgentLookup();
    const [selectedRecruit, setSelectedRecruit] = useState<Recruit | null>(null);
    const [selectedAgent, setSelectedAgent] = useState<Agent | undefined>(undefined);
    const [openActivityDialog, setOpenActivityDialog] = useState(false);
    const [token, setToken] = useState(false);
    const [kvCoreUser, setKvCoreUser] = useState(false);
    const [openAddHashTags, setOpenAddHashTags] = useState(false);
    const [openWarningMessage, setOpenWarningMessage] = useState(false);
    const [hashTags, setHashTags] = useState('');
    const [contactToCreate, setContactToCreate] = useState<ContactToCreate | null>(null);
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [allowAddContact, setAllowAddContact] = useState(false);
    const [confirmationMessage, setConfirmationMessage] = useState('');
    const [paginationModel, setPaginationModel] = useState<{page: number, pageSize: number}>({
      page: 0,
      pageSize: 10,
    });
    const [sortModel, setSortModel] = useState<GridSortItem[]>([
      {
        field: 'recruitLastActivity',
        sort: 'asc',
      },
    ]);

    const warningPopupMessage =
      'This user will be synced to BoldTrail and immediately added to the campaigns you’ve selected. The campaigns will run automatically based on their rules in BoldTrail. Continue?';

    useEffect(() => {
      const getToken = kvCoreService.checkToken().subscribe((data: boolean) => setToken(data));
      authService.kvCoreSync().subscribe((data: boolean) => {
        setKvCoreUser(data);
      });
      kvCoreService.addContactAllowed().subscribe((data: boolean) => {
        setAllowAddContact(data);
      });
      return () => getToken.unsubscribe();
    }, [kvCoreUser, setKvCoreUser, setAllowAddContact, allowAddContact]);

    const handleOpenActivityDialog = (recruit: Recruit) => {
      setSelectedRecruit(recruit);
      const agent = agentLookup.get(recruit.memberKey);
      setSelectedAgent(agent);
      setOpenActivityDialog(true);
    };

    const handleCloseAddHashTags = (hashTags: string, confirmed: boolean) => {
      setOpenAddHashTags(false);
      if (confirmed) {
        setHashTags(hashTags);
        setOpenWarningMessage(true);
      } else {
        setContactToCreate(null);
      }
    }

    const addContact = (
      contact: ContactToCreate,
      recruit: ViewModel
    ) => {
      if (!recruit.allowAddContact) {
        setConfirmationMessage(
          'You have exceed the number of contacts you may add in a 24-hour period'
        );
        setConfirmationOpen(true);
      } else {
        setContactToCreate(contact);
        setOpenAddHashTags(true);
      }
    };

    const saveContact = () => {
      if (contactToCreate) {
        contactToCreate.hashTags = hashTags;
        kvCoreService.createContact(contactToCreate).subscribe(response => {
          let newArray = [...recruits];
          let updatedRecruit = newArray.find(r => r.id === contactToCreate.recruitId);
          if (updatedRecruit) {
            let index = newArray.findIndex(r => r.id === contactToCreate.recruitId);
            newArray.splice(index, 1, {...updatedRecruit, kvCoreContactId: response.kvCoreContactId});
            setRecruits([...newArray]);
          }
          kvCoreService.addContactAllowed().subscribe((data: boolean) => {
            setAllowAddContact(data);
          });
        });
      }
    };

    const searchTerm = useObservable(AgentsQuery.instance.searchTerm);
    const searchInput = useInput(searchTerm ?? '');

    const data = useMemo(
      () =>
        recruits
          .filter(r => !r.recruitedDate)
          .filter(r => volume.has(r.memberKey!))
          .map(
            (recruit, i) =>
              ({
                id: recruit.memberKey,
                index: i,
                recruit,
                volume: volume.get(recruit.memberKey!),
                toggleRecruit,
                isRecruit,
                isRecruited,
                managerId,
                token,
                allowAddContact,
                hasKvCoreId: recruit.kvCoreContactId == null ? false : true
              } as ViewModel)
          )
          .filter(
            item =>
              item.volume?.agent &&
              item.volume?.agent.fullName &&
              item.volume?.agent.fullName.toLowerCase().includes(searchInput.value.toLowerCase())
          ),
      [
        recruits,
        volume,
        searchInput,
        isRecruit,
        isRecruited,
        managerId,
        toggleRecruit,
        token,
      ]
    );
    const classes = useStyles();
    const onSearch = (input: string) => {
      searchInput.setValue(input);
    };

    const columns: DataTableColumn<ViewModel>[] = [
      {
        renderHeader: () => <Typography noWrap fontWeight="bold">WATCHING</Typography>,
        headerAlign: 'center',
        align: 'center',
        display: 'flex',
        field: 'watching',
        sortable: false,
        filterable: false,
        renderCell: ({ row }) => (
          <>
            <RecruitAgent
              memberKey={row.recruit.memberKey!}
              isRecruit={row.isRecruit(row.recruit.memberKey!)}
              isRecruited={row.isRecruited(row.recruit.memberKey!)}
              toggleRecruit={row.toggleRecruit}
            />
          </>
        ),
      },
      {
        renderHeader: () => <Typography noWrap fontWeight="bold">ADD ACTIVITY</Typography>,
        headerAlign: 'center',
        align: 'center',
        display: 'flex',
        field: 'forum',
        sortable: false,
        filterable: false,
        renderCell: ({ row }) => (
          <>
          {!row.recruit.kvCoreContactId &&
              <IconButton size="small" onClick={() => handleOpenActivityDialog(row.recruit)}>
                <Forum style={{color: palette.neutralDark}} fontSize="small" />
              </IconButton>
          }
          {row.recruit.kvCoreContactId &&
              <Tooltip arrow placement="right" title="Synced to BoldTrail">
                <IconButton size="small">
                  <NotInterested />
                </IconButton>
              </Tooltip>
          }
          </>
        ),
      },
      {
        renderHeader: () => <Typography noWrap fontWeight="bold">NAME</Typography>,
        headerAlign: 'center',
        align: 'center',
        display: 'flex',
        field: 'search',
        renderCell: ({ row }) => (
          <>
            {row.volume && (
              <>
                <Link to={`/pipeline/${row.recruit.id}/agent`} className={classes.link}>
                  {row.volume.agent.fullName}
                </Link>
                {!row.volume?.agent.active && <Chip size="small" label="Inactive" />}
              </>
            )}
            <a
              href={`https://www.google.com/search?q=${row.volume?.agent.fullName} Realtor ${row.volume?.agent.officeName}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: palette.primary, margin: '0 .25rem' }}
            >
              <FontAwesomeIcon icon={icons.searchGlass} size="sm" />
            </a>
          </>
        ),
        flex: 2,
        valueGetter: (value, row) => row.volume?.agent.fullName,
        sortable: true,
      },
      {
        renderHeader: () => <Typography noWrap fontWeight="bold">ADD CONTACT</Typography>,
        field: 'contactButton',
        sortable: false,
        filterable: false,
        align: 'center',
        headerAlign: 'center',
        display: 'flex',
        flex: 1.5,
        renderCell: ({ row }) =>
          CreateContactButton(
            row.token,
            row.recruit,
            row.allowAddContact,
            (contact: ContactToCreate) => {
              addContact(contact, row);
            },
            row.hasKvCoreId
          )
      },
      {
        renderHeader: () => <Typography noWrap fontWeight="bold">LAST ACTIVITY</Typography>,
        headerAlign: 'center',
        renderCell: ({ row }) => (
          <>
            {row.recruit.lastActivity && (
              <CaptionedValue
                align="left"
                size="13px"
                value={DateFormatters.shortDate`${parseISO(row.recruit.lastActivity.recordedAt!)}`}
              >
                {row.recruit.lastActivity.type}
              </CaptionedValue>
            )}
          </>
        ),
        sortable: true,
        field: 'recruitLastActivity',
        align: 'center',
        valueGetter: (value, row) => new Date(row.recruit.lastActivity?.recordedAt || DATETIME_MIN_VALUE),
        sortComparator: (v1: Date, v2: Date) => {
          return v1.getTime() - v2.getTime()
        },
        filterable: false,
        type: "date"
      },
      {
        renderHeader: () => <Typography noWrap fontWeight="bold">ACTIVE</Typography>,
        headerAlign: 'center',
        align: 'center',
        display: 'flex',
        renderCell: ({ row }) => row.volume && <ValueComparison {...row.volume.activeListings} align='center' size="13px" />,
        sortable: true,
        valueGetter: (value, row) => row.volume?.activeListings.current,
        field: 'volume.activeListings.current',
        type: 'number'
      },
      {
        renderHeader: () => <Typography noWrap fontWeight="bold">PENDING</Typography>,
        headerAlign: 'center',
        align: 'center',
        display: 'flex',
        renderCell: ({ row }) => row.volume && <ValueComparison {...row.volume.pendingListings} align='center' size="13px" />,
        sortable: true,
        valueGetter: (value, row) => row.volume?.pendingListings.current,
        field: 'volume.pendingListings.current',
        type: 'number'
      },
      {
        renderHeader: () => <Typography noWrap fontWeight="bold">LIST TRANS</Typography>,
        headerAlign: 'center',
        align: 'center',
        display: 'flex',
        renderCell: ({ row }) => row.volume && <ValueComparison {...row.volume.listTransactions} align='center' size="13px" />,
        sortable: true,
        valueGetter: (value, row) => row.volume?.listTransactions.current,
        field: 'volume.listTransactions.current',
        type: 'number'
      },
      {
        renderHeader: () => <Typography noWrap fontWeight="bold">LIST TRANS VOL</Typography>,
        headerAlign: 'center',
        align: 'center',
        display: 'flex',
        renderCell: ({ row }) =>
          row.volume && (
            <ValueComparison {...row.volume.listTransactionsVol} size="13px" align='center' formatter={NumberFormatters.currency} />
          ),
        sortable: true,
        valueGetter: (value, row) => row.volume?.listTransactionsVol.current,
        field: 'volume.listTransactionsVol.current',
        type: 'number'
      },
      {
        renderHeader: () => <Typography noWrap fontWeight="bold">SELL TRANS</Typography>,
        headerAlign: 'center',
        align: 'center',
        display: 'flex',
        renderCell: ({ row }) => row.volume && <ValueComparison {...row.volume.sellTransactions} align='center' size="13px" />,
        sortable: true,
        valueGetter: (value, row) => row.volume?.sellTransactions.current,
        field: 'volume.sellTransactions.current',
        type: 'number'
      },
      {
        renderHeader: () => <Typography noWrap fontWeight="bold">SELL TRANS VOL</Typography>,
        headerAlign: 'center',
        align: 'center',
        display: 'flex',
        renderCell: ({ row }) =>
          row.volume && (
            <ValueComparison {...row.volume.sellTransactionsVol} size="13px" align='center' formatter={NumberFormatters.currency} />
          ),
        sortable: true,
        valueGetter: (value, row) => row.volume?.sellTransactionsVol.current,
        field: 'volume.sellTransactionsVol.current',
        type: 'number'
      },
      {
        renderHeader: () => <Typography noWrap fontWeight="bold">TOTAL TRANS</Typography>,
        headerAlign: 'center',
        align: 'center',
        display: 'flex',
        renderCell: ({ row }) => row.volume && <ValueComparison {...row.volume.totalTransactions} align='center' size="13px" />,
        sortable: true,
        valueGetter: (value, row) => row.volume?.totalTransactions.current,
        field: 'volume.totalTransactions.current',
        type: 'number'
      },
      {
        renderHeader: () => <Typography noWrap fontWeight="bold">TOTAL VOL</Typography>,
        headerAlign: 'center',
        align: 'center',
        display: 'flex',
        renderCell: ({ row }) => (
          <>
            {row.volume && (
              <ValueComparison {...row.volume.totalVolume} size="13px" align='center' formatter={NumberFormatters.currency} />
            )}
          </>
        ),
        sortable: true,
        valueGetter: (value, row) => row.volume?.totalVolume.current,
        field: 'volume.totalVolume.current',
        type: 'number'
      },
      {
        headerName: '',
        headerAlign: 'center',
        field: 'volumeSumary',
        sortable: false,
        filterable: false,
        renderCell: ({ row }) =>
          !!row.volume && (
            <AgentVolumeSummaryTooltip
              memberKey={row.volume.agent.memberKey}
              agentName={row.volume.agent.fullName}
            />
          ),
      },
    ];

    const loading = useLoadingIndicator();
    return (
      <>
        {loading && <LinearProgress />}
        <Card>
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={4}>
                <SearchInput
                  value={searchInput.value}
                  onChange={event => onSearch(event.target.value)}
                />
              </Grid>

              <Grid container item xs={12} sm={8} justifyContent="flex-end" spacing={1}>
                <Grid item>
                  <Button
                    onClick={() => history.push('/search')}
                    startIcon={<FontAwesomeIcon icon={icons.plus} size="lg" />}
                  >
                    Add
                  </Button>
                </Grid>

                <Grid item>
                  <RecruitingExportCSV csvData={data} fileName={'recruitingAgents'} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginTop: '.5rem' }}>
              <LoadingFade isContentLoading={loading}>
                <DataTable
                  className={classes.grid}
                  rows={data}
                  columns={columns}
                  progressPending={loading}
                  showPagination
                  pageSizeOptions={[10, 25, 50, 100]}
                  paginationModel={paginationModel}
                  onPaginationModelChange={setPaginationModel}
                  sortModel={sortModel}
                  onSortModelChange={setSortModel}
                  initialState={{
                    sorting: {
                      sortModel: sortModel,
                    },
                    pagination: {
                      paginationModel: paginationModel
                    }
                  }}
                />
              </LoadingFade>
            </Grid>
          </CardContent>
        </Card>
        {selectedRecruit && selectedAgent && (
          <ActiveAgentContext.Provider value={{ agent: selectedAgent }}>
            <AddEditActivity
              open={openActivityDialog}
              setOpen={setOpenActivityDialog}
              recruitId={selectedRecruit.id!}
              activity={{}}
              managerId={managerId}
              kvCoreId={selectedRecruit.kvCoreContactId}
            />
          </ActiveAgentContext.Provider>
        )}
        <AddHashTagsDialog
          open={openAddHashTags}
          onClose={handleCloseAddHashTags}
        />
        <ConfirmationDialog
          isOpen={confirmationOpen}
          title={'Confirm'}
          onClose={() => setConfirmationOpen(false)}
          onConfirm={() => setConfirmationOpen(false)}
        >
          <Typography>{confirmationMessage}</Typography>
        </ConfirmationDialog>

        <ConfirmationDialog
          isOpen={openWarningMessage}
          title="Warning"
          onClose={() => {
            setOpenWarningMessage(false);
          }}
          onConfirm={() => {
            setOpenWarningMessage(false);
            saveContact();
          }}
          onCancel={() => {
            window.location.reload();
            setOpenWarningMessage(false);
          }}
        >
          <Typography>{warningPopupMessage}</Typography>
        </ConfirmationDialog>
      </>
    );
  }
);

export interface ViewModel {
  id: Recruit['memberKey'];
  index: number;
  recruit: Recruit;
  volume?: AgentVolumeSummary;
  toggleRecruit: (memberKey: string) => void;
  isRecruited: (memberKey: string) => boolean;
  isRecruit: (memberKey: string) => boolean;
  managerId: string;
  token: boolean;
  allowAddContact: boolean;
  hasKvCoreId: boolean;
}

function CreateContactButton(
  token: boolean,
  recruit: Recruit,
  allowAddContact: boolean,
  addContact: (contact: ContactToCreate, allowAddContact: boolean) => void,
  hasKvCoreId: boolean,
) {
  const agentLookup = useAgentLookup();

  let member = agentLookup.get(recruit.memberKey);

  const contact: ContactToCreate = {
    recruitId: recruit.id,
    firstName: member!.firstName,
    lastName: member!.lastName,
    email: member!.email,
    cellPhone1: member!.mobilePhone,
    status: 0,
    dealType: 'agent',
    source: 'AmpStats',
    emailOptin: 0,
    phoneOn: 0,
    textOn: 0,
  };

  const handleCreate = () => {
    addContact(contact, allowAddContact);
  };

  const BoldTrailLogo = <img style={{maxHeight: '50%'}} src="/BoldTrail-Icon-FullColor-Flat.svg"/>;

  return (
    <>
      {!token && (
        <Tooltip arrow placement="right" title="Integrate with BoldTrail to enable functionality">
          {BoldTrailLogo}
        </Tooltip>
      )}
      {token && !hasKvCoreId && <Button onClick={() => handleCreate()}>Add Contact</Button>}
      {token && hasKvCoreId && (
        <Tooltip arrow placement="right" title='Synced to BoldTrail'>
          {BoldTrailLogo}
        </Tooltip>
      )}
    </>
  );
}

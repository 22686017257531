import React from 'react';
import ReactDOM from 'react-dom';

import { configureStores } from './state';
import { App } from './app';

import './index.css';

configureStores();

ReactDOM.render(<App />, document.getElementById('root'));

import React, { memo, useMemo } from 'react';
import { Card, CardHeader, CardContent, Grid, Box } from '@mui/material';
import { VictoryPie, VictoryLabel } from 'victory';

import { AgentVolumeLookup } from '../../state';
import { Block } from 'shared/components/block';
import { CaptionedValue } from 'shared/components/captioned-value';
import { NumberFormatters } from 'shared/utils/formatters';
import { ThemeColors } from 'shared/theme';
import { RecruitModel } from '../../api/app';

import { isThisYear } from 'date-fns';
import themeService from 'theme/ThemeService';

interface RecruitingStats {
  agents: number;
  volume: number;
  transactions: number;
  companyDollar: number;
}

interface Props {
  recruits: RecruitModel[];
  volume: AgentVolumeLookup;
  goal: RecruitingStats;
}

const palette = themeService.getPalette();

export const RecruitingGoal: React.FC<Props> = ({ recruits, volume, goal }) => {
  const current: RecruitingStats = useMemo(() => {
    let volumeTotal = 0;
    let transactionTotal = 0;
    const recruitedAgents = recruits.filter(
      r => !!r.recruitedDate && isThisYear(new Date(r.recruitedDate))
    );
    recruitedAgents.forEach(r => {
      const vol = volume.get(r.memberKey ?? '');
      volumeTotal += vol?.totalVolume.current ?? 0;
      transactionTotal += vol?.totalTransactions.current ?? 0;
    });
    return {
      agents: recruitedAgents.length,
      volume: volumeTotal,
      transactions: transactionTotal,
      companyDollar: goal.companyDollar,
    };
  }, [recruits, volume, goal]);

  return (
    <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }} >
      <Card>
        <CardHeader
          title="Recruiting Goal"
          subheader={
            goal.companyDollar !== 0 && (
              <>Company Dollar: {NumberFormatters.percent`${goal.companyDollar}`}</>
            )
          }
        />
        <CardContent>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} sm={6} lg={5}>
              <RecruitingGoalChart current={current} goal={goal} />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              lg={7}
              container
              direction="column"
              spacing={2}
              justifyContent="center"
            >
              <Grid item>
                <Block title="Current">
                  <Box py={1}>
                    <CaptionedValue
                      value={NumberFormatters.decimal`${current.agents ?? 0}`}
                      size="200%"
                      color={palette.neutralDark}
                    >
                      {NumberFormatters.currency`${current.volume ?? 0}`}
                    </CaptionedValue>
                  </Box>
                </Block>
              </Grid>
              <Grid item>
                <Block title="Goal">
                  <Box py={1}>
                    <CaptionedValue
                      value={NumberFormatters.decimal`${goal.agents ?? 0}`}
                      size="200%"
                      color={palette.neutralDark}
                    >
                      {NumberFormatters.currency`${goal.volume ?? 0}`}
                    </CaptionedValue>
                  </Box>
                </Block>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

interface ChartProps {
  current: RecruitingStats;
  goal: RecruitingStats;
}

const RecruitingGoalChart: React.FC<ChartProps> = memo(({ current, goal }) => {
  const currentVolume = current.volume ?? 0;
  const goalVolume = goal.volume ?? 0;
  return (
    <svg viewBox="0 0 200 200">
      <defs>
        <linearGradient
          id="magentaGradient"
          x1="0%"
          y1="0%"
          x2="0%"
          y2="100%"
        >
          <stop offset="0%" stopColor="#CD00FC"/>
          <stop offset="100%" stopColor="#FF0000"/>
        </linearGradient>
      </defs>
      <VictoryPie
        height={190}
        width={190}
        padding={25}
        radius={90}
        innerRadius={75}
        cornerRadius={10}
        data={[
          { x: 'Recruited', y: currentVolume, fill: 'url(#magentaGradient)' },
          {
            x: 'Goal',
            y: Math.max(goalVolume - currentVolume, 100),
            fill: palette.neutralLight,
          },
        ]}
        style={{ data: { fill: ({ datum }) => datum.fill } }}
        labels={() => ''}
        standalone={false}
      />
      <VictoryLabel
        x={95}
        y={95}
        textAnchor="middle"
        verticalAnchor="middle"
        text={NumberFormatters.percent`${currentVolume / (goalVolume > 0 ? goalVolume : 1)}`}
        style={{ fontSize: 40, fontWeight: 700 }}
      />
    </svg>
  );
});

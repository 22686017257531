import { CookieNames } from '../cookieNames';

/**
 *
 * @param setCookies {@link https://www.npmjs.com/package/react-cookie#setname-value-options}
 * @param removeCookies {@link https://www.npmjs.com/package/react-cookie#removename-options}
 * @returns Function for refreshing any cookie(removes cookie, then sets a new cookie).
 */
export function RefreshCookie(
  setCookie: (name: CookieNames, value: any, options?: any) => void,
  removeCookie: (name: CookieNames, options?: any) => void
) {
  const refreshCookie = (name: CookieNames, value: any, options?: any) => {
    removeCookie(name);
    setCookie(name, value, options);
  };

  return refreshCookie;
}

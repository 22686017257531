import { Card, CardHeader, CardContent, Grid } from '@mui/material';
import React from 'react';
import { AgentVolumeTrend } from '../../agents/components';
import { NumberFormatters } from '..';
import { AgentVolume } from '../../state/mls';
import { Table, TableBody, TableRow, TableCell, TableHead } from 'shared/components';

interface Props {
  summary: AgentVolume;
}

export const AgentVolumeSummaryAnnualTrends: React.FC<Props> = ({ summary }) => {
  return (
    <Card>
      <CardHeader title="Agent Trends" />
      <CardContent>
        <Grid container spacing={2}>
          {!summary || summary.periods.length < 2 ? (
            <Grid item sm lg={7}>
              <b>Insufficient Data</b>
            </Grid>
          ) : (
            <>
              <Grid item xs={12} lg={2}>
                <AgentVolumeTrend summary={summary.periods} chartScope="volume" />
              </Grid>
              <Grid item xs={12} lg={4}>
                {summary.periods && summary.periods.length > 1 && (
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>From</TableCell>
                        <TableCell>To</TableCell>
                        <TableCell align="right">Volume</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {summary.periods.map((item, idx) => (
                        <TableRow key={idx}>
                          <TableCell>{item.dates.from}</TableCell>
                          <TableCell>{item.dates.to}</TableCell>
                          <TableCell align="right">
                            {NumberFormatters.currency`${item.volume || 0}`}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                )}
              </Grid>
              <Grid item xs={12} lg={2}>
                <AgentVolumeTrend summary={summary.periods} chartScope="transactions" />
              </Grid>
              <Grid item xs={12} lg={4}>
                {summary.periods && summary.periods.length > 1 && (
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>From</TableCell>
                        <TableCell>To</TableCell>
                        <TableCell align="right">Transactions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {summary.periods.map((item, idx) => (
                        <TableRow key={idx}>
                          <TableCell>{item.dates.from}</TableCell>
                          <TableCell>{item.dates.to}</TableCell>
                          <TableCell align="right">
                            {NumberFormatters.decimal`${item.transactions || 0}`}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                )}
              </Grid>
            </>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

import { applyTransaction, setLoading } from '@datorama/akita';
import { Mls } from '../../api';
import { ProcessLogStore, processLogStore } from './logs.store';
import { map } from 'rxjs/operators';
import { from } from 'rxjs';

export class ProcessLogService {
  constructor(
    private readonly api: Mls.FeedStatusApi,
    private readonly processLogStore: ProcessLogStore
  ) {}

  getProcessLogs() {
    from(this.api.mlsFeedstatusGet())
      .pipe(
        setLoading(processLogStore),
        map(response => response.data.data)
      )
      .subscribe(data =>
        applyTransaction(() => {
          this.processLogStore.set({ ...data });
        })
      );
  }
}

export const processLogService = new ProcessLogService(new Mls.FeedStatusApi(), processLogStore);

import { useEffect, useMemo, useState, useRef } from 'react';

import { ObservableInput, from } from 'rxjs';

type ObservableState<T> = { hasValue: false; value: undefined } | { hasValue: true; value: T };

export function useObservable<T>(input: ObservableInput<T>): T;
export function useObservable<T>(input: ObservableInput<T>, async: 'async'): T | undefined;
export function useObservable<T>(input: ObservableInput<T>, async?: 'async'): T | undefined {
  const [, render] = useState<any>();
  const obsState = useRef<ObservableState<T>>({ hasValue: false, value: undefined });

  const subscription = useMemo(
    () =>
      from(input).subscribe(x => {
        render((obsState.current = { hasValue: true, value: x }));
      }),
    [input]
  );
  useEffect(() => () => subscription.unsubscribe(), [subscription]);

  const { hasValue, value } = obsState.current;

  if (!hasValue && async !== 'async') {
    console.warn('Observable is expected to have a synchonrous initial value.');
  }

  return value;
}

import { QueryEntity } from '@datorama/akita';
import { combineLatest, from } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { OfficeModel } from '../../../api/mls';

import { MlsofficesState, mlsofficesStore } from './mlsoffices.store';

export class MlsofficesQuery extends QueryEntity<MlsofficesState> {
  companyKeys = this.select(state => state.ui.companyKeys);

  feeds = this.select(({ ui }) => ui.feeds);

  // Show main offices from the selected feeds,
  // as well as any offices that have already been selected
  companies = from(combineLatest([this.feeds, this.companyKeys])).pipe(
    switchMap(([feeds, companyKeys]) =>
      this.selectAll({
        filterBy: o =>
          (o.officeKey === o.mainOfficeKey && inFeeds(feeds, o)) || inCompanyKeys(companyKeys, o),
        sortBy: (a, b) => a.officeName.localeCompare(b.officeName),
      })
    )
  );

  offices = this.select(state => state.ui.companyKeys).pipe(
    switchMap(companyKeys =>
      this.selectAll({
        filterBy: o => !!companyKeys && companyKeys.some(k => k === o.mainOfficeKey),
      })
    )
  );
}

export const mlsofficesQuery = new MlsofficesQuery(mlsofficesStore);

const inFeeds = (feeds: number[] | undefined, o: OfficeModel) => {
  return feeds === undefined || feeds.length === 0 || feeds.includes(o.feed);
};

const inCompanyKeys = (keys: string[] | undefined, o: OfficeModel) => {
  return !!keys && keys.includes(o.officeKey);
};

import { Zsg } from "api";
import { InterstitialStore, interstitialStore, termsAcceptanceStore, TermsAcceptanceStore } from "./interstitial.store";
import {from} from "rxjs";
import {applyTransaction, setLoading} from "@datorama/akita";
import {map} from "rxjs/operators";

export class InterstitialService {
  constructor(
    private readonly interstitialStore: InterstitialStore,
    private readonly termsStore: TermsAcceptanceStore,
    private readonly adminApi: Zsg.AdminApi
  ) {}


  getInterstitial() {
    from(this.adminApi.adminInterstitialGet())
    .pipe(
      map(x => x.data),
      setLoading(this.interstitialStore)
    )
    .subscribe(x =>
      applyTransaction(() => {
        this.interstitialStore.upsert(x.id, x);
        this.interstitialStore.setActive(x.id);
      })
    );
  }

  getTermsAcceptance() {
    from(this.adminApi.adminTermsAcceptanceGet())
      .pipe(
        map(x => x.data.data),
        setLoading(this.termsStore)
      )
      .subscribe(x => {
        applyTransaction(() => {
          this.termsStore.upsertMany(x);
        })
      });
  }
}

export const interstitialService = new InterstitialService(interstitialStore, termsAcceptanceStore, new Zsg.AdminApi());

import { Listing } from '../mls.models';
import { ListingDateSelector, DateListingFilter } from '../date-filter';
import { Statistics } from './types';
import { ListingFilter } from '../listing-filter';
import { CompareValue, AvgCompareValue } from '../../compare-value';
import { getCompareProp } from '../util';

export class VolumeAggregator implements Statistics {
  private readonly listings = new Set<string>();

  private readonly compareProp: (
    listing: Listing,
    compareBy: ListingDateSelector
  ) => keyof CompareValue<any> | undefined;

  constructor(readonly listingFilter: ListingFilter, readonly dateFilter: DateListingFilter) {
    this.compareProp = getCompareProp(dateFilter);
  }

  readonly activeListings = { current: 0, previous: undefined };
  readonly activePriceSum = { current: 0, previous: undefined  };
  readonly pendingListings = { current: 0, previous: undefined };
  readonly pendingPriceSum = { current: 0, previous: undefined  };
  readonly expiredListings = { current: 0, previous: 0 };
  readonly listTransactions = { current: 0, previous: 0 };
  readonly sellTransactions = { current: 0, previous: 0 };
  readonly listTransactionsVol = { current: 0, previous: 0 };
  readonly sellTransactionsVol = { current: 0, previous: 0 };
  readonly totalTransactions = { current: 0, previous: 0 };
  readonly totalVolume = { current: 0, previous: 0 };
  readonly avgSalesPrice = new AvgCompareValue();
  readonly avgDaysOnMarket = new AvgCompareValue();

  addListing(listing: Listing) {
    // filter listings first
    const { list, sell, share } = this.listingFilter.getShare(listing);

    if (!list && !sell) return;

    // don't double count a listing
    if (this.listings.has(listing.listingKey)) return;
    else this.listings.add(listing.listingKey);

    if (listing.status === 'Active'){
      this.activeListings.current++;
      this.activePriceSum.current += listing.listPrice;
    } 
    else if (listing.status === 'Pending') {
      this.pendingListings.current++;
      this.pendingPriceSum.current += listing.listPrice;
    }

    const closeDateProp = this.compareProp(listing, l => l.closeDate);

    if (closeDateProp === undefined) {
      const listDateProp = this.compareProp(listing, l => l.listDate);
      if (listDateProp === undefined) return;

      if (listing.status === 'Expired') {
        this.expiredListings[listDateProp]++;
      }
      return;
    }

    // Transactions where an agent performed both sides count twice towards the total
    if (list) {
      this.listTransactions[closeDateProp]++;
      this.totalTransactions[closeDateProp]++;
    }
    if (sell) {
      this.sellTransactions[closeDateProp]++;
      this.totalTransactions[closeDateProp]++;
    }

    if (listing.closePrice) {
      this.listTransactionsVol[closeDateProp] += list ? listing.closePrice : 0;
      this.sellTransactionsVol[closeDateProp] += sell ? listing.closePrice : 0;

      this.totalVolume[closeDateProp] += share * listing.closePrice;

      this.avgSalesPrice.add(closeDateProp, listing.closePrice);

      if (listing.daysOnMarket !== undefined) {
        this.avgDaysOnMarket.add(closeDateProp, listing.daysOnMarket);
      }
    }
  }

  serialize(): Statistics {
    return {
      activeListings: this.activeListings,
      activePriceSum: this.activePriceSum,
      pendingListings: this.pendingListings,
      pendingPriceSum: this.pendingPriceSum,
      expiredListings: this.expiredListings,
      listTransactions: this.listTransactions,
      sellTransactions: this.sellTransactions,
      listTransactionsVol: this.listTransactionsVol,
      sellTransactionsVol: this.sellTransactionsVol,
      totalTransactions: this.totalTransactions,
      totalVolume: this.totalVolume,
      avgSalesPrice: this.avgSalesPrice.serialize(),
      avgDaysOnMarket: this.avgDaysOnMarket.serialize(),
    };
  }
}

import React from 'react';
import { RouteComponentProps, Switch, Route, Redirect } from 'react-router-dom';

import { OverviewPage } from './pages';

export const RetentionRoutes: React.FC<RouteComponentProps> = ({ match }) => (
  <Switch>
    <Route path={match.path} exact component={OverviewPage} />
    <Redirect to={match.path} />
  </Switch>
);

import { QueryEntity } from '@datorama/akita';
import { Observable, combineLatest } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import _ from 'lodash';

import * as Mls from 'api/mls';
import { MlsQuery, mlsQuery } from '../mls';
import { distinctUntilArrayChanged, distinctUntilSetChanged } from '../mls/util';
import { distinctStrings } from '../util';

import { CompanyQuery, companyQuery } from './company.query';
import { AppOffice, MarketRegion } from './offices.model';
import { OfficeState, OfficeStore, officeStore } from './offices.store';

export class OfficeQuery extends QueryEntity<OfficeState> {
  constructor(
    officeStore: OfficeStore,
    private readonly companyQuery: CompanyQuery,
    private readonly mlsQuery: MlsQuery
  ) {
    super(officeStore);
  }

  /** List of all loaded offices for the manager. */
  offices = this.selectAll();

  selectedBrokerId = this.select(({ ui }) => ui.brokerId);
  selectedBranchId = this.select(({ ui }) => ui.branchId);

  officeHierarchy = combineLatest([
    this.offices,
    this.companyQuery.companyCodes,
    this.mlsQuery.offices,
  ]).pipe(
    map(([offices, companyCodes, lookup]) => getOfficeHierarchy(offices, companyCodes, lookup))
  );

  brokerOffices = this.officeHierarchy.pipe(
    map(h => Array.from(h.values()).map(x => x.broker)),
    sortedOffices()
  );

  branchOffices = combineLatest([this.officeHierarchy, this.selectedBrokerId]).pipe(
    map(([h, brokerId]) => (brokerId ? h.get(brokerId)?.branches ?? [] : [])),
    sortedOffices()
  );

  private activeOffices = combineLatest([
    this.officeHierarchy,
    this.selectedBrokerId,
    this.selectedBranchId,
  ]).pipe(
    map(([h, brokerId, branchId]) => {
      // if we have selected both broker and branch, we will filter by both and return 0 or 1 matching branches in an array
      if (brokerId && branchId) {
        return h.get(brokerId)?.branches.filter(branch => branch.officeId === branchId) ?? [];
      }

      // if we have selected only broker, we will filter and return 0 or more matching branches
      if (brokerId) {
        return h.get(brokerId)?.branches ?? [];
      }

      // otherwise, we haven't selected anything and we will return all branches
      return this.getAll();
    }),
    sortedOffices(),
    distinctUntilArrayChanged(),
    shareReplay(1)
  );

  activeOfficeCodes: Observable<string[]> = this.activeOffices.pipe(
    map(offices => offices.flatMap(o => o.codes)),
    distinctUntilSetChanged()
  );

  activeMarketRegion = combineLatest([
    this.officeHierarchy,
    this.selectedBrokerId,
    this.selectedBranchId,
  ]).pipe(
    map(([h, brokerId, branchId]) => getMarketRegion(h, brokerId, branchId)),
    shareReplay(1)
  );
}

type OfficeHierarchy = Map<number, { broker: AppOffice; boards: string[]; branches: AppOffice[] }>;

function getOfficeHierarchy(
  offices: AppOffice[],
  companyCodes: string[],
  lookup: Map<string, Mls.OfficeModel>
): OfficeHierarchy {
  // brokers are the main offices that are indicated by companyCodes
  const brokers = offices.filter(office => office.codes.some(code => companyCodes.includes(code)));

  // to map branches to brokers, we need to relate them through the MainOfficeKey
  // .. this list should also include the main office as a branch
  return new Map(
    brokers.map(broker => [
      broker.officeId,
      {
        broker,
        boards: distinctStrings(broker.codes.map(brokerCode => lookup.get(brokerCode)?.board)),
        branches: offices.filter(branch =>
          broker.codes.some(brokerCode =>
            branch.codes.some(branchCode => lookup.get(branchCode)?.mainOfficeKey === brokerCode)
          )
        ),
      },
    ])
  );
}

function getMarketRegion(
  h: OfficeHierarchy,
  brokerId: number | undefined,
  branchId: number | undefined
): MarketRegion {
  if (brokerId) {
    const boards = distinctStrings(h.get(brokerId)?.boards ?? []);

    if (branchId) {
      const cities = distinctStrings(
        (h.get(brokerId)?.branches.filter(branch => branch.officeId === branchId) ?? []).flatMap(
          i => i.cities
        )
      );

      if (cities.length > 0) {
        // if no cities are configured for the office, default to the board
        return {
          boards,
          cities,
        };
      }
    }

    // if we have selected only broker, we will filter and return 0 or more matching branches
    return { boards };
  }

  // otherwise, we haven't selected anything and we will return all branches
  return { boards: distinctStrings(Array.from(h.values()).flatMap(i => i.boards)) };
}

function sortedOffices() {
  return map<AppOffice[], AppOffice[]>(res =>
    _.sortBy(
      res,
      k => k.name,
      k => k.officeNumber
    )
  );
}

export const officeQuery = new OfficeQuery(officeStore, companyQuery, mlsQuery);

import { MonoTypeOperatorFunction, Subject } from 'rxjs';
import { distinctUntilChanged, map, bufferTime, filter } from 'rxjs/operators';
import { Listing } from './mls.models';
import { CompareValue } from '../compare-value';
import { DateListingFilter, ListingDateSelector } from './date-filter';

export type ComparePropFn = (
  listing: Listing,
  compareBy: ListingDateSelector
) => keyof CompareValue<any> | undefined;

export function getCompareProp(dateFilter: DateListingFilter): ComparePropFn {
  return (listing: Listing, compareBy: ListingDateSelector) => {
    if (dateFilter.currentPeriod(listing, compareBy)) return 'current';
    if (dateFilter.previousPeriod(listing, compareBy)) return 'previous';

    return undefined;
  };
}

export function distinctUntilObjectChanged<T>(): MonoTypeOperatorFunction<T> {
  return distinctUntilChanged<T>((a, b) => JSON.stringify(a) === JSON.stringify(b));
}

export function distinctUntilArrayChanged<T extends Array<U>, U>(): MonoTypeOperatorFunction<T> {
  return distinctUntilChanged<T>(
    (a, b) => a?.length === b?.length && JSON.stringify(a) === JSON.stringify(b)
  );
}

export function distinctUntilSetChanged<T>(): MonoTypeOperatorFunction<Array<T>> {
  return source =>
    source.pipe(
      map(arr => Array.from(new Set<T>(arr))),
      distinctUntilArrayChanged()
    );
}

export function batchedRequest<T>(time: number = 500) {
  const subject = new Subject<T>();
  const batch = subject.pipe(
    bufferTime(time),
    filter(arr => arr.length > 0)
  );

  const result = {
    batch,
    request(next: T) {
      subject.next(next);
    },
    subscribe(subscriber: (items: T[]) => void) {
      return batch.subscribe(subscriber);
    },
  };

  return result;
}

export class IgnoreCaseMap<V> extends Map<string, V> {
  set(key: string, value: V) {
      return super.set(key.toLocaleLowerCase(), value);
  }

  get(key: string): V | undefined {
      if (!key) return undefined;
      return super.get(key.toLocaleLowerCase());
  }
}
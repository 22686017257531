import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  Grid,
  Box,
  Card,
  CardHeader,
  CardContent,
  InputAdornment,
} from '@mui/material';
import { Button, TextField } from 'shared/components';

import { asErrorBoundry, useObservable, ThemeColors } from '../../shared';

import { useNetIncomeCalculatorTransactions } from '../../agents/components';
import { mlsQuery } from '../../state';
import { ArrowBack, House, MonetizationOn } from '@mui/icons-material';
import { RecruitingComparisonCalculatorBarComponent } from '../components/net-income-calculator/recruiting-comparison-calculator-bar';
import { RecruitingFixedFeeScheduleTableComponent } from '../components/net-income-calculator/recruiting-fixed-fee-schedule-table';
import { RecruitingVariableFeeScheduleTableComponent } from '../components/net-income-calculator/recruiting-variable-fee-schedule-table';
import { NetIncomeSummaryFooterComponent } from '../components/net-income-calculator/recruiting-calculator-summary-footer';
import FeeCalculator from '../components/net-income-calculator/FeeCalculator';
import { withManagerId } from '../../shared/utils/withManagerId';
import { CustomCurrencyInputMasker } from '../../shared/components/custom-currency-input-masker';

import { FeeScheduleItem, FeeCategory, FeeType } from './net-income-valuation.types'

const FIXED_FEES: FeeScheduleItem[] = [
  {
    type: FeeType.AgentGrossCommission,
    label: 'Agent Gross GCI',
    feeGroup: 'fixed',
    calculate: (calculator: FeeCalculator) => calculator.calculateAgentGrossCommission(),
  },
  {
    type: FeeType.LessFranchiseFee,
    label: 'Less Franchise Fee',
    feeGroup: 'fixed',
    calculate: (calculator: FeeCalculator) => calculator.calculateFranchiseFee(),
  },
  {
    type: FeeType.AgentAdjustedGross,
    label: 'Agent Adjusted Gross',
    feeGroup: 'fixed',
    calculate: (calculator: FeeCalculator) => calculator.calculateAgentAdjustedGross(),
  },
  {
    type: FeeType.LessCompanyDollarCap,
    label: 'Less Company Dollar (Cap)',
    feeGroup: 'fixed',
    calculate: (calculator: FeeCalculator) => calculator.calculateLessCompanyDollarCap(),
  },
  {
    type: FeeType.AfterCapCompanyDollar,
    label: 'After Cap Company Dollar',
    feeGroup: 'fixed',
    calculate: (calculator: FeeCalculator) => calculator.calculateAfterCompanyCap(),
  },
];

const VARIABLE_FEES: FeeScheduleItem[] = [
  { type: FeeType.MonthlyFeesTech, label: 'Monthly Fees (tech)', feeGroup: 'variable' },
  { type: FeeType.EOInsurance, label: 'E&O Insurance', feeGroup: 'variable' },
  { type: FeeType.DeskOfficeFee, label: 'Desk or office fee', feeGroup: 'variable' },
  { type: FeeType.LeadGeneration, label: 'Lead Generation', feeGroup: 'variable' },

  { type: FeeType.AdminExpense, label: 'Admin Expense', feeGroup: 'variable' },
  { type: FeeType.Copies, label: 'Copies', feeGroup: 'variable' },
  { type: FeeType.Advertising, label: 'Advertising', feeGroup: 'variable' },
  { type: FeeType.Signs, label: 'Signs', feeGroup: 'variable' },
  { type: FeeType.Postage, label: 'Postage', feeGroup: 'variable' },
  { type: FeeType.MarketingMaterials, label: 'Marketing Materials', feeGroup: 'variable' },
  { type: FeeType.Other, label: 'Other', feeGroup: 'variable' },
];

interface Props {
  managerId: string;
}

const NetIncomeValuation: React.FC<Props> = ({ managerId }) => {
  const { recruitId, memberKey } = useParams<{ recruitId: string; memberKey: string }>();
  const id = parseInt(recruitId);
  const dateRange = useObservable(mlsQuery.activeDateRange);
  const { agent, volume } = useNetIncomeCalculatorTransactions(managerId, memberKey, id, dateRange);

  const history = useHistory();
  const backButtonClick = () => {
    history.goBack();
  };

  const [salesVolume, setSalesVolume] = useState(volume?.totalVolume.current || 0);

  //datasources for the fees in the two tables
  const [fixedFeeSchedule, setFixedFeeSchedule] = useState<FeeScheduleItem[]>([...FIXED_FEES]);
  const [variableFeeSchedule, setVariableFeeSchedule] = useState<FeeScheduleItem[]>([
    ...VARIABLE_FEES,
  ]);

  useEffect(() => {
    //need to have some way to treat this one special, since it's fee * total transactions
    if (volume?.totalTransactions.current) {
      setVariableFeeSchedule([
        ...VARIABLE_FEES,
        {
          type: FeeType.TransactionFees,
          label: 'Transaction Fees',
          feeGroup: 'variable',
          customMultiplier: volume?.totalTransactions.current || 0, // totalTransactions,
        },
      ]);
    }
    if (volume?.totalVolume.current) {
      setSalesVolume(volume?.totalVolume.current);
    }
  }, [volume]);

  //shows the calculated numbers in the footer
  const [currentTrueNetIncome, setCurrentTrueNetIncome] = useState(0);
  const [currentTrueValueSplit, setCurrentTrueValueSplit] = useState(0);
  const [oursTrueNetIncome, setOursTrueNetIncome] = useState(0);
  const [oursTrueValueSplit, setOursTrueValueSplit] = useState(0);

  //fee totals from fixed fees, used to calculate numbers in the footer
  const [fixedCurrentFeeTotals, setFixedCurrentFeeTotals] = useState(0);
  const [fixedOursFeeTotals, setFixedOursFeeTotals] = useState(0);

  //fee totals from variable fees, used to calculate numbers in the footer
  const [variableCurrentFeeTotals, setVariableCurrentFeeTotals] = useState(0);
  const [variableOursFeeTotals, setVariableOursFeeTotals] = useState(0);

  const [notes, setNotes] = useState('');

  useEffect(() => {
    //if we have a total of the fixed 'Current' fees
    if (fixedCurrentFeeTotals) {
      const trueNetIncomeDollars = fixedCurrentFeeTotals - variableCurrentFeeTotals;
      const trueNetIncomePercent =
        (fixedCurrentFeeTotals - variableCurrentFeeTotals) /
        (fixedFeeSchedule.find(fee => fee.type === FeeType.AgentGrossCommission)!
          .totalCostCurrent || 0);
      setCurrentTrueNetIncome(trueNetIncomeDollars);
      setCurrentTrueValueSplit(trueNetIncomePercent);
    }

    //if we at least have a total of fixed 'Our' fees
    if (fixedOursFeeTotals) {
      const trueNetIncomeDollars = fixedOursFeeTotals - variableOursFeeTotals;
      const trueNetIncomePercent =
        (fixedOursFeeTotals - variableOursFeeTotals) /
        (fixedFeeSchedule.find(fee => fee.type === FeeType.AgentGrossCommission)!.totalCostOurs ||
          0);
      setOursTrueNetIncome(trueNetIncomeDollars);
      setOursTrueValueSplit(trueNetIncomePercent);
    }
  }, [
    fixedFeeSchedule,
    fixedCurrentFeeTotals,
    fixedOursFeeTotals,
    variableCurrentFeeTotals,
    variableOursFeeTotals,
  ]);

  return (
    <>
      {agent && volume && (
        <Grid container spacing={2}>
          <Grid item xs={1}>
            {history.length > 0 && (
              <Button onClick={() => backButtonClick()} variant='subtle'>
                <ArrowBack />
              </Button>
            )}
          </Grid>
          <Grid item xs={11}>
            <Box mb={2}>
              <Box fontSize="200%" flexGrow={1}>
                {agent.fullName}
              </Box>
              <Box
                flexGrow={1}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <MonetizationOn style={{ color: ThemeColors.Green }} /> Sales Volume: &nbsp;
                <TextField
                  label=""
                  value={salesVolume}
                  onChange={e => {
                    setSalesVolume(Number(e.target.value));
                  }}
                  startAdornment={<InputAdornment position="start">$</InputAdornment>}
                  _component={CustomCurrencyInputMasker as any}
                />
                &nbsp;
                <House style={{ color: ThemeColors.Green }} /> Transaction Count:{' '}
                {(volume?.totalTransactions.current || 0).toString()}
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} container spacing={1}>
            <Grid item xs={12}>
              <Box mb={2}>
                <RecruitingComparisonCalculatorBarComponent
                  salesVolume={salesVolume || 0}
                  barTitle="Current Company"
                  color={ThemeColors.Magenta}
                  feeSchedule={fixedFeeSchedule}
                  onCalculate={setFixedFeeSchedule}
                  feeCategory={FeeCategory.Current}
                />
              </Box>

              <Box mb={2}>
                <RecruitingComparisonCalculatorBarComponent
                  salesVolume={salesVolume || 0}
                  color={ThemeColors.Green}
                  barTitle="Our Company"
                  feeSchedule={fixedFeeSchedule}
                  onCalculate={setFixedFeeSchedule}
                  feeCategory={FeeCategory.Ours}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <RecruitingFixedFeeScheduleTableComponent
              feeSchedule={fixedFeeSchedule}
              bubbleFixedCurrentFeeTotals={setFixedCurrentFeeTotals}
              bubbleFixedOursFeeTotals={setFixedOursFeeTotals}
            />
          </Grid>
          <Grid item xs={12}>
            <RecruitingVariableFeeScheduleTableComponent
              feeSchedule={variableFeeSchedule}
              setFeeSchedule={setVariableFeeSchedule}
              bubbleVariableCurrentFeeTotals={setVariableCurrentFeeTotals}
              bubbleVariableOursFeeTotals={setVariableOursFeeTotals}
            />
          </Grid>
          <Grid item xs={12}>
            <Box>
              <Card>
                <CardHeader title={'Additional Notes'} />
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Box>
                        <TextField
                          id="outlined-multiline-static"
                          type="textarea"
                          rows={4}
                          value={notes}
                          onChange={e => setNotes(e.target.value)}
                          fullWidth
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <p>&nbsp;</p>

            <NetIncomeSummaryFooterComponent
              currentTrueNetIncome={currentTrueNetIncome}
              currentTrueValueSplit={currentTrueValueSplit}
              oursTrueNetIncome={oursTrueNetIncome}
              oursTrueValueSplit={oursTrueValueSplit}
              agent={agent}
              fixedFeeSchedule={fixedFeeSchedule}
              variableFeeSchedule={variableFeeSchedule}
              fixedCurrentFeeTotals={fixedCurrentFeeTotals}
              fixedOursFeeTotals={fixedOursFeeTotals}
              variableCurrentFeeTotals={variableCurrentFeeTotals}
              variableOursFeeTotals={variableOursFeeTotals}
              notes={notes}
              salesVolume={salesVolume}
              totalTransactions={volume?.totalTransactions.current || 0}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export const NetIncomeValuationPage = withManagerId(asErrorBoundry(NetIncomeValuation));

import { SurveySubmission } from '../../state/surveys';
import { Link } from '../../shared';
import { useAgentLookup } from '../../agents';
import { SubmissionScore } from './submission-score';
import { mlsService } from '../../state';
import { NotInterested, Search } from '@mui/icons-material';
import { Typography, Card, CardHeader, CardContent, Grid } from '@mui/material';
import { Button } from 'shared/components'
import { DataTable, DataTableColumn } from 'shared/components/data-table';

interface Props {
  submissions: Array<SurveySubmission>;
  loading: boolean;
}

export function SubmissionsList({ submissions, loading }: Props) {
  mlsService.getAgents([
    ...submissions.filter(s => !!s.memberKey).map(s => s.memberKey!),
    ...submissions.filter(s => !!s.coMemberKey).map(s => s.coMemberKey!),
  ]);

  const columns: DataTableColumn<SurveySubmission>[] = [
    {
      headerName: 'Submission Date',
      field: 'submisisonDate',
      renderCell: ({ row }) => row.submissionDate ? new Date(row.submissionDate).toLocaleString() : undefined,
      sortable: true,
    },
    {
      headerName: 'Agent',
      field: 'agent',
      renderCell: ({ row }) => <AgentNameCell memberKey={row.memberKey || undefined} />,
      sortable: true,
    },
    {
      headerName: 'Co-Member',
      field: 'coMember',
      renderCell: ({ row }) => <AgentNameCell memberKey={row.coMemberKey || ''} />,
      sortable: true,
    },
    {
      headerName: 'Score',
      field: 'score',
      renderCell: ({ row }) => (
        <Typography>
          {!!row.submissionDate ? (
            <SubmissionScore totalPoints={row.totalPoints || 0} possiblePoints={row.possiblePoints || 0} />
          ) : (
            <>Not Submitted</>
          )}
        </Typography>
      ),
      sortable: true,
    },
    {
      headerName: '',
      field: 'view',
      renderCell: ({ row }) => (
        <Link to={`${row.id}/submission`}>
          <Button>View Submission</Button>
        </Link>
      ),
    },
  ];

  return (
    <Card>
      <CardHeader title="Submissions" />
      <CardContent>
        <DataTable
          columns={columns}
          rows={submissions}
          progressPending={loading}
          noDataComponent={
            <Grid container direction="column" alignItems="center">
              <NotInterested fontSize="large" />
              <Typography>No survey submissions yet</Typography>
            </Grid>
          }
          initialState={{
            sorting: {
              sortModel: [
                {
                  field: 'submisisonDate',
                  sort: 'asc',
                },
              ],
            },
          }}
        />
      </CardContent>
    </Card>
  );
}

interface AgentProps {
  memberKey?: string;
}

function AgentNameCell({ memberKey }: AgentProps) {
  const agents = useAgentLookup();

  if (!memberKey) return <></>;

  const agent = agents.get(memberKey);

  return (
    <Typography>
      <>
        <Link to={`/agents/${memberKey}`} color="primary">
          <>{agent?.fullName}</>
        </Link>
        <a
          href={`https://www.google.com/search?q=${agent?.fullName} Realtor ${agent?.officeName}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Search color="primary"/>
        </a>
      </>
    </Typography>
  );
}
